<div class="sidebar">
  <div class="sidebar-links-container">
    <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.Login)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-column-center-full"
      routerLink="/rooms"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.Login)
            ? 'icon-rooms font-size23 white'
            : 'icon-rooms font-size23 gray'
        ]"
      ></i>
      <p class="text-align-center">{{'Rooms' | translate}}</p>
    </a>

    <a
    [ngClass]="[
      signedInUser.havePermission(swPermissions.Login)
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-row"
    routerLink="/common-area"
    routerLinkActive="sidebar-active-link"

  >
    <i
      [ngClass]="[
        signedInUser.havePermission(swPermissions.Login)
          ? 'icon-corridor font-size23 white'
          : 'icon-corridor font-size23 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'Common areas' | translate}}</p>
  </a>
      <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.LocationGroupsUpdate)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-row"
      routerLink="/groups"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.LocationGroupsUpdate)
            ? 'icon-group font-size23 white'
            : 'icon-group font-size23 gray'
        ]"
      ></i>
      <p class="text-align-center">{{'Groups' | translate}}</p>
    </a>

    <a
    [ngClass]="[
      signedInUser.havePermission(swPermissions.UsersView)
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-row"
    routerLink="/cards"
    routerLinkActive="sidebar-active-link"

  >
    <i
      [ngClass]="[
        signedInUser.havePermission(swPermissions.UsersView)
          ? 'icon-card font-size25 white'
          : 'icon-card font-size25 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'Cards' | translate}}</p>
  </a>


  <a
  [ngClass]="[
    signedInUser.havePermission(swPermissions.LocationSettings)
      ? 'sidebar-link'
      : 'sidebar-link-disabled'
  ]"
  class="flex-row"
  routerLink="/schedule"
  routerLinkActive="sidebar-active-link"

>
  <i
    [ngClass]="[
      signedInUser.havePermission(swPermissions.LocationSettings)
        ? 'icon-automation white font-size23'
        : 'icon-automation gray font-size23'
    ]"
  ></i>
  <p class="text-align-center">{{'Automations' | translate}}</p>
</a>



    <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.AccessLogView)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-row"
      routerLink="/logs/access"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.AccessLogView)
            ? 'icon-access-log white font-size23'
            : 'icon-access-log gray font-size23'
        ]"
      ></i>
      <p class="text-align-center">{{'Access log' | translate}}</p>
    </a>
    <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.AlarmsLogView)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-row"
      routerLink="/logs/alarms"
      routerLinkActive="sidebar-active-link"

    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.AlarmsLogView)
            ? 'icon-alarm_log white font-size23'
            : 'icon-alarm_log gray font-size23'
        ]"
      ></i>
      <p class="text-align-center">{{'Alarm log' | translate}}</p>
    </a>
    <!-- ANALYTICS -->
    <a
      [ngClass]="[
        signedInUser.havePermission(swPermissions.Settings)
          ? 'sidebar-link'
          : 'sidebar-link-disabled'
      ]"
      class="flex-row"
      routerLink="/settings/analytics"
      routerLinkActive="sidebar-active-link"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <i
        [ngClass]="[
          signedInUser.havePermission(swPermissions.Settings)
            ? 'icon-analytics font-size30 white'
            : 'icon-analytics font-size30 gray'
        ]"
      ></i>
      <p class="text-align-center">{{ "Analytics" | translate }}</p>
    </a>
    <!-- SETTINGS -->
    <a
    [ngClass]="[
      signedInUser.havePermission(swPermissions.Settings)
        ? 'sidebar-link'
        : 'sidebar-link-disabled'
    ]"
    class="flex-row"
    routerLink="/settings"
    routerLinkActive="sidebar-active-link"
    [routerLinkActiveOptions]="{ exact: true }"

  >
    <i
      [ngClass]="[
        signedInUser.havePermission(swPermissions.Settings)
          ? 'icon-settings font-size23 white'
          : 'icon-settings font-size23 gray'
      ]"
    ></i>
    <p class="text-align-center">{{'Settings' | translate}}</p>
  </a>
  </div>
</div>
