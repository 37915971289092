import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'propExistInController'
})
export class PropExistInControllerPipe implements PipeTransform {

  transform(controller: Controller, propFunct: Function):  boolean {
    let exists = false;
    controller.controllerProperties.$values.find( prop => {
      let target: Property;
      target = propFunct(prop);
      if (target) {
        exists = true;
        return true;
      }
    })
    
    return exists;
  }


}


