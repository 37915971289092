 <div class="flex-row-center-full rooms-header">
    <div class="tabs">
      <input *ngFor="let controller of controllers;let i = index" class="margin-right10" type="radio" name="tab-control"
        [attr.id]="'tab' + i" [checked]="i === 0">

      <ul>
        <li *ngFor="let controller of controllers;let i = index" (click)="selectController(controller)">
          <label [attr.for]="'tab' + i" role="button">
            {{ controller.name }}
          </label>
        </li>
      </ul>

      <div class="bottom-slider" [ngClass]="getIndicatorWidth()">
        <div class="indicator"></div>
      </div>
    </div>
  </div>

  <ion-card class="data-container">
    <ion-card-title class="flex-row-center-h width100 padding5 background-red">
      {{'Alarms' | translate}}
    </ion-card-title>

      <div class="hide-when-not-alone width100 flex-center height40">
        <div>{{'No alarms' | translate}}</div>
      </div>

      <div class="property-list">
        <ng-container *ngIf="
            (selectedControllers | findProperty: Property.isSOS | propIsActive) ||
            (selectedControllers | findProperty: Property.isBurglary | propIsActive) ||
            (selectedControllers | findProperty: Property.isSafeBurglary | propIsActive)
          ">
              <div class="single-prop" *ngIf="selectedControllers | findProperty: Property.isBurglary | propIsActive">
                <div class="facility-text">{{'Burglary detected' | translate}}</div>
                <div title="Burglary detected" class="text-align-center margin-auto">
                  {{'Burglary' | translate}}
                </div>
              </div>
              <ng-container *ngIf="selectedControllers | findProperty: Property.isSOS as sosProps">
                <ng-container *ngFor="let sos of sosProps">
                  <div *ngIf="sos | propIsActive" class="single-prop">
                    <div class="facility-text">{{ sos.name }}</div>
                    <div title="SOS" class="i-sos-alarm facility-icon"></div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="
                  selectedControllers
                    | findProperty: Property.isSafeBurglary as safeBurglaryProps
                ">
                <ng-container *ngFor="let safeBurglaryProp of safeBurglaryProps">
                  <div *ngIf="safeBurglaryProp | propIsActive" class="single-prop">
                    <div class="facility-text">{{ safeBurglaryProp.name }}</div>
                    <div title="Safe burglary detected" class="icon-safe font-size28 red facility-icon"></div>
                  </div>
                </ng-container>
              </ng-container>

        </ng-container>
        <ng-container *ngIf="
            (selectedControllers | findProperty: Property.isFlood | propIsActive) ||
            (selectedControllers
              | findProperty: Property.isBurglaryDetectedByPIR
              | propIsActive) ||
            (selectedControllers | findProperty: Property.isSafeCommError | propIsActive) ||
            (selectedControllers
              | findProperty: Property.isBurglaryDetectedDoor
              | propIsActive) ||
            (selectedControllers
              | findProperty: Property.isBurglaryDetectedWindow
              | propIsActive) ||
            (selectedControllers | findProperty: Property.isHVACLargeTempDiffRoom | propIsActive) ||
            (selectedControllers | findProperty: Property.isHVACLargeTempDiffBathroom | propIsActive) ||

            (selectedControllers
              | findProperty: Property.isDoorOpenTooLongAlarm
              | propIsActive) ||
            (selectedControllers
              | findProperty: Property.isWindowOpenTooLongAlarm
              | propIsActive)
          ">

          <ng-container *ngIf="selectedControllers | findProperty: Property.isFlood as isFloodProps">
            <ng-container *ngFor="let floodProp of isFloodProps">
              <div *ngIf="floodProp | propIsActive" class="single-prop">
                <div class="facility-text">{{ floodProp.name }}</div>
                <div title="Flood detected" class="icon-flood-alarm font-size20 facility-icon"></div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="
              selectedControllers
                | findProperty
                  : Property.isBurglaryDetectedByPIR as isBurglaryDetectedByPIRProps
            ">
            <ng-container *ngFor="
                let burglaryDetectedByPIRProp of isBurglaryDetectedByPIRProps
              ">
              <div *ngIf="burglaryDetectedByPIRProp | propIsActive" class="single-prop">
                <div class="facility-text">
                  {{ burglaryDetectedByPIRProp.name }}
                </div>
                <div title="Burglary detected by PIR" class="text-align-center margin-auto">
                  {{'Burglary' | translate}} <br />
                  PIR
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="
              selectedControllers | findProperty: Property.isSafeCommError as safeCommErrorProps
            ">
            <ng-container *ngFor="let safeCommErrorProp of safeCommErrorProps">
              <div *ngIf="safeCommErrorProp | propIsActive" class="single-prop">
                <div class="facility-text">{{ safeCommErrorProp.name }}</div>
                <div title="Safe Comm Error" class="text-align-center margin-auto">
                  {{'Safe Comm' |translate}} <br />
                  {{'Error' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="
              selectedControllers
                | findProperty
                  : Property.isBurglaryDetectedDoor as isBurglaryDetectedDoorProps
            ">
            <ng-container *ngFor="
                let burglaryDetectedDoorProp of isBurglaryDetectedDoorProps
              ">
              <div *ngIf="burglaryDetectedDoorProp | propIsActive" class="single-prop">
                <div class="facility-text">
                  {{ burglaryDetectedDoorProp.name }}
                </div>
                <div title="Burglary detected door" class="text-align-center margin-auto">
                  {{'Burglary door' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="
              selectedControllers
                | findProperty
                  : Property.isBurglaryDetectedWindow as burglaryDetectedWindowProps
            ">
            <ng-container *ngFor="
                let burglaryDetectedWindowProp of burglaryDetectedWindowProps
              ">
              <div *ngIf="burglaryDetectedWindowProp | propIsActive" class="single-prop">
                <div class="facility-text">
                  {{ burglaryDetectedWindowProp.name }}
                </div>
                <div title="Burglary detected window" class="text-align-center margin-auto">
                  {{'Burglary window' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="
              selectedControllers
                | findProperty: Property.isDoorOpenTooLongAlarm as doorOpenTooLongProps
            ">
            <ng-container *ngFor="let doorOpenTooLongProp of doorOpenTooLongProps">
              <div *ngIf="doorOpenTooLongProp | propIsActive" class="single-prop">
                <div class="facility-text">
                  {{ doorOpenTooLongProp.name }}
                </div>
                <div title="Door open too long" class="text-align-center margin-auto" >
                  {{'Door open too long' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="
              selectedControllers
                | findProperty
                  : Property.isWindowOpenTooLongAlarm as windowOpenTooLongProps
            ">
            <ng-container *ngFor="let windowOpenTooLongProp of windowOpenTooLongProps">
              <div *ngIf="windowOpenTooLongProp | propIsActive" class="single-prop">
                <div class="facility-text">
                  {{ windowOpenTooLongProp.name }}
                </div>
                <div title="Window open too long" class="text-align-center margin-auto">
                  {{'Win. open too long' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="
              selectedControllers
                | findProperty: Property.isHVACLargeTempDiffRoom as hvacLargeTempDiffRoomProps
            ">
            <ng-container *ngFor="let hvacLargeTempDiffRoomProp of hvacLargeTempDiffRoomProps">
              <div *ngIf="hvacLargeTempDiffRoomProp | propIsActive" class="single-prop">
                <div class="facility-text">{{ hvacLargeTempDiffRoomProp.name }}</div>
                <div title="Hvac large temperature difference room" class="text-align-center margin-auto">
                  {{'Hvac large' |translate}} <br />{{'temp. diff. room' |translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="
          selectedControllers
            | findProperty: Property.isHVACLargeTempDiffBathroom as hvacLargeTempDiffBathroomProps
        ">
        <ng-container *ngFor="let hvacLargeTempDiffBathroomProp of hvacLargeTempDiffBathroomProps">
          <div *ngIf="hvacLargeTempDiffBathroomProp | propIsActive" class="single-prop">
            <div class="facility-text">{{ hvacLargeTempDiffBathroomProp.name }}</div>
            <div title="Hvac large temperature difference bathroom" class="text-align-center margin-auto">
              {{'Hvac large' |translate}} <br />{{'temp. diff. bathroom' |translate}}
            </div>
          </div>
        </ng-container>
      </ng-container>
        </ng-container>
      </div>

  </ion-card>

  <ion-card class="data-container">
    <ion-card-title class="flex-row-center-h width100 padding5 background-blue">
      {{'Statuses' | translate}}
    </ion-card-title>
    <div class="property-list">
      <ng-container *ngIf="selectedControllers | findProperty: Property.isDndStatus as dndProps">
        <ng-container *ngFor="let dnd of dndProps">
          <div class="single-prop">
            <div class="facility-text">{{ dnd.name }}</div>
            <div *ngIf="dnd | propIsActive; else dndOff" title="Do not disturb" class="icon-dnd font-size20 red facility-icon"></div>
            <ng-template #dndOff>
              <div title="Do not disturb not active" class="icon-dnd font-size20 gray facility-icon"></div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>

      <div class="single-prop" *ngIf="selectedControllers | findProperty: Property.isRoomArmed as isRoomArmedProp">
        <ng-container *ngIf="isRoomArmedProp | propIsActive; else roomArmedInactive">
          <div class="facility-text">{{'Room armed' | translate}}</div>
          <div title="Room armed" class="icon-room-armed font-size25 facility-icon"></div>
        </ng-container>
        <ng-template #roomArmedInactive>
          <div class="facility-text">{{'Room not armed' | translate}}</div>
          <div title="Room not armed" class="icon-room-armed font-size25 gray facility-icon"></div>
        </ng-template>
      </div>
      <ng-container *ngIf="
            selectedControllers | findProperty: Property.isCardDeposited as cardDepositedProps
          ">
        <ng-container *ngFor="let cardDepositedProp of cardDepositedProps">
          <div class="single-prop">
            <div class="facility-text">{{ cardDepositedProp.name }}</div>
            <div *ngIf="cardDepositedProp | propIsActive; else cardNotDeposited" title="Card deposited"
              class="icon-card font-size25 facility-icon"></div>
            <ng-template #cardNotDeposited>
              <div title="Card not deposited" class="icon-card font-size25 gray facility-icon"></div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedControllers | findProperty: Property.isCardType as cardTypeProps">
        <ng-container *ngFor="let cardTypeProp of cardTypeProps">
          <div class="single-prop">
            <div class="facility-text">{{ cardTypeProp.name }}</div>
            <div [title]="cardTypeProp.value" class="text-align-center margin-auto">
              {{ cardTypeProp.value | getCardTypeNameByCode: cardTypes }}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedControllers | findProperty: Property.isMakeUpRoom as makeUpRoomProps">
        <ng-container *ngFor="let makeUpRoomProp of makeUpRoomProps">
          <div class="single-prop">
            <div class="facility-text">{{ makeUpRoomProp.name }}</div>
            <div *ngIf="makeUpRoomProp | propIsActive; else makeUpRoomOff">
              <div title="Make up room" class="text-align-center margin-auto">{{'Yes' | translate}}</div>
            </div>
            <ng-template #makeUpRoomOff>
              <div title="Make up room inactive" class="text-align-center opacity-05 margin-auto">
                {{'No' | translate}}
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedControllers | findProperty: Property.isNewCard as newCardProps">
        <ng-container *ngFor="let newCardProp of newCardProps">
          <div class="single-prop">
            <div class="facility-text">{{ newCardProp.name }}</div>
            <div *ngIf="newCardProp | propIsActive; else noNewCard">
              <div title="New card" class="text-align-center margin-auto">{{'Yes' | translate}}</div>
            </div>
            <ng-template #noNewCard>
              <div title="No new card" class="text-align-center opacity-05 margin-auto">
                {{'No' | translate}}
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </div>

  </ion-card>

  <ion-card class="data-container">
    <ion-card-title class="flex-row-center-h width100 padding5 background-blue">
      {{'Doors and Windows' | translate}}
    </ion-card-title>
    <div class="property-list">

      <ng-container *ngIf="selectedControllers | findProperty: Property.isWindowOpened as windowProps">
        <ng-container *ngFor="let window of windowProps">
          <div class="single-prop">
            <div class="facility-text">{{ window.name }}</div>
            <div *ngIf="window | propIsActive; else windowClosed" title="Window open" class="icon-window-open font-size20 facility-icon">
            </div>
            <ng-template #windowClosed>
              <div title="Window closed" class="icon-window-closed font-size20 facility-icon"></div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedControllers | findProperty: Property.isDoorOpened as doorProps">
        <ng-container *ngFor="let door of doorProps">
          <div class="single-prop">
            <div class="facility-text">{{ door.name }}</div>
            <div *ngIf="door | propIsActive; else doorClosed" title="Door open" class="icon-door_opened_status font-size25 facility-icon"></div>
            <ng-template #doorClosed>
              <div title="Door closed" class="icon-door font-size25 facility-icon"></div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ion-card>

  <ion-card class="data-container">
    <ion-card-title class="flex-row-center-h width100 padding5 background-blue">
      HVAC
    </ion-card-title>
    <div class="property-list">
      <ng-container *ngIf="
          selectedControllers
            | findProperty
              : Property.isRoomTemperatureCurrent as roomTemperatureCurrentProps">
        <ng-container *ngFor="let roomTemperatureCurrentProp of roomTemperatureCurrentProps">
          <div class="single-prop">
            <div class="facility-text">{{ roomTemperatureCurrentProp.name }}</div>
            <div class="flex-center margin-auto">
              <span>{{ roomTemperatureCurrentProp.value }} </span><span>°C</span>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
        selectedControllers
          | findProperty: Property.isRoomTemperatureSet as roomTemperatureSetProps
      ">
        <ng-container *ngFor="let roomTemperatureSetProp of roomTemperatureSetProps">
          <div class="single-prop">
            <div class="facility-text">{{ roomTemperatureSetProp.name }}</div>
            <div class="flex-center margin-auto">
              <span>{{ roomTemperatureSetProp.value }} </span><span>°C</span>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedControllers | findProperty: Property.isHvacActive as hvacProps">
        <ng-container *ngFor="let hvac of hvacProps">
          <div class="single-prop">
            <div class="facility-text">{{ hvac.name }}</div>
            <div *ngIf="hvac | propIsActive; else hvacOff" title="Hvac on" class="flex-center margin-auto">{{'On' | translate}}</div>
            <ng-template #hvacOff>
              <div title="Hvac off" class="i-hvac-off facility-icon"></div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>

      <ng-container
      *ngIf="
        selectedControllers
          | findProperty: Property.isHvacHeatCool as hvacHeatingCoolingProps
      "
    >
      <ng-container
        *ngFor="let hvacHeatingCoolingProp of hvacHeatingCoolingProps"
      >
        <div class="single-prop">
          <div class="facility-text">{{ hvacHeatingCoolingProp.name }}</div>
          <div *ngIf="hvacHeatingCoolingProp | propIsActive" class="margin-auto">
            <div
              title="Cooling (HVAC H/C Mode true)"
              class="text-align-center"
            >
              {{'COOL' | translate}}
            </div>
          </div>
          <div *ngIf="!(hvacHeatingCoolingProp | propIsActive)" class="margin-auto">
            <div
              title="Heating (HVAC H/C Mode false)"
              class="text-align-center"
            >
              {{'HEAT' | translate}}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

          <div
          class="single-prop"
          *ngIf="selectedControllers | findProperty: Property.isFancoilV1 as isFancoilV1Prop"
        >
          <ng-container *ngIf="isFancoilV1Prop | propIsActive; else fanCoilV1Off">
            <div class="facility-text">{{'Fan V1 on' | translate}}</div>
            <div title="Fancoil V1 on" class="text-align-center margin-auto">{{'Fan V1 on' |translate}}</div>
          </ng-container>
          <ng-template #fanCoilV1Off>
              <div class="facility-text">{{'Fan V1 off' | translate}}</div>
              <div title="Fancoil V1 off" class="text-align-center opacity-05 margin-auto">
                {{'Fan V1 off' | translate}}
              </div>
          </ng-template>
        </div>


      <div
          class="single-prop"
          *ngIf="selectedControllers | findProperty: Property.isFancoilV2 as isFancoilV2Prop"
        >
          <ng-container *ngIf="isFancoilV2Prop | propIsActive; else fanCoilV2Off">
            <div class="facility-text">{{'Fan V2 on' | translate}}</div>
            <div title="Fancoil V2 on" class="text-align-center margin-auto">{{'Fan V2 on' | translate}}</div>
          </ng-container>
          <ng-template #fanCoilV2Off>
              <div class="facility-text">{{'Fan V2 off' | translate}}</div>
              <div title="Fancoil V2 off" class="text-align-center opacity-05  margin-auto">
                {{'Fan V2 off' | translate}}
              </div>
          </ng-template>
        </div>
        <div
          class="single-prop"
          *ngIf="selectedControllers | findProperty: Property.isFancoilV3 as isFancoilV3Prop"
        >
          <ng-container *ngIf="isFancoilV3Prop | propIsActive; else fanCoilV3Off">
            <div class="facility-text">{{'Fan V3 on' | translate}}</div>
            <div title="Fancoil V3 on" class="text-align-center margin-auto">{{'Fan V3 on' | translate}}</div>
          </ng-container>
          <ng-template #fanCoilV3Off>
              <div class="facility-text">{{'Fan V3 off' | translate}}</div>
              <div title="Fancoil V3 off" class="text-align-center opacity-05 margin-auto">
                {{'Fan V3 off' | translate}}
              </div>
          </ng-template>
        </div>
        <ng-container
        *ngIf="
          selectedControllers
            | findProperty: Property.isRoomFloorHeating as isRoomFloorHeatingProps
        "
      >
        <ng-container
          *ngFor="let roomFloorHeatingProp of isRoomFloorHeatingProps"
        >
          <div class="single-prop">
            <div class="facility-text">{{ roomFloorHeatingProp.name }}</div>
            <ng-container
              *ngIf="
                roomFloorHeatingProp | propIsActive;
                else roomFloorHeatingPropOff
              "
            >
              <div title="Room floor heating on" class="text-align-center margin-auto">
                {{'On' | translate}}
              </div>
            </ng-container>
            <ng-template #roomFloorHeatingPropOff>
              <div
                title="Room floor heating off"
                class="text-align-center opacity-05  margin-auto"
              >
                {{'Off' | translate}}
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <ng-container
      *ngIf="
        selectedControllers
          | findProperty: Property.isBathroomRadiator as isBathroomRadiatorProps
      "
    >
      <ng-container
        *ngFor="let bathroomRadiatorProp of isBathroomRadiatorProps"
      >
        <div class="single-prop">
          <div class="facility-text">{{ bathroomRadiatorProp.name }}</div>
          <div
            *ngIf="
              bathroomRadiatorProp | propIsActive;
              else bathroomRadiatorPropOff
            "
          >
            <div title="Bathroom radiator on" class="text-align-center margin-auto">
              {{'On' | translate}}
            </div>
          </div>
          <ng-template #bathroomRadiatorPropOff>
            <div
              title="Bathroom radiator off"
              class="text-align-center opacity-05 margin-auto"
            >
              {{'Off' | translate}}
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>

    </div>
  </ion-card>


  <div class="flex-row-space-evenly margin-top30">
    <ion-item lines="none">
      <ion-checkbox color="primary"></ion-checkbox>
      {{'Option' | translate}} 1
    </ion-item>

    <ion-item lines="none">
      <ion-checkbox color="primary"></ion-checkbox>
      {{'Option' | translate}} 2
    </ion-item>

    <ion-item lines="none">
      <ion-checkbox color="primary"></ion-checkbox>
      {{'Option' | translate}} 3
    </ion-item>

    <ion-item lines="none">
      <ion-checkbox color="primary"></ion-checkbox>
      {{'Option' | translate}} 4
    </ion-item>
  </div>

  <div class="flex-row-center-h">
    <ion-button color="primarđ" fill="outline" (click)="confirm()">{{'Confirm' | translate}}</ion-button>
  </div>








  <!-- <div *ngIf="selectedControllers" class="property-list">
    <div *ngFor="let propToDisplay of (selectedPropertyDisplayConfig$ | async).data" class="single-prop" [ngClass]="isBoxShadow(propToDisplay)">
        <ng-container *ngIf="propToDisplay.mode === 'singlePropType'">
          <ng-container
            *ngIf="
            selectedControllers
                | findPropertyByCode
                  : propToDisplay.propInfo.codeFrom
                  : propToDisplay.propInfo.codeTo as properties
            "
          >
            <ng-container
              *ngIf="
                properties
                  | propertiesAreActive: propToDisplay.propInfo.activeCondition;
                else propertiesNotActive">
                <div class="facility-icon" [ngClass]="propToDisplay.propInfo.activeIcon"></div>
                <div class="facility-text">{{ propToDisplay.propInfo.activeName }}</div>
            </ng-container>
            <ng-template #propertiesNotActive>
                <div class="facility-icon" [ngClass]="propToDisplay.propInfo.inactiveIcon"></div>
                <div class="facility-text">{{ propToDisplay.propInfo.inactiveName }}</div>
              </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="propToDisplay.mode === 'numericalPropType'">
          <ng-container
            *ngIf="
            selectedControllers
                | findPropertyByCode
                  : propToDisplay.propInfo.codeFrom
                  : propToDisplay.propInfo.codeTo as properties">

                <div class="facility-icon" [ngClass]="propToDisplay.propInfo.activeIcon"></div>
                <div class="facility-text">
                  <ng-container
                    *ngIf="
                      properties[0].value !== null && properties[0].value !== undefined;
                      else noTemp">{{ properties[0].value }}
                    <ng-container>
                      {{ propToDisplay.propInfo.sufix }}
                    </ng-container></ng-container>
                  <ng-template #noTemp> - </ng-template>
                </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="propToDisplay.mode === 'status'">
          <ng-container
            *ngIf="
            selectedControllers
                | statusIsActive
                  : propToDisplay.propInfo.statusName
                  : propToDisplay.propInfo.activeCondition;
              else statusNotActive">
              <div class="facility-icon" [ngClass]="[propToDisplay.propInfo.activeIcon]"></div>
              <div class="facility-text">
                {{ propToDisplay.propInfo.activeName }}
              </div>
          </ng-container>
          <ng-template #statusNotActive>
              <div class="facility-icon" [ngClass]="[propToDisplay.propInfo.inactiveIcon]"></div>
              <div class="facility-text">
                {{ propToDisplay.propInfo.inactiveName }}
              </div>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="propToDisplay.mode === 'custom(fanSpeed)'">
          <ng-container
            *ngIf="
            selectedControllers
                | getFirstControllerWithProperty: isFancoilSpeed
                | findProperty: isFancoilSpeed as fanCoilSpeedProps">
            <ng-container *ngIf="fanCoilSpeedProps | getActiveProps as activeFanCoilSpeedProps">
                <div class="facility-icon icon-fan font-size19 white"></div>
                <div
                  *ngIf="activeFanCoilSpeedProps.length > 0" class="facility-text">
                  <ng-container *ngIf="isFancoilV1(activeFanCoilSpeedProps[0])">
                    V1
                  </ng-container>
                  <ng-container *ngIf="isFancoilV2(activeFanCoilSpeedProps[0])">
                    V2
                  </ng-container>
                  <ng-container *ngIf="isFancoilV3(activeFanCoilSpeedProps[0])">
                    V3
                  </ng-container>
                </div>

                <div *ngIf="activeFanCoilSpeedProps.length === 0" class="facility-text">
                  Off
                </div>
            </ng-container>
          </ng-container>
        </ng-container>
    </div>
  </div> -->
