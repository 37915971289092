import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Controller } from 'src/app/core/models/controller.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model';
import { API_BASE_URL } from 'src/environments/environment';
import { ProjectService } from '../project.service';
import { LocationGroup } from 'src/app/shared/services/api-location-groups.service';
import { ControllerFilters } from 'src/app/core/models/view-filter.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';


export interface KeyOption {
  controller: Controller;
  objectId: number;
  subObjectId: number;
  floor: LocationGroup;
  checked: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiProjectService {

  projectReady$ =  new AsyncSubject<boolean>();
  // filterSUb: Subscription

  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
    ) { }



    getObjects() {
      return this.http.get<ProjectObject[]>(API_BASE_URL + '/project/objects').pipe(
          tap(objects => {
              this.projectService.setObjects(objects);
          })
      );
    }

    getSubobjects() {
      return this.http.get<ProjectSubObject[]>(API_BASE_URL + '/project/subobjects').pipe(
          tap(subObjects => {
              this.projectService.setSubObjects(subObjects);
          })
      );
    }


 /*  isProjectLoaded() {
    return this.projectReady$.asObservable();
  } */

  /* return this.settingsService.getFrontendSettings().pipe(take(1), switchMap((frontendSettings: FrontendSettings)=> {
    const updatedFrontendSettings: FrontendSettings = JSON.parse(JSON.stringify(frontendSettings));
    updatedFrontendSettings.settings.defaultCheckInTime = defaultCheckinTime;
    updatedFrontendSettings.settings.defaultCheckoutTime = defaultCheckoutTime;
    return this.updateFrontendSettings(updatedFrontendSettings);
  })) */

  /* getFilteredProjectFat() {
    return this.filtersService.getFilters().pipe(take(1), switchMap((filters: ControllerFilters)=> {
      return this.http.post<Project>(API_BASE_URL + 'project/controllers/full', filters).pipe(
        tap( project => {
            // project.controllers = this.sortProject(project);
            this.projectService.setProject(project);
            this.settingsService.setupViewConfigs(project);
            this.projectReady$.next(true);
            this.projectReady$.complete();
        })
      );
    })) */

    getFilteredControllersFat(filters: ControllerFilters, storeResponse = true) {
      return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers/full', filters).pipe(
        tap( controllers => {
            if (storeResponse) {
              this.projectService.setFilteredControllersFat(controllers)
            }
        })
      );
  }

/*   getFilteredCommonAreaControllersFat(filters: ControllerFilters) {
    return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers/full', filters).pipe(
      tap( controllers => {
          // project.controllers = this.sortProject(project);
          // this.projectService.setProject(project);
          this.projectService.setFilteredControllersFat(controllers)
          // this.settingsService.setupViewConfigs(controllers);
          // this.projectReady$.next(true);
          // this.projectReady$.complete();
      })
    );
} */

  getAllControllersFat() {
    const filters = new ControllerFilters(['Room2','AccessControl_v1', 'IOCommonArea', 'HvacCommonArea']);
    return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers/full', filters).pipe(
      tap( controllers => {
          // project.controllers = this.sortProject(project);
          // this.projectService.setProject(project);
          this.projectService.setAllControllersFat(controllers)
      })
    );
  /* return this.http.post<Project>(API_BASE_URL + 'project/controllers/full', {}).pipe(
    tap( project => {
        // project.controllers = this.sortProject(project);
        this.projectService.setProject(project);
        this.settingsService.setupViewConfigs(project);
        this.projectReady$.next(true);
        this.projectReady$.complete();
    })
  ); */
}

getAllControllersThin() {
  const filters = new ControllerFilters(['Room2','AccessControl_v1', 'IOCommonArea', 'HvacCommonArea']);
  return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers', filters).pipe(
    tap( controllers => {
        this.projectService.setAllControllersThin(controllers)
    })
  );
}

getFilteredControllersThin(filters: ControllerFilters) {
  return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers', filters)
  }

getControllersByDesignation(object: string, subobject: string, zone: string) {
  return this.http.get<Controller[]>(API_BASE_URL +
    `/project/controllers/byDesignation?designation=${object}/${subobject}/${zone}/*`)
}

getAllAccessControlsThin() {
  const filters = new ControllerFilters(['AccessControl_v1']);
  return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers', filters);
/* return this.http.post<Project>(API_BASE_URL + 'project/controllers/full', {}).pipe(
  tap( project => {
      // project.controllers = this.sortProject(project);
      this.projectService.setProject(project);
      this.settingsService.setupViewConfigs(project);
      this.projectReady$.next(true);
      this.projectReady$.complete();
  })
); */
}
getControllersThin(filters: ControllerFilters) {
  return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers', filters)
}

getFilteredControllers(filters: ControllerFilters) {
  return this.http.post<Controller[]>(API_BASE_URL + '/project/controllers/full', filters).pipe(
  tap( controllers => {
      return controllers;
  }));
}


 /*  getProject() {
    return this.http.get<Project>(API_BASE_URL + '/project').pipe(
      tap( project => {
          // project.controllers = this.sortProject(project);
          this.projectService.setProject(project);
          this.settingsService.setupViewConfigs(project);
          this.projectReady$.next(true);
          this.projectReady$.complete();
      })
    );
  } */

   /* reduceProjectByUserPermissions(user: User) {
    const viewAll = user.permissions.find( perm => perm.name.includes('SW_Object_View_All'));
    let viewAllFromRole = false;
    if (user.roles) {
      user.roles.some( role => {
        viewAllFromRole = role.permissions.some( perm => perm.name.includes('SW_Object_View_All'))
        if (viewAllFromRole) { return true;}
      })
    }

    if (!viewAll && !viewAllFromRole ) { // if user doesn't have all permissions
      const permissions = [];
      user.permissions.forEach( perm => {
        if (perm.name.includes('SW_Object_View')) {
          const object = perm.name.substring(perm.name.length - 3).split('_');
          permissions.push(object)
        }
      })

      if (user.roles) {
        user?.roles.forEach (role => {
          role.permissions.forEach (perm => {
            if (perm.name.includes('SW_Object_View')) {
              const object = perm.name.substring(perm.name.length - 3).split('_');
              if (!permissions.includes(object)) {
                permissions.push(object)
              }
            }
          })
        })
      }

      this.projectService.getProject().pipe(take(1)).subscribe(  project => {
        project.controllers.forEach( (cont) => {
            cont[1] = cont[1].filter( (object) => {
            if (this.checkForPermission(permissions, object, 0)) {
                object[1] = object[1].filter( subObject => {
                  if (this.checkForPermission(permissions,subObject, 1)) {
                    return true;
                  } else {
                    return false
                  }
                })
              return true
            } else {
              return false
            }
          })
        })
        project.controllers = project.controllers.filter( cont => cont[1].length !== 0)
        this.projectService.setObjects(project)
        this.projectService.initializeSelectedObjectFromStorage(user);
        this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.FloorGroup).subscribe(()=> {
          this.roomsOverviewService.initializeFloorFilterFromStorage(user);
        });
      })
    } else { // if user has ALL permissions
      this.projectService.getProject().pipe(take(1)).subscribe( project => {
        this.projectService.setObjects(project);
        this.projectService.initializeSelectedObjectFromStorage(user);
        this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.FloorGroup).subscribe(()=> {
          this.roomsOverviewService.initializeFloorFilterFromStorage(user);
        });
      });
    }
    // this.filterSUb.unsubscribe()
  } */

 /*  checkForPermission(perms: any, building: any, position: number ) {
    let doesExist = false;
    perms.forEach( perm => {
      if (perm[position] === building[0]) {
        doesExist = true;
      }
    })
    return doesExist;
  } */


  /* private sortProject(project: Project) {
    let targetDrv , targetObj, targetSubObj, targetZone;

    let tempControllers = [];
    project.controllers.forEach( (controller: Controller) => {
      if (tempControllers.length === 0) {
        tempControllers.push([controller.driverPrefix.toLowerCase(), []])
        targetDrv = tempControllers[tempControllers.length - 1];
      } else {
          targetDrv = tempControllers.find( array => {
            if (array[0] === controller.driverPrefix.toLowerCase()) {
              return true;
            }
          })

          if (targetDrv === undefined) {
            tempControllers.push([controller.driverPrefix.toLowerCase(), []])
            targetDrv = tempControllers[tempControllers.length - 1];          }
        }

        targetObj = targetDrv[1].find( array => {
          if (array[0] === controller.object) return true;
        })

        if (targetObj === undefined) {
          targetDrv[1].push([controller.object, []])
          targetObj = targetDrv[1][targetDrv[1].length - 1];
        }

        targetSubObj = targetObj[1].find( array => {
          if (array[0] === controller.subObject) return true;
        })

        if (targetSubObj === undefined) {
          targetObj[1].push([controller.subObject, []])
          targetSubObj = targetObj[1][targetObj[1].length - 1];
        }

        targetZone = targetSubObj[1].find( array => {
          if (array[0] === controller.zone) return true;
        })

        if (targetZone === undefined) {
          targetSubObj[1].push([controller.zone, []])
          targetZone = targetSubObj[1][targetSubObj[1].length - 1]
        }
        targetZone[1].push([controller.subZone, controller])
      })

      return tempControllers;
  } */

  changeProperty(designation: string, propertyId: number, newValue: number) {
        const headersVariable: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
        .set('designation', designation)
        .set('propertyId', propertyId.toString())
        .set('value', newValue.toString());
        const body = '';
        return this.http.post(`${API_BASE_URL}/equipment/`, `"${body}"`, { params, responseType: 'text', headers: headersVariable }
        );
  }

  changeMultiplePropertiesByGroup(groupId: number, body){
    return this.http.post(API_BASE_URL + `/equipment/group/multiple/${groupId}`, body, { responseType: 'text'})
  }

  changeMultiplePropertiesByLocation(designation: string /* 0/0/101/0 */, body){
    const httpParams = new HttpParams()
    .set('designation', designation.toString())
    return this.http.post(API_BASE_URL + `/equipment/multiple`, body, {
       responseType: 'text',
       params: httpParams})
  }

  changeGroup(groupId: number, value: number, typeCode: number) {
    const params = new HttpParams()
    .set('value', value.toString())
    .set('groupId', groupId.toString())
    .set('typeCode', typeCode.toString());
    return this.http.post(`${API_BASE_URL}/equipment/group`, null, {params})
  }

  updateControllers(updatedControllers: Controller[]) {
   return this.http.put(`${API_BASE_URL}/project/controllers`, updatedControllers)/* .pipe(tap(()=> {
     this.projectService.updateControllersInProject(updatedControllers);
   })) */
  }

  getGraph(body: ValueGraph) {
    return this.http.post<any>(API_BASE_URL + '/valuelogs', body).pipe(
        map( response => {
            return response;
        })
    );
}

getNotPolledControllers() {
  return this.http.get<any>(`${API_BASE_URL}/project/notpolling`).pipe(
    map( response => {
      {
        let notPolledControllers = []
        response.forEach( value => {
          notPolledControllers = [...notPolledControllers, ...value.controllersnotpolling]
        })
        this.projectService.setNotPolledControllers(notPolledControllers)
        return notPolledControllers;
      }
    })
  );
}

addToNotPolledControllers(controllers: Controller[]) {
  return this.http.post<any>(`${API_BASE_URL}/project/notpollinglist`, controllers).pipe(
    map( () => {
        this.getNotPolledControllers().subscribe()
    })
  );
}

stopPolling(controller: Controller) {
  return this.http.post<Controller>(`${API_BASE_URL}/project/stoppolling`, controller).pipe(
    map( () => {
      this.getNotPolledControllers().subscribe()
    })
  );
}

resumePolling(controller: Controller) {
  return this.http.post<Controller>(`${API_BASE_URL}/project/resumepolling`, controller).pipe(
    map( () => {
      this.getNotPolledControllers().subscribe()
    })
  );
}


getKeyOptionsAccessControllListForLocationId(locationId: number) {
  return this.http.get<KeyOption[]>(`${API_BASE_URL}/locations/aclistforlocid?locid=${locationId}`)
}

renameObject(body: ProjectObject) {
  return this.http.put(`${API_BASE_URL}/project/object`, body)
}

renameSubObject(body: any) {
  return this.http.put(`${API_BASE_URL}/project/subobject`, body)
}

syncHvacSettings(id: number) {
  return this.http.get<any>(`${API_BASE_URL}/project/controllers/synchvacsettings?id=${id}`)
}

}
