import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';

import { User } from 'src/app/core/models/user/user.model';
import { UserType } from 'src/app/core/models/user/user-type.model';
import { Card } from 'src/app/core/models/card/card.model';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private users: User[] = [];
    usersChanged = new Subject<User[]>();

    nonGuestUsers$ = new BehaviorSubject<User[]>([]);

    private userTypes: UserType[] = [];
    userTypesChanged = new Subject<UserType[]>();

    projectId: number;
    projectIdChanged = new Subject<number>();

    newGuestAdded = new Subject<boolean>();

    constructor() {}

    setUsers(users: User[]) {
        this.users = users.slice();
        this.usersChanged.next(this.users);
    }

    getUsers() {
        return this.users.slice();
    }

    getUser(id: number) {
        if (!this.users) {
            return undefined;
        }
        return this.users.find(user => user.userId === id);
    }

    getUserTypes() {
        return this.userTypes.slice();
    }

    setUserTypes(userTypes: UserType[]) {
        this.userTypes = userTypes.slice();
        this.userTypesChanged.next(this.userTypes);
    }

    updateUser(user: User) {
        const index = this.users.findIndex(userToChange => userToChange.userId === user.userId);
        if (index !== -1) {
            this.users[index] = user;
            this.usersChanged.next(this.users);
        } else {
            this.addUser(user);
        }
    }

    addUser(user: User) {
        this.users.push(user);
        this.usersChanged.next(this.users);
    }

    deleteUser(id: number) {
        const index = this.users.findIndex(user => user.userId === id);
        this.users.splice(index, 1);
        this.usersChanged.next(this.users);
    }

    updateUserCard(card: Card) {
        const userIndex = this.users.findIndex(userToUpdate => userToUpdate.userId === card.userId);
        if (userIndex !== -1) {
            const cardIndex = this.users[userIndex].cards.findIndex(cardToUpdate => cardToUpdate.uid === card.uid);
            this.users[userIndex].cards[cardIndex] = card;
            this.usersChanged.next(this.users);
        } else {
            throwError('User not found');
        }
    }

    updateUserCardLater(card: Card) {
        const userIndex = this.users.findIndex(userToUpdate => userToUpdate.userId === card.userId);
        if (userIndex !== -1) {
            const cardIndex = this.users[userIndex].cardLaters.findIndex(cardToUpdate => cardToUpdate.uid === card.uid);
            this.users[userIndex].cardLaters[cardIndex] = card;
            this.usersChanged.next(this.users);
        } else {
            throwError('User not found');
        }
    }

    activateUserCard(card: Card) {
        const userIndex = this.users.findIndex(userToUpdate => userToUpdate.userId === card.userId);
        if (userIndex !== -1) {
            const cardIndex = this.users[userIndex].cardLaters.findIndex(cardToUpdate => cardToUpdate.uid === card.uid);
            this.users[userIndex].cards.push(this.users[userIndex].cardLaters[cardIndex])
            this.users[userIndex].cardLaters.splice(cardIndex,1)
            this.usersChanged.next(this.users);
        } else {
            throwError('User not found');
        }
    }

    deactivateUserCard(card: Card) {
        const userIndex = this.users.findIndex(userToUpdate => userToUpdate.userId === card.userId);
        if (userIndex !== -1) {
            const cardIndex = this.users[userIndex].cards.findIndex(cardToUpdate => cardToUpdate.uid === card.uid);
            this.users[userIndex].cardLaters.push(this.users[userIndex].cards[cardIndex])
            this.users[userIndex].cards.splice(cardIndex,1)
            this.usersChanged.next(this.users);
        } else {
            throwError('User not found');
        }
    }


    addUserCard(card: Card) {
        const userIndex = this.users.findIndex(userToUpdate => userToUpdate.userId === card.userId);
        if (userIndex !== -1) {
            this.users[userIndex].cards.push(card);
            this.usersChanged.next(this.users);
        } else {
            throwError('User not found');
        }
    }

    deleteUserCard(card: Card) {
        const userIndex = this.users.findIndex(userToUpdate => userToUpdate.userId === card.userId);
        if (userIndex !== -1) {
            let cardIndex: number;
            cardIndex = this.users[userIndex].cards.findIndex(cardToUpdate => cardToUpdate.uid === card.uid);
            if (cardIndex !== -1) {
                this.users[userIndex].cards.splice(cardIndex, 1);
            }
            else if (cardIndex === -1) {
            cardIndex = this.users[userIndex].cardLaters.findIndex(cardToUpdate => cardToUpdate.uid === card.uid);
            this.users[userIndex].cardLaters.splice(cardIndex, 1);
            }
            this.usersChanged.next(this.users);
        } else {
            throwError('User not found');
        }
    }

    setProjectId(projectId: number) {
        this.projectId = projectId;
        this.projectIdChanged.next(this.projectId);
    }

    guestAdded() {
        this.newGuestAdded.next(true);
    }

    getProjectId() {
        return this.projectId;
    }


    setNonGuestUsers(nonGuestUsers: User[]) {
      this.nonGuestUsers$.next(nonGuestUsers);
    }

    getNonGuestUsers() {
      return this.nonGuestUsers$.asObservable();
    }
}
