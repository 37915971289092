import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DaliGateway } from 'src/app/core/models/dali/dali-gateway.model';
import { DaliProject } from 'src/app/core/models/dali/dali-project.model';
import { DaliSceneElement } from 'src/app/core/models/dali/dali-scene-element.model';
import { DaliScene } from 'src/app/core/models/dali/dali-scene.model';
import { Light } from 'src/app/core/models/dali/light.model';
import { RgbLight } from 'src/app/core/models/dali/rgbLight.model';

@Injectable({
  providedIn: 'root'
})
export class DaliProjectService {

  private daliProject$ = new BehaviorSubject<DaliProject>(null)

  constructor() { }

  getDaliProject() {
    return this.daliProject$.asObservable();
  }

  setDaliProject(daliProject: any) {
    this.daliProject$.next(daliProject);
  }

  getDaliLight(daliLightId): Light {
    return  this.daliProject$.getValue()?.listOfLights.find((dLight) => {
      return String(dLight.id) === String(daliLightId);
    });
  }

  getDaliRgbLight(daliLightId): RgbLight {
    return  this.daliProject$.getValue()?.listOfRgbs.find((dLight) => {
      return dLight.id === daliLightId;
    });
  }


  getDaliScene(daliSceneId: string): DaliScene {
    return  this.daliProject$.getValue()?.listOfScenes.find((daliScene: DaliScene) => {
      return String(daliSceneId) === String(daliScene.id);
    });
  }

  getDaliGateway(daliGatewayId): DaliGateway {
    return  this.daliProject$.getValue()?.listOfGateways.find((daliGateway: DaliGateway) => {
      return String(daliGatewayId) === String(daliGateway.id);
    });
  }

  updateDaliProjectByMqtt(daliType: string, daliId: string, payload: string) {
       if (daliType === 'light') {
      const daliLight = this.daliProject$.getValue().listOfLights.find((l: Light) => {
        return String(l.id) === String(daliId);
      });
      if (daliLight) {
        const newValue = payload.split('|')[1];
        daliLight.value = newValue;
      }
    } else if (daliType === 'rgb') {

      const daliRgbLight =  this.daliProject$.getValue().listOfRgbs.find((rgb) => {
        return String(rgb.id) === String(daliId);
      });
      if (daliRgbLight) {
        let newValue: string;
        if (payload.split('|')[1]) {
          newValue = `${payload.split('|')[1]}|${payload.split('|')[2]}`;

        } else {
          newValue = `${payload.split('|')[1]}`;
        }
        daliRgbLight.value = newValue;
      }
    } else  if (daliType === 'scene') {
      const daliScene =  this.daliProject$.getValue().listOfScenes.find((sc) => {
        return String(sc.id) === String(daliId);
      });
      const newValue = payload.split('|')[1];
      if (Number(newValue) === 0) {
        daliScene.active = false;
      } else {
        daliScene.active = true;
      }
    }
    this.setDaliProject(this.daliProject$.getValue());
  }


  getGatewayLights(daliGatewayId: number): Light[] {
    const scenesForGateway = this.daliProject$.getValue().listOfScenes.filter((scene: DaliScene)=> {
      return scene.gatewayId === daliGatewayId;
    })

    const gatewayLightIds = scenesForGateway.reduce((gtwLightIds: number[], scene: DaliScene)=> {
      scene.elements.forEach((elem: DaliSceneElement)=> {
        if (elem.elementType?.toLowerCase() === 'light' && !gtwLightIds.includes(elem.elementId)) {
          gtwLightIds.push(elem.elementId)
        }
      })
      return gtwLightIds;
    },[]);

    gatewayLightIds.sort((a,b)=>  a - b)
    return gatewayLightIds.map((id)=> {
      return this.getDaliLight(id)
    })
  }

  getGatewayRGBLights(daliGatewayId: number): RgbLight[] {
    const scenesForGateway = this.daliProject$.getValue().listOfScenes.filter((scene: DaliScene)=> {
      return scene.gatewayId === daliGatewayId;
    })

    const gatewayRGBLightIds = scenesForGateway.reduce((gtwLightIds: number[], scene: DaliScene)=> {
      scene.elements.forEach((elem: DaliSceneElement)=> {
        if (elem.elementType?.toLowerCase() === 'rgb' && !gtwLightIds.includes(elem.elementId)) {
          gtwLightIds.push(elem.elementId)
        }
      })
      return gtwLightIds;
    },[]);

    gatewayRGBLightIds.sort((a,b)=>  a - b)
    return gatewayRGBLightIds.map((id)=> {
      return this.getDaliRgbLight(id)
    })
  }




}
