import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { GroupedPermission } from 'src/app/core/models/permissions/grouped-permission.model';

@Pipe({
  name: 'userHaveAccessOrAssignPermission'
})
export class UserHaveAccessOrAssignPermissionPipe implements PipeTransform {

  transform(controllers: Controller[], permission: GroupedPermission): boolean {

    if (permission.locationId !== null) {
      let controllerLocationId: string;
      if (permission?.locationPermission) {
        controllerLocationId = permission?.locationPermission?.name?.split(' ')[2];
      }
      if (permission?.assignPermission) {
        controllerLocationId = permission?.assignPermission?.name?.split(' ')[2];
      }
      return this.isLocationAccessControl(controllerLocationId, controllers);
    }
  }

  isLocationAccessControl(locationId: string, controllers: Controller[]) {
    const controller = Controller.getControllerByLocationId(controllers, locationId)
    if (controller) {
      return Controller.isAccessControl(controller);
    }
  }
}
