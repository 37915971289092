import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getFirstControllerWithProperty'
})
export class GetFirstControllerWithPropertyPipe implements PipeTransform {

  transform(controllers: Controller[], filterFunction: (property: Property) => boolean ): Controller[] {
    if (!controllers) {
      return null;    }
    const firstControllerWithProp =  controllers?.find((controller)=> {
      return controller?.controllerProperties.$values?.some((prop: Property) => {
        return filterFunction(prop);
      });
    });
    return [firstControllerWithProp]
  }

}
