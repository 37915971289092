import { Directive, OnInit, HostListener } from '@angular/core';

@Directive({
    selector: '[stickyHeaderDirectiveRooms]'
})
export class StickyHeaderDirectiveRooms implements OnInit {


    ngOnInit() {
    }

    constructor() {}

    @HostListener('scroll', ['$event']) private onScroll($event: any): void {
        sessionStorage.setItem('scrollTopRooms', $event.target.scrollTop);
    }   
}