import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Location } from 'src/app/core/models/project/location.model';
import { API_BASE_URL } from 'src/environments/environment';
import { LocationsService } from './locations.service';

@Injectable({
  providedIn: 'root'
})
export class ApiLocationsService {

  constructor(
    private http: HttpClient,
    private locationsService: LocationsService
    ) {}

  getLocations() {
    return this.http.get<Location[]>(API_BASE_URL + '/locations').pipe(
        tap(locations => {
            this.locationsService.setLocations(locations);
        })
      );
  }

  updateLocations(updatedLocations: Location[]) {
    console.log(updatedLocations);
  }

}
