import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Component({
  selector: 'app-modal-view-boolean-prop',
  templateUrl: './modal-view-boolean-prop.component.html',
  styleUrls: ['./modal-view-boolean-prop.component.scss']
})
export class ModalViewBooleanPropComponent implements OnInit {
  @Input() controllers: Controller[];
  @Input() propFilterFunction: (property: Property) => boolean
  @Input() activeText: string;
  @Input() inactiveText: string;
  @Input() iconActive: string;
  @Input() iconInactive: string;

  constructor() { }

  ngOnInit(): void {
  }

}
