import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { Location } from 'src/app/core/models/project/location.model';

@Component({
  selector: 'app-edit-location-modal',
  templateUrl: './edit-location-modal.component.html',
  styleUrls: ['./edit-location-modal.component.scss']
})

export class EditLocationModalComponent implements OnInit {
  @Input() locationInput: Location;
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();
  location: Location;
  submitted = false;

  constructor(
    ) { }

  ngOnInit(): void {
    // this.location = JSON.parse(JSON.stringify(this.locationInput));
    this.location = structuredClone(this.locationInput);

  }

  clickAllowEveryCardInSystem(value: boolean) {
    if (value === true) {
      this.location.allowEveryCardInSystem = 1;
    } else if (value === false) {
      this.location.allowEveryCardInSystem = 0;
    }
  }

  clickAllowEveryCard(value: boolean) {
    if (value === true) {
      this.location.allowEveryCard = 1;
    } else if (value === false) {
      this.location.allowEveryCard = 0;
    }
   }

   decisionClick(choice: boolean) {
     if (choice === true ) {
      // this.modalController.dismiss(this.location);
      // TODO update location
    } else {
      this.cancelEvent.next(true);
      // this.modalController.dismiss();
    }
   }

   /* async openDeleteModal(location: Location) {
    const alert = await this.alertController.create({
      header: 'Confirm delete',
      message: `Delete location ${location.name} ?`,
      buttons: [ {
        text: 'Cancel',
        role: 'cancel',
      },
        {
          text: 'Delete',
          handler: () => {
            this.deleteLocation(location);
          }
        }
      ],
    });
    await alert.present();
   } */


   /* deleteLocation(location: Location) {
     console.log("delete location")
    console.log(location);
    this.modalController.dismiss();
   } */


}
