import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'searchControllers'
})
export class SearchControllersPipe implements PipeTransform {

  transform(controllers: Controller[][], keyword: string):  Controller[][] {
    return controllers.filter((roomControllers: Controller[]) => {
      return roomControllers.some((cont) => {
        const zone = cont.designation.split('/')[2];
        return zone.startsWith(keyword);
      });
    });
  }

}


