export class  SsFilterSort {
  filtering: string = null;
  sorting: string = null;
  dateTimeFrom: Date | string = null;
  dateTimeTo: Date | string = null;
  users?: Array<number> = [];
  uids?: Array<string> = []
  confirmUsers?: Array<number> = [];
  levels?: Array<number> = [];
  types?: Array<number> = [];
  confirmed?: Array<number> = [];
  events?: Array<number> = [];
  locationDesignations?: Array<string> = [];
  ipAddresses?: Array<string> = [];
  eventCodes?: Array<string> = []
}