import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'showSubobject'
})
export class ShowSubobjectPipe implements PipeTransform {
  constructor() {}

    transform(controllers: Controller[], floorList: LocationGroup[], /* object, subobject,
       controllerType: 'isGuestRoom' | 'isAccessControl' | 'isIOCommonArea' | 'isHVacCommonArea', filters: ControllerFilters */ ): boolean {
      if (floorList.length === 0) {
       return false;
     }

     const subObjectHasRoomsOnAnyFloor =  floorList.some((floor)=> {
      return controllers.some((cont)=> {
        return floor.locationIds.includes(cont.designation)
      })

     /*  const subObjectHasRoomsOnAnyFloor =  controllers.some((cont)=> {
        return floorList.some((floor)=> {
          return floor.locationIds.includes(cont.designation)
        }) */
       /* const controllersForRoomsOnFloor = this.getControllersForRoomsOnFloor.transform(
         controllers,
         floor,
         new Date(),
         object,
         subobject
       )
       const filteredControllersForRoomsOnFloor = this.filterByStatus.transform(controllersForRoomsOnFloor, filters)
       return filteredControllersForRoomsOnFloor.length > 0; */
     });
     // return true
     return subObjectHasRoomsOnAnyFloor;
   }

 /*  transform(floorList: any[], object, subobject, controllers: Controller[], controllerType: 'isGuestRoom'
  | 'isAccessControl' | 'isIOCommonArea' | 'isHVacCommonArea', filters: ControllerFilters ): boolean {
     if (floorList.length === 0) {
      return false;
    }
    const objectHasRoomsOnAnyFloor =  floorList.some((floor)=> {
      const controllersForRoomsOnFloor = this.getControllersForRoomsOnFloor.transform(
        controllers,
        floor,
        new Date(),
        object,
        subobject
      )
      const filteredControllersForRoomsOnFloor = this.filterByStatus.transform(controllersForRoomsOnFloor, filters)
      return filteredControllersForRoomsOnFloor.length > 0;
    });
    return objectHasRoomsOnAnyFloor;
  } */

}
