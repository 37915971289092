import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessagingService } from '../../../core/services/messaging.service';
import { insertRemoveTrigger } from '../../animations/animations';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  animations: [insertRemoveTrigger],
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit, OnDestroy {

  message = '';
  messageSubscription: Subscription;
  showMessage = false;

  constructor(private messagingService: MessagingService) { }

  ngOnInit() {
    this.getMessage();
  }

  getMessage() {
    this.message = this.messagingService.getMessage();
    // added setTimeout because of expression changed after it was checked error in dev mode
    this.messageSubscription = this.messagingService.messageChanged.subscribe(message => setTimeout(() => {
        this.message = message;
        if (message !== '') {
          this.showMessage = true;
          setTimeout(() => this.removeMessage(), 5000);
        }
      }, 0));
  }

  removeMessage() {
    this.messagingService.setMessage('');
    this.showMessage = false;
  }
  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }
}
