import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { GroupedPermission } from 'src/app/core/models/permissions/grouped-permission.model';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'isPermissionAccessControl'
})
export class IsPermissionAccessControl implements PipeTransform {

  transform(controllers: Controller[], permission: GroupedPermission): boolean {

    if (permission.locationId !== null) {
        const controllerLocationId = permission?.locationPermission?.name?.split(' ')[2];
        return this.isLocationAccessControl(controllerLocationId, controllers);
      }
  }

  isLocationAccessControl(locationId: string, controllers: Controller[]) {
    const controller = Controller.getControllerByLocationId(controllers, locationId)
    if (controller) {
      return Controller.isAccessControl(controller);
    }
  }
}
