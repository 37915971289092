import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'findPropertyByCode'
})
export class FindPropertyByCodePipe implements PipeTransform {

  transform(controllers: Controller[], codeFrom: number, codeTo: number ): Property[] {
    if (!controllers) {
      return null;
    }
    controllers.sort(this.sortByLocation)

    return controllers.reduce((allFilteredProps, controller)=> {
      const filteredPropsOnController = controller.controllerProperties.$values.filter((prop: Property) => {
        return (prop.type >= codeFrom && prop.type <= codeTo)
      });
      allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
      return allFilteredProps;
    },[]);
  }


sortByLocation(controllerA: Controller, controllerB: Controller) {
  return Number(controllerA.subZone) - Number(controllerB.subZone)
}


}

