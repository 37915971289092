<ng-container *ngIf="controllers.length > 0 && propToDisplay">
  <!-- SINGLE PROP TYPE -->
  <ng-container *ngIf="propToDisplay.mode === 'singlePropType'">
    <ng-container
      *ngIf="
        controllers
          | findPropertyByCode
            : propToDisplay.propInfo.codeFrom
            : propToDisplay.propInfo.codeTo as properties
      "
    >
      <ng-container
        *ngIf="
          properties
            | propertiesAreActive : propToDisplay.propInfo.activeCondition;
          else propertiesNotActive
        "
      >
        <div
          *ngIf="
            displaySize === 'facility' &&
            propDisplayArray.includes(propToDisplay.propInfo.activeName)
          "
          class="facility-hover-status"
        >
          {{ propToDisplay.propInfo.activeName | translate }}
        </div>
        <div
          [title]="propToDisplay.propInfo.activeTooltip | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-on"
            [ngClass]="[
              propToDisplay.propInfo.activeIcon,
              displaySize === 'facility' ? 'facility-icon' : ''
            ]"
          ></div>

          <div *ngIf="displaySize === 'large'" class="status-text-on">
            {{ propToDisplay.propInfo.activeName | translate }}
          </div>
        </div>
      </ng-container>
      <ng-template #propertiesNotActive>
        <div
          *ngIf="
            displaySize === 'facility' &&
            propDisplayArray.includes(propToDisplay.propInfo.inactiveName)
          "
          class="facility-hover-status"
        >
          {{ propToDisplay.propInfo.inactiveName | translate }}
        </div>
        <div
          [title]="propToDisplay.propInfo.inactiveTooltip | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-off"
            [ngClass]="[
              propToDisplay.propInfo.inactiveIcon,
              displaySize === 'facility' ? 'facility-icon' : ''
            ]"
          ></div>
          <div *ngIf="displaySize === 'large'" class="status-text-off">
            {{ propToDisplay.propInfo.inactiveName | translate }}
          </div>
        </div></ng-template
      >
    </ng-container>
  </ng-container>
  <!-- NUMERICAL PROP TYPE -->

  <ng-container *ngIf="propToDisplay.mode === 'numericalPropType'">
    <ng-container
      *ngIf="
        controllers
          | findPropertyByCode
            : propToDisplay.propInfo.codeFrom
            : propToDisplay.propInfo.codeTo as properties
      "
    >
      <div
        *ngIf="
          displaySize === 'facility' &&
          propDisplayArray.includes(propToDisplay.propInfo.activeIcon)
        "
        class="facility-hover-status"
      >
        {{ propToDisplay.propInfo.activeTooltip }}
      </div>
      <div
        [title]="propToDisplay.propInfo.activeTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'status-container-minimal' : ''
        ]"
        class="flex-row full-height"
      >
        <div
          class="icon-background-off"
          [ngClass]="[
            propToDisplay.propInfo.activeIcon,
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>
        <div class="status-text-on">
          <ng-container
            *ngIf="
              properties[0]?.value !== null && properties[0]?.value !== undefined;
              else noTemp
            "
            >{{ properties[0].value }}
            <ng-container
              *ngIf="displaySize === 'large' || displaySize === 'facility'"
            >
              {{ propToDisplay.propInfo.sufix }}
            </ng-container></ng-container
          >
          <ng-template #noTemp> - </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- STATUS -->

  <ng-container *ngIf="propToDisplay.mode === 'status'">
    <ng-container
      *ngIf="
        controllers
          | statusIsActive
            : propToDisplay.propInfo.statusName
            : propToDisplay.propInfo.activeCondition;
        else statusNotActive
      "
    >
      <div
        *ngIf="
          displaySize === 'facility' &&
          propDisplayArray.includes(propToDisplay.propInfo.activeName)
        "
        class="facility-hover-status"
      >
        {{ propToDisplay.propInfo.activeName | translate }}
      </div>
      <div
        [title]="propToDisplay.propInfo.activeTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-on"
          [ngClass]="[
            propToDisplay.propInfo.activeIcon,
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>
        <div *ngIf="displaySize === 'large'" class="status-text-on">
          {{ propToDisplay.propInfo.activeName | translate }}
        </div>
      </div>
    </ng-container>
    <ng-template #statusNotActive>
      <div
        *ngIf="
          displaySize === 'facility' &&
          propDisplayArray.includes(propToDisplay.propInfo.inactiveName)
        "
        class="facility-hover-status"
      >
        {{ propToDisplay.propInfo.inactiveName }}
      </div>
      <div
        [title]="propToDisplay.propInfo.inactiveTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-off"
          [ngClass]="[
            propToDisplay.propInfo.inactiveIcon,
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>
        <div *ngIf="displaySize === 'large'" class="status-text-off">
          {{ propToDisplay.propInfo.inactiveName }}
        </div>
      </div></ng-template
    >
  </ng-container>

  <!-- CUSTOM (FAN SPEED) -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(fanSpeed)'">
    <ng-container
      *ngIf="
        controllers
          | getFirstControllerWithProperty : Property.isFancoilSpeed
          | findProperty : Property.isFancoilSpeed as fanCoilSpeedProps
      "
    >
      <ng-container
        *ngIf="fanCoilSpeedProps | getActiveProps as activeFanCoilSpeedProps"
      >
        <div
          [ngClass]="[
            displaySize === 'minimal' ? 'status-container-minimal' : ''
          ]"
          class="flex-row full-height"
        >
          <!-- <div *ngIf="displaySize === 'facility' && propDisplayArray.includes('fanSpeed')" class="facility-hover-status">
            {{'Fan speed' | translate }}
          </div> -->
          <div
            [ngClass]="[
              propToDisplay.propInfo.activeIcon,
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length > 0
                ? 'icon-background-on'
                : '',
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length === 0
                ? 'icon-background-off'
                : '',
              displaySize === 'facility' ? 'facility-icon' : ''
            ]"
            class="font-size19"
          ></div>
          <div
            *ngIf="
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length > 0
            "
            class="status-text-on"
          >
            <ng-container
              *ngIf="Property.isFancoilV1(activeFanCoilSpeedProps[0])"
            >
              V1
            </ng-container>
            <ng-container
              *ngIf="Property.isFancoilV2(activeFanCoilSpeedProps[0])"
            >
              V2
            </ng-container>
            <ng-container
              *ngIf="Property.isFancoilV3(activeFanCoilSpeedProps[0])"
            >
              V3
            </ng-container>
          </div>

          <div
            *ngIf="
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length === 0
            "
            class="status-text-off"
          >
            {{ "Off" | translate }}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- CUSTOM (HVAC HEAT COOL AUTO) -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(hvacHeatCoolAuto)'">
    <ng-container
      *ngIf="
        controllers
          | findProperty : Property.isHvacHeatCool as hvacHeatCoolProps
      "
    >
      <ng-container
        *ngIf="
          controllers
            | findProperty : Property.isHvacHeatCoolAuto as hvacHCAutoProps
        "
      >
        <div
          [ngClass]="[
            displaySize === 'minimal' ? 'status-container-minimal' : ''
          ]"
          class="flex-row-center-v"
        >
          <!-- HVAC HEAT COOL AUTO -->
          <ng-container *ngIf="hvacHCAutoProps.length > 0">
            <ng-container
              *ngIf="displaySize === 'large' || displaySize === 'minimal'"
            >
              <div
                [ngClass]="[propToDisplay.propInfo.activeIcon]"
                class="icon-background-on font-size19"
              ></div>
              <div
                *ngIf="hvacHCAutoProps[0]?.value.toString() === '1'"
                class="status-text-on"
                title="{{ 'Auto' | translate }}"
              >
                {{ displaySize === "large" ? "Auto" : ("A" | translate) }}
              </div>
              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() === '0'
                "
                title="{{ 'Heating' | translate }}"
                class="status-text-on"
              >
                {{ displaySize === "large" ? "Heating" : ("H" | translate) }}
              </div>
              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() === '1'
                "
                title="{{ 'Cooling' | translate }}"
                class="status-text-on"
              >
                {{ displaySize === "large" ? "Cooling" : ("C" | translate) }}
              </div>

              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() !== '0' &&
                  hvacHeatCoolProps[0]?.value.toString() !== '1'
                "
                class="status-text-off"
                title="{{ '? Heat/Cool/Auto' | translate }}"
              >
                {{
                  displaySize === "large"
                    ? " ? Heat/Cool/Auto"
                    : ("?" | translate)
                }}
              </div>
            </ng-container>
          </ng-container>
          <!-- HVAC HEAT COOL  -->
          <ng-container
            *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0"
          >
            <ng-container
              *ngIf="hvacHeatCoolProps[0] | propIsActive; else propNotActive"
            >
              <div
                [ngClass]="[propToDisplay.propInfo.activeIcon]"
                class="icon-background-on font-size19"
              ></div>
              <div class="status-text-on">
                {{ "Cooling" | translate }}
              </div>
            </ng-container>
            <ng-template #propNotActive>
              <div
                [ngClass]="[propToDisplay.propInfo.activeIcon]"
                class="icon-background-off font-size19"
              ></div>
              <div class="status-text-off">
                {{ "Heating" | translate }}
              </div>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
