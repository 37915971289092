<div class="flex-row-space-evenly margin-top30 modal-content-container">
  <div class="width90 flex-row">
    <div class="flex-column width15">
      <ion-list *ngIf="controllers.length > 1" class="margin20 width-150">
        <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
        <ion-item
          button
          *ngFor="let controller of controllers"
          (click)="selectController(controller)"
          [ngClass]="{
            'ion-list-activated': selectedControllers[0].id === controller.id
          }"
        >
          {{ controller.name }}
        </ion-item>
      </ion-list>
    </div>
    <ng-container *ngIf="renderNumberInputs">

    <div class="flex-column width25">
      <div class="margin-bottom30 uppercase">{{ "Room" | translate }}</div>
      <app-room-settings-temps
       [selectedControllers]="selectedControllers"
       [hvacBaseSet]="Property.isHVACRoomBaseSet"
       [hvacEcoHyst1]="Property.isHvacRoomEcoHyst1"
       [hvacEcoHyst2]="Property.isHvacRoomEcoHyst2"

       ></app-room-settings-temps>
      <div class="flex-row-center-v">
        <app-number-input
          class="flex-center"
          [controllers]="selectedControllers"
          [findPropFunction]="Property.isHvacRegulation"
        ></app-number-input>
      </div>
      <app-room-temp-info [selectedControllers]="selectedControllers"
        [location]="'room'"
      ></app-room-temp-info>
    </div>

    <div class="flex-column width25">
      <div class="margin-bottom30 uppercase">{{ "Bathroom" | translate }}</div>
      <app-room-settings-temps
      [selectedControllers]="selectedControllers"
      [hvacBaseSet]="Property.HVACBathroomBaseSet"
      [hvacEcoHyst1]="Property.isHvacBathroomEcoHyst1"
      [hvacEcoHyst2]="Property.isHvacBathroomEcoHyst2"
      ></app-room-settings-temps>
      <div style="height:54px"></div><!-- SPACER -->
      <app-room-temp-info [selectedControllers]="selectedControllers"
        [location]="'bathroom'"
      ></app-room-temp-info>
    </div>
  </ng-container>
  <div class="width25" *ngIf="!renderNumberInputs"></div><!-- SPACER. Kees spacing while rerendering components -->
  <div class="width25" *ngIf="!renderNumberInputs"></div>

    <div class="flex-column width30">
      <div class="flex-column programming-box">
        <div class="margin-bottom30 uppercase">
          {{ "Programming" | translate }}
        </div>
        <ion-button
          size="small"
          class="margin5"
          color="light"
          (click)="openPopoverToSelectPreset($event)"
          ><ion-icon class="margin-right10" name="settings-sharp"></ion-icon>
          {{ "Reset to default settings" | translate }}</ion-button>

          <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCool as hvacHeatCoolProps">
            <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCoolAuto as hvacHCAutoProps">
              <!-- HEAT/COOL/AUTO -->
              <div *ngIf="hvacHCAutoProps.length > 0" class="flex-row">
                <ion-button class="margin5" size="small"
                  [disabled]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)"
                  [color]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(0)">
                  Heat
                </ion-button>
                <ion-button class="margin5" size="small"
                  [disabled]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)"
                  [color]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(1)">
                  Cool
                </ion-button>
                <ion-button class="margin5"
                  size="small"
                  [disabled]="hvacHCAutoProps | propIsActive"
                  [color]="(hvacHCAutoProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(2)">
                  Auto
                </ion-button>
              </div>
              <!-- HEAT/COOL -->
              <div *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0" class="flex-row">
                <ion-button class="margin5" size="small"
                  [disabled]="!(hvacHeatCoolProps | propIsActive)"
                  [color]="!(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(0)">
                  Heat
                </ion-button>
                <ion-button class="margin5" size="small"
                  [disabled]="(hvacHeatCoolProps | propIsActive)"
                  [color]="(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(1)">
                  Cool
                </ion-button>
              </div>
            </ng-container>
          </ng-container>

        <ng-container
          *ngIf="controllers | findProperty: Property.isRoomArmed as roomArmedProps"
        >
          <ion-button
            *ngIf="roomArmedProps?.length > 0"
            class="margin5"
            color="light"
            (click)="armRoom()"
            size="small"
            ><ion-icon class="margin-right10" name="shield-half-outline">
            </ion-icon
            >{{'Arm room' | translate}} (alarm)</ion-button
          >
        </ng-container>
        <ng-container
          *ngIf="
            controllers
              | findProperty
                : Property.isMinibarWasOpenedStatus as isMinibarWasOpenedProps
          "
        >
          <ion-button
            *ngIf="isMinibarWasOpenedProps?.length > 0"
            size="small"
            class="margin5"
            color="light"
            (click)="resetMinibar()"
            ><ion-icon class="margin-right10" name="wine-outline"></ion-icon
            >{{ "Reset minibar" | translate }}</ion-button
          >
        </ng-container>

        <ion-button
        class="margin5"
        color="light"
        (click)="syncHvac()"
        size="small"
        ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
        {{'Settings sync' | translate}}</ion-button>

        <app-boolean-input
          class="margin-top10"
          [controllers]="controllers"
          [findPropFunction]="Property.isPanelBlocked"
          >{{ "Block keyboard" | translate }}</app-boolean-input
        >
        <app-boolean-input
          [controllers]="controllers"
          [findPropFunction]="Property.isIgnoreWindow"
          >{{ "Ignore window" | translate }}</app-boolean-input
        >
        <app-boolean-input
          [controllers]="controllers"
          [findPropFunction]="Property.isIgnoreCardTray"
          >{{ "Ignore card tray" | translate }}</app-boolean-input
        >
        <ion-item lines="none" class="item-background-color">
          <ion-checkbox
            (ionChange)="changeResetDefaultPropertiesOnCheckout($event)"
            slot="start"
            class="margin-right5"
            [checked]="controllers[0].restoreDefaultOnCheckout"
          ></ion-checkbox>
          {{ "Reset to default settings on checkout" | translate }}
        </ion-item>

        <ion-item lines="none" class="item-background-color">
          <ion-checkbox
            (ionChange)="changeControllerPooling($event)"
            slot="start"
            class="margin-right5"
            [checked]="stopControllerPolling"
          ></ion-checkbox>
          {{ "Stop controller polling" | translate }}
        </ion-item>
      </div>
    </div>
  </div>
</div>
