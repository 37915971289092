import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthenticationService } from './../../core/authentication/authentication.service';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from './../../core/services/user.service';
import { API_BASE_URL } from 'src/environments/environment';
import { UrlService } from 'src/app/shared/services/url.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  user: User = new User();
  forgotPassword = false;
  passwordHasReset = false;
  emailForgotenPassword: string;
  interval;
  timeout;
  refreshIt = true;

  constructor(private auth: AuthenticationService,
              private userService: UserService,
              private router: Router,
              private http: HttpClient,
              private urlService: UrlService,
              private location: Location) { }

  ngOnInit() {
    if (this.urlService.lastUrl && this.isLoggedIn() && !this.urlService.lastUrl.includes('login')) {
      this.router.navigate([this.urlService.lastUrl]);
    }

    this.interval = setInterval(() => {
      // console.log('usao u login interval')
    if (this.isLoggedIn()) {
       // console.log('usao u isLogedin funkciju')
      this.router.navigate(['/rooms']);
        if (this.urlService.lastUrl && !this.urlService.lastUrl.includes('login')) {
          this.router.navigate([this.urlService.lastUrl]);
        } else {
          this.router.navigate(['/rooms']);
          }
          this.timeout = setTimeout( () => {
            if (this.refreshIt) {
              this.location.replaceState('/rooms');
              window.location.reload()
            }
          },3000)
      }
    }, 2000);


  }

  onLogin(form: NgForm) {
    if (form.valid) {
      this.auth.login(this.user).subscribe(
        response => {
          // console.log(response)
          this.userService.setUser(response.user);
          if (this.urlService.lastUrl && !this.urlService.lastUrl.includes('login')) {
            this.router.navigate([this.urlService.lastUrl]);
          } else {
            this.router.navigate(['/rooms']);
          }

          //if (this.urlService.lastUrl && !this.urlService.lastUrl.includes('login')) {
          //  this.router.navigate([this.urlService.lastUrl]);
          //} else {
          //  try {
          //    console.log("trying navigate")
          //    this.router.navigate(['/rooms']);
          //    console.log("navigate success")
          //  } catch (err) {
          //    console.log(err, "error")
          //  }
          //
          //}
        }
      );
    }
  }

  isLoggedIn(): boolean {
    return this.auth.isValid();
  }

  backToLogin() {
    this.forgotPassword = false;
    this.passwordHasReset = false;
    this.emailForgotenPassword = '';
  }

  onResetPassword(form: NgForm) {
    if (form.valid) {
      this. passwordHasReset = true;
      this.apiResetPassword(this.emailForgotenPassword).subscribe();
    }
  }

  apiResetPassword(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<User[]>(API_BASE_URL + '/login/reset', {params});
  }

  continue() {
    this.router.navigateByUrl('/rooms');
  }

  ngOnDestroy(): void {
    this.refreshIt = false;
    if (this.interval) {
      clearInterval(this.interval);
   }
    if(this.timeout) {
      clearTimeout(this.timeout);
    }
  }

}
