import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router,  } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_BASE_URL } from 'src/environments/environment';
import { User } from '../models/user/user.model';
import { UserService } from './../services/user.service';
import { ILoginResponse } from '../models/login-response.interface';
import { MqttProjectService } from '../app-load/mqtt-project.service';
import { CardLogin } from '../models/card-login.modal';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  refreshTokenInProgress = false;
  refreshChanged = new BehaviorSubject<boolean>(false);

  private headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient,
              private router: Router,
              private userService: UserService,
              private mqttProjectService: MqttProjectService) { }

  login(user: User): Observable<ILoginResponse> {
    // TODO::: should import from setting on init... but card reader messed up
    const url = API_BASE_URL + '/login';
    return this.http.post<ILoginResponse>(url, JSON.stringify(user), {headers: this.headers}).pipe(
      map(response => {
        this.setAccessToken(response.tokens.accessToken);
        this.setRefreshToken(response.tokens.refreshToken);
        this.userService.setUser(response.user);

        return response;
      })
    );
  }

  loginWithCard(cardLogin: CardLogin): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(API_BASE_URL + '/cardlogin', cardLogin, {headers: this.headers}).pipe(
      map( response => {
        this.setAccessToken(response.tokens.accessToken);
        this.setRefreshToken(response.tokens.refreshToken);
        this.userService.setUser(response.user);

        return response;
      })
    )
  }

  getUserByToken() {
    return this.http.get<User>(API_BASE_URL + '/users/loggedin').pipe(
        map(user => {
            this.userService.setUser(user);
            return user;
        }),
    );
  }


  setRefreshTokenInProgress(inProgress) {
    this.refreshTokenInProgress = inProgress;
    this.refreshChanged.next(this.refreshTokenInProgress);
  }

  refreshAccessToken() {
    const body = {
      accessToken: this.getAccessToken(),
      refreshToken: this.getRefreshToken()
    };
    return this.http.post<ILoginResponse>(API_BASE_URL + '/refresh', body);
  }

  logout(withReturnUrl?: boolean) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    this.userService.mqttInitialised = false;
    this.mqttProjectService.unsubscribeFromMqtt();
    // this.userService.removeAlarmAlertsFromBody();
    // TODO :: Create logout on the backend
    // get with user id
    // const url: string = API_BASE_URL + '/logout';
    // this.http.get(url, {headers: this.headers}).subscribe();
    // this.router.navigate(['login']);
    if (!withReturnUrl) {
      this.router.navigate(['/login']);
    } else {
      // console.log(this.router.url)
      this.router.navigate(['/login'], {
        queryParams: {
            return: this.router.url
        }
      });
    }
    return true;
  }

  setAccessToken(accessToken: string) {
    localStorage.setItem('access_token', accessToken);
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('refresh_token', refreshToken);
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  isValid() {
    const token = this.getAccessToken();
    if (token) {
      const helper = new JwtHelperService();
      const isExpired = helper.isTokenExpired(token);
      return !isExpired;
    } else {
      return false;
    }
  }
}
