import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'findProperty',
})
export class FindPropertyPipe implements PipeTransform {
  transform(controllers: Controller[], filterFunction: (property: Property) => boolean ): Property[] {
    if (!controllers) {
      return null;
    }

    return controllers?.reduce((allFilteredProps, controller)=> {
      const filteredPropsOnController = controller?.controllerProperties?.$values?.filter((prop: Property) => {
        return filterFunction(prop);
      });
      if (filteredPropsOnController) {
        allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
      }
      return allFilteredProps;
    },[]);
  }
}
