export enum SoftwarePermissionId {
    Login = 1,
    LocationsView,
    UsersView,
    RolesView,
    LocationsAdd,
    LocationsUpdate,
    LocationsDelete,
    LocationsProgramming,
    LocationsRemoteUnlock,
    LocationGroupsAdd,
    LocationGroupsUpdate,
    LocationGroupsDelete,
    UsersAdd,
    UsersUpdate,
    UsersDelete,
    RolesAdd,
    RolesUpdate,
    RolesDelete,
    RolesViewSoftwarePermissions,
    RolesViewNonMember,
    ViewNonMemberSwPerm,
    AuditLogView,
    AccessLogView,
    CardsAdd,
    CardsAddForSelf,
    AlarmsBurglaryReceive,
    AlarmsSystemReceive,
    AlarmsSafetyReceive,
    AlarmsSosReceive,
    AlarmsHvacReceive,
    AlarmsOpenTooLongReceive,
    AlarmsLogView,
    Settings,
    CardsGuestsAdd,
    LocationDetails,
    LocationSettings,
    RoomOverviewRoomService,
    RoomOverviewSecurity,
    RoomOverviewStatus,
    RoomOverviewHvac,
    RoomOverviewMaintenance
}
