import { Injectable } from '@angular/core';
import { Subscription, switchMap, take } from 'rxjs';
import { ApiService } from '../http/api.service';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { USE_DALI } from 'src/environments/environment';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiHvacModesService } from 'src/app/modules/groups/services/api-hvac-modes.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ControllerFilters } from '../models/view-filter.model';

@Injectable({
  providedIn: 'root'
})
export class MqttProjectService {

  mqttSubscription = new Subscription();
  mqttCliSubscriptin = new Subscription();
  daliMqttSubscription = new Subscription();

  constructor(
    private apiService: ApiService,
    private mqttService: MqttService,
    private projectService: ProjectService,
    private alarmAlertsService: AlarmAlertsService,
    private apiDaliProjectService: ApiDaliProjectService,
    private daliProjectService: DaliProjectService,
    private apiHvacModeService: ApiHvacModesService,
    private filterService: FiltersService,
    ) {}

  initMqttForProject() {
    this.subscribeToMqtt();
    this.subscribeToMqttCli();
    if (USE_DALI) {
      this.subscribeToDaliMqtt();
    }
  }

  subscribeToMqtt() {
    this.apiService.getBaseMqttSubscription().subscribe((mqttBase) => {
      const alarmRegex: RegExp = new RegExp(mqttBase + '/alarm/.+')
      this.mqttSubscription = this.mqttService.observe(`${mqttBase}/#`).subscribe((message: IMqttMessage) => {
        if (alarmRegex.test(message.topic)) {// ALARMS
          this.alarmAlertsService.handleMqttAlarms(message);
          if (message.payload.toString().includes('Controller offline')) {
            const strWithControllerId = message.payload.toString().split('-')[3];
            if (strWithControllerId) {
              const controllerStr = strWithControllerId.substring(strWithControllerId.indexOf(' ')+1, strWithControllerId.lastIndexOf(' '))
              this.projectService.updateControllerIsOnlineStatus(controllerStr, false);
            }

          } else if(message.payload.toString().includes('Controller online')) {
            const strWithControllerId = message.payload.toString().split('-')[3];
            if(strWithControllerId) {
              const controllerStr = strWithControllerId.substring(strWithControllerId.indexOf(' ')+1, strWithControllerId.lastIndexOf('|'))
              // const controllerStr = strWithControllerId.substring(strWithControllerId.indexOf(' ')+1, strWithControllerId.lastIndexOf(' '))
              this.projectService.updateControllerIsOnlineStatus(controllerStr, true);
            }
          }
        } else if(message.topic.toLowerCase().includes('isrented')) { // ROOM IS RENTED
          this.updateControllerIsRentedStatus(message, mqttBase)
        }
        else if(message.topic.toLowerCase().includes('isjoined')) { // ROOM IS JOINED
          this.updateControllerIsJoinedStatus(message, mqttBase)
        }
        else if(message.topic.toLowerCase().includes('hvacmodestatuschange')) { // HVAC MODE STATUS CHANGE
          this.filterService.getFilters().pipe(take(1), switchMap((filters: ControllerFilters)=> {
            let objectId;
            if (filters.Location.Objects.length === 0) {
              objectId = '0';
            } else {
              objectId = filters.Location.Objects[0]
            }
            return this.apiHvacModeService.getActiveHvacMode(objectId)
          }))
          .subscribe()

        }
      });
    });
  }

  subscribeToDaliMqtt() {
    this.apiDaliProjectService.getBaseDaliMqttSubscription().subscribe((daliMqttBase) => {
      this.daliMqttSubscription = this.mqttService.observe(`${daliMqttBase}/#`).subscribe((message: IMqttMessage) => {
        const daliType = message.topic.split('/')[2];
        const daliId = message.topic.split('/')[4];
        this.daliProjectService.updateDaliProjectByMqtt(daliType, daliId, message.payload.toString());
      });
    })

  }

  updateControllerIsRentedStatus(message: IMqttMessage, mqttBase: string) {
    let locationId = message.topic.substring(mqttBase.length+1, message.topic.length);
    locationId = locationId.substring(0,locationId.length-9);
    const newValue: string = message.payload.toString().split('|')[1];
    let newIsRentedValue: boolean;
    if (newValue?.toLowerCase() === 'true' || Number(newValue)>0) {
      newIsRentedValue = true;
    } else if (newValue?.toLowerCase() === 'false' || Number(newValue) === 0) {
      newIsRentedValue = false;
    }
    this.projectService.updateControllerIsRentedStatus(locationId, newIsRentedValue);
  }

  updateControllerIsJoinedStatus(message: IMqttMessage, mqttBase: string) {
    let locationId = message.topic.substring(mqttBase.length+1, message.topic.length);
    locationId = locationId.substring(0,locationId.length-9);
    const newValue: string = message.payload.toString().split('|')[1];
    let newIsJoinedValue: boolean;
    if (newValue?.toLowerCase() === 'true' || Number(newValue)>0) {
      newIsJoinedValue = true;
    } else if (newValue?.toLowerCase() === 'false' || Number(newValue) === 0) {
      newIsJoinedValue = false;
    }
    this.projectService.updateControllerIsJoinedStatus(locationId, newIsJoinedValue);
  }




  subscribeToMqttCli() {
    this.mqttCliSubscriptin = this.mqttService.observe('cli/#').subscribe((message: IMqttMessage) => {
      if (!message.topic.includes('messagequeue') && !message.topic.includes('timeout') && !message.topic.includes('responseerror')) {
        this.projectService.updateFilteredProjectByMqtt(message);
        this.projectService.updateFullProjectByMqtt(message)
        this.projectService.singleControllerMessage$.next(message);
      }
    })
  }

  unsubscribeFromMqtt() {
    if (this.mqttCliSubscriptin) {
      this.mqttCliSubscriptin.unsubscribe()
      this.mqttCliSubscriptin = new Subscription()
    }
    if (this.mqttSubscription) {
      this.mqttSubscription.unsubscribe();
      this.mqttSubscription = new Subscription();
    }
    if (this.daliMqttSubscription) {
      this.daliMqttSubscription.unsubscribe();
      this.daliMqttSubscription = new Subscription()
    }
  }
}
