import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { ControllerFilters } from 'src/app/core/models/view-filter.model';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'transformFloors'
})
export class TransformFloorsPipe implements PipeTransform {

  transform(
    fList: LocationGroup[],  filters: ControllerFilters,  controllers: Controller[],
     targetObject: ProjectObject, targetSubobject: ProjectSubObject
    ): LocationGroup[] {

      const targetObj: string = targetObject.designation.split('/')[0];
      const targetSubobj: string = targetSubobject.designation.split('/')[1];

      const floorsInObjectAndSuboject = fList.filter((flo)=> {
        return flo.locationIds.some((locId: string)=> {
          const floorObject = locId.split('/')[0];
          const floorSubobject = locId.split('/')[1];
          return targetObj === floorObject && targetSubobj === floorSubobject
        })
      })

      //
      /* fList = fList.filter((f)=> {
        const floorListObject = f.locationIds[0].split('/')[0];
        const floorListSubObject = f.locationIds[0].split('/')[1];
        const targetObj: string = targetObject.designation.split('/')[0];
        const targetSubobj: string = targetObject.designation.split('/')[1];
        return Number(floorListObject) === Number(targetObj) && Number(floorListSubObject) === Number(targetSubobj)
      }).slice();
      console.log(fList) */


        const floorList = floorsInObjectAndSuboject.slice();
        const controllersOnFloors:string[]  = floorList.reduce((flList,floor)=> {
            flList = [...floor.locationIds, ...flList]
            return flList;
          },[])
        const floorForUndistributedControllers: LocationGroup = {
            id: 9999,
            name: 'Undistributed',
            locationGroupTypeId: 3,
            locationIds: []
          };

          controllers.forEach((cont : Controller)=> {
            // const controllerObj: string = cont.designation.split('/')[0];
            // const controllerSubobj: string = cont.designation.split('/')[1];
            if (cont.object === targetObj && cont.subObject === targetSubobj && !controllersOnFloors.includes(cont.designation)) {
              floorForUndistributedControllers.locationIds.push(cont.designation);
            }
          })

          let filteredFloorList = floorList;
          // filter floors

          if (filters.Location.Floors.length > 0) {
            filteredFloorList = filteredFloorList.filter((floor)=> {
              return filters.Location.Floors.includes(floor.id)
              /* const objParams = floorFilterParams.find((params: FloorFilterParams)=> {
                return Number(params.subObject) === Number (targetSubobject) && Number(params.object) === Number (targetObject)
              })
              const fl = objParams.floors.find((f)=>{
                return Number(floor.id) === Number (f.floorId)
              })
              return fl?.show */
            })
          }



          if (floorForUndistributedControllers.locationIds.length>0) {
            filteredFloorList.push(floorForUndistributedControllers)
          }
          return filteredFloorList;








    /* fList = fList.filter((f)=> {
      const floorListObject = f.locationIds[0].split('/')[0];
      const floorListSubObject = f.locationIds[0].split('/')[1];
      return Number(floorListObject) === Number(targetObject) && Number(floorListSubObject) === Number(targetSubobject)
    }).slice();

    if (!fList || fList.length === 0) { // no floorlist, generates floor list
       let  filteredGeneratedFloorList  = Project.getGeneratedFloors(project,targetObject, targetSubobject).slice()
       filteredGeneratedFloorList = filteredGeneratedFloorList.filter((gFl)=> {
          const objParams = floorFilterParams?.find((params: FloorFilterParams)=> {
            return Number(params.subObject) === Number (targetSubobject) && Number(params.object) === Number (targetObject)
          })
          const fl = objParams?.floors?.find((f)=>{
            return Number(gFl) === Number (f.name)
          })
          return fl?.show;
       })
      return filteredGeneratedFloorList;
    } else {
      const floorList = fList.slice();
        const controllersOnFloors:string[]  = floorList.reduce((flList,floor)=> {
          flList = [...floor.locationIds, ...flList]
          return flList;
        },[])
        const floorForUndistributedControllers: LocationGroup = {
          id: 9999,
          name: 'Undistributed',
          locationGroupTypeId: 3,
          locationIds: []
        };
        project.controllers.forEach((driver) => {
          driver[1].forEach((object) => {
            if (Number(object[0]) === Number(targetObject)) {
              object[1].forEach((subObj) => {
                if (Number(subObj[0]) === Number(targetSubobject)) {
                  subObj[1].forEach((zone) => {
                    zone[1].forEach((subZone) => {
                      if (!controllersOnFloors.includes(subZone[1].locationId)) {
                        floorForUndistributedControllers.locationIds.push(subZone[1].locationId);
                      }
                    });
                  });
                }
              });
            }
          });
        });
        const filteredFloorList = fList.slice().filter((floor)=> {
          const objParams = floorFilterParams.find((params: FloorFilterParams)=> {
            return Number(params.subObject) === Number (targetSubobject) && Number(params.object) === Number (targetObject)
          })
          const fl = objParams.floors.find((f)=>{
            return Number(floor.id) === Number (f.floorId)
          })
          return fl?.show
        })

        if (floorForUndistributedControllers.locationIds.length>0) {
          filteredFloorList.push(floorForUndistributedControllers)
        }
        return filteredFloorList;
      }*/
    }
}
