import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OutpuModeStatus } from 'src/app/core/models/common-area/output-mode.enum';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ControllerFilters } from 'src/app/core/models/view-filter.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { PropertyCopyService } from '../../services/property-copy.service';

@Component({
  selector: 'app-output-mode-copy-paste',
  templateUrl: './output-mode-copy-paste.component.html',
  styleUrls: ['./output-mode-copy-paste.component.scss']
})
export class OutputModeCopyPasteComponent implements OnInit {

  // project$ = this.projectService.getProject();

  controllers: Controller[] = [];
  showControllerProp: string[] = [];

  propsAndControllersForPaste: any[] = [];

  outputModesEnum = OutpuModeStatus;
  isOutputStartTimeT1 = Property.isOutputStartTimeT1;
  isOutputEndTimeT1 = Property.isOutputEndTimeT1;
  isOutputStartTimeT2 = Property.isOutputStartTimeT2;
  isOutputEndTimeT2 = Property.isOutputEndTimeT2;
  isOutputMode = Property.isOutputMode;
  constructor(
              private apiProjectService: ApiProjectService,
              private propertyCopyService: PropertyCopyService,
              private modalController: ModalController
              ) { }

  ngOnInit(): void {
    this.apiProjectService.getFilteredControllersFat(new ControllerFilters(['IOCommonArea']), false).subscribe((conts: Controller[])=> {
      this.controllers = conts;
    })
  }

  pasteSelectedOutputMode() {
    this.propsAndControllersForPaste.forEach( element => {
      this.pasteProperty(element[0],element[1]);
    })

    this.modalController.dismiss();
  }

  isSavedProperty(prop: Property) {
    const propToPaste = this.propertyCopyService.getSavedProperty();
  }


  addOutputModeToPaste(event: any, prop: Property, cont: Controller) {
    if (event.target.checked) {
      this.propsAndControllersForPaste.push([prop,cont]);
    } else {
      this.propsAndControllersForPaste = this. propsAndControllersForPaste.filter( element => {
        if (element[0].id === prop.id && element[1].locationId === cont.locationId) {
          return false;
        } else {
          return true;
        }
      })
    }
  }

  getDenyFromProperty(outputMode: Property, tStart: Function) {
    const outputModeNum = outputMode.mqttTopic.split('/')[3];
    const prop = this.controllers[0].controllerProperties.$values.find( p => {
      if (tStart(p) && p.mqttTopic.split('/')[4] === outputModeNum) {
        return true;
      }
    })
    if (prop) {
      return prop;
    }
  }

  getDenyToProperty(outputMode: Property, tEnd: Function) {
    const outputModeNum = outputMode.mqttTopic.split('/')[3];
    const prop = this.controllers[0].controllerProperties.$values.find( p => {
      if (tEnd(p) && p.mqttTopic.split('/')[4] === outputModeNum) {
        return true;
      }
    })
    if (prop) {
      return prop;
    }
  }

  outputModeWithInterval(property: Property) {
    if (+property.value === this.outputModesEnum.TimeInterval ||
       +property.value === this.outputModesEnum.TimeIntervalAndPIR || +property.value === this.outputModesEnum.LuxomatAndTimeInterval) {
      return true;
    }
  }

  pasteProperty(outputMode: Property, cont: Controller) {
    const propToPaste = this.propertyCopyService.getSavedProperty();
    outputMode.value = propToPaste.value;

    if (this.outputModeWithInterval(outputMode)) {
      const copyDenyFromT1 = this.getDenyFromProperty(propToPaste, this.isOutputStartTimeT1)
      const copyDenyToT1 = this.getDenyToProperty(propToPaste, this.isOutputEndTimeT1)
      const copyDenyFromT2 = this.getDenyFromProperty(propToPaste, this.isOutputStartTimeT2)
      const copyDenyToT2 = this.getDenyToProperty(propToPaste, this.isOutputEndTimeT2)

      const denyFromT1 = this.getDenyFromProperty(outputMode, this.isOutputStartTimeT1)
      const denyToT1 = this.getDenyToProperty(outputMode, this.isOutputEndTimeT1)
      const denyFromT2 = this.getDenyFromProperty(outputMode, this.isOutputStartTimeT2)
      const denyToT2 = this.getDenyToProperty(outputMode, this.isOutputEndTimeT2)

      denyFromT1.value = copyDenyFromT1.value;
      denyToT1.value = copyDenyToT1.value;
      denyFromT2.value = copyDenyFromT2.value;
      denyToT2.value = copyDenyToT2.value;

      const propsToSend = [{propertyId: outputMode.id.toString(), value: propToPaste.value},
                          {propertyId: denyFromT1.id.toString(), value: denyFromT1.value},
                          {propertyId: denyToT1.id.toString(), value: denyToT1.value},
                          {propertyId: denyFromT2.id.toString(), value: denyFromT2.value},
                          {propertyId: denyToT2.id.toString(), value: denyToT2.value}]
      this.apiProjectService.changeMultiplePropertiesByLocation(cont.designation, propsToSend).subscribe();
    }
  }

}
