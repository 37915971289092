import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'userCanEditRole'
})
export class UserCanEditRolePipe implements PipeTransform {

  transform(user: User, roleId: number): boolean {
    return user.canEditRole(roleId)
  }

}
