<ng-container
  *ngIf="controllers | findProperty : propFilterFunction as temperatureProps"
>
  <div
    *ngFor="let temperatureProp of temperatureProps"
    class="status-container-small flex-row-center-v margin-bottom12 width200"
  >
    <div class="icon-temp icon-background-off font-size19"></div>
    <div>
      <span class="status-text-off">{{ temperatureProp.value }} °C</span>
      <span class="status-text-off-small">{{
        temperatureProp.name | translate
      }}</span>
    </div>
  </div>
</ng-container>
