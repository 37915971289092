
<div>
  <h2>All props</h2>
  
    <div *ngFor="let controller of controllers">
      <div class="flex-column" style="max-height: 200px">
        <h3>
          {{ controller.name }}
        </h3>
          <div *ngFor="let prop of controller.controllerProperties.$values" >
            <div>
              {{prop.name}} -> {{ prop.value }}
            </div>
          </div>
      </div>
    </div>
  </div>

<div class="flex-display margin20">
  <div class="loc-sidebar">
    <div class="loc-sidebar-header">{{'Controller ids' | translate}}</div>
    <div
      class="listSingleItem"
      *ngFor="let controller of controllers"
      (click)="selectController(controller)"
    >
      {{ controller.locationId }}
    </div>
    <div class="loc-sidebar-header margin-top100">{{'Location ids' | translate}}</div>
    <div
      class="listSingleItem"
      *ngFor="let controller of controllers"
      (click)="selectLocation(controller.locationId)"
    >
      {{ controller.locationId }}
    </div>
  </div>
  <div
    *ngIf="!selectedLocation && !selectedController"
    class="margin-top50 margin-left100"
  >
    {{'Select a location Id or controller' | translate}}
  </div>

  <app-edit-location-modal
    *ngIf="selectedLocation"
    [locationInput]="selectedLocation"
    (cancelEvent)="cancelSelection()"
  ></app-edit-location-modal>

  <app-edit-controller
    *ngIf="selectedController"
    [controllerInput]="selectedController"
    (cancelEvent)="cancelSelection()"
  ></app-edit-controller>
</div>

