import { Component, OnInit } from '@angular/core';

import { ApiAuditService } from '../../services/http/api-audit.service';
import { AuditLog } from 'src/app/core/models/logs/audit-log.model';
import { PaginationData } from 'src/app/core/models/pagination-data.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';



@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss' ]
  })
export class AuditComponent implements OnInit {
  
  auditLogsData: AuditLog[] = [];
  paginationData: PaginationData = new PaginationData();

  queryParams = {
    pageNumber: 1,
    pageSize: 50,
    includeArchive: false
  };
  requestBody: SsFilterSort = new SsFilterSort();
  showAdditionalOptions = false;
  loading = false;

  constructor(
    private api: ApiAuditService) { }

  ngOnInit(): void {
    this.onSubmit(1, 'DSC|TIME');
  }

  onSubmit(pageNumber: number = 1, pageSort?: string) {
    this.loading = true;
    if (pageSort) {
      this.requestBody.sorting = pageSort;
    }
    this.queryParams.pageNumber = pageNumber;
    this.api.getAuditLogsRequest(this.requestBody, this.queryParams).subscribe(response => {
      this.paginationData = JSON.parse(response.headers.get('X-Pagination'));
      this.auditLogsData = response.body;
      this.loading = false;
      });
  }

  onSort(sortBy: string) {
    if (this.requestBody.sorting === 'ASC|' + sortBy) {
      this.requestBody.sorting = 'DSC|' + sortBy;
    }  else {
      this.requestBody.sorting = 'ASC|' + sortBy;
    }
    this.onSubmit(this.paginationData.CurrentPage);
  }

  searchFilterReady(searchFilter: string) {
    this.requestBody.filtering = searchFilter;
    this.onSubmit();
  }

  setDateTimeFromTo(selectedDateTime: Date[]) {
    this.requestBody.dateTimeFrom = selectedDateTime[0];
    this.requestBody.dateTimeTo = selectedDateTime[1];
    this.onSubmit();
  }



}
