import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, take } from 'rxjs';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { LocationGroup } from 'src/app/shared/services/api-location-groups.service';

@Component({
  selector: 'app-group-settings',
  templateUrl: './group-settings.component.html',
  styleUrls: ['./group-settings.component.scss']
})
export class GroupSettingsComponent implements OnInit {
  @Input() selectedGroup: LocationGroup;
  defaultPresets: DefaultsPreset[];
  presetProps: Property [];
  targetProps
  loading = false;

  constructor(
    private apiSettingsService: ApiSettingsService,
    private apiProjectService: ApiProjectService

    ) { }

  ngOnInit(): void {
    combineLatest([
      this.apiSettingsService.getDefaultPresets(),
      this.apiSettingsService.getPresetProps(),
    ]).
        subscribe(([defaults, presetProps])=> {
          this.defaultPresets = defaults;
          this.presetProps = presetProps;
          this.combinePropsWithDefaults([]);
        })
  }

  combinePropsWithDefaults(defaultProps) {
    // const presetProps = JSON.parse(JSON.stringify(this.presetProps));
    const presetProps: any[] = structuredClone(this.presetProps);
     this.targetProps = presetProps.map((preset)=> {
      const dProp = defaultProps.find((prop)=> {
        return prop.type === preset.type
      })
      if (!dProp) {
        preset.include = false;
        return preset;
      } else {
        preset.include = true;
        preset.value = dProp.value;
        return preset
      }
     })
  }

  onClickPreset(preset) {
    this.combinePropsWithDefaults( preset.equipmentPresetProperties.$values)
  }

  onClickNoPreset() {
    this.combinePropsWithDefaults([])
  }

  onApply() {
    let propsToSend: any[] = this.targetProps.filter((prop) => {
      return prop.include;
    });
    propsToSend = propsToSend.map((prop) => {
      return {
        typeCode: prop.type,
        value: prop.value
      }
    });
    if (propsToSend.length > 0 && this.selectedGroup.id) {
      this.loading = true;
      this.apiProjectService.changeMultiplePropertiesByGroup(this.selectedGroup.id, propsToSend).subscribe({
        next: () => {
         this.loading = false;
       },
       error: (err)=> {
        this.loading = false;
       }});
    }
  }

}
