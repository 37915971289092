import { Injectable } from '@angular/core';
import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AlarmNotifyType } from 'src/app/core/models/alarms/alarm-notify-type.model';
import { AlarmNotify } from 'src/app/core/models/alarms/alarm-notify.model';
import { DefaultsPreset, FrontendSettings } from './http/api-settings.service';
import { DisplayConfig, DisplayPropertyParams } from 'src/app/shared/components/display-property/display-property.component';
import { map } from 'rxjs/operators';
import { User } from 'src/app/core/models/user/user.model';
import { Controller } from 'src/app/core/models/controller.model';

export interface CheckInOutTime {
  hour: number;
  minute: number
}

export interface StoredView {
  userId: number;
  view: DisplayConfig;
}

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private presets$ = new BehaviorSubject<DefaultsPreset[]>([])
    // private defaultCheckoutTime = new BehaviorSubject<CheckoutTime>({hour: 12, minute: 0})
    private frontendSettings$ = new BehaviorSubject<FrontendSettings>(null);
    private filteredPropertyDisplayConfigs$ = new BehaviorSubject<DisplayConfig[]>(null);
    private selectedPropertyDisplayConfig$  = new BehaviorSubject<DisplayConfig>(null);

    alarmTypes: AlarmType[] = [];
    alarmTypesChanged = new Subject<AlarmType[]>();
    alarmNotifyTypes: AlarmNotifyType[] = [];
    alarmNotifyTypesChanged = new Subject<AlarmNotifyType[]>();
    alarmSubscriptions: AlarmNotify[] = [];
    alarmSubscriptionsChanged = new Subject<AlarmNotify[]>();

    loadedViewForUserId: number = undefined;
    selectedViewsLocalStorageKey = 'irooms_selected_views';

    constructor() {}

    getDefaultPresets() {
      return this.presets$.asObservable();
    }

    setDefaultPresets(presets: DefaultsPreset[]) {
      this.presets$.next(presets);
    }

    getAlarmTypes() {
        return this.alarmTypes;
    }

    setAlarmTypes(alarmTypes: AlarmType[]) {
        this.alarmTypes = alarmTypes;
        this.alarmTypesChanged.next(this.alarmTypes);
    }

    getAlarmNotifyTypes() {
        return this.alarmNotifyTypes;
    }

    setAlarmNotifyTypes(alarmNotifyTypes: AlarmNotifyType[]) {
        this.alarmNotifyTypes = alarmNotifyTypes;
        this.alarmNotifyTypesChanged.next(this.alarmNotifyTypes);
    }

    getAlarmSubscriptions() {
        return this.alarmSubscriptions;
    }

    setAlarmSubscriptions(alarmSubscriptions: AlarmNotify[]) {
        this.alarmSubscriptions = alarmSubscriptions;
        this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
    }

    getAlarmSubscription(id: number) {
        if (!this.alarmSubscriptions) {
            return undefined;
        }
        return this.alarmSubscriptions.find(alarmSubscription => alarmSubscription.alarmNotifyId === id);
    }

    updateAlarmSubscription(alarmSubscription: AlarmNotify) {
        const index = this.alarmSubscriptions.findIndex
        (alarmSubToChange => alarmSubToChange.alarmNotifyId === alarmSubscription.alarmNotifyId);
        if (index !== -1) {
            this.alarmSubscriptions[index] = alarmSubscription;
            this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
        } else {
            this.addAlarmSubscription(alarmSubscription);
        }
    }

    addAlarmSubscription(alarmSubscription: AlarmNotify) {
        this.alarmSubscriptions.push(alarmSubscription);
        this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
    }

    deleteAlarmSubscription(id: number) {
        const index = this.alarmSubscriptions.findIndex(alarmSubscription => alarmSubscription.alarmNotifyId === id);
        this.alarmSubscriptions.splice(index, 1);
        this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
    }

    getUseBalconyLights(): Observable<boolean> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.useBalconyLightsSchedule;
      }))
    }

    getRequiredAlarmText(): Observable<boolean> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings?.settings.requireAckAlarmText;
      }))
    }

    getDefaultCheckoutTime(): Observable<CheckInOutTime> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.defaultCheckoutTime;
      }))
    }

    getDefaultCheckinTime(): Observable<CheckInOutTime> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.defaultCheckInTime;
      }))
    }

    getDefaultStayDuration(): Observable<number> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.defaultStayDurationDays;
      }))
    }

    /* getPropertyDisplayConfigs(): Observable<DisplayConfig[]> {
      // return this.propertyDisplayConfigs$.asObservable();
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.propertyDisplayConfigs;
      }))
    } */

    getFilteredPropertyDisplayConfigs(): Observable<DisplayConfig[]> {
       return this.filteredPropertyDisplayConfigs$.asObservable();
    }

    setFrontendSettings(frontendSettings: FrontendSettings) {
      this.frontendSettings$.next(frontendSettings);
    }

    getFrontendSettings() {
      return this.frontendSettings$.asObservable();
    }

    getSelectedPropertyDisplayConfig() {
      return this.selectedPropertyDisplayConfig$.asObservable();
    }

    setSelectedPropertyDisplayConfig(singlePropDisplayConfig: DisplayConfig, activeUser: User) {
      this.storeSelectedView(singlePropDisplayConfig, activeUser);
      this.selectedPropertyDisplayConfig$.next(singlePropDisplayConfig)
    }




    /* setupViewConfigs(controllers: Controller[]) {
      const viewConfigs: DisplayConfig[] = this.frontendSettings$.getValue().settings.propertyDisplayConfigs;
      const filteredConfigs = this.filterViewConfigs(controllers, viewConfigs)
      this.filteredPropertyDisplayConfigs$.next(filteredConfigs);
      this.selectedPropertyDisplayConfig$.next(filteredConfigs[0]);
    } */


    setupViewConfigs() {
      const viewConfigs: DisplayConfig[] = this.frontendSettings$.getValue().settings.propertyDisplayConfigs;
      // const filteredConfigs = this.filterViewConfigs(controllers, viewConfigs)
      this.filteredPropertyDisplayConfigs$.next(viewConfigs);
      this.selectedPropertyDisplayConfig$.next(viewConfigs[0]);
    }


    filterViewConfigs(controllers: Controller[], usedViewConfigs: DisplayConfig[]) {
      return usedViewConfigs.map((config: DisplayConfig)=> {
        return {
          name: config.name,
          permissionRequired: config.permissionRequired,
          data: this.filterUnusedConfigData(controllers, config.data)
        }
      })
    }

    filterUnusedConfigData(controllers: Controller[], displayData: DisplayPropertyParams[]) : DisplayPropertyParams[] {
      return displayData.filter((propParam: DisplayPropertyParams)=> {
        if (propParam.mode === 'status') {
          return true
        }
        return Controller.isPropertyInControllers(controllers, propParam.propInfo.codeFrom, propParam.propInfo.codeTo)
      })
    }

     /*
   setupViewConfigs(project: Project) {
      const viewConfigs: DisplayConfig[] = this.frontendSettings$.getValue().settings.propertyDisplayConfigs;
      const filteredConfigs = this.filterViewConfigs(project, viewConfigs)
      this.filteredPropertyDisplayConfigs$.next(filteredConfigs);
      this.selectedPropertyDisplayConfig$.next(filteredConfigs[0]);
    } */


    /* filterViewConfigs(project: Project, usedViewConfigs: DisplayConfig[]) {
      return usedViewConfigs.map((config: DisplayConfig)=> {
        return {
          name: config.name,
          permissionRequired: config.permissionRequired,
          data: this.filterUnusedConfigData(project, config.data)
        }
      })
    } */

    /* filterUnusedConfigData(project, displayData: DisplayPropertyParams[]) : DisplayPropertyParams[] {
      return displayData.filter((propParam: DisplayPropertyParams)=> {
        if (propParam.mode === 'status') {
          return true
        }
        return Project.isPropertyInProject(project, propParam.propInfo.codeFrom, propParam.propInfo.codeTo)
      })
    } */

    storeSelectedView(singlePropDisplayConfig: DisplayConfig, activeUser: User) {
      const storedSelectedViewsString: string = localStorage.getItem(this.selectedViewsLocalStorageKey);
      const newStoredView: StoredView = {
        userId: activeUser.userId,
        view: singlePropDisplayConfig
        }
      if (storedSelectedViewsString === null) {
        const storedView: StoredView [] = [newStoredView]
        localStorage.setItem(this.selectedViewsLocalStorageKey, JSON.stringify(storedView));
      } else {
        const storedSelectedViews: StoredView [] = JSON.parse(storedSelectedViewsString);
        const newStoredViews = storedSelectedViews.filter((storedView) => storedView.userId !== activeUser.userId)
        newStoredViews.push(newStoredView);
        localStorage.setItem(this.selectedViewsLocalStorageKey, JSON.stringify(newStoredViews));
      }
    }

    initializeViewFromStorage(user: User) {
      if (this.loadedViewForUserId !== user.userId) {
        const storedSelectedViews: StoredView [] = JSON.parse(localStorage.getItem(this.selectedViewsLocalStorageKey));
        const storedSelectedViewForUser = storedSelectedViews?.find(
          (storedView: StoredView) => Number(storedView.userId) === Number(user.userId))
        if (storedSelectedViewForUser && user.havePermission(storedSelectedViewForUser.view.permissionRequired)) {
          this.selectedPropertyDisplayConfig$.next(storedSelectedViewForUser.view);
          this.loadedViewForUserId = user.userId;
        } else {
          this.setFirstPropertyDisplayConfigWithPermission(user);
        }
      }
    }

    setFirstPropertyDisplayConfigWithPermission(user: User) {
      const firstConfigWithPermission = this.filteredPropertyDisplayConfigs$.getValue().find((config: DisplayConfig)=> {
        return user.havePermission(config.permissionRequired)
      })
      this.setSelectedPropertyDisplayConfig(firstConfigWithPermission, user);
      // this.selectedPropertyDisplayConfig$.next(firstConfigWithPermission);
    }
}
