import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'filterGroupsForSubobject'
})
export class FilterGroupsForSubobjectPipe implements PipeTransform {

  transform(groups: LocationGroup[], selectedDesignation = '*/*/*/*' ): LocationGroup[] {

   /*    if (targetObjects.length === 0) { // no objects in filter, return all groups
        return groups
      }

      const objects: string[] = targetObjects.map((obj: ProjectObject)=> {
        return obj.designation.split('/')[0]
      })

      if (targetSubobjects.length === 0) { // ignore subobjects filter
        return groups.filter((group)=> {
          return group.locationIds.some((locId)=> {
            return objects.includes(locId.split('/')[0]);
          })
        })
      }

      // both object and subobject filter
      const subObjects: string[] = targetSubobjects.map((subobj: ProjectSubObject)=> {
        return subobj.designation.split('/')[1]
      })

      return groups.filter((group)=> {
        return group.locationIds.some((locId)=> {
          return objects.includes(locId.split('/')[0]) && subObjects.includes(locId.split('/')[1]);
        })
      }) */


    const targetObject = selectedDesignation.split('/')[0];
    const targetSubobject = selectedDesignation.split('/')[1];

    if (!groups) {
      return [];
    }
    if (targetObject === '*' && targetSubobject === '*') {
      return groups;
    }

    if ( targetSubobject === '*') {
      return groups.filter((gr)=> {
        return gr.locationIds.every((locationId)=> {
          const obj = locationId.split('/')[0];
          return Number(obj) === Number(targetObject)
        })
      })
    }

    return groups.filter((gr)=> {
      return gr.locationIds.every((locationId)=> {
        const obj = locationId.split('/')[0];
        const subObj = locationId.split('/')[1];
        return Number(obj) === Number(targetObject) && Number(subObj) === Number(targetSubobject)
      })
    })
  }
}
