<div  class="content-header flex-row margin10 modal-header">
  <h1>{{'Select locations and date for new card' | translate }}</h1>
      <ion-button class="to-right"  (click)="onSave()">{{'Save' | translate}}</ion-button>
</div>


<ion-content
[scrollEvents]="true"
(ionScrollEnd)="onScrollEnd()"
class="main-content"
>
<div>
  <ion-list (keyup.enter)="onSave()" class="br-r-s">
      <ion-item>
        <div class="content-form-group">
          <label class="width-100px">{{'Arrival' | translate}}:</label>
          <div class="date-time-container">
            <input
              [owlDateTimeTrigger]="dtFrom"
              [owlDateTime]="dtFrom"
              (dateTimeInput)="dateTimeInput()"
              [(ngModel)]="selectedDateTime"
              [selectMode]="'rangeFrom'"
              placeholder="{{'Date and time' | translate}}"
            />
            <div *ngIf="fromDateValid" class="red margin-left5">{{'Required' | translate}}</div>
          </div>
          <i class="icon-calendar font-size32" [owlDateTimeTrigger]="dtFrom"></i>
          <owl-date-time
            #dtFrom
            (afterPickerClosed)="onCloseFrom()"
          ></owl-date-time>
        </div>
      </ion-item>
      <ion-item
        ><div class="content-form-group">
          <label class="width-100px">{{'Departure' | translate}}:</label>
          <div class="date-time-container">
            <input
              type="text"
              [owlDateTimeTrigger]="dtTo"
              [owlDateTime]="dtTo"
              (dateTimeInput)="dateTimeInput()"
              [(ngModel)]="selectedDateTime"
              [selectMode]="'rangeTo'"
              placeholder="{{'Date and time' | translate}}"
              />
            <div *ngIf="toDateValid" class="red margin-left5">{{'Required' | translate}}</div>
          </div>
          <i class="icon-calendar font-size32" [owlDateTimeTrigger]="dtTo"></i>

          <owl-date-time
            #dtTo
            (afterPickerClosed)="onCloseTo()"
          ></owl-date-time></div
      ></ion-item>
    </ion-list>
</div>

<div class="margin-left20">
  <ng-container *ngIf="objectsFilterFull$ | async as selectedObjectGlobal" >
  {{'Location' | translate}}
  <select
    class="margin-left20"
    (change)="onChangeObjectSubobjectRooms($event)"
    [ngModel]="selectedRoomDesignation"
  >
    <ng-container *ngFor="let object of (allObjects$ | async) | getObjects: selectedObjectGlobal[0]">
      <option [value]="object.designation">
         {{ object.name }}
      </option>
      <option
        *ngFor="let subObject of object.projectSubobjects.$values"
        [value]="subObject.designation"
      >
       {{ subObject.name }}
      </option>
    </ng-container>
    <option
      [value]="'*/*/*/*'"
    >
      {{ "All" | translate }}
    </option>
  </select>
</ng-container>
</div>

<dts-select-container
#selectContainer="dts-select-container"
class="drag-area"
[(selectedItems)]="selectedRooms"
>
<div class="rooms-data">
<ng-container *ngIf="acControllers$ | async as controllers">
    <ng-container *ngIf="floorList$ | async as floorList">
        <div
        *ngFor=" let floor of floorList | getFloorsForRoomSelection : selectedRoomDesignation"
         class="floor-box">
        <div class="floor-number-box">
          <span [ngClass]="{ 'vertical-text': floor.name }">{{
            floor.name || floor
          }}</span>
        </div>
        <div>
          <div class="flex-row">
            <div
              *ngFor="
                let room of controllers
                  | getRoomsOnFloorForSelection: floor
              "
              [dtsSelectItem]="room.locationId"
              class="selectable-access-control">
              <p>{{ room.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
</dts-select-container>
</ion-content>
<app-modal-confirmation
  [type]="'cardholder'"
  [content]=""
  (decision)="onModalDecision($event)"
  *ngIf="openModal">
</app-modal-confirmation>

<app-modal-confirmation
  [type]="'cardExist'"
  [content]=""
  (decision)="onModalDecisionCardExist($event)"
  *ngIf="cardExist">
</app-modal-confirmation>
