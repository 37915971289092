                 <ion-item lines="none" (click)="toggleValue($event)" class="item-background-color">
  <ion-checkbox
    slot="start"
    class="margin-right5"
    [checked]="
    targetValue || (controllers | findProperty: findPropFunction | propIsActive)
    "
  ></ion-checkbox>
  <ion-label><ng-content></ng-content></ion-label>
</ion-item>
