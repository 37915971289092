import { Pipe, PipeTransform } from '@angular/core';
import { KeyOption, } from 'src/app/modules/project/services/http/api-project.service';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'getFloorsForControllerSelection'
})
export class GetFloorsForControllerSelectionPipe implements PipeTransform {

  transform(allFloors: LocationGroup[], keyOptions: KeyOption[],
    selectedDesignation: string): LocationGroup[] {

    const targetObj: string = selectedDesignation.split('/')[0];
      const targetSubobj: string = selectedDesignation.split('/')[1];

      const floorsInObjectAndSuboject = allFloors.filter((flo)=> {
        return flo.locationIds.some((locId: string)=> {
          const floorObject = locId.split('/')[0];
          const floorSubobject = locId.split('/')[1];
          return (targetObj === floorObject || targetObj === '*')  && (targetSubobj === floorSubobject || targetSubobj === '*')
        })
      })



      //
      /* fList = fList.filter((f)=> {
        const floorListObject = f.locationIds[0].split('/')[0];
        const floorListSubObject = f.locationIds[0].split('/')[1];
        const targetObj: string = targetObject.designation.split('/')[0];
        const targetSubobj: string = targetObject.designation.split('/')[1];
        return Number(floorListObject) === Number(targetObj) && Number(floorListSubObject) === Number(targetSubobj)
      }).slice();
      console.log(fList) */


        const floorList = floorsInObjectAndSuboject.slice();
        const controllersOnFloors:string[]  = floorList.reduce((flList,floor)=> {
            flList = [...floor.locationIds, ...flList]
            return flList;
          },[])
        const floorForUndistributedControllers: LocationGroup = {
            id: 9999,
            name: 'Undistributed',
            locationGroupTypeId: 3,
            locationIds: []
          };

          keyOptions.forEach((keyOption : KeyOption)=> {
            // const controllerObj: string = cont.designation.split('/')[0];
            // const controllerSubobj: string = cont.designation.split('/')[1];
            if ((targetObj === keyOption.controller.object || targetObj === '*' )
            && (targetSubobj === keyOption.controller.subObject || targetSubobj === '*') &&
             !controllersOnFloors.includes(keyOption.controller.designation)) {
              floorForUndistributedControllers.locationIds.push(keyOption.controller.designation);
            }
          })



          if (floorForUndistributedControllers.locationIds.length>0) {
            floorList.push(floorForUndistributedControllers)
          }
          return floorList;

    /* let floorsForSubobject;
    if (Number(targetObject) < 0 && Number(targetSubobject) < 0) {
      floorsForSubobject = allFloors;
    }
    else if ( Number(targetSubobject) < 0) {
      floorsForSubobject = allFloors.filter((fl)=> {
        return fl.locationIds.every((locationId)=> {
          const obj = locationId.split('/')[0];
          return Number(obj) === Number(targetObject)
        })
      })
    } else {
      floorsForSubobject = allFloors.filter((f) => {
        const floorListObject = f.locationIds[0].split('/')[0];
        const floorListSubObject = f.locationIds[0].split('/')[1];
        return (
          Number(floorListObject) === Number(targetObject) && Number(floorListSubObject) === Number(targetSubobject)
        );
      })
      .slice();
    } */
   /*  if (!floorsForSubobject || floorsForSubobject.length === 0) {
      // no floorlist, generates floor list
      return Project.getGeneratedFloors(
        project,
        targetObject,
        targetSubobject
      ).slice();
    } else { */
     /*  const floorList = floorsForSubobject.slice();
      const controllersOnFloors: string[] = floorList.reduce(
        (flList, floor) => {
          flList = [...floor.locationIds, ...flList];
          return flList;
        },
        []
      );
      const floorForUndistributedControllers: LocationGroup = {
        id: 99999,
        name: 'Undistributed',
        locationGroupTypeId: 3,
        locationIds: [],
      };

      project.controllers.forEach((driver) => {
        driver[1].forEach((object) => {
          if (Number(object[0]) === Number(targetObject) || Number(targetObject) < 0 ) {
            object[1].forEach((subObj) => {
              if (Number(subObj[0]) === Number(targetSubobject) || Number(targetSubobject) < 0) {
                subObj[1].forEach((zone) => {
                  zone[1].forEach((subZone) => {
                    if (!controllersOnFloors.includes(subZone[1].locationId)) {
                      floorForUndistributedControllers.locationIds.push(
                        subZone[1].locationId
                      );
                    }
                  });
                });
              }
            });
          }
        });
      });
      if (floorForUndistributedControllers.locationIds.length > 0) {
        floorList.push(floorForUndistributedControllers);
      }
      return floorList; */
   /*  } */
  }

}
