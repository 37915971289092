import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'getRoomsOnFloorForSelection'
})
export class GetRoomsOnFloorForSelectionPipe implements PipeTransform {

  transform(
    guestRoomControllers: Controller[], fl: LocationGroup  ): Controller[] {

    if (guestRoomControllers.length === 0) {
      return  [];
    }
    return this.getRoomNumbersForFloor(guestRoomControllers, fl)

  }



  getRoomNumbersForFloor(controllers: Controller [],fl:LocationGroup) {
      // const floor: LocationGroup = fl as LocationGroup;
      // const controllersOnFloor: Controller[] = [];

      /* controllers.forEach((cont)=> {
        if (floor.locationIds.includes(cont.designation)) {
          controllersOnFloor.push(cont)
        }
      }) */

      const controllersOnFloor = controllers.filter((cont )=> {
        return fl.locationIds.includes(cont.designation)
      })

      const locationIdsOnFloor = [];
      return controllersOnFloor.reduce(( roomDataOnFloor, controller: Controller) => {
        // const roomNumber = controller.zone;
        if (!locationIdsOnFloor.includes(controller.designation) && fl.locationIds.includes(controller.designation)) {
          locationIdsOnFloor.push(controller.designation)
          // const roomNumber = controller.zone;
          roomDataOnFloor.push(controller)
        }
        return roomDataOnFloor
      }, [])
  }


}
