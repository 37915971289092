import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss']
})
export class BooleanInputComponent implements OnInit, OnDestroy {
  @Input() controllers: Controller[];
  @Input() findPropFunction: (property: Property) => boolean;

  inputDelay = 2000;
  targetValue: boolean;
  valueSet$ = new Subject<boolean>();
  valueSubscription = new Subscription();
  resetTargetSubscription = new Subscription();

  constructor(private apiProjectService: ApiProjectService) { }

  ngOnInit(): void {
    this.valueSubscription = this.valueSet$
      .pipe(
        debounceTime(this.inputDelay),
       // distinctUntilChanged()
       )
      .subscribe((newValue) => {
        let property: Property;
        const parentController: Controller = this.controllers.find(
          (controller: Controller) => {
            property = controller.controllerProperties.$values.find(this.findPropFunction);
            return property;
          }
        );
        const designation = parentController.designation;
        const propertyId = property.id;
        const value = (newValue === true? 1 : 0 );
        this.apiProjectService
          .changeProperty(designation, propertyId, value)
          .subscribe();
      });
    this.resetTargetSubscription = this.valueSet$
      .pipe(debounceTime(5000))
      .subscribe(() => {
        this.targetValue = null;
      });
  }

  toggleValue(event) {
    this.targetValue = event.target.checked;
    // event.preventDefault();
    // event.stopPropagation();
    this.valueSet$.next(this.targetValue);
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
    this.resetTargetSubscription.unsubscribe();
  }

}
