<div class="flex-column margin-top5 modal-content-container">
  <div class="flex-row-space-evenly width100">
    <ion-list *ngIf="controllers.length > 1" class="margin20 width-150">
      <ion-list-header>{{'Controllers' | translate}}</ion-list-header>
      <ion-item button *ngFor="let controller of controllers" (click)="selectController(controller)" [ngClass]="{
          'ion-list-activated': selectedControllers[0].id === controller.id
        }">
        {{ controller.name }}
      </ion-item>
    </ion-list>

    <ng-container>
      <div class="flex-column margin-left10 margin-right10 width100">
        <ion-card-title class="flex-row-center-h full-minus-20 padding5 backgroundBlue">
          {{ selectedControllers[0].name }}
        </ion-card-title>
      </div>

      <div class="flex-row margin-top25 border-bottom-gray width90">
        <div class="prop-container-first">
          <div class="prop-container-first-title">{{'LIGHTS' | translate}}</div>
        </div>
        <ng-container *ngIf="controllers | findProperty: isLight as lights">
          <ng-container *ngFor="let light of lights">
            <div *ngIf="selectedControllers[0] | outputModeForLightIsControlPipe : light : isOutputMode : outputModesEnum.OutputControl;else lightButtons" class="prop-container-for-button" (click)="lightOnOff(light)">
              <div class="prop-title">{{ light.name }}</div>
              <div *ngIf="light | propIsActive; else lightOff" title="Light on" class="icon-welcome-light font-size20 yellow"></div>
              <ng-template #lightOff>
                <div title="Light off" class="icon-welcome-light font-size20"></div>
              </ng-template>
            </div>
            <ng-template #lightButtons>
              <div class="prop-container">
                <div class="prop-title">{{ light.name }}</div>
                <div *ngIf="light | propIsActive; else lightOff" title="Light on" class="icon-welcome-light font-size20 yellow"></div>
                <ng-template #lightOff>
                  <div title="Light off" class="icon-welcome-light font-size20"></div>
                </ng-template>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="controllers | findProperty: isLuxomatBrodcast as luxomats">
          <ng-container *ngFor="let luxomat of luxomats">
              <div class="prop-container">
                <div class="prop-title">{{ luxomat.name }}</div>
                <div *ngIf="luxomat | propIsActive; else luxomatBrodcastOff" title="Luxomat on" class="icon-moon_and_sun font-size32 yellow"></div>
                <ng-template #luxomatBrodcastOff>
                  <div title="Luxomat off" class="icon-moon_and_sun font-size32 gray"></div>
                </ng-template>
              </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="controllers | findProperty: isLuxomat as luxomats">
          <ng-container *ngFor="let luxomat of luxomats">
              <div class="prop-container">
                <div class="prop-title">{{ luxomat.name }}</div>
                <div *ngIf="luxomat | propIsActive; else luxomatOff" title="Luxomat on" class="icon-moon_and_sun font-size32 yellow"></div>
                <ng-template #luxomatOff>
                  <div title="Luxomat off" class="icon-moon_and_sun font-size32 gray"></div>
                </ng-template>
              </div>
          </ng-container>
        </ng-container>
     </div>

      <div  *ngIf="loggedInUser | userHasPermission : 8" class="
        full-minus-20
        margin-top10 margin-left10 margin-right10
        flex-stretch">
      <div class="flex-column">
        <ion-card-title
          class="flex-row-center-h width100 padding5 backgroundBlue"
        >
          {{'Programing' | translate}}
        </ion-card-title>
        <ion-card-content class="width100">
            <ion-list class="flex-column-center-h">
              <ng-container *ngIf="controllers | findProperty: isOutputMode as outputModes">
                <div class="flex-row-center-v" *ngFor="let outputMode of outputModes">
                  <div class="flex-row-center-v">
                    <h2>{{ outputMode.name }}</h2>
                    <select class="custom-select margin-left10" [(ngModel)]="outputMode.value" (change)="modeSelected($event, outputMode)" >
                      <option [value]="''" disabled>Select output mode</option>
                      <option [value]="outputModesEnum.Off"> {{ getStatusName(outputModesEnum.Off) }}</option>
                      <option [value]="outputModesEnum.On"> {{ getStatusName(outputModesEnum.On) }}</option>
                      <option [value]="outputModesEnum.Luxomat"> {{ getStatusName(outputModesEnum.Luxomat) }}</option>
                      <option [value]="outputModesEnum.TimeInterval"> {{ getStatusName(outputModesEnum.TimeInterval) }}</option>
                      <option [value]="outputModesEnum.LuxomatAndTimeInterval"> {{ getStatusName(outputModesEnum.LuxomatAndTimeInterval) }}</option>
                      <option [value]="outputModesEnum.OutputControl"> {{ getStatusName(outputModesEnum.OutputControl) }}</option>
                      <option [value]="outputModesEnum.PIR"> {{ getStatusName(outputModesEnum.PIR) }}</option>
                      <option [value]="outputModesEnum.KeyboardIN1"> {{ getStatusName(outputModesEnum.KeyboardIN1) }}</option>
                      <option [value]="outputModesEnum.SwitchIN1"> {{ getStatusName(outputModesEnum.SwitchIN1) }}</option>
                      <option [value]="outputModesEnum.LuxomatAndPIR"> {{ getStatusName(outputModesEnum.LuxomatAndPIR) }}</option>
                      <option [value]="outputModesEnum.TimeIntervalAndPIR"> {{ getStatusName(outputModesEnum.TimeIntervalAndPIR) }}</option>
                    </select> 
                    <div title="Copy" class="icon-copy font-size32 margin-left 10" (click)="copyProperty(outputMode)"></div>

                  </div>
                  <ng-container *ngIf="outputModeWithInterval(outputMode)">
                    <app-select-time-common-room
                    class="margin-left10 padding10"
                    (setTime$)="procesTimeObject($event)"
                    [denyFrom]="getDenyFromProperty(outputMode,isOutputStartTimeT1)"
                    [denyTo]="getDenyToProperty(outputMode,isOutputEndTimeT1)">
                    </app-select-time-common-room>
                    <app-select-time-common-room
                    class="margin-left10 padding10"
                    (setTime$)="procesTimeObject($event)"
                    [denyFrom]="getDenyFromProperty(outputMode,isOutputStartTimeT2)"
                    [denyTo]="getDenyToProperty(outputMode,isOutputEndTimeT2)">
                    </app-select-time-common-room>
                  </ng-container>

                </div>
                <div class="flex-row-center-v">
                  <ion-button
                  class="margin5"
                  color="light"
                  (click)="syncHvac()"
                  size="small"
                  ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
                  {{'Settings sync' | translate}}</ion-button>

                  <ion-item lines="none" class="item-background-color">
                    <ion-checkbox
                      (ionChange)="changeControllerPooling($event)"
                      slot="start"
                      class="margin-right5"
                      [checked]="stopControllerPolling"
                    ></ion-checkbox>
                    {{ "Stop controller polling" | translate }}
                  </ion-item>
                </div>
              </ng-container>
            </ion-list>          
        </ion-card-content>
      </div>
    </div>
    </ng-container>
  </div>
</div>