import { Pipe, PipeTransform } from '@angular/core';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'filterByGroupType'
})
export class FilterByGroupTypePipe implements PipeTransform {

  transform(groups: LocationGroup[], groupType: LocationGroupType): LocationGroup[] {
    if (!groups) {
      return [];
    }

    if (!groupType ) {
      return groups;
    }

    if ( groupType < 0 ) { // all non floor groups
      return groups.filter((group)=> {
        return group.locationGroupTypeId === LocationGroupType.AccessGroup ||
        group.locationGroupTypeId === LocationGroupType.CustomGroup ||
        group.locationGroupTypeId === LocationGroupType.HvacModeGroup;
      })
    }

    return groups.filter((group)=> {
      return group.locationGroupTypeId === groupType;
    })
  }

}
