import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlarmAlertsService } from '../../services/alarm-alerts.service';
import { insertRemoveTrigger } from 'src/app/shared/animations/animations';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';

@Component({
  selector: 'app-alarm-alerts',
  templateUrl: './alarm-alerts.component.html',
  animations: [insertRemoveTrigger],
  styleUrls: ['./alarm-alerts.component.scss']
})
export class AlarmAlertsComponent implements OnInit, OnDestroy {
  alarms: AlarmAlert[] = [];
  newAlarmSubscription: Subscription;
  constructor(private alarmsService: AlarmAlertsService) { }

  ngOnInit(): void {
    this.getAlarms();
  }

  getAlarms() {
    this.newAlarmSubscription = this.alarmsService.alarmAdded.subscribe(alarm => {
      const lastIndex = this.alarms.length;
      this.alarms.push(alarm);
      let timeout = 1000;
      switch(alarm.level) {
        case 1: {
          timeout = 5000;
          break;
        }
        case 2: {
          timeout = 10000;
          break;
        }
        case 3: {
          timeout = 15000;
          break;
        }
        default: {
          break;
        }
      }
      setTimeout(() => {
        alarm.displayAlert = false;
        this.alarms[lastIndex] = alarm;
      }, timeout)
    })
  }

  get anyAlertForDisplay() {
    return this.alarms.some(alarm => alarm.displayAlert);
  }

  //TODO
  displayAlert(i: number) {
    let c = 0;
    let lastIndexToDisplay = this.alarms.length;
    let show = true;
    this.alarms.slice().reverse().forEach(a => {
      a.displayAlert ? c++ : null;
      if (c <= 7) {
        lastIndexToDisplay--;
      }
    });
    if (i < lastIndexToDisplay) {
      show = false;
    }
    return show;
  }

  confirmAlarm(index: number) {
    let alarm = this.alarms[index];
    alarm.confirmed = true;
    alarm.displayAlert = false;
    this.alarmsService.updateAlarm(index, alarm);
  }

  ngOnDestroy() {
    this.newAlarmSubscription.unsubscribe();
  }

}
