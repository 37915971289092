<div class="flex-row-space-evenly margin-top30 full-height">
  <div class="width90 flex-row">
    <div *ngIf="controllers.length > 1" class="flex-column width12">
        <ion-list class="width-150">
        <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
        <ion-item button
        (click)="selectAllControllers()"
        [ngClass]="{
          'ion-list-activated':
          selectedControllers.length > 1
        }"
        >{{'All' | translate}}</ion-item>
        <ion-item
          button
          *ngFor="let controller of controllers"
          (click)="selectControllers(controller)"
          [ngClass]="{
            'ion-list-activated':
            selectedControllers[0].id === controller.id && selectedControllers.length === 1
          }"
        >
          {{ controller.name }}
        </ion-item>
      </ion-list>
    </div>

<!-- STATUSES -->
    <ng-container *ngIf="controllers.length == 1;else moreControllers">
      <div
      class="flex-column"
      [ngClass]="[controllers.length > 1 ? 'width22' : 'width25']">
      <div class="margin-bottom30">{{'STATUSES' | translate}}</div>

      <!-- DO NOT DISTURB -->



      <app-modal-view-boolean-prop
        [controllers]="selectedControllers"
        [propFilterFunction]="Property.isDndStatus"
        [activeText]="'Do not disturb on'"
        [inactiveText]="'Do not disturb off'"
        [iconActive]="'icon-forbidden'"
        [iconInactive]="'icon-forbidden'">
      </app-modal-view-boolean-prop>

        <!-- ROOM ARMED -->

      <app-modal-view-boolean-prop
        [controllers]="selectedControllers"
        [propFilterFunction]="Property.isRoomArmed"
        [activeText]="'Room armed'"
        [inactiveText]="'Room not armed'"
        [iconActive]="'icon-room-armed'"
        [iconInactive]="'icon-room-armed'">
      </app-modal-view-boolean-prop>

      <!--CARD DEPOSITED -->

      <app-modal-view-boolean-prop
        [controllers]="selectedControllers"
        [propFilterFunction]="Property.isCardDeposited"
        [activeText]="'Card deposited'"
        [inactiveText]="'Card not deposited'"
        [iconActive]="'icon-card'"
        [iconInactive]="'icon-card'">
      </app-modal-view-boolean-prop>

      <!--MAKE UP ROOM -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isMakeUpRoom"
      [activeText]="'Make up room active'"
      [inactiveText]="'Make up room inactive'"
      [iconActive]="'icon-broom'"
      [iconInactive]="'icon-broom'">
    </app-modal-view-boolean-prop>

      <!-- NEW CARD -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isNewCard"
      [activeText]="'New card'"
      [inactiveText]="'No new card'"
      [iconActive]="'icon-card'"
      [iconInactive]="'icon-card'">
    </app-modal-view-boolean-prop>

    <!--CARD TYPE -->

      <ng-container *ngIf="controllers | findProperty: Property.isCardType as cardTypeProps">
      <div *ngFor="let cardTypeProp of cardTypeProps" class="status-container-small flex-row-center-v margin-bottom12 width200">
        <div class="icon-card icon-background-off font-size19"></div>
        <div class="status-text-off">
        {{ cardTypeProp.name | translate }} -
         {{ cardTypeProp.value | getCardTypeNameByCode: cardTypes }}
        </div>
      </div>
    </ng-container>


    </div>

    <!-- DOORS AND WINDOWS -->

    <div class="flex-column"
    [ngClass]="[controllers.length > 1 ? 'width22' : 'width25']">
      <div class="margin-bottom30">
        {{'DOORS' | translate}} {{'AND' | translate}} {{'WINDOWS' | translate}}</div>

        <!-- WINDOWS -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isWindowOpened"
      [activeText]="'Window open'"
      [inactiveText]="'Window closed'"
      [iconActive]="'icon-window-open'"
      [iconInactive]="'icon-window-closed'">
    </app-modal-view-boolean-prop>

      <!-- DOORS -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isDoorOpened"
      [activeText]="'Door open'"
      [inactiveText]="'Door closed'"
      [iconActive]="'icon-door_opened_status'"
      [iconInactive]="'icon-door'">
    </app-modal-view-boolean-prop>
    </div>

    <!-- HVAC -->
    <div class="flex-column"
    [ngClass]="[controllers.length > 1 ? 'width22' : 'width25']">
      <div class="margin-bottom30"> HVAC</div>

      <!-- ROOM TEMPERATURE CURRENT -->

      <app-modal-view-numeric-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isRoomTemperatureCurrent"
      >
      </app-modal-view-numeric-prop>

      <!-- ROOM TEMPERATURE SET -->

      <app-modal-view-numeric-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isRoomTemperatureSet"
      >
      </app-modal-view-numeric-prop>

      <!-- HVAC ACTIVE -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isHvacActive"
      [activeText]="'Hvac on'"
      [inactiveText]="'Hvac off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

      <!-- HVAC HEATING/COOLING -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isHvacHeatCool"
      [activeText]="'Cooling mode'"
      [inactiveText]="'Heating mode'"
      [iconActive]="'icon-snowflake'"
      [iconInactive]="'icon-snowflake'">
    </app-modal-view-boolean-prop>

    <!-- HVAC HEAT COOL AUTO -->

    <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isHvacHeatCoolAuto"
      [activeText]="'Hvac Auto on'"
      [inactiveText]="'Hvac Auto off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

    <!-- <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCoolAuto as hvacHeatCoolAutoProps">
      <div *ngFor="let hvacHeatCoolAutoProp of hvacHeatCoolAutoProps" class="status-container-small flex-row-center-v margin-bottom12 width200">
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() === '0'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div class="status-text-off">
            <span class="status-text-on">HEAT </span>/
            <span class="status-text-off">Cool </span>/
            <span class="status-text-off">Auto</span>
            </div>
        </ng-container>
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() === '1'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div >
            <span class="status-text-off">Heat </span>/
            <span class="status-text-on">COOL </span>/
            <span class="status-text-off">Auto</span>
            </div>
        </ng-container>
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() === '2'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div class="status-text-off">
            <span class="status-text-off">Heat </span>/
            <span class="status-text-of">Cool </span>/
            <span class="status-text-on">AUTO</span>
            </div>
        </ng-container>
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() !== '0' && hvacHeatCoolAutoProp?.value.toString() !== '1' && hvacHeatCoolAutoProp?.value.toString() !== '2'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div class="status-text-off">
            ?
            <span class="status-text-off"> Heat </span>/
            <span class="status-text-of">Cool </span>/
            <span class="status-text-off">Auto</span>
            </div>
        </ng-container>
      </div>
    </ng-container> -->

      <!-- HVAC FANCOIL V1 -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isFancoilV1"
      [activeText]="'Fancoil V1 on'"
      [inactiveText]="'Fancoil V1 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop>

      <!-- HVAC FANCOIL V2 -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isFancoilV2"
      [activeText]="'Fancoil V2 on'"
      [inactiveText]="'Fancoil V2 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop>

      <!-- HVAC FANCOIL V3 -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isFancoilV3"
      [activeText]="'Fancoil V3 on'"
      [inactiveText]="'Fancoil V3 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop>

      <!-- ROOM FLOOR HEATING -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isRoomFloorHeating"
      [activeText]="'Room floor heating on'"
      [inactiveText]="'Room floor heating off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

      <!-- BATHROOM CURRENT TEMPERATURE -->

      <app-modal-view-numeric-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isBathroomTemperatureCurrent"
      >
      </app-modal-view-numeric-prop>


      <!-- BATHROOM SET TEMPERATURE -->

      <app-modal-view-numeric-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isBathroomTemperatureSet"
      >
      </app-modal-view-numeric-prop>

      <!-- BATHROOM RADIATOR -->

      <app-modal-view-boolean-prop
      [controllers]="selectedControllers"
      [propFilterFunction]="Property.isBathroomRadiator"
      [activeText]="'Bathroom radiator on'"
      [inactiveText]="'Bathroom radiator off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

    <!--VRV -->

    <ng-container
        *ngIf="
          controllers | findProperty : Property.isVrvOnOff as vrvOnOffProps
        "
      >
        <ng-container
          *ngIf="
            controllers | findProperty : Property.isVrvSpeed as vrvSpeedProps
          "
        >
          <ng-container *ngIf="vrvOnOffProps.length > 0">
            <div
              class="status-container-small flex-row-center-v margin-bottom12 width200"
            >
            <!-- VRV ON -->
              <ng-container
                *ngIf="vrvOnOffProps[0] | propIsActive; else vrvOff"
              >
                <!-- VRV HIGH -->
                <ng-container *ngIf="vrvSpeedProps | propIsActive; else vrvLow">
                  <div class="icon-fan icon-background-on font-size19"></div>
                  <div class="status-text-on">{{ "Vrv high" | translate }}</div>
                </ng-container>
                 <!-- VRV LOW -->
                <ng-template #vrvLow>
                  <div class="icon-fan icon-background-on font-size19"></div>
                  <div class="status-text-on">{{ "Vrv low" | translate }}</div>
                </ng-template>
              </ng-container>
              <!-- VRV OFF -->
              <ng-template #vrvOff>
                <div class="icon-fan icon-background-off font-size19"></div>
                <div class="status-text-off">{{ "Vrv off" | translate }}</div>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    </ng-container>

    <ng-template #moreControllers>
      <div
      class="flex-column"
      [ngClass]="[controllers.length > 1 ? 'width22' : 'width25']">
      <div class="margin-bottom30">{{'STATUSES' | translate}}</div>


      <div *ngFor="let controller of selectedControllers">

        <div>
          {{controller.name}}
        </div>
              <!-- DO NOT DISTURB -->
      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isDndStatus"
      [activeText]="'Do not disturb on'"
      [inactiveText]="'Do not disturb off'"
      [iconActive]="'icon-forbidden'"
      [iconInactive]="'icon-forbidden'">
    </app-modal-view-boolean-prop>

      <!-- ROOM ARMED -->

    <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isRoomArmed"
      [activeText]="'Room armed'"
      [inactiveText]="'Room not armed'"
      [iconActive]="'icon-room-armed'"
      [iconInactive]="'icon-room-armed'">
    </app-modal-view-boolean-prop>

    <!--CARD DEPOSITED -->

    <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isCardDeposited"
      [activeText]="'Card deposited'"
      [inactiveText]="'Card not deposited'"
      [iconActive]="'icon-card'"
      [iconInactive]="'icon-card'">
    </app-modal-view-boolean-prop>

    <!--MAKE UP ROOM -->

    <app-modal-view-boolean-prop
    [controllers]="[controller]"
    [propFilterFunction]="Property.isMakeUpRoom"
    [activeText]="'Make up room active'"
    [inactiveText]="'Make up room inactive'"
    [iconActive]="'icon-broom'"
    [iconInactive]="'icon-broom'">
  </app-modal-view-boolean-prop>

    <!-- NEW CARD -->

    <app-modal-view-boolean-prop
    [controllers]="[controller]"
    [propFilterFunction]="Property.isNewCard"
    [activeText]="'New card'"
    [inactiveText]="'No new card'"
    [iconActive]="'icon-card'"
    [iconInactive]="'icon-card'">
  </app-modal-view-boolean-prop>

  <!--CARD TYPE -->

    <ng-container *ngIf="[controller] | findProperty: Property.isCardType as cardTypeProps">
    <div *ngFor="let cardTypeProp of cardTypeProps" class="status-container-small flex-row-center-v margin-bottom12 width200">
      <div class="icon-card icon-background-off font-size19"></div>
      <div class="status-text-off">
      {{ cardTypeProp.name | translate }} -
       {{ cardTypeProp.value | getCardTypeNameByCode: cardTypes }}
      </div>
    </div>
  </ng-container>
      </div>

    </div>

    <!-- DOORS AND WINDOWS -->

    <div class="flex-column"
    [ngClass]="[controllers.length > 1 ? 'width22' : 'width25']">
      <div class="margin-bottom30">
        {{'DOORS' | translate}} {{'AND' | translate}} {{'WINDOWS' | translate}}</div>

      <div *ngFor="let controller of selectedControllers">

        <div>
          {{controller.name}}
        </div>

        <app-modal-view-boolean-prop
        [controllers]="[controller]"
        [propFilterFunction]="Property.isWindowOpened"
        [activeText]="'Window open'"
        [inactiveText]="'Window closed'"
        [iconActive]="'icon-window-open'"
        [iconInactive]="'icon-window-closed'">
      </app-modal-view-boolean-prop>

        <!-- DOORS -->

        <app-modal-view-boolean-prop
        [controllers]="[controller]"
        [propFilterFunction]="Property.isDoorOpened"
        [activeText]="'Door open'"
        [inactiveText]="'Door closed'"
        [iconActive]="'icon-door_opened_status'"
        [iconInactive]="'icon-door'">
      </app-modal-view-boolean-prop>
      </div>
        <!-- WINDOWS -->
    </div>

    <!-- HVAC -->
    <div class="flex-column"
    [ngClass]="[controllers.length > 1 ? 'width22' : 'width25']">
      <div class="margin-bottom30"> HVAC</div>

      <div *ngFor="let controller of selectedControllers">
        <div>
          {{controller.name}}
        </div>

              <!-- ROOM TEMPERATURE CURRENT -->

      <app-modal-view-numeric-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isRoomTemperatureCurrent"
      >
      </app-modal-view-numeric-prop>

      <!-- ROOM TEMPERATURE SET -->

      <app-modal-view-numeric-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isRoomTemperatureSet"
      >
      </app-modal-view-numeric-prop>

      <!-- HVAC ACTIVE -->

      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isHvacActive"
      [activeText]="'Hvac on'"
      [inactiveText]="'Hvac off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

      <!-- HVAC HEATING/COOLING -->

      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isHvacHeatCool"
      [activeText]="'Cooling mode'"
      [inactiveText]="'Heating mode'"
      [iconActive]="'icon-snowflake'"
      [iconInactive]="'icon-snowflake'">
    </app-modal-view-boolean-prop>

    <!-- HVAC HEAT COOL AUTO -->

    <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isHvacHeatCoolAuto"
      [activeText]="'Hvac Auto on'"
      [inactiveText]="'Hvac Auto off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

    <!-- <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCoolAuto as hvacHeatCoolAutoProps">
      <div *ngFor="let hvacHeatCoolAutoProp of hvacHeatCoolAutoProps" class="status-container-small flex-row-center-v margin-bottom12 width200">
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() === '0'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div class="status-text-off">
            <span class="status-text-on">HEAT </span>/
            <span class="status-text-off">Cool </span>/
            <span class="status-text-off">Auto</span>
            </div>
        </ng-container>
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() === '1'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div >
            <span class="status-text-off">Heat </span>/
            <span class="status-text-on">COOL </span>/
            <span class="status-text-off">Auto</span>
            </div>
        </ng-container>
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() === '2'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div class="status-text-off">
            <span class="status-text-off">Heat </span>/
            <span class="status-text-of">Cool </span>/
            <span class="status-text-on">AUTO</span>
            </div>
        </ng-container>
        <ng-container *ngIf="hvacHeatCoolAutoProp?.value.toString() !== '0' && hvacHeatCoolAutoProp?.value.toString() !== '1' && hvacHeatCoolAutoProp?.value.toString() !== '2'">
          <div class="icon-temp icon-background-off font-size19"></div>
          <div class="status-text-off">
            ?
            <span class="status-text-off"> Heat </span>/
            <span class="status-text-of">Cool </span>/
            <span class="status-text-off">Auto</span>
            </div>
        </ng-container>
      </div>
    </ng-container> -->

      <!-- HVAC FANCOIL V1 -->

      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isFancoilV1"
      [activeText]="'Fancoil V1 on'"
      [inactiveText]="'Fancoil V1 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop>

      <!-- HVAC FANCOIL V2 -->

      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isFancoilV2"
      [activeText]="'Fancoil V2 on'"
      [inactiveText]="'Fancoil V2 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop>

      <!-- HVAC FANCOIL V3 -->

      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isFancoilV3"
      [activeText]="'Fancoil V3 on'"
      [inactiveText]="'Fancoil V3 off'"
      [iconActive]="'icon-fan'"
      [iconInactive]="'icon-fan'">
    </app-modal-view-boolean-prop>

      <!-- ROOM FLOOR HEATING -->

      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isRoomFloorHeating"
      [activeText]="'Room floor heating on'"
      [inactiveText]="'Room floor heating off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

      <!-- BATHROOM CURRENT TEMPERATURE -->

      <app-modal-view-numeric-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isBathroomTemperatureCurrent"
      >
      </app-modal-view-numeric-prop>


      <!-- BATHROOM SET TEMPERATURE -->

      <app-modal-view-numeric-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isBathroomTemperatureSet"
      >
      </app-modal-view-numeric-prop>

      <!-- BATHROOM RADIATOR -->

      <app-modal-view-boolean-prop
      [controllers]="[controller]"
      [propFilterFunction]="Property.isBathroomRadiator"
      [activeText]="'Bathroom radiator on'"
      [inactiveText]="'Bathroom radiator off'"
      [iconActive]="'icon-temp'"
      [iconInactive]="'icon-temp'">
    </app-modal-view-boolean-prop>

    <!--VRV -->

    <ng-container
        *ngIf="
        [controller] | findProperty : Property.isVrvOnOff as vrvOnOffProps
        "
      >
        <ng-container
          *ngIf="
          [controller]
           | findProperty : Property.isVrvSpeed as vrvSpeedProps
          "
        >
          <ng-container *ngIf="vrvOnOffProps.length > 0">
            <div
              class="status-container-small flex-row-center-v margin-bottom12 width200"
            >
            <!-- VRV ON -->
              <ng-container
                *ngIf="vrvOnOffProps[0] | propIsActive; else vrvOff"
              >
                <!-- VRV HIGH -->
                <ng-container *ngIf="vrvSpeedProps | propIsActive; else vrvLow">
                  <div class="icon-fan icon-background-on font-size19"></div>
                  <div class="status-text-on">{{ "Vrv high" | translate }}</div>
                </ng-container>
                 <!-- VRV LOW -->
                <ng-template #vrvLow>
                  <div class="icon-fan icon-background-on font-size19"></div>
                  <div class="status-text-on">{{ "Vrv low" | translate }}</div>
                </ng-template>
              </ng-container>
              <!-- VRV OFF -->
              <ng-template #vrvOff>
                <div class="icon-fan icon-background-off font-size19"></div>
                <div class="status-text-off">{{ "Vrv off" | translate }}</div>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      </div>

    </div>
    </ng-template>


    <div class="flex-column"
    [ngClass]="[controllers.length > 1 ? 'width22' : 'width25']">

     <!-- ALARM BOX RED  -->

      <div class="alarm-box-red">
        <div class="flex-row-center-v margin-bottom5 width100">
          <div class="icon-alert font-size20 red"></div>
          <div class="margin-left20 margin-top5"> {{'Alarms' | translate}}</div>
        </div>

        <ion-button color="dark" size="small" fill="outline" expand="block" class="margin-bottom5 align-self-center clear-btn"
        (click)="clearStatus()">{{'Clear' | translate}}</ion-button>


        <ng-container *ngIf="
          (selectedControllers | findProperty: Property.isSOS | propIsActive) ||
          (selectedControllers | findProperty: Property.isBurglary | propIsActive) ||
          (selectedControllers | findProperty: Property.isSafeBurglary | propIsActive)
        ">
          <div class="flex-column margin-left20">
            <div class="no-wrap flex-row-center-h">
              <div *ngIf="selectedControllers | findProperty: Property.isBurglary | propIsActive">
                <div class="prop-title">{{'Burglary detected' | translate}}</div>
              </div>
              <ng-container *ngIf="selectedControllers | findProperty: Property.isSOS as sosProps">
                <ng-container *ngFor="let sos of sosProps">
                  <div *ngIf="sos | propIsActive">
                    <div class="prop-title">{{ sos.name }}</div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="
              selectedControllers
                  | findProperty: Property.isSafeBurglary as safeBurglaryProps">
                <ng-container *ngFor="let safeBurglaryProp of safeBurglaryProps">
                  <div *ngIf="safeBurglaryProp | propIsActive">
                    <div class="prop-title">{{ safeBurglaryProp.name }}</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>

          </div>
        </ng-container>
      </div>

      <!-- ALARM BOX YELLOW  -->
      <div class="alarm-box-yellow">
        <div class="flex-row-center-v margin-bottom5 width100">
          <div class="icon-alert font-size20 yellow"></div>
          <div class="margin-left20 margin-top5"> {{'Warnings' | translate}}</div>
        </div>

        <div class="flex-row margin-left20" *ngIf="
        (selectedControllers | findProperty: Property.isFlood | propIsActive) ||
        (controllers
          | findProperty: Property.isBurglaryDetectedByPIR
          | propIsActive) ||
        (selectedControllers | findProperty: Property.isSafeCommError | propIsActive) ||
        (controllers
          | findProperty: Property.isBurglaryDetectedDoor
          | propIsActive) ||
        (controllers
          | findProperty: Property.isBurglaryDetectedWindow
          | propIsActive) ||
        (selectedControllers | findProperty: Property.isHVACLargeTempDiffRoom | propIsActive) ||
        (selectedControllers | findProperty: Property.isHVACLargeTempDiffBathroom | propIsActive) ||
        (controllers
          | findProperty: Property.isDoorOpenTooLongAlarm
          | propIsActive) ||
        (controllers
          | findProperty: Property.isWindowOpenTooLongAlarm
          | propIsActive)
      ">
      <ng-container *ngIf="selectedControllers | findProperty: Property.isFlood as isFloodProps">
        <ng-container *ngFor="let floodProp of isFloodProps">
          <div *ngIf="floodProp | propIsActive">
            <div class="prop-title">{{ getAlarmName(floodProp) }}</div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
      selectedControllers
            | findProperty
              : Property.isBurglaryDetectedByPIR as isBurglaryDetectedByPIRProps
        ">
        <ng-container *ngFor="
            let burglaryDetectedByPIRProp of isBurglaryDetectedByPIRProps
          ">
          <div *ngIf="burglaryDetectedByPIRProp | propIsActive">
            <div class="prop-title">
              {{ getAlarmName(burglaryDetectedByPIRProp) }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
          selectedControllers | findProperty: Property.isSafeCommError as safeCommErrorProps
        ">
        <ng-container *ngFor="let safeCommErrorProp of safeCommErrorProps">
          <div *ngIf="safeCommErrorProp | propIsActive">
            <div class="prop-title">{{ getAlarmName(safeCommErrorProp) }}</div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
      selectedControllers
            | findProperty
              : Property.isBurglaryDetectedDoor as isBurglaryDetectedDoorProps
        ">
        <ng-container *ngFor="
            let burglaryDetectedDoorProp of isBurglaryDetectedDoorProps
          ">
          <div *ngIf="burglaryDetectedDoorProp | propIsActive">
            <div class="prop-title">
              {{ getAlarmName(burglaryDetectedDoorProp) }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
      selectedControllers
            | findProperty
              : Property.isBurglaryDetectedWindow as burglaryDetectedWindowProps
        ">
        <ng-container *ngFor="
            let burglaryDetectedWindowProp of burglaryDetectedWindowProps
          ">
          <div *ngIf="burglaryDetectedWindowProp | propIsActive">
            <div class="prop-title">
              {{ getAlarmName(burglaryDetectedWindowProp) }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
      selectedControllers
            | findProperty: Property.isDoorOpenTooLongAlarm as doorOpenTooLongProps
        ">
        <ng-container *ngFor="let doorOpenTooLongProp of doorOpenTooLongProps">
          <div *ngIf="doorOpenTooLongProp | propIsActive">
            <div class="prop-title">
              {{ getAlarmName(doorOpenTooLongProp) }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
      selectedControllers
            | findProperty
              : Property.isWindowOpenTooLongAlarm as windowOpenTooLongProps
        ">
        <ng-container *ngFor="let windowOpenTooLongProp of windowOpenTooLongProps">
          <div *ngIf="windowOpenTooLongProp | propIsActive">
            <div class="prop-title">
              {{ getAlarmName(windowOpenTooLongProp) }}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
          controllers
            | findProperty: Property.isHVACLargeTempDiffRoom as hvacLargeTempDiffRoomProps
        ">
        <ng-container *ngFor="let hvacLargeTempDiffRoomProp of hvacLargeTempDiffRoomProps">
          <div *ngIf="hvacLargeTempDiffRoomProp | propIsActive">
            <div class="prop-title">{{ getAlarmName(hvacLargeTempDiffRoomProp) }}</div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="
          controllers
            | findProperty: Property.isHVACLargeTempDiffBathroom as hvacLargeTempDiffBathroomProps
        ">
        <ng-container *ngFor="let hvacLargeTempDiffBathroomProp of hvacLargeTempDiffBathroomProps">
          <div *ngIf="hvacLargeTempDiffBathroomProp | propIsActive">
            <div class="prop-title">{{ getAlarmName(hvacLargeTempDiffBathroomProp) }}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
      </div>
    </div>


  </div>

</div>
<!-- <app-d3-room [controller]="mainController"></app-d3-room> -->
