<div class="flex-row">
  <div class="content-form-group margin-right50 content-form-group-row">
    <label class="width-100px">{{'From' | translate}}:</label>
    <div class="date-time-container">
    <input
      type="text"
      [owlDateTimeTrigger]="dtFrom"
      [owlDateTime]="dtFrom"
      (dateTimeInput)="dateTimeInput()"
      [(ngModel)]="selectedDateTime"
      [selectMode]="'rangeFrom'"
      placeholder="{{'Date and time' | translate}}">
    <span
      class="delete-btn icon-clear font-size22"
      *ngIf="selectedDateTime[0]!==undefined  && selectedDateTime[0]!==null"
      (click)=onClearDateTime(0) >
    </span>
    </div>
    <i class="icon-calendar font-size32"  [owlDateTimeTrigger]="dtFrom"></i>

    <owl-date-time #dtFrom (afterPickerClosed)="onCloseFrom()"></owl-date-time>
  </div>

  <div class="content-form-group content-form-group-row">

    <label class="width-100px" >{{'To' | translate}}:</label>
    <div class="date-time-container">
      <input
      type="text"
      [owlDateTimeTrigger]="dtTo"
      [owlDateTime]="dtTo"
      (dateTimeInput)="dateTimeInput()"
      [(ngModel)]="selectedDateTime"
      [selectMode]="'rangeTo'"
      placeholder="{{'Date and time' | translate}}">
    <span
      class="delete-btn icon-clear font-size22"
      *ngIf="selectedDateTime[1]!==undefined && selectedDateTime[1]!==null"
      (click)=onClearDateTime(1) >
    </span>
    </div>
    <i class="icon-calendar font-size32"   [owlDateTimeTrigger]="dtTo"></i>

    <owl-date-time #dtTo (afterPickerClosed)="onCloseTo()" ></owl-date-time>
  </div>
</div>
