import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Location } from 'src/app/core/models/project/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  private locations$ = new BehaviorSubject<Location[]>(null);

  constructor() { }


  getLocations() {
    return this.locations$.asObservable();
  }

  setLocations(locations: Location[]) {
    this.locations$.next(locations);
  }
}
