<div class="modal-background-layer">
    <div class="modal-container">
        <div class="modal-header">
            <div [ngSwitch]="type">
                <div *ngSwitchCase="'userdelete'">
                    <h2 >{{'You will delete user' | translate }}:
                      <br>
                      <span class="text-tooltip">
                        <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 18}}</span>
                        <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 18">{{content.firstName + ' ' + content.lastName}}</span>
                      </span>
                      <br>
                    </h2>
                </div>
                <div *ngSwitchCase="'userlistcantdelete'">
                    <h2 >{{'Can't delete user' | translate }}:
                      <br>
                      <span class="text-tooltip">
                        <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 18}}</span>
                        <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 18">{{content.firstName + ' ' + content.lastName}}</span>
                      </span>
                      <br>
                    </h2>
                </div>
                <div *ngSwitchCase="'door'" class="text-tooltip">
                    <h2 >{{'You will delete door' | translate}}:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                    <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'roomSwap'" class="text-tooltip">
                  <h2 >{{'Move guest to room' | translate }}:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
              </div>
                <div *ngSwitchCase="'doorgroup'" class="text-tooltip">
                  <h2 >{{'You will delete door group' | translate}}:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'room'" class="text-tooltip">
                  <h2 >{{'You will delete room' | translate}}: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'role'" class="text-tooltip">
                  <h2 >{{'You will delete role' | translate}}: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'card'">
                  <h2 >{{'You will delete card' | translate}}</h2>
                </div>
                <div *ngSwitchCase="'alarmsubscription'" class="text-tooltip">
                  <h2 class="text-align-center">{{'You will delete alarm subscription' | translate}}</h2>
                </div>
                <div *ngSwitchCase="'doorunlock'" class="text-tooltip">
                    <h2 >{{'You will unlock door' | translate}}:<br> {{content.name | textSizeFilterPipe : 22}}.</h2>
                    <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'cardholder'">
                    <h2 >{{'Put card on card reader' | translate}}</h2>
                </div>
                <div *ngSwitchCase="'cardExist'">
                    <h2 >{{'Card already in use' | translate}}</h2>
                </div>
                <div *ngSwitchCase="'haveassigned'">
                    <h2 >{{'Can't delete. User has assigned permission or roles, revoke or transfer' | translate }}!</h2>
                </div>
                <div *ngSwitchCase="'revokepermissions'">
                    <h2 >{{'You will revoke permissions that' | translate}} <br>
                      <span class="text-tooltip">
                        <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 22}}</span>
                        <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 22">{{content.firstName + ' ' + content.lastName}}</span>
                    </span>
                    <br> {{'assigned' | translate}}!</h2>
                </div>
                <div *ngSwitchCase="'revokeroles'">
                    <h2 >{{'You will revoke roles that' | translate}}
                    <br>
                    <span class="text-tooltip">
                      <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 22}}</span>
                      <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 22">{{content.firstName + ' ' + content.lastName}}</span>
                    </span>
                    <br>
                    {{'assigned' | translate}}!</h2>
                </div>
                <div *ngSwitchDefault>
                    <h2 >{{'You will delete a' | translate}} {{type}}.</h2>
                </div>
            </div>
            <h3 *ngIf="type !== 'cardholder' && type !== 'noassigned' && type !== 'haveassigned' && type !== 'userlistcantdelete' && type !== 'cardExist'">{{'Are you sure' | translate}}?</h3>
            <h3 *ngIf="type === 'userlistcantdelete'">{{'Go to details and resolve assigned permissions/roles' |translate}}!</h3>
        </div>
        <div class="modal-button-box">
            <button *ngIf="type !== 'haveassigned' && type !== 'userlistcantdelete' && type !== 'cardExist'" (click)="decisionClick(false)">{{'Cancel' | translate}}</button>
            <button *ngIf="type === 'haveassigned' || type === 'userlistcantdelete' || type === 'cardExist'" (click)="decisionClick(false)">Ok</button>
            <button *ngIf="type === 'roomSwap'" (click)="decisionClick(true)">Ok</button>
            <button *ngIf="type === 'userdelete' || type === 'door' || type === 'rule' || type === 'role' || type === 'room' || type === 'doorgroup' || type === 'card'|| type === 'alarmsubscription'"  (click)="decisionClick(true)">{{'Delete' | translate}}</button>
            <button *ngIf="type === 'doorunlock'" (click)="decisionClick(true)">{{'Unlock' | translate}}</button>
            <button *ngIf="type === 'revokepermissions' || type === 'revokeroles'" (click)="decisionClick(true)">{{'Revoke' | translate}}</button>
        </div>
    </div>
</div>
