import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AlarmAlertsService } from './modules/alarms/services/alarm-alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from './shared/services/user-settings.service';
import { Platform } from '@ionic/angular';
import { ApiProjectService } from './modules/project/services/http/api-project.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showHeader = true;
  showFooter = true;
  showSidebar = false;
  showMessages = false;
  alarmsFullSize$ = this.alarmAlertsService.getAlarmsSize()

  constructor(private router: Router,
              private translate: TranslateService,
              private alarmAlertsService: AlarmAlertsService,
              private userSettingsService: UserSettingsService,
              public platform: Platform,
              private apiProjectService: ApiProjectService
              ) {

    router.events.subscribe(
      (event) => {
        this.translate.setDefaultLang(this.userSettingsService.getLanguage());
        if (event instanceof NavigationStart) {
        }
        if (event instanceof NavigationEnd) {
          if (event.urlAfterRedirects.includes('login')) {
            this.showHeader = false;
            this.showFooter = false;
            this.showSidebar = false;
            this.showMessages = false;
          }
          else {
            this.showHeader = true;
            this.showFooter = true;
            this.showSidebar = true;
            this.showMessages = true;
          }
          if(!this.platform.is('desktop')) {
            this.showSidebar = false;
            this.showMessages = false;
            this.showFooter = false;
          }
        }
      }
    );
  }

  ngOnInit(): void {
  }

  /* alarmsFullSize() {
    let size = this.alarmAlertsService.getAlarmsSize().getValue()
    return size;
  } */

  isFacilityDetails() {
    if(!this.platform.is('desktop') && (this.router.url.indexOf('/rooms/details') > -1)) {
      return true;
    }
  }
}
