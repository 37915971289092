<ion-content *ngIf="controllers; else loading"  [ngClass]="{offline: !controllers[0].isOnline}">
<ng-container>
  <div class="flex-row-center-v margin-left20 margin-top10">
    <div *ngIf="!roomIsJoined; else roomJoined" class="room-name" [ngClass]="[!controllers[0].isOnline ? 'inactive-icon' : '']">{{controllers[0].zone}}</div>
    <ng-template #roomJoined>
      <div class="room-name-joined" [ngClass]="[!controllers[0].isOnline ? 'inactive-icon' : '']">{{controllers | getJoinedControllersName}}</div>
    </ng-template>
    <ion-item class="margin-left80">
      <ion-segment [(ngModel)]="activeView" (ngModelChange)="tabClicked($event)" >
        <ion-segment-button *ngIf="!(controllers[0] | isAccessControl) && !(controllers[0] | isIoCommonArea)" class="width100" value="view">
          <ion-label class="ion-text-wrap">{{'View' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="signedInUser.havePermission(swPermissions.LocationSettings)" class="width100" value="settings">
          <ion-label *ngIf="(controllers[0] | isIoCommonArea) || (controllers[0] | isAccessControl)" class="ion-text-wrap">{{'View and Settings' | translate}}</ion-label>
          <ion-label *ngIf="!((controllers[0] | isIoCommonArea) || (controllers[0] | isAccessControl))" class="ion-text-wrap">{{'Settings' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="(signedInUser | userHasPermission : swPermissions.LocationDetails) && !(controllers[0] | isIoCommonArea) && !(controllers[0] | isHvacCommonArea)" class="width100" value="cards">
          <ion-label class="ion-text-wrap">{{'Cards' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="signedInUser | userHasPermission : swPermissions.LocationDetails" class="width100" value="log">
          <ion-label class="ion-text-wrap">{{'Log' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button *ngIf="(signedInUser | userHasPermission : swPermissions.LocationsUpdate) && !(controllers[0] | isIoCommonArea) && !(controllers[0] | isHvacCommonArea)" class="width100" value="service">
          <ion-label class="ion-text-wrap">{{'Service' | translate}}</ion-label>
        </ion-segment-button>
      </ion-segment>
  </ion-item>
  <div class="flex-row margin-left150">
    <ion-button *ngIf="(signedInUser | userHasPermission : swPermissions.CardsGuestsAdd) && !(controllers[0] | isHvacCommonArea) && !(controllers[0] | isIoCommonArea) && !(controllers[0] | isAccessControl) && !cardMaxNumberReached" color="light" (click)="newCard()">{{'New card (F9)' | translate}}</ion-button>
    <div *ngIf="cardMaxNumberReached">{{ 'Maximum number of guest cards reached' | translate}}</div>
  </div>
  </div>
  <ng-container *ngIf="activeView === 'view'">
    <app-room-modal-view *ngIf="(controllers[0] | isGuestRoom)" [controllers]="controllers"></app-room-modal-view>
    <app-hvac-common-area-modal-view *ngIf="(controllers[0] | isHvacCommonArea)" [controllers]="controllers"></app-hvac-common-area-modal-view>
  </ng-container>
  <div *ngIf="activeView === 'settings'">
    <app-room-modal-settings *ngIf="(controllers[0] | isGuestRoom)" [controllers]="controllers"></app-room-modal-settings>
    <app-access-control-modal-settings *ngIf="(controllers[0] | isAccessControl)" [controllers]="controllers"></app-access-control-modal-settings>
    <app-io-common-area-modal-settings *ngIf="(controllers[0] | isIoCommonArea)" [controllers]="controllers"></app-io-common-area-modal-settings>
    <app-hvac-common-area-modal-settings *ngIf="(controllers[0] | isHvacCommonArea)" [controllers]="controllers"></app-hvac-common-area-modal-settings>
  </div>
  <div *ngIf="activeView === 'cards'">
    <app-room-modal-cards *ngIf="(controllers[0] | isGuestRoom)" (guestCardsUpdated)="cardsUpdated($event)" [controllers]="controllers" ></app-room-modal-cards>
    <app-access-control-modal-cards *ngIf="(controllers[0] | isAccessControl)" [controllers]="controllers"></app-access-control-modal-cards>
  </div>
  <div *ngIf="activeView === 'log'">
    <app-room-modal-log *ngIf="(controllers[0] | isGuestRoom)" [controllers]="controllers"></app-room-modal-log>
    <app-access-control-modal-log *ngIf="(controllers[0] | isAccessControl)" [controllers]="controllers"></app-access-control-modal-log>
    <app-io-common-area-modal-log *ngIf="(controllers[0] | isIoCommonArea)" [controllers]="controllers"></app-io-common-area-modal-log>
    <app-hvac-common-area-modal-log *ngIf="(controllers[0] | isHvacCommonArea)" [controllers]="controllers"></app-hvac-common-area-modal-log>
  </div>
  <div *ngIf="activeView === 'service'">
    <app-room-modal-service *ngIf="(controllers[0] | isGuestRoom)" [controllers]="controllers"></app-room-modal-service>
    <app-access-control-modal-service *ngIf="(controllers[0] | isAccessControl)" [controllers]="controllers"></app-access-control-modal-service>
  </div>
</ng-container>
</ion-content>
<ng-template #loading>
  <ion-content>
    <div class="flex-row flex-center height100">
      <ion-spinner name="lines-sharp"></ion-spinner>
    </div>
  </ion-content>
</ng-template>
