<div *ngIf="!roomSwap">
<div class="content-container">
  <div class="flex-row-center-full">
    <ion-spinner *ngIf="!tablesReady" class="flex-row-center-full" name="lines-sharp"></ion-spinner>
  </div>
  <div *ngIf="tablesReady" class="flex-row margin-left50 width75">
        <div class="margin-bottom30 uppercase">{{'Access Cards' |translate}}</div>
          <table *ngIf="guestCards.length > 0">
            <tr>
              <th class="uppercase bg-white">{{'Num' | translate}}.</th>
              <th class="uppercase bg-white">{{'Valid from' | translate}}</th>
              <th class="uppercase bg-white">{{'Valid to' | translate}}</th>
              <th class="uppercase bg-white">{{'User' | translate}}</th>
              <th class="uppercase bg-white">{{'Additional passages' | translate}}</th>
              <th class="uppercase bg-white">{{'Cards' | translate}}</th>
            </tr>
              <tr *ngFor="let card of guestCards; let i = index" class="br-gray-b">
                <td class="opacity0-4">{{ getCardPosition(card) }}.</td>
                <td class="opacity0-4">
                  <div class="flex-row-space-between">
                    <span>{{ card.validFrom | date: "dd.MM.yyyy. " }}</span>
                    <span>{{ card.validFrom | date: "HH:mm" }}</span>
                  </div>
                </td>
                <td class="opacity0-4">
                  <div class="flex-row-space-between">
                    <span>{{ card.validTo | date: "dd.MM.yyyy. " }}</span>
                    <span>{{ card.validTo | date: "HH:mm" }}</span>
                  </div>
                </td>
                <td class="opacity0-4">{{ card.user.fullName }}</td>
                <td class="opacity0-4">
                  <ion-button
                  (mouseenter)="showAccess($event, card)"
                  (mouseleave)="showAccess($event, card)"
                  color="primary"
                  class="margin-right5 ion-no-border"
                  fill="outline"
                  size="small"
                  >{{'Show' | translate}}
                </ion-button>
                <div *ngIf="showAccessPopup(card.uid)" class="trigger" [@enterAnimation]>
                  <div class="popup">
                    <div *ngIf="!userToShowInPopup" class="inner">
                      <div class="lds-dual-ring"></div>
                    </div>
                    <div *ngIf="userToShowInPopup" class="inner flex-row">
                      <div *ngFor="let loc of userToShowInPopup.permissions; let i = index;" class="flex-row margin-all5">
                        <div class="br-b">{{loc.locationName}}</div>
                        <div *ngIf="userToShowInPopup.permissions.length !== i+1">,</div>
                      </div>
                      <div *ngIf="userToShowInPopup.permissions.length === 0" class="width100">
                        <div class="text-align-center">{{'Empty' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                </td>
                <td>
                  <ion-button
                  *ngIf="+getCardPosition(card) !== getBackupPosition() && signedInUser | userHasPermission : swPermissions.CardsGuestsAdd"
                  (click)="deleteGuest(card.user)"
                  color="danger"
                  class="margin-right5"
                  fill="outline"
                  size="small">
                    <ion-icon name="close-outline"></ion-icon>
                  </ion-button>

                  <ion-button
                  (click)="syncGuest(card.uid)"
                  color="warning"
                  fill="outline"
                  class="margin-right5"
                  size="small">
                    <ion-icon name="sync"></ion-icon>
                  </ion-button>

                  <ion-button
                  *ngIf="+getCardPosition(card) !== getBackupPosition() && (signedInUser | userHasPermission : swPermissions.CardsGuestsAdd)"
                  (click)="editCard(card.user.userId)"
                  color="primary"
                  fill="outline"
                  size="small">
                    <ion-icon name="create"></ion-icon>
                  </ion-button>
                </td>
              </tr>
          </table>
          <h3 *ngIf="guestCards.length === 0" class="flex-row-center-h margin-top50">
            {{'No cards for access control' | translate}}
          </h3>
        </div>
        <div *ngIf="signedInUser | userHasPermission : swPermissions.CardsGuestsAdd" class="flex-row backup-btn"><ion-button class="to-right" color="light" (click)="createBackupCard()">{{'Create backup card' | translate}}</ion-button></div>
</div>
</div>

<!-- <ion-content
[scrollEvents]="true"
(ionScrollEnd)="onScrollEnd()"
(click)="onScrollEnd()"
class="main-content">
<div class="flex-row">
  <div *ngIf="roomSwap" class="margin-auto margin-top30 margin-bottom30">
    {{'Location' | translate}}
    <select
      class="margin-left20"
      (change)="onChangeObjectSubobjectRooms($event)"
      ngModel="{{selectedObjectRooms}},{{selectedObjectRooms}}"
    >
      <option value="{{mainController.object}},{{mainController.subObject}}">
        {{'Object' | translate}}: {{mainController.object}}
        {{'Subobject' | translate}}: {{mainController.subObject}}
      </option>
      <option value="{{mainController.object}},{{-1}}">{{'Object' | translate}} {{mainController.object}}</option>
      <option value="{{-1}},{{-1}}">{{'All objects and subobjects' | translate}}</option>
    </select>

  </div>
  <ion-button *ngIf="roomSwap" class="margin-right20 margin-top30" size="default" (click)="roomSwap = !roomSwap">{{'Cancel' | translate}}</ion-button>
</div>
<dts-select-container
#selectContainerRooms="dts-select-container"
[hidden]="!roomSwap"
[disableDrag]="true"
[disableRangeSelection]="true"
(itemSelected)="itemSelected($event)"
class="drag-area"
[(selectedItems)]="newRoom"
>
<div class="rooms-data">
   <ng-container *ngIf="project$ | async as project">
    <ng-container *ngIf="floorList$ | async as floorList">
      <div
        *ngFor="
          let floor of floorList
            | getFloorsForEditingGroups
              : project
              : selectedObjectRooms
              : selectedSubObjectRooms
        "
        class="floor-box"
      >
        <div class="floor-number-box">
          <span [ngClass]="{ 'vertical-text': floor.name }">{{
            floor.name || floor
          }}</span>
        </div>
        <div>
          <div class="flex-row">
            <div
              *ngFor="
                let room of project
                  | getRoomsOnFloor: floor:'isGuestRoom' :selectedObjectRooms:selectedSubObjectRooms:  true
              "
              [dtsSelectItem]="room"
              class="selectable-guest-room"
            >
              <p>{{ room.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
</dts-select-container>
</ion-content> -->

<app-modal-confirmation
    [type]="'cardholder'"
    [content]=""
    (decision)="onCardOnreaderModalDecision($event)"
    *ngIf="openCardOnReaderModal">
</app-modal-confirmation>

<app-modal-confirmation
    [type]="'cardExist'"
    [content]=""
    (decision)="onModalDecisionCardExist($event)"
    *ngIf="cardExist">
</app-modal-confirmation>
