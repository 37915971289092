<nav class="flex-row header">
    <!-- <div class="header-title-container flex-row">
        <a class="margin-left10 margin-top10" href="https://www.ae.hr"><i class="i-logo"></i></a>
        <p class="header-title" [routerLink]="['/home']">ACCESS CONTROL</p>
    </div> -->
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <div class="to-right mob-to-middle" *ngIf="alarms | getLatestHighestPriorityAlarm as alarm">
        <div *ngIf="unconfirmedAlerts > 0" class="alert-box blob flex-row-center-full clickable" [ngClass]="alarms | getAlarmsLevel" routerLink="/alarms/unconfirmed">
            <ng-container *ngIf="(unconfirmedAlerts > 1)">
              <p >{{alarms | getUnconfirmedAlarmText : alarm}} ({{ unconfirmedAlerts }} ALARMS) </p>
            </ng-container>
            <p *ngIf="unconfirmedAlerts === 1">{{ alarms | getUnconfirmedAlarmText }}</p>
        </div>
    </div>
    <div class="to-right mob-to-middle flex-row">
        <div *ngIf="{value : activeHvacModeGlobal$ | async} as activeHvacModeGlobal" routerLink="/hvac-mode" >
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Heating" class="icon-temp font-size22 margin-top2"  [title]="'Heating'"></div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Cooling" class="icon-snowflake font-size22 margin-top2" [title]="'Cooling'"></div>
          <div  *ngIf="activeHvacModeGlobal.value === HvacModeType.Mixed"
            [title]="'Cooling/Heating'"
            class="margin-top2 icon-hvac font-size24" >
          </div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Auto" class="font-size16 margin-top10" [title]="'Auto'">Auto</div>
          <div *ngIf="activeHvacModeGlobal.value === HvacModeType.Unknown" class="font-size24 margin-top8" [title]="'Unknown'">?</div>
        </div>

        <div *ngIf="platform.platforms().includes('desktop')" class="tooltip margin-top5">
            <i [ngClass]="[cardReaderConnected ? 'icon-reader font-size24' : 'icon-reader_deny font-size24']"></i>
            <span class="bottom">{{ cardReaderConnected ? 'Card reader connected' : 'Card reader disconnected' }}</span>
        </div>
        <div *ngIf="user | userHavePermissionForAnyAlarms"
            routerLink="/alarms/unconfirmed" class="clickable margin-top8 outline-none tooltip">
            <i class="icon-alert font-size19"></i>
            <!-- <span *ngIf="unconfirmedAlerts > 0" class="alarm-count">{{ unconfirmedAlerts }}</span> -->
            <span *ngIf="unconfirmedAlerts > 0" class="alarm-active-dot"></span>
            <span class="bottom">{{ unconfirmedAlerts > 0 ? 'Unconfirmed alerts' : 'No unconfirmed alerts' }}</span>
        </div>
        <div class="vertical-line"></div>
        <div class="header-dropdown" (click)="dropdownOpened=!dropdownOpened; langDropdownOpened= false; objectDropdownOpened = false">
            <div *ngIf="user" class="text-tooltip">
                <p class="header-user">
                    {{user?.firstName | textSizeFilterPipe : 20}} {{user?.lastName | textSizeFilterPipe : 12}}
                    <span class="bottom"
                    *ngIf="user?.firstName?.length > 20 || user?.lastName?.length > 20">{{user?.fullName}}</span>
                    <i class="header-arrow" [ngClass]="[dropdownOpened ? 'up' : 'down']"></i>
                </p>
            </div>
        </div>

        <div class="header-dropdown" (click)="langDropdownOpened=!langDropdownOpened; dropdownOpened= false; objectDropdownOpened = false">
          <div *ngIf="currentLang">
              <p class="header-user"  *ngIf="currentLang==='hr'">HR
                  <i class="header-arrow" [ngClass]="[langDropdownOpened ? 'up' : 'down']"></i>
              </p>
              <p class="header-user" *ngIf="currentLang==='en'">EN
                  <i class="header-arrow" [ngClass]="[langDropdownOpened ? 'up' : 'down']"></i>
              </p>
          </div>
        </div>
        <div class="header-dropdown" (click)="objectDropdownOpened=!objectDropdownOpened; dropdownOpened= false; langDropdownOpened= false;">
          <p class="header-user" >
            <ng-container *ngIf="objectsFilter$ | async as objFilter">
              <span *ngIf="objFilter.length === 0">All</span>
              <span *ngIf="objFilter.length > 0">
                <ng-container *ngIf="(objects$ | async) | getObjectsByIds: objFilter as obj">
                  {{obj[0]?.name}}
                </ng-container>
              </span>
            </ng-container>
            <!-- <ng-container *ngIf="(objects$ | async) | getObjectsByIds: (objectsFilter$ | async) as obj">
            <span *ngIf="obj.length > 0"> {{obj[0].name}}</span>
            <span *ngIf="obj.length === 0">All</span>
          </ng-container> -->

            <i class="header-arrow" [ngClass]="[objectDropdownOpened ? 'up' : 'down']"></i>
        </p>
        </div>
        <div *ngIf="dropdownOpened" class="margin-right-100 header-dropdown-content"  [@enterAnimation]>
            <a routerLink="/users/{{user.userId}}" (click)="dropdownOpened=false">{{'View Profile' | translate}}</a>
            <a class="header-link" (click)="logout()" (click)="dropdownOpened=false">{{'Logout' | translate}}</a>
        </div>
        <div  *ngIf="langDropdownOpened" class="header-dropdown-content" [@enterAnimation]>
          <a class="header-link" i18n="@@croatian" (click)="setLang('hr')">Croatian</a>
          <a class="header-link" i18n="@@english" (click)="setLang('en')">English</a>
        </div>

        <div  *ngIf="objectDropdownOpened" class="header-dropdown-content" [@enterAnimation]>
          <ng-container *ngIf="objects$ | async as objects">
            <a class="header-link" *ngFor="let object of objects" (click)="selectObject(object)">
              {{'Object' | translate}} {{object.name}}
            </a>
          </ng-container>

          <a class="header-link" (click)="selectObject(null)">{{'All objects' | translate}}</a>
        </div>
    </div>
</nav>
