<div class="flex-row flex-row-space-evenly margin-top30 full-height">
  <div class="width90 flex-column">
    <ion-card class="width100 margin-top25">
      <ion-card-title
        class="flex-row-center-h width100 padding5 background-red"
      >
        {{'Alarms' | translate}}
      </ion-card-title>
      <ion-card-content class="flex-row no-wrap width100">
        <div class="hide-when-not-alone width100 flex-center height40">
          <div>{{'No alarms' | translate}}</div>
        </div>

        <ng-container *ngIf="(controllers | findProperty: isCommunicationError | propIsActive)">
          <div class="flex-column border-dashed-grey">
            <div class="no-wrap flex-row-center-h">
              <div
                class="prop-container"
                *ngIf="controllers | findProperty: isCommunicationError | propIsActive"
              >
                <div class="prop-title">{{'Communication error' | translate}}</div>
                <div title="Burglary detected" class="text-align-center">
                  {{'Communication error' | translate}}
                </div>
              </div>
            </div>
            <ion-button
              size="small"
              fill="outline"
              expand="block"
              class="margin-bottom5 align-self-center"
              (click)="clearStatus()">{{'Clear' | translate}}</ion-button
            >
          </div>
        </ng-container>
        <div
          class="flex-row"
          *ngIf="
            (controllers | findProperty: isHVACLargeTempDiffRoom | propIsActive) ||
            (controllers | findProperty: isHVACLargeTempDiffBathroom | propIsActive) ||
            (controllers
              | findProperty: isWindowOpenTooLongAlarm
              | propIsActive)">
          <ng-container
            *ngIf="
              controllers
                | findProperty
                  : isWindowOpenTooLongAlarm as windowOpenTooLongProps
            "
          >
            <ng-container
              *ngFor="let windowOpenTooLongProp of windowOpenTooLongProps"
            >
              <div
                *ngIf="windowOpenTooLongProp | propIsActive"
                class="prop-container"
              >
                <div class="prop-title">
                  {{ windowOpenTooLongProp.name }}
                </div>
                <div title="Window open too long" class="text-align-center">
                  {{'Win. open too long' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              controllers
                | findProperty: isHVACLargeTempDiffRoom as hvacLargeTempDiffRoomProps
            "
          >
            <ng-container
              *ngFor="let hvacLargeTempDiffRoomProp of hvacLargeTempDiffRoomProps"
            >
              <div
                *ngIf="hvacLargeTempDiffRoomProp | propIsActive"
                class="prop-container"
              >
                <div class="prop-title">{{ hvacLargeTempDiffRoomProp.name }}</div>
                <div
                  title="Hvac large temperature difference in room"
                  class="text-align-center"
                >
                  {{'Hvac large' | translate}} <br />{{'temp. diff. room' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
              controllers
                | findProperty: isHVACLargeTempDiffBathroom as hvacLargeTempDiffBathroomProps
            "
          >
            <ng-container
              *ngFor="let hvacLargeTempDiffBathroomProp of hvacLargeTempDiffBathroomProps"
            >
              <div
                *ngIf="hvacLargeTempDiffBathroomProp | propIsActive"
                class="prop-container"
              >
                <div class="prop-title">{{ hvacLargeTempDiffBathroomProp.name }}</div>
                <div
                  title="Hvac large temperature difference in bathroom"
                  class="text-align-center"
                >
                  {{'Hvac large' | translate}} <br />{{'temp. diff. bathroom' | translate}}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ion-card-content>
    </ion-card>

    <div class="flex-row width100 border-bottom-gray">
      <div class="prop-container-first">
        <div class="prop-container-first-title">
          {{'DOORS' | translate}} <br />{{'AND' | translate}} <br />
          {{'WINDOWS' | translate}}
        </div>
      </div>
      <ng-container
        *ngIf="controllers | findProperty: isWindowOpened as windowProps"
      >
        <ng-container *ngFor="let window of windowProps">
          <div class="prop-container">
            <div class="prop-title">{{ window.name }}</div>
            <div
              *ngIf="window | propIsActive; else windowClosed"
              title="Window open"
              class="icon-window-open font-size20"
            ></div>
            <ng-template #windowClosed>
              <div title="Window closed" class="icon-window-closed font-size20"></div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>

    </div>

    <div class="flex-row">
      <div class="prop-container-first">
        <div class="prop-container-first-title">HVAC</div>
      </div>
      <ng-container
        *ngIf="
          controllers
            | findProperty
              : isRoomTemperatureCurrent as roomTemperatureCurrentProps
        "
      >
        <ng-container
          *ngFor="let roomTemperatureCurrentProp of roomTemperatureCurrentProps"
        >
          <div class="prop-container">
            <div class="prop-title">{{ roomTemperatureCurrentProp.name }}</div>
            <div class="flex-center margin-top10">
              <span>{{ roomTemperatureCurrentProp.value }} </span
              ><span>°C</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container
        *ngIf="
          controllers
            | findProperty: isRoomTemperatureSet as roomTemperatureSetProps
        "
      >
        <ng-container
          *ngFor="let roomTemperatureSetProp of roomTemperatureSetProps"
        >
          <div class="prop-container">
            <div class="prop-title">{{ roomTemperatureSetProp.name }}</div>
            <div class="flex-center margin-top10">
              <span>{{ roomTemperatureSetProp.value }} </span><span>°C</span>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="controllers | findProperty: isHvacActive as hvacProps"
      >
        <ng-container *ngFor="let hvac of hvacProps">
          <div class="prop-container">
            <div class="prop-title">{{ hvac.name }}</div>
            <div
              *ngIf="hvac | propIsActive; else hvacOff"
              title="Hvac on"
              [ngClass]="getHvacActiveMode()"
            ></div>
            <ng-template #hvacOff>
              <div title="Hvac off" class="i-hvac-off"></div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>


      <!-- HVAC HEATING COOLING -->
      <ng-container
        *ngIf="
          controllers
            | findProperty: isHvacHeatCool as hvacHeatingCoolingProps
        "
      >
        <ng-container
          *ngFor="let hvacHeatingCoolingProp of hvacHeatingCoolingProps"
        >
          <div class="prop-container">
            <div class="prop-title">{{ hvacHeatingCoolingProp.name }}</div>
            <div *ngIf="hvacHeatingCoolingProp | propIsActive">
              <!--  <div class="prop-title">Cooling</div> -->
              <div
                title="Cooling (HVAC H/C Mode true)"
                class="text-align-center"
              >
                {{'COOL' | translate}}
              </div>
            </div>
            <div *ngIf="!(hvacHeatingCoolingProp | propIsActive)">
              <!--  <div class="prop-title">Heating</div> -->
              <div
                title="Heating (HVAC H/C Mode false)"
                class="text-align-center"
              >
                {{'HEAT' | translate}}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

       <!-- HVAC HEATING COOLING AUTO-->
      <ng-container
      *ngIf="
        controllers
          | findProperty: isHvacHeatCoolAuto as hvacHeatingCoolingAutoProps
      "
    >
      <ng-container
        *ngFor="let hvacHeatingCoolingAutoProp of hvacHeatingCoolingAutoProps"
      >
        <div class="prop-container">
          <div class="prop-title">{{ hvacHeatingCoolingAutoProp.name }}</div>

          <div *ngIf="hvacHeatingCoolingAutoProp?.value.toString() === '0'">
            <!--  <div class="prop-title">Heating</div> -->
            <div
              title="Heating"
              class="text-align-center"
            >
              {{'HEAT' | translate}}
            </div>
          </div>
           <div *ngIf="hvacHeatingCoolingAutoProp?.value.toString() === '1'">
            <!--  <div class="prop-title">Cooling</div> -->
            <div
              title="Cooling"
              class="text-align-center"
            >
              {{'COOL' | translate}}
            </div>
          </div>
          <div *ngIf="hvacHeatingCoolingAutoProp?.value.toString() === '2'">
            <!--  <div class="prop-title">Cooling</div> -->
            <div
              title="Auto"
              class="text-align-center"
            >
              {{'AUTO' | translate}}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
      <div
        class="prop-container"
        *ngIf="controllers | findProperty: isFancoilV1 as isFancoilV1Prop"
      >
        <div *ngIf="isFancoilV1Prop | propIsActive; else fanCoilV1Off">
          <div class="prop-title">{{'Fan V1 on' | translate}}</div>
          <div title="Fancoil V1 on" class="text-align-center">{{'Fan V1 on' | translate}}</div>
        </div>
        <ng-template #fanCoilV1Off>
          <div>
            <div class="prop-title">{{'Fan V1 off' | translate}}</div>
            <div title="Fancoil V1 off" class="text-align-center opacity-05">
              {{'Fan V1' | translate}} <br />
              {{'off' | translate}}
            </div>
          </div>
        </ng-template>
      </div>
      <div
        class="prop-container"
        *ngIf="controllers | findProperty: isFancoilV2 as isFancoilV2Prop"
      >
        <div *ngIf="isFancoilV2Prop | propIsActive; else fanCoilV2Off">
          <div class="prop-title">{{'Fan V2 on' | translate}}</div>
          <div title="Fancoil V2 on" class="text-align-center">{{'Fan V2 on' | translate}}</div>
        </div>
        <ng-template #fanCoilV2Off>
          <div>
            <div class="prop-title">{{'Fan V2 off' | translate}}</div>
            <div title="Fancoil V2 off" class="text-align-center opacity-05">
              {{'Fan V2' | translate}} <br />
              {{'off' | translate}}
            </div>
          </div>
        </ng-template>
      </div>
      <div
        class="prop-container"
        *ngIf="controllers | findProperty: isFancoilV3 as isFancoilV3Prop"
      >
        <div *ngIf="isFancoilV3Prop | propIsActive; else fanCoilV3Off">
          <div class="prop-title">{{'Fan V3 on' | translate}}</div>
          <div title="Fancoil V3 on" class="text-align-center">{{'Fan V3 on' | translate}}</div>
        </div>
        <ng-template #fanCoilV3Off>
          <div>
            <div class="prop-title">{{'Fan V3 off' | translate}}</div>
            <div title="Fancoil V3 off" class="text-align-center opacity-05">
              {{'Fan V3' | translate}} <br />
              {{'off' | translate}}
            </div>
          </div>
        </ng-template>
      </div>
    </div>
</div>
