import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'getJoinedControllersName'
})
export class GetJoinedControllersNamePipe implements PipeTransform {

  transform(controllers: Controller[]) : string {
    let names = [];
    controllers.forEach ( cont => {
        if (!names.includes(cont.zone)) {
            names.push(cont.zone)
        }})

    let name = ''
    names.forEach( part => {
        if (name == '') {
            name = part;
        } else {
            name = name + '/' + part;
        }
    })
    return name;
  }
}
