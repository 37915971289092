import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { RoomViewSize, RoomsOverviewService } from 'src/app/shared/services/rooms-overview.service';

@Component({
  selector: 'app-io-common-area-card',
  templateUrl: './io-common-area-card.component.html',
  styleUrls: ['./io-common-area-card.component.scss']
})
export class IOCommonAreaCardComponent implements OnInit {
  @Input() controllers: Controller[];
  roomViewSize$: Observable<RoomViewSize> = this.roomsOverviewService.getViewSize();
  isAnyAlarm = Property.isAnyAlarm;

  constructor(private roomsOverviewService: RoomsOverviewService) { }

  ngOnInit(): void {
  }

  getNumberOfOnLights() {
  let count = 0
   this.controllers[0].controllerProperties.$values.forEach (prop => {
      if (Property.isLight(prop)) {
        if (+prop.value === 1) {
          count++;
        }
      }
    })
    return count;
  }

  getNumberOfOffLights() {
    let count = 0
    this.controllers[0].controllerProperties.$values.forEach (prop => {
       if (Property.isLight(prop)) {
         if (+prop.value !== 1) {
           count++;
         }
       }
     })
     return count;
  }

}
