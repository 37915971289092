import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'statusIsActive'
})
export class StatusIsActivePipe implements PipeTransform {

  transform(controllers: Controller[], statusName: string, activeCondition: 'any' | 'every' | 'first'): boolean {
    if (controllers?.length === 0) {
      return false;
    }

    if (activeCondition === 'first') {
      controllers.sort(this.sortByLocation)
      return controllers[0][statusName] === true
    } else if (activeCondition === 'every'){
      return controllers.every((controller: Controller) => {
        return controller[statusName] === true;
      });
    } else {
      return controllers.some((controller: Controller) => {
        return controller[statusName] === true;
      });
    }
  }

  sortByLocation(controllerA: Controller, controllerB: Controller) {
    return Number(controllerA.subZone) - Number(controllerB.subZone)
  }

}
