import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';

@Pipe({
  name: 'filterProjectSubobjects'
})
export class FilterProjectSubobjectsPipe implements PipeTransform {

  transform(subobjects: ProjectSubObject[], selectedObjects: ProjectObject[], selectedSubobject: string)  : ProjectSubObject[] {
    if (selectedObjects.length === 0) {
      return subobjects;
    }
    /* if (Number(selectedObject) === -1 && Number(selectedSubobject) === -1) {
      return subobjects;
    } */

    return subobjects.filter((subObj)=> {
      return selectedObjects.some((obj)=> {
        return obj.projectObjectId === subObj.projectObjectId
      })
    })

    /* return subobjects.filter((subobject)=> {
      if (Number(selectedSubobject) === -1 ) {
        return Number(subobject.designation.split('/')[0]) === Number(selectedObject)
      }
      return Number(subobject.designation.split('/')[0]) === Number(selectedObject)
       && Number(subobject.designation.split('/')[1]) === Number(selectedSubobject);
    }); */
  }

  /* transform(subobjects: ProjectSubObject[], selectedObject: string, selectedSubobject: string)  : ProjectSubObject[] {
    if (Number(selectedObject) === -1) {
      return subobjects;
    }
    if (Number(selectedObject) === -1 && Number(selectedSubobject) === -1) {
      return subobjects;
    }

    return subobjects.filter((subobject)=> {
      if (Number(selectedSubobject) === -1 ) {
        return Number(subobject.designation.split('/')[0]) === Number(selectedObject)
      }
      return Number(subobject.designation.split('/')[0]) === Number(selectedObject)
       && Number(subobject.designation.split('/')[1]) === Number(selectedSubobject);
    });
  } */

}
