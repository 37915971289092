import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Controller } from 'src/app/core/models/controller.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model'
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Property } from 'src/app/core/models/project/property.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { DateTimeFromToComponent } from 'src/app/modules/logs/components/date-time-from-to/date-time-from-to.component';
import { ApiAccessLogsService } from 'src/app/modules/logs/services/http/api-access-logs.service';
import { ApiAlarmLogsService } from 'src/app/modules/logs/services/http/api-alarm-logs.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-room-modal-log',
  templateUrl: './room-modal-log.component.html',
  styleUrls: ['./room-modal-log.component.scss']
})
export class RoomModalLogComponent implements OnInit {

  @ViewChild('dateTimeFromTo') dateTimeFromTo: DateTimeFromToComponent;
  @ViewChild('tablePropertiesSelect') tablePropertiesSelect: any;
  @ViewChild('tableTemperatureSelect') tableTemperatureSelect: any;
  @Input() controllers: Controller[];
  private readonly EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/cntproptypes.json';
  activeCard: string = 'value';
  propertiesList: Array<any>;

  // graph
  graphCreated = false;
  tableCreated = true;
  tempGraph: any[];
  circumstancesGraph: any[];
  tableData: any = [];
  tableProperties: string[] = [];
  temperatureProperties: string[] = [];
  selectedController: Controller;
  LegendPosition = LegendPosition;

  body: ValueGraph = new ValueGraph;

  // options
  xAxisLabel = 'Time';
  yAxisLabel = 'Values';
  // colorScheme = {domain: ['#6BA0C4', '#03143A']};
  hideCustomTime = true;
  tempEvent: CustomEvent;
  tempEventTemperature: CustomEvent;
  showSelect = true;

  Property = Property;

  propSelect: any;
  temperatureTypesSelect: any;

  // event log
  page = 1;
  giveAccess: boolean = false;
  giveAlarm: boolean = false;
  logsLoading = false;

  accessCheckbox = false;
  alarmCheckbox = false;

  queryParams = {
    pageNumber: 1,
    pageSize: 10000,
    includeArchive: false
  };


  constructor(private apiProjectService: ApiProjectService,
              private apiAccessLogService: ApiAccessLogsService,
              private apiAlarmLogsService: ApiAlarmLogsService,
              private http: HttpClient) { }

  ngOnInit(): void {
    this.selectedController = this.controllers[0];
    this.http.get<any>(this.EQUIPMENT_PROPERTY_TYPES_URL).subscribe( resp => {
      this.propertiesList = resp.Definitions;
    })

  }

  cardClicked(card: string) {
    this.activeCard = card;
    this.tableProperties = [];
    this.temperatureTypesSelect = [];
    this.temperatureProperties = [];
    if (this.activeCard === 'temperature') {
      this.selectedController.controllerProperties.$values.forEach ( prop => {
        if (Property.isRoomTemperatureCurrent(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          this.temperatureTypesSelect.push(Property.isRoomTemperatureCurrent)
        } else if (Property.isBathroomTemperatureCurrent(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          this.temperatureTypesSelect.push(Property.isBathroomTemperatureCurrent)
        }
      })
      this.createGraph();
    }
    if (this.activeCard === 'fullGraph') {
      setTimeout (() => {
        this.showLast7days();
      },200)
    }
  }

  getPropertyName(propType: string) {
    const target = this.propertiesList.find( (prop: PropertyType) => {
      if (+propType >= prop.CodeFrom && +propType <= prop.CodeTo) { return true}
    })

    if (propType) {
      if (target) {
        return target.Name;
      } else {
        return 'Unknown property';
      }
    } else {
      return null;
    }
  }

  createGraph() {
    this.logsLoading = true;
    this.body.controllerDesignations = [];
    this.body.controllerDesignations.push(this.selectedController.designation);
    if (this.activeCard === 'temperature') {
      this.graphCreated = false;
      // this.body.properties = ["6250", "6200", ...this.tableProperties];
      this.body.properties = [...this.temperatureProperties,...this.tableProperties];
    }
    if (this.activeCard === 'fullGraph') {
      this.tableCreated = false;
      this.body.properties = this.tableProperties;
    }

    if ((this.tableProperties.length > 0 && this.activeCard === 'fullGraph') || this.activeCard === 'temperature') {
      this.apiProjectService.getGraph(this.body).subscribe( response => {
        if (response) {
          if (this.activeCard === 'temperature') {
            this.tempGraph = this.createDataFromResponse(response);
            console.log(this.circumstancesGraph)
            console.log(this.tempGraph)
            this.graphCreated = true;
          }

          if (this.activeCard === 'fullGraph') {
            this.tableData = this.reduceDataForTableView(response);
            this.getAlarmAndAcessLogs();
          }
        } else {
          this.graphCreated = false;
        }
      });
    } else {
      this.getAlarmAndAcessLogs();
    }
  }

  reduceDataForTableView(response: any) {
    const tempData = [];
    this.tableProperties.forEach( prop => {
      response.forEach( (element , index) => {
        if ((index === 0 && element.propertyType === prop) || (element.propertyType === prop &&
           response[index].value !== response[index-1].value)) {
            tempData.push(element);
        }
      });
    })
    return tempData;
  }

  createDataFromResponse(resp: any) {
    let results = [];
    this.body.properties.forEach( prop => {
      results.push({name: this.getPropertyName(prop), series: []})
    })

    resp.forEach( data => {
      const target = results.find( element => {
       if (element.name === this.getPropertyName(data.propertyType)) { return true;}
      })
      target.series.push({name: new Date(data.timestamp), value: data.value})
    });
    if (this.activeCard === 'temperature') {
      this.circumstancesGraph = [];
      this.tableProperties.forEach( propType => {
        const prop = this.selectedController.controllerProperties.$values.find (p => p.type === +propType);
        if (prop) {
          this.circumstancesGraph.push(...results.filter( data => data.name === this.getPropertyName(prop.type.toString())));
          // We need to change every value 9 to value 1 in windows
          const target = this.circumstancesGraph.find( element => element.name === 'Window');
          if (target) {
            target.series.forEach( data => {
              if (data.value == 9) {
                data.value = 1;
              }
            })
          }
        }
        results = results.filter( data => data.name !== this.getPropertyName(propType));
      })
    }
    return results;
  }

  yAxisTickFormatting = (value) => {
    if (value == 0) {
      return `Closed`
    }
    if (value == 1) {
      return `Open`
    }
  };

  setDateTimeFromTo(selectedDateTime: Date[]) {
    if (!(selectedDateTime[0] === undefined || selectedDateTime[0] === null)
    && !(selectedDateTime[1] === undefined || selectedDateTime[1] === null)) {
      this.body.dateTimeFrom = selectedDateTime[0].toISOString();
      this.body.dateTimeTo = selectedDateTime[1].toISOString();
      this.createGraph();
    }
  }

  getAlarmAndAcessLogs() {
    if (this.accessCheckbox) {
      this.removeAccessLog();
      this.getAccessLog(1)
    }
    if (this.alarmCheckbox) {
      this.removeAlarmLog()
      this.getAlarmLog(1)
    }

    if (!this.alarmCheckbox && !this.accessCheckbox) {
      this.tableCreated = true;
      this.logsLoading = false;
    }
  }


  showLast24hours() {
    this.dateTimeFromTo.showLast24hours();
  }

  showLast7days() {
    this.dateTimeFromTo.showLast7days();
  }

  showLast30days() {
    this.dateTimeFromTo.showLast30days();
  }

  selectChanged(event: CustomEvent) {
    // saving last selected props for more user friendly exp when swaping controllers
    this.tempEvent = event;
    this.runEventCode(event);
    this.createGraph();
  }

  selectTemperatureChanged(event: CustomEvent) {
    this.tempEventTemperature = event;
    this.runEventCodeForTemperature(event)
    this.createGraph();
  }

  runEventCode(event: CustomEvent) {
    this.tableProperties = [];
    this.selectedController.controllerProperties.$values.forEach ( prop => {
      let target: Property;
      event.detail.value.forEach( value => {
        target = value(prop);
        if(target && this.tableProperties.indexOf(prop.type.toString()) === -1) {
          this.tableProperties.push(prop.type.toString());
        }
      });
    })
  }

  runEventCodeForTemperature (event: CustomEvent) {
    this.temperatureProperties = [];
    this.selectedController.controllerProperties.$values.forEach ( prop => {
      let target: Property;
      event.detail.value.forEach( value => {
        target = value(prop);
        if(target && this.temperatureProperties.indexOf(prop.type.toString()) === -1) {
          this.temperatureProperties.push(prop.type.toString());
        }
      });
    })
  }

  controllerChanged(controller: Controller) {
    this.selectedController = controller;
    if (this.tempEvent) {
      this.runEventCode(this.tempEvent);
    }

    if (this.tempEventTemperature) {
      this.runEventCodeForTemperature(this.tempEventTemperature)
    }
    this.createGraph()

    // force ion-select update when swaping controllers
    this.showSelect = false
    setTimeout( () => {
      this.showSelect = true;
    },100)
  }

  showCustom() {
    if (this.hideCustomTime === true) {
      this.hideCustomTime = false;
    } else {
      this.hideCustomTime = true;
      this.dateTimeFromTo.showLast7days();
    }
  }

  onAccessLogClick(event: any) {
    if (event.target.checked) {
      this.accessCheckbox = true;
      this.createGraph()
    } else {
      this.accessCheckbox = false;
      this.removeAccessLog()
      this.giveAccess = false;
    }
  }

  onAlarmLogClick(event: any) {
    if (event.target.checked) {
      this.alarmCheckbox = true;
      this.createGraph()
    } else {
      this.alarmCheckbox = false;
      this.removeAlarmLog()
      this.giveAlarm = false;
    }
  }

  getAccessLog(pageNumber: number) {
    this.tableCreated = false;
    this.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    requestBody.locationDesignations = [];
    // requestBody.locationDesignations.push(this.selectedController.locationId);
    this.queryParams.pageNumber = pageNumber;

    this.apiAccessLogService.getAccessLogs(requestBody, this.queryParams).subscribe((resp: HttpResponse<AccessLog[]>) => {
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      let accessLogData = resp.body;

      // filtering for single room
        accessLogData = accessLogData.filter( accessData => {
          if (accessData.locationName.includes(this.selectedController.name)) {
            return true;
          }
        })

        this.combineData(accessLogData);
        if (paginationData.HasNext) {
          this.getAccessLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.giveAccess = true;
        }

    });
  }


  getAlarmLog(pageNumber: number) {
    this.tableCreated = false;
    this.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    // requestBody.locationDesignations.push(this.selectedController.locationId);


    this.queryParams.pageNumber = pageNumber;


    this.apiAlarmLogsService.getAlarmLogs(requestBody, this.queryParams).subscribe(resp => {
      let alarmLogData = resp.body;
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      alarmLogData = alarmLogData.filter( alarm => {
          if (alarm.data.includes(this.selectedController.designation)) {
            return true;
          }
        })

        this.combineData(null, alarmLogData);
        if (paginationData.HasNext) {
          this.getAlarmLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.giveAlarm = true;
        }
    });
  }

  combineData(accessData?: AccessLog[], alarmData?: any) {
    if (accessData) {
      accessData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), user: data.userFullName, locationName: data.locationName, type: 'access'})
      })
    }

    if(alarmData) {
      alarmData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), level: data.level, description: data.description, type: 'alarm'})
      })
    }
  }

  removeAccessLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'access');
  }

  removeAlarmLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'alarm');
  }

  sortTable() {
    this.tableData.sort( (a, b) => {
      return <any>new Date(a.timestamp) - <any> new Date(b.timestamp)
    })
    this.logsLoading = false;
    this.tableCreated = true;
  }

  onPageChange(value: any) {
    this.page = value
  }

  showDataAtPage(index: number) {
    if (index === 0 && this.page === 1) {
      return true
    } else {
      const value = index / 50;
      return (this.page === Math.ceil(value))
    }
  }
}
