import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
// import { ControllerFilters } from 'src/app/core/models/view-filter.model';
// import {  ControllerTypeFilter } from '../services/filters.service';

@Pipe({
    name: 'filterByControllerType',
  })
export class FilterByControllerType implements PipeTransform {

    transform( controllers: Controller[][],  type: 'isAccessControl' | 'isIOCommonArea'| 'isHvacCommonArea') : Controller[][] {
        if (!controllers) {
            return null;
          }

        // filters.controllerType.forEach( (typeFilter: ControllerTypeFilter) => {
          controllers = controllers.filter( cont => {
              const filterFunct = this.getControllerTypeFilterFunction(type)
              return filterFunct(cont[0]);
              /* if (filterFunct(cont[0])) {
                  return false;
              } else {
                  return true;
              } */
          })
        // })
        return controllers;

    }

    getControllerTypeFilterFunction(filterName: 'isAccessControl' | 'isIOCommonArea'| 'isHvacCommonArea'):
     (controller: Controller) => boolean {
      switch (filterName) {
        case 'isAccessControl': {
          return Controller.isAccessControl;
        }
        case 'isIOCommonArea': {
          return Controller.isIOCommonArea;
        }
        case 'isHvacCommonArea': {
          return Controller.isHvacCommonArea;
        }
        default: {
          break;
        }
      }
    }
}
