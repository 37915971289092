import { Component, Input, OnInit } from '@angular/core';

import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Setting } from 'src/app/core/models/project/setting.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';


@Component({
  selector: 'app-access-control-modal-settings',
  templateUrl: './access-control-modal-settings.component.html',
  styleUrls: ['./access-control-modal-settings.component.scss'],
})
export class  AccessControlModalSettingsComponent implements OnInit {
  @Input() controllers: Controller[];
  isAccessControl = Controller.isAccessControl;
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;
  defaultSettings: DefaultsPreset[];
  stopControllerPolling: boolean = false;
  controllerPollingSubscription: Subscription;


  isDoorOpened = Property.isDoorOpened;

  isACDoorOpenPushButton = Property.ACDoorOpenPushButton;
  isACDoorOpenAnyCard = Property.ACDoorOpenAnyCard;
  isOfflineTimer = Setting.offlineTimer;
  isACAlwaysOpen = Property.ACAlwaysOpen;

  isACDoorGuestDenyFrom = Property.ACDoorGuestDenyFrom;
  isACDoorGuestDenyTo = Property.ACDoorGuestDenyTo;

  isACDenyGuests = Property.ACDenyGuests;
  isACDenyStaff = Property.ACDenyStaff;
  isACDenyAll = Property.ACDenyAll;

  constructor(
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private apiSettingsService: ApiSettingsService) {}

  ngOnInit(): void {
    this.selectController(this.controllers[0]);
    this.isIntervalForDoorsActive();
    this.apiSettingsService.getDefaultPresets().subscribe( value => {
      this.defaultSettings = value;
    })

    this.isControllerPolled();
  }

  isControllerPolled() {
    this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
          && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    })
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( () => {
      this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    })
  }



  selectController(controller: Controller) {
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
  }

  onSetIntervalClicked(event: any) {
    if (event.target.checked) {
      this.intervalForDoorsActive = true;
    } else {
      this.intervalForDoorsActive = false;
      this.setToTime(100),
      this.setFromTime(100);
    }
  }

  isIntervalForDoorsActive() {
    let denyFrom: Property;
    let denyTo: Property;

    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        denyFrom = controller.controllerProperties.$values.find(this.isACDoorGuestDenyFrom);
        return denyFrom;
      }
    );

    if (parentController) {
      denyTo = parentController.controllerProperties.$values.find(this.isACDoorGuestDenyTo);
    }

    if (denyFrom && denyTo) {
      if (denyFrom.value !== '100' && denyTo.value !== '100') {
        this.intervalForDoorsActive = true;
      }
    }
  }


  procesTimeObject(data: any[]) {
    const timeFrom = data[0].getHours() * 60 + data[0].getMinutes();
    this.setFromTime(Math.floor(timeFrom / 15));

    const timeTo = data[1].getHours() * 60 + data[1].getMinutes();
    this.setToTime(Math.floor(timeTo / 15))

  }

  setFromTime(value: number) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.isACDoorGuestDenyFrom);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  setToTime(value: number) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.isACDoorGuestDenyTo);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }


  syncHvac() {
    this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
  }

  changeControllerPooling(event: any) {
    const contToSend = structuredClone(this.controllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  ngOnDestroy(): void {
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }
  }
}
