import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'getControllersForRoom'
})
export class GetControllersForRoomPipe implements PipeTransform {

  transform(controllers: Controller[], targetZone: string, targetObj: string, targetSubobj: string, updated: Date): Controller[] {
    if (!controllers || !targetZone) {
    return null;
  }

  const controllersForRoom: Controller[] = [];
  controllers.forEach( cont => {
    if ((Number(targetZone) === Number(cont.zone) || Number(targetZone) === -1) &&
     Number(targetObj) === Number(cont.object || Number(targetObj) === -1) &&
     Number(targetSubobj) === Number(cont.subObject) || Number(targetSubobj) === -1) {
      controllersForRoom.push(cont);
     }

     //
    /* driver[1].forEach( object => {
      if (Number(object[0]) === Number(targetObj) || (Number(targetObj) < 0) ) {
        object[1].forEach( subObj => {
          if (Number(subObj[0]) === Number(targetSubobj) || (Number(targetSubobj) < 0)) {
            subObj[1].forEach( zone => {
              zone[1].forEach( subZone => {
                if (subZone[1].zone === targetZone.toString() || Number(targetZone)<0) {
                  controllersForRoom.push(subZone[1]);
                }
              });
            })
          }
        });
      }
    }); */
  });

  if(controllersForRoom.length > 0) {
    return controllersForRoom;
  }

  }
}
