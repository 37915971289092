import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  message = '';
  messageChanged = new Subject<string>();

  constructor() { }

  getMessage() {
    return this.message;
  }

  setMessage(message: string) {
    this.message = message;
    this.messageChanged.next(this.message);
  }
}
