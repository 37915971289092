import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { API_BASE_URL } from 'src/environments/environment';
import { AlarmSubscriptionsService } from '../alarm-subscriptions.service';
import { AlarmNotifyType } from 'src/app/core/models/alarms/alarm-notify-type.model';
import { AlarmNotify } from 'src/app/core/models/alarms/alarm-notify.model';
import { AlarmAlertsService } from '../alarm-alerts.service';
import { Alarms } from 'src/app/core/models/alarms.model';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';

@Injectable({
    providedIn: 'root'
})
export class ApiAlarmsService {

    constructor(private http: HttpClient,
         private alarmSubscriptionsService: AlarmSubscriptionsService,
         private alarmAlertsService: AlarmAlertsService) {
    }

    getAlarmTypes() {
        return this.http.get<AlarmType[]>(API_BASE_URL + '/alarms/types').pipe(
            map(alarmTypes => {
                this.alarmSubscriptionsService.setAlarmTypes(alarmTypes);
                return alarmTypes;
            }),
        );
    }

    getAlarmNotifyTypes() {
        return this.http.get<AlarmNotifyType[]>(API_BASE_URL + '/alarms/notifytypes').pipe(
            map(alarmNotifyTypes => {
                this.alarmSubscriptionsService.setAlarmNotifyTypes(alarmNotifyTypes);
                return alarmNotifyTypes;
            }),
        );
    }

    getAlarmSubscriptions() {
        return this.http.get<AlarmNotify[]>(API_BASE_URL + '/alarms/notify').pipe(
            map(alarmSubscriptions => {
                this.alarmSubscriptionsService.setAlarmSubscriptions(alarmSubscriptions);
                return alarmSubscriptions;
            }),
        );
    }

    getAlarmSubscription(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.get<AlarmNotify>(API_BASE_URL + '/alarms/notify/byId', {params}).pipe(
            map(alarmSubscription => {
                this.alarmSubscriptionsService.updateAlarmSubscription(alarmSubscription);
                return alarmSubscription;
            }),
        );
    }

    addAlarmSubscription(newAlarmSubscription: AlarmNotify) {
        let params = new HttpParams()
            .set('typeId', newAlarmSubscription.alarmTypeId.toString())
            .set('notificationTypeId', newAlarmSubscription.alarmNotifyTypeId.toString())
            .set('destination', newAlarmSubscription.destination);
        return this.http.post<AlarmNotify>(API_BASE_URL + '/alarms/notify', null, {params}).pipe(
            map(receivedAlarmSub => {
                this.alarmSubscriptionsService.addAlarmSubscription(receivedAlarmSub);
                return receivedAlarmSub;
            }),
        );
    }

    updateAlarmSubscription(updatedAlarmSubscription: AlarmNotify) {
        return this.http.put<AlarmNotify>(API_BASE_URL + '/alarms/notify', updatedAlarmSubscription).pipe(
            map(receivedAlarmSub => {
                this.alarmSubscriptionsService.updateAlarmSubscription(receivedAlarmSub);
                return receivedAlarmSub;
            }),
        );
    }

    deleteAlarmSubscrption(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.delete(API_BASE_URL + '/alarms/notify', {params}).pipe(
            map(() => {
                this.alarmSubscriptionsService.deleteAlarmSubscription(id);
                return;
            }),
        );
    }

    getUnconfirmedAlarms(pagenumber: number, pagesize: number, requestBody: Alarms) {
        const params = new HttpParams()
        .set('pagenumber', pagenumber.toString())
        .set('pagesize', pagesize.toString());
        return this.http.post<AlarmAlert[]>(API_BASE_URL + '/alarms', requestBody, {params}).pipe(
            map( alarms => {
                this.alarmAlertsService.setAlarms(alarms);
                return alarms;
            })
        );
    }

    confirmAlarm(alarmId: number, text: string,  userId: number) {
        return this.http.post<any>(API_BASE_URL + '/alarms/confirm', {AlarmLogId: alarmId, Text: text, confirmUserId: userId}).pipe(
            map( resp => {
                this.alarmAlertsService.confirmAlarm(alarmId);
                this.getUnconfirmedAlarms(1, 100, {
                    Filtering: '',
                    Sorting: '',
                    DateTimeFrom: '',
                    DateTimeTo: '',
                    Levels: [],
                    Types: [],
                    Confirmed: [0],
                    ConfirmUsers: []
                  }).subscribe();
                return resp;
            })
        );
    }

        confirmAllAlarms(text: string, userId: number ) {
            return this.http.post<any>(API_BASE_URL + '/alarms/confirmall', {Text: text, confirmUserId: userId}).pipe(
                map( resp => {
                    this.alarmAlertsService.setAlarms([]);
                    return resp;
                })
            );
        }
}
