import { Injectable } from '@angular/core';
import { IMqttMessage } from 'ngx-mqtt';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { Property } from 'src/app/core/models/project/property.model';

export interface StoredObject {
  userId: number;
  object: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  // private objects$ = new BehaviorSubject<any[]>(null)
  // private selectedObject$ = new BehaviorSubject<ProjectObject>(null)

  private objects$ = new BehaviorSubject<ProjectObject[]>([]);
  private subObjects$ = new BehaviorSubject<ProjectSubObject[]>([]);


  private filteredControllersFat$ = new BehaviorSubject<Controller[]>([])
  private loadingFilteredControllersFat$ = new BehaviorSubject<boolean>(false)
  // private filteredCommonAreaControllersFat$ = new BehaviorSubject<Controller[]>([])
  private allControllersFat$ = new BehaviorSubject<Controller[]>([])
  private allControllersThin$ = new BehaviorSubject<Controller[]>([])
  updatedPipe$ = new Subject<Date>();

  doorsChanged = new Subject<[string, string]>();

  selectedobjectLocalStorageKey = 'irooms_selected_object';
  loadedObjectForUserId: number;


  private notPolledControllers: Array<Controller> = [];
  notPolledControllersChanged = new Subject<Controller[]>();

  allControllersReady$ = new BehaviorSubject<boolean>(false);
  singleControllerMessage$ = new Subject<IMqttMessage>();

  constructor() {}


  getFilteredControllersFat() {
    return this.filteredControllersFat$.asObservable();
  }


  setFilteredControllersFat(controllers: Controller[]) {
    this.filteredControllersFat$.next(controllers);
  }

  getLoadingFilteredControllersFat() {
    return this.loadingFilteredControllersFat$.asObservable();
  }


  setLoadingFilteredControllersFat(loading: boolean) {
    this.loadingFilteredControllersFat$.next(loading);
  }

  /* getFilteredCommonAreaControllersFat() {
    return this.filteredCommonAreaControllersFat$.asObservable();
  }


  setFilteredCommonAreaControllersFat(controllers: Controller[]) {
    this.filteredCommonAreaControllersFat$.next(controllers);
  } */

  allControllersReady() {
    return this.allControllersReady$.getValue();
  }

  getAllControllersFat() {
    return this.allControllersFat$.asObservable();
  }

  getRoom2Controllers$() {
    const controllers = this.allControllersFat$.getValue().reduce( (roomList, cont) => {
      if (Controller.isGuestRoom(cont)) {
        roomList.push(cont);
      }
      return roomList
    }, [])
    return of(controllers)
  }

  getHvacControllers$() {
    const controllers = this.allControllersFat$.getValue().reduce( (roomList, cont) => {
      if (Controller.isHvacCommonArea(cont)) {
        roomList.push(cont);
      }
      return roomList
    }, [])
    return of(controllers)
  }

  getLightControllers$() {
    const controllers = this.allControllersFat$.getValue().reduce( (roomList, cont) => {
      if (Controller.isIOCommonArea(cont)) {
        roomList.push(cont);
      }
      return roomList
    }, [])
    return of(controllers)
  }

  getAccessControllControllers$() {
    const controllers = this.allControllersFat$.getValue().reduce( (roomList, cont) => {
      if (Controller.isAccessControl(cont)) {
        roomList.push(cont);
      }
      return roomList
    }, [])
    return of(controllers)
  }

  setAllControllersFat(controllers: Controller[]) {
    this.allControllersFat$.next(controllers);
    this.allControllersReady$.next(true);
  }

  setAllControllersThin(controllers: Controller[]) {
    this.allControllersThin$.next(controllers);
  }

  getAllControllersThin() {
    return this.allControllersThin$.asObservable();
  }


  setObjects(objects: ProjectObject[]){
    this.objects$.next(objects)
  }

  getObjects(): Observable<ProjectObject[]> {
    return this.objects$.asObservable();
  }

  setSubObjects(subObjects: ProjectSubObject[]){
    this.subObjects$.next(subObjects)
  }

  getSubObjects() {
    return this.subObjects$.asObservable();
  }

 /*  getGeneratedFloors(object: number, subobject: number ): Observable<number[]> {
      const floorList = Project.getGeneratedFloors(this.project$.getValue(), object, subobject);
      return of(floorList);
  } */

  /* getAllRooms(): string[] {
    return this.filteredControllersFat$.getValue().reduce( (roomList, cont) => {
              if (!roomList.includes(cont.zone)) {
                roomList.push(cont.zone);
              }
        return roomList;
      }, []);
  } */



  getControllerByLocationId(locId: string) {
    return this.allControllersFat$.getValue().find( cont => cont.locationId === +locId)
  }

  getThinControllerByLocationId(locId: string) {
    return this.allControllersThin$.getValue().find( cont => cont.locationId === +locId)
  }

  getThinControllerByRoomNumber(roomNo: string) {
    return this.allControllersThin$.getValue().find( cont => {
      return cont.zone.toString() === roomNo.toString()
    })
  }

  getControllerByDesignation(designation: string) {
    return this.filteredControllersFat$.getValue().find((cont: Controller)=> {
      return cont.designation === designation;
    })
  }

  getControllerByDesignationFromFull(designation: string) {
    return this.allControllersFat$.getValue().find((cont: Controller)=> {
      return cont.designation === designation;
    })
  }

  getControllersByDesignation(object: string, subObj: string, zone: string): Controller[] {
      return this.allControllersFat$.getValue().filter( cont => {
        const contDesignationSplit = cont.designation.split('/')
        if (object == contDesignationSplit[0] && subObj == contDesignationSplit[1] && zone == contDesignationSplit[2]) {
          return true;
        }
      })
  }

  findPropByType(controller: Controller, propDown: number, propUp: number) {
    return controller.controllerProperties.$values.find( prop => {
      if (prop.type >= propDown && prop.type <= propUp) {
        return true;
      }
    })
  }

  updateFilteredProjectByMqtt(message: IMqttMessage) {
    const filteredControllersFat = this.filteredControllersFat$.getValue();
    if (filteredControllersFat.length === 0) {
      return;
    }
    const payloadIndex = message.payload.toString().lastIndexOf('|');
    const payload = message.payload.toString().slice(payloadIndex + 1);

    // example topic cli/driver/1/0/0/101/0/hvac/temp/set/room
    const driverPref = message.topic.split('/')[1] + '/' + message.topic.split('/')[2];
    const obj = message.topic.split('/')[3];
    const subObj = message.topic.split('/')[4];
    const zoneId = message.topic.split('/')[5];
    const subZoneId = message.topic.split('/')[6];
    const propTopic = this.getPropTopic(message.topic);
    let targetProp: Property;


    if (propTopic) {
      filteredControllersFat.forEach((cont)=> {
        if (cont.driverPrefix.toLowerCase() === driverPref.toLowerCase() && cont.object === obj && cont.subObject === subObj && cont.zone === zoneId && cont.subZone === subZoneId) {
          targetProp = cont.controllerProperties.$values.find((prop)=> {
            return prop.mqttTopic === propTopic
          })
          return;
        }
      })
    }

    if (targetProp) {
      targetProp.value = payload;
      this.setFilteredControllersFat(filteredControllersFat);

      // we use this for 3D model of room
      if (Property.isDoorOpened(targetProp)) {
        this.doorsChanged.next([targetProp.value, obj + '/' + subObj + '/' + zoneId + '/' + subZoneId]);
      }
      this.updatePipe();
    }
  }

  updateFullProjectByMqtt(message: IMqttMessage) {
    const allControllersFat = this.allControllersFat$.getValue();
    if (allControllersFat.length === 0) {
      return;
    }
    const payloadIndex = message.payload.toString().lastIndexOf('|');
    const payload = message.payload.toString().slice(payloadIndex + 1);

    // example topic cli/driver/1/0/0/101/0/hvac/temp/set/room
    const driverPref = message.topic.split('/')[1] + '/' + message.topic.split('/')[2];
    const obj = message.topic.split('/')[3];
    const subObj = message.topic.split('/')[4];
    const zoneId = message.topic.split('/')[5];
    const subZoneId = message.topic.split('/')[6];
    const propTopic = this.getPropTopic(message.topic);
    let targetProp: Property;


    if (propTopic) {
      allControllersFat.forEach((cont)=> {
        if (cont.driverPrefix.toLowerCase() === driverPref.toLowerCase() && cont.object === obj && cont.subObject === subObj && cont.zone === zoneId && cont.subZone === subZoneId) {
          targetProp = cont.controllerProperties.$values.find((prop)=> {
            return prop.mqttTopic === propTopic
          })
          return;
        }
      })
    }

    if (targetProp) {
      targetProp.value = payload;
      this.setAllControllersFat(allControllersFat);

      // we use this for 3D model of room
      if (Property.isDoorOpened(targetProp)) {
        this.doorsChanged.next([targetProp.value, obj + '/' + subObj + '/' + zoneId + '/' + subZoneId]);
      }
      this.updatePipe();
    }
  }


  /* updateSortedProjectByMqtt(message: IMqttMessage) {// cli/driver/1/0/0/101/0/hvac/temp/set/room
    const payloadIndex = message.payload.toString().lastIndexOf('|');
    const payload = message.payload.toString().slice(payloadIndex + 1);

    const project = this.project$.getValue();
    const driverPref = message.topic.split('/')[1] + '/' + message.topic.split('/')[2];
    const obj = message.topic.split('/')[3];
    const subObj = message.topic.split('/')[4];
    const zoneId = message.topic.split('/')[5];
    const subZoneId = message.topic.split('/')[6];
    const propTopic = this.getPropTopic(message.topic);
    let targetProp: Property;


    if (propTopic) {
      project.controllers.find( driver => {
        if (driver[0] === driverPref) {
          return driver[1].find( object => {
            if(object[0] === obj) {
              return object[1].find( subObject => {
                if(subObject[0] === subObj) {
                  return subObject[1].find( zone => {
                    if (zone[0] === zoneId) {
                      return zone[1].find( subzone => {
                        if (subzone[0] === subZoneId) {
                          return (subzone[1] as Controller).properties.find( prop => {
                            if (prop.mqttTopic === propTopic) {
                              targetProp = prop;
                              return;
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
      })
    }

    if (targetProp) {
      targetProp.value = payload;
      this.project$.next(project);

      // we use this for 3D model of room
      if (Property.isDoorOpened(targetProp)) {
        this.doorsChanged.next([targetProp.value, obj + '/' + subObj + '/' + zoneId + '/' + subZoneId]);
      }
      this.updatePipe();
    }
  } */

  updateControllerIsOnlineStatus(designation: string, isOnline: boolean) {
    const controller = this.getControllerByDesignation(designation);
    const controllerFromFull = this.getControllerByDesignationFromFull(designation)
    if (controller) {
      controller.isOnline = isOnline;
    }

    if (controllerFromFull) {
      controllerFromFull.isOnline = isOnline;
    }
    this.updatePipe();
  }

  updateControllerIsRentedStatus(designation: string, isRented: boolean) {
    const controller = this.getControllerByDesignation(designation);
    const controllerFromFull = this.getControllerByDesignationFromFull(designation)
    if (controller) {
      controller.isRented = isRented;
    }

    if (controllerFromFull) {
      controllerFromFull.isRented = isRented;
    }
    this.updatePipe();
  }

  updateControllerIsJoinedStatus(designation: string, isJoined: boolean) {
    const controller = this.getControllerByDesignation(designation);
    const controllerFromFull = this.getControllerByDesignationFromFull(designation)
    if (controller) {
      controller.isJoined = isJoined;
    }

    if (controllerFromFull) {
      controllerFromFull.isJoined = isJoined;
    }
    this.updatePipe();
  }

  getPropTopic(topic: string): string { // example input: dev/irooms/1/0/0/101/1/hvac/temp/meas/room,
    const topicArray = topic.split('/');
    let propTopic = '';
    for (let index = 7; index < topicArray.length; index++) {
      propTopic = propTopic + topicArray[index];
      if (index < topicArray.length-1) {
        propTopic = propTopic + '/';
      }
    }
    return propTopic;  // example output : hvac/temp/meas/room
  }

  updatePipe() {
    const date = new Date();
    this.updatedPipe$.next(date);
  }

  // controlers no longer need updating. They are fetched every time
 /*  updateControllersInProject(updatedControllers: Controller[]) {
    // only updates name , restoreDefaultPropertiesOnCheckout, isAccessControl, isGuestRoom
    const project = JSON.parse(JSON.stringify(this.project$.getValue()));
    updatedControllers.forEach((updatedController: Controller) => {
      project.controllers.forEach((driver) => {
        driver[1].forEach((object) => {
          object[1].forEach((subObj) => {
            subObj[1].forEach((zone) => {
              zone[1].forEach((subZone) => {
                if (subZone[1].id === updatedController.id) {
                  subZone[1].name = updatedController.name;
                  // subZone[1].settings = updatedController.settings;
                  subZone[1].settings = updatedController.controllerSettings;
                  subZone[1].restoreDefaultPropertiesOnCheckout = updatedController.restoreDefaultOnCheckout;
                }
                }
              );
            });
          });
        });
      });
    });
    this.setProject(project);
  }
 */

 /*  getObjects() {
    return this.objects$.asObservable();
  }

  setObjects(project: Project) {
    const objectList = []
    project.controllers.forEach( driver => {
        driver[1].forEach(object => {
          if (!objectList.includes(object[0])) {
            objectList.push(object[0]);
          }
        });
      });
    this.objects$.next(objectList);
  } */

  /* getSelectedObject() {
    return this.selectedObject$.asObservable();
  }

  setSelectedObject(objectName: ProjectObject, user: User) {
    this.storeSelectedObject(objectName, user);
    this.selectedObject$.next(objectName);
  } */

  /* storeSelectedObject(selectedObject, activeUser: User) {
    const storedSelectedObjectString: string = localStorage.getItem(this.selectedobjectLocalStorageKey);
    const newStoredObject: StoredObject = {
      userId: activeUser.userId,
      object: selectedObject
    }
    if (storedSelectedObjectString === null) {
      const storedObject: StoredObject [] = [newStoredObject]
      localStorage.setItem(this.selectedobjectLocalStorageKey, JSON.stringify(storedObject));
    } else {
      const storedSelectedObjects: StoredObject [] = JSON.parse(storedSelectedObjectString);
      const newStoredObjects = storedSelectedObjects
        .filter((storedObject: StoredObject) => storedObject.userId !== activeUser.userId)
        newStoredObjects.push(newStoredObject);
      localStorage.setItem(this.selectedobjectLocalStorageKey, JSON.stringify(newStoredObjects));
    }
  } */

  /* initializeSelectedObjectFromStorage(user: User) {
    if (this.loadedObjectForUserId !== user.userId) { // check if filters are already loaded for this user
      const storedSelectedObjects: StoredObject [] = JSON.parse(localStorage.getItem(this.selectedobjectLocalStorageKey));
      const storedSelectedObjectForUser = storedSelectedObjects?.find( (storedObject: StoredObject) => {
          return Number(storedObject.userId) === Number(user.userId)
        }
      )
      const objectsList = this.objects$.getValue();
      // this.getObjects().pipe(take(1)).subscribe( value => {
      if (storedSelectedObjectForUser && objectsList.includes(storedSelectedObjectForUser?.object)
      || storedSelectedObjectForUser?.object === '-1') {
        this.setSelectedObject(storedSelectedObjectForUser.object, user)
        this.loadedObjectForUserId = user.userId;
      } else {
        if (objectsList.length > 0) {
          this.setSelectedObject(objectsList[0].toString(), user);
        }
      }
      // })
    }

  } */

  setNotPolledControllers(controllers: Controller[]) {
    this.notPolledControllers = controllers;
    this.notPolledControllersChanged.next(this.notPolledControllers);
  }

  getNotPolledControllers() {
    return this.notPolledControllers.slice();
  }

  isControllerPolled(controller: Controller) {
    let stopPolling = false;
    const controllers = this.getNotPolledControllers();
    controllers.forEach( cont => {
      if(cont.object === controller.object && cont.subObject === controller.subObject
        && cont.zone === controller.zone && cont.subZone === controller.subZone) {
        stopPolling = true;
      }
    })
    return stopPolling;
  }

}



