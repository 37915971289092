import { Pipe, PipeTransform } from '@angular/core';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'getFloorsInSubobject'
})
export class GetFloorsInSubobjectPipe implements PipeTransform {

  transform(fList: LocationGroup[], selectedSubobject: ProjectSubObject): LocationGroup[] {
    const targetObj: string = selectedSubobject.designation.split('/')[0];
    const targetSubobj: string = selectedSubobject.designation.split('/')[1];

    const floorsInObjectAndSuboject = fList.filter((flo)=> {
      return flo.locationIds.some((locId: string)=> {
        const floorObject = locId.split('/')[0];
        const floorSubobject = locId.split('/')[1];
        return targetObj === floorObject && targetSubobj === floorSubobject
      })
    })
    return floorsInObjectAndSuboject;      
  }
}