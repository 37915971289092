import { Pipe, PipeTransform } from '@angular/core';
import { CARD_DANGER, CARD_WARNING } from 'src/environments/environment';

@Pipe({
  name: 'getValidToCssClass'
})
export class GetValidToCssClassPipe implements PipeTransform {

  transform(vldTo) {
    const validTo = new Date(vldTo)
    const today = new Date()
    const date3: Date = new Date (new Date().setDate(today.getDate() + CARD_DANGER))
    const date5: Date = new Date (new Date().setDate(today.getDate() + CARD_WARNING))
    if (validTo < date3) {
      return 'red'
    } else if (validTo < date5) {
      return 'orange'
    } else {
      return ''
    }
  }

}
