<ng-container
  *ngIf="
    selectedControllers
      | findProperty : Property.isHvacHeatCool as hvacHeatCoolProps
  "
>
  <ng-container
    *ngIf="
      selectedControllers
        | findProperty : Property.isHvacHeatCoolAuto as hvacHCAutoProps
    "
  >
    <ng-container
      *ngIf="
        selectedControllers
          | getRoomTemperatureData
            : location
            : (updated$ | async) as roomTempData
      "
    >
      <!-- AUTO MODE-->
      <ng-container
        *ngIf="
          hvacHCAutoProps.length > 0 &&
            hvacHCAutoProps[0]?.value.toString() === '1';
          else heatCool
        "
      >
        <div class="margin-top50">
          <div>
            {{ location === "bathroom" ? "Bathroom" : "Room" }} HVAC inactive
            when temperature is in range:
          </div>
          <ul class="margin-left30">
            <li>Someone in room: {{ roomTempData.baseSet }} ℃</li>
            <li>
              Room rented but empty:
              {{ +roomTempData.baseSet - +roomTempData.ecoHyst1 }} -
              {{ +roomTempData.baseSet + +roomTempData.ecoHyst1 }} ℃
            </li>
            <li>
              Room not rented:
              {{
                +roomTempData.baseSet -
                  +roomTempData.ecoHyst1 -
                  +roomTempData.ecoHyst2
              }}
              -
              {{
                +roomTempData.baseSet +
                  +roomTempData.ecoHyst1 +
                  +roomTempData.ecoHyst2
              }}
              ℃
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-template #heatCool>
        <ng-container *ngIf="hvacHeatCoolProps | propIsActive; else heat">
          <!-- COOLING MODE-->
          <div class="margin-top50">
            <div>
              {{ location === "bathroom" ? "Bathroom" : "Room" }} temperature
              set will be:
            </div>
            <ul class="margin-left30">
              <li>Someone in room: {{ roomTempData.baseSet }} ℃</li>
              <li>
                Room rented but empty:
                {{ +roomTempData.baseSet + +roomTempData.ecoHyst1 }} ℃
              </li>
              <li>
                Room not rented:
                {{
                  +roomTempData.baseSet +
                    +roomTempData.ecoHyst1 +
                    +roomTempData.ecoHyst2
                }}
                ℃
              </li>
            </ul>
            <div *ngIf="location === 'room'" class="margin-top10">
              In room regulation is between
              {{ +roomTempData.baseSet - +roomTempData.regulation }} ℃ and
              {{ +roomTempData.baseSet + +roomTempData.regulation }} ℃
            </div>
          </div>
        </ng-container>
        <!-- HEATING MODE -->
        <ng-template #heat>
          <div class="margin-top50">
            <div>
              {{ location === "bathroom" ? "Bathroom" : "Room" }} temperature
              set will be:
            </div>
            <ul class="margin-left30">
              <li>Someone in room: {{ roomTempData.baseSet }} ℃</li>
              <li>
                Room rented but empty:
                {{ roomTempData.baseSet - roomTempData.ecoHyst1 }} ℃
              </li>
              <li>
                Room not rented:
                {{
                  roomTempData.baseSet -
                    roomTempData.ecoHyst1 -
                    roomTempData.ecoHyst2
                }}
                ℃
              </li>
            </ul>
            <div *ngIf="location === 'room'" class="margin-top10">
              In room regulation is between
              {{ roomTempData.baseSet - roomTempData.regulation }} ℃ and
              {{ +roomTempData.baseSet + +roomTempData.regulation }} ℃
            </div>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
