import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';

@Component({
  selector: 'app-hvac-common-area-modal-view',
  templateUrl: './hvac-common-area-modal-view.component.html',
  styleUrls: ['./hvac-common-area-modal-view.component.scss']
})
export class HvacCommonAreaModalViewComponent implements OnInit {
  @Input() controllers: Controller[];


  isRoomTemperatureCurrent = Property.isRoomTemperatureCurrent;
  isRoomTemperatureSet = Property.isRoomTemperatureSet;
  // isHVACLargeTempDiff = Property.isHVACLargeTempDiff;
  isHvacHeatCool = Property.isHvacHeatCool
  isHvacHeatCoolAuto = Property.isHvacHeatCoolAuto
  isHVACLargeTempDiffRoom = Property.isHVACLargeTempDiffRoom;
  isHVACLargeTempDiffBathroom = Property.isHVACLargeTempDiffBathroom;
  // isHvacHeatingCooling = Property.isHvacHeatingCooling;


  isHvacActive = Property.isHvacActive;
  isFancoilV1 = Property.isFancoilV1;
  isFancoilV2 = Property.isFancoilV2;
  isFancoilV3 = Property.isFancoilV3;

  isCommunicationError = Property.isCommunicationError;
  isWindowOpened = Property.isWindowOpened;
  isWindowOpenTooLongAlarm = Property.isWindowOpenTooLongAlarm;




  constructor(
    private apiProjectService: ApiProjectService
    ) { }

  ngOnInit(): void {
  }

  clearStatus() {
    // to clear status of /safety/sos,   /security/burglary, and  /security/safe/burglary,
    //  send any true value to any of those endpoints
    let commError: string;
    let commErrorid: number;
    let commErrorValue;


    this.controllers.forEach((controller: Controller) => {
      controller.controllerProperties.$values.forEach((prop)=> {
        if(Property.isCommunicationError(prop)) {
          commError = controller.designation;
          commErrorid = prop.id;
          commErrorValue = prop.value;
        }
      })
      }
    );

      if(Number(commErrorValue)) {
        this.apiProjectService.changeProperty(commError, commErrorid, 1).subscribe();
      }
  }

  getHvacActiveMode() {
    const target = this.controllers[0].controllerProperties.$values.find(prop => {
      if (Property.fancoilMode(prop) && typeof (+prop.value) === 'number'  && prop.value !== '') {
        return true;
      }
    })

    if(+target.value === 0) {
      return 'i-hvac-off';
    } else if (+target.value === 1) {
      return 'icon-hvac-low font-size15';
    } else if(+target.value === 2) {
      return 'icon-hvac-mid font-size15';
    } else if (+target.value === 3) {
      return 'icon-hvac-high font-size15';
    } else if (+target.value === 6) {
      return 'i-hvac-auto'
    }
  }


}
