import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Card } from 'src/app/core/models/card/card.model';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { User } from 'src/app/core/models/user/user.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRolesService } from 'src/app/modules/roles/services/http/api-roles.service';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { UsersService } from 'src/app/modules/users/services/users.service';

import { BACKUP_CARD_YEARS_ACTIVE } from 'src/environments/environment';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { enterAnimation } from 'src/app/shared/animations/animations';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { EditCardModalComponent } from 'src/app/modules/rooms/components/edit-card-modal/edit-card-modal.component';
import { LocationGroup, ApiLocationGroupsService } from 'src/app/shared/services/api-location-groups.service';
import { LocationGroupsService } from 'src/app/shared/services/location-groups.service';
import { CardOnHolderService } from 'src/app/core/services/card-on-holder.service';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';

@Component({
  selector: 'app-access-control-modal-cards',
  templateUrl: './access-control-modal-cards.component.html',
  animations: [enterAnimation],
  styleUrls: ['./access-control-modal-cards.component.scss'],
})
export class AccessControlModalCardsComponent implements OnInit, OnDestroy/* , AfterViewInit */  {
  // @ViewChild('selectContainerRooms') selectContainerRooms: SelectContainerComponent;
  @Input() controllers: Controller[];

  mainController: Controller;
  popoverOpened = false;
  cards: Card[] = [];
  guestCards: Card[] = [];
  staffCards: Card[] = [];
  roomSwap = false;
  openModal = false;

  permissions: Permission[];
  permissionsSubscription: Subscription;

  accessControlIds: string[] = [];
  floorList$: Observable<LocationGroup[]> = this.locationGroupsService.getFloorList();

  // newRoom: Controller[] = [];
  newArrayOfLocations: string[] = [];

  movingGuest: User;

  // selectedObjectRooms;
  // selectedSubObjectRooms;

  guestAddedSubscription: Subscription;

  cardOnHolder: boolean;
  cardOnHolderSubscription: Subscription;
  openCardOnReaderModal = false;
  cardExist = false;
  userBeingCreated = false;
  // showStaffCards = false
  activePopup: string;
  userToShowInPopup: User;
  tablesReady = false;

  signedInUser: User;
  swPermissions = SoftwarePermissionId;



  constructor(private modalController: ModalController,
              private apiUsersService: ApiUsersService,
              private usersService: UsersService,
              private apiLocationGroupsService: ApiLocationGroupsService,
              private projectService: ProjectService,
              private locationGroupsService: LocationGroupsService,
              private rolesService: RolesService,
              private apiRolesService: ApiRolesService,
              private cardOnHolderService: CardOnHolderService,
              private cardsService: CardsService,
              private currentUserStoreService: CurrentUserStoreService
              ) {}

  ngOnInit(): void {
    this.getMainController();
    this.getCardsByLocation();
    this.getPermissions();
    this.getCardOnHolderStatus();
    this.guestAddedSubscription = this.usersService.newGuestAdded.subscribe( () => {
      this.getCardsByLocation();
    })
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
  }

  /* ngAfterViewInit() {
    setTimeout( () => {
      this.groupControllersByLocation(this.mainController, this.accessControlIds).subscribe();
      this.selectContainerRooms.selectItems<Controller>(item => {
        if (item.id === this.mainController.id) {
          return true;
        }
      })
    },100)
  }

  onScrollEnd() {
    this.selectContainerRooms.update();
  } */

  getCardOnHolderStatus() {
    this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    this.cardOnHolderSubscription = this.cardOnHolderService.cardOnHolderChanged.subscribe(cardOnHolder =>
      this.cardOnHolderChanged(cardOnHolder));
  }

  cardOnHolderChanged(cardOnHolder: boolean) {
    this.cardOnHolder = cardOnHolder;
    if (this.openCardOnReaderModal === true && this.cardOnHolder === true) {
        this.createBackupCard();
    }
  }

  checkIfcardExists() {
    return this.cardOnHolderService.cardByUidExists(this.cardsService.lastCardsOnHolderUid);
  }


  getPermissions() {
    this.permissions = this.rolesService.getPermissions();
    this.apiRolesService.getPermissions().subscribe();
    this.permissionsSubscription = this.rolesService.permissionsChanged.subscribe(() => {
      this.permissions = this.rolesService.getPermissions();
    });
  }


  /* itemSelected(item: Controller) {
    if (item.id !== this.mainController.id) {
      this.openModal = true;
      this.newRoom[0] = item
    }
  } */

  getCardsByLocation() {
    this.apiUsersService.getCardsByLocation(this.mainController.designation).subscribe( (value: Card[]) => {
      this.cards = value;
      this.guestCards = this.cards.filter( card => card.type === 7);
      this.staffCards = this.cards.filter( card => card.type !==7);

      console.log(this.cards)

      this.guestCards = this.guestCards.sort((a,b) => (+this.getCardPosition(a)) - (+this.getCardPosition(b)))
      this.tablesReady = true;
    })
  }


  getMainController() {
    this.mainController = Controller.getMainController(this.controllers)
 /*    this.newRoom[0] = this.mainController;
    this.selectedObjectRooms = this.mainController.object
    this.selectedSubObjectRooms = this.mainController.subObject; */

    // this.controllers.find( (controller: Controller) => {
    //   const lastElement = controller.locationId.substring(controller.locationId.length - 1);
    //   if (lastElement === '0') {
    //     this.mainController = controller;
    //     this.newRoom[0] = controller;
    //     this.selectedObjectRooms = controller.object
    //     this.selectedSubObjectRooms = controller.subObject;
    //     return;
    //   }
    // })
  }

  createBackupCard() {
    if (this.cardOnHolder) {
      this.openCardOnReaderModal = false;
      this.checkIfcardExists().subscribe( value => {
        if (value === false) {
          const accessControl = [];
          this.accessControlIds.forEach( id => {
           accessControl.push(this.projectService.getControllerByLocationId(id));
          })

          const user: User = new User();
          user.typeId = 2;
          user.firstName = 'Backup'
          user.lastName = this.mainController.name;
          user.permissions = [];

            this.permissions.forEach( perm => {
              const locationId = perm.name.split(' ')[2]
              if((locationId  && perm.typeId === 1) &&
               (this.mainController.designation === locationId || this.accessControlIds.includes(locationId.toString()))) {
                user.permissions.push(perm);
              }
            })


          user.cards = [];

          const card: Card = new Card();
          const todayDate = new Date();
          const todayDate2 = new Date();
          const backupPosition: number = this.getBackupPosition();

          card.type = 7;
          card.userId = 0;
          card.uid = this.cardsService.lastCardsOnHolderUid;
          card.validFrom = todayDate;
          card.validTo = new Date (todayDate2.setFullYear(todayDate2.getFullYear() + BACKUP_CARD_YEARS_ACTIVE));
          card.blacklisted = 0;
          card.customData = '{"Positions": [{"Position": ' + backupPosition + ', "LocationId": ' + '"' + this.mainController.locationId + '"' + '}]}';


           user.cards.push(card);

            if (!this.userBeingCreated) {
              this.userBeingCreated = true;
              if (!this.backupCardExists()) {
                this.apiUsersService.addUser(user).subscribe(createdUser => {
                  this.userBeingCreated = false;
                  this.usersService.guestAdded();
                });
              } else {
                const backupCard: Card = this.getBackupCard();
                this.createNewBackupCard(backupCard.user, user)
              }
            }


        } else {
          this.cardExist = true;
        }
      })
    } else {
      this.openCardOnReaderModal = true;
    }
  }

  getBackupPosition() {
    let backupPosition: number;
    const backupSetting = this.mainController.controllerSettings.$values.find( setting => {
      if (setting.name === 'GuestCardPositions') {
        return true;
      }
    })

    if (backupSetting) {
      backupPosition = +backupSetting.value;
    } else {
      backupPosition = 10;
    }
    return backupPosition;
  }

  getCardPosition(card: Card) {
    const smallerStr = card.customData.substring(card.customData.indexOf('['),card.customData.indexOf(']'));
    const value = smallerStr.substring(smallerStr.indexOf(':')+1, smallerStr.indexOf(','))

    if (value) {
      return value;
    } else {
      return this.getBackupPosition()+1;
    }
  }

  backupCardExists() {
    if(this.cards) {
      const backupPosition: number = this.getBackupPosition();

      const backupCard = this.cards.find( card => {
      const smallerStr = card.customData.substring(card.customData.indexOf('['),card.customData.indexOf(']'));
      const value = smallerStr.substring(smallerStr.indexOf(':')+1, smallerStr.indexOf(','))
        if (+value === backupPosition) {
          return true;
        }
      })

      if (backupCard) {
        return true;
      } else {
        return false;
      }
    }
  }

  getBackupCard() {
    if(this.cards) {
      const backupPosition: number = this.getBackupPosition();

      const backupCard = this.cards.find( card => {
      const smallerStr = card.customData.substring(card.customData.indexOf('['),card.customData.indexOf(']'));
      const value = smallerStr.substring(smallerStr.indexOf(':')+1, smallerStr.indexOf(','))
        if (+value === backupPosition) {
          return true;
        }
      })

      if (backupCard) {
        return backupCard;
      }
    }
  }


  deleteGuest(user: User) {
    this.apiUsersService.deleteUser(user.userId).subscribe( () => {
      this.getCardsByLocation();
    });
  }

  createNewBackupCard(user: User, newUser: User) {
    this.apiUsersService.deleteUser(user.userId).subscribe( () => {
      this.apiUsersService.addUser(newUser).subscribe( () => {
        this.userBeingCreated = false;
        this.usersService.guestAdded();
        this.getCardsByLocation();
      });
    });
  }

  syncGuest(uid: string) {
    this.apiUsersService.syncCard(uid).subscribe();
  }

  groupControllersByLocation(mainController: Controller, arrayOfLocations: string[]) {
    return this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.AccessGroup).pipe(
      map( value => {
        value.forEach( group => {
          if (group.locationIds.includes(mainController.designation)) {
            group.locationIds.forEach( location => {
              const controller = this.projectService.getControllerByLocationId(location);
              const controllerIsAccessControl = Controller.isAccessControl(controller);
              if (controllerIsAccessControl) {
              // if (this.projectService.getControllerByLocationId(location).isAccessControl) {

                arrayOfLocations.push(location);
              }
            })
          }
        })
      })
    )
  }

/*   onChangeObjectSubobjectRooms(event) {
    this.selectedObjectRooms = event.target.value.split(',')[0];
    this.selectedSubObjectRooms = event.target.value.split(',')[1];
    this.selectContainerRooms.clearSelection();
    this.selectContainerRooms.update();
  }
 */
  onCardOnreaderModalDecision(decision) {
    this.openCardOnReaderModal = false;
 }

 onModalDecisionCardExist(decision) {
  this.cardExist = false;
}

  /* onModalDecision(decision) {
    if (decision) {
      this.newArrayOfLocations = [];
      lastValueFrom(this.groupControllersByLocation(this.newRoom[0], this.newArrayOfLocations)).then( () => {
        this.movingGuest.permissions = [];
        this.permissions.forEach( perm => {
          const locationId = perm.name.split(' ')[2]
          if((locationId  && perm.typeId === 1) &&
           (this.newRoom[0].designation === locationId || this.newArrayOfLocations.includes(locationId.toString()))) {
            this.movingGuest.permissions.push(perm);
          }
        })

        this.apiUsersService.getUser(this.movingGuest.userId).subscribe( user => {
          this.movingGuest.cards = user.cards;
          this.apiUsersService.updateUser(this.movingGuest).subscribe( () => {
            this.getCardsByLocation();
          });
        })
      });
    } else {
      this.newRoom[0] = this.mainController;
    }
    this.openModal = false;
    this.roomSwap = !this.roomSwap;
  } */

  showAccess(event: Event, card: Card) {
    if (event.type === 'mouseenter') {
      this.activePopup = card.uid;
      if (!this.userToShowInPopup) {
        this.apiUsersService.getUser(card.userId).subscribe( user => {
          this.userToShowInPopup = user;
          this.userToShowInPopup.permissions = user.permissions.filter( (perm: Permission) => {
            const locId = perm.name.split(' ')[2]
              const cont = this.projectService.getControllerByLocationId(locId)
              if (cont) {
                if (Controller.isAccessControl(cont)) {
                  return true;
                }
              }
          })
        })
      }
    } else if (event.type === 'mouseleave') {
      this.activePopup = undefined;
      this.userToShowInPopup = undefined;
    }
  }

  showAccessPopup(uid: string) {
    if (this.activePopup === uid) {
      return true;
    }
  }

  async editCard(userId: number) {
    const modal = await this.modalController.create({
      component: EditCardModalComponent,
      cssClass: 'cards-popover',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps:  {
        controllersInRoom: this.controllers,
        userId: userId
        }
    });
    return await modal.present();
  }

  ngOnDestroy() {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
    if (this.guestAddedSubscription) {
      this.guestAddedSubscription.unsubscribe();
    }
    if (this.cardOnHolderSubscription) {
      this.cardOnHolderSubscription.unsubscribe();
    }
  }

}
