import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'roomsCanBeJoined'
})
export class RoomsCanBeJoinedPipe implements PipeTransform {

  transform(controller: Controller): boolean {
    return Controller.roomCanBeJoined(controller);
  }

}
