import { Pipe, PipeTransform } from '@angular/core';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'getFloorsForRoomSelection'
})
export class GetFloorsForRoomSelectionPipe implements PipeTransform {

  transform(floorList: LocationGroup[], selectedDesignation: string): LocationGroup[] {
    const targetObj: string = selectedDesignation.split('/')[0];
    const targetSubobj: string = selectedDesignation.split('/')[1];

    const floorsInObjectAndSuboject = floorList.filter((flo)=> {
      return flo.locationIds.some((locId: string)=> {
        const floorObject = locId.split('/')[0];
        const floorSubobject = locId.split('/')[1];
        return( targetObj === floorObject  || targetObj === '*') && (targetSubobj === floorSubobject || targetSubobj === '*')
      })
    })

    return floorsInObjectAndSuboject;
  }

}
