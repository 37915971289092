import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
// import { Project } from 'src/app/core/models/project/project.model';

@Pipe({
  name: 'getRoomNumbers'
})
export class GetRoomNumbersPipe implements PipeTransform {

  transform(controllers: Controller[],
     controllerType: 'isGuestRoom' | 'isAccessControl', selectedDesignation: string): any[] {
    if (!controllers) {
      return [];
    }
    const targetObject = selectedDesignation.split('/')[0]
    const targetSubobject = selectedDesignation.split('/')[1]


    const roomNumbers = [];
    const roomData = [];
    controllers.forEach((cont)=> {
      const roomNumber: string = cont.designation.split('/')[2];
      if (!roomNumbers.includes(roomNumber) &&
      ((Number(cont.object) === Number(targetObject) || targetObject === '*') &&
       (Number(cont.subObject) === Number(targetSubobject) || targetSubobject === '*')) ) {
        roomNumbers.push(roomNumber);
        if (controllerType === 'isGuestRoom' && Controller.isGuestRoom(cont)) {
          roomData.push({
            number: roomNumber,
            name: cont.name
          });
        } else if (controllerType === 'isAccessControl'
        &&  Controller.isAccessControl(cont)) {
          roomData.push({
            number: roomNumber,
            name: cont.name
          });
        }
      }
    })
    /* project.controllers.forEach( driver => {
      driver[1].forEach( object => {
        if (Number(object[0]) === Number(targetObject) || (Number(targetObject) < 0) ) {
        object[1].forEach( subObj => {
          if (Number(subObj[0]) === Number(targetSubObject) || (Number(targetSubObject) < 0)) {
            subObj[1].forEach( zone => {
              zone[1].forEach( subZone => {
                const roomNumber: string = subZone[1].locationId.split('/')[2];
                if (!roomNumbers.includes(roomNumber)) {
                  roomNumbers.push(roomNumber);
                  if (controllerType === 'isGuestRoom' && Controller.isGuestRoom(subZone[1])) {
                    roomData.push({
                      number: roomNumber,
                      name: subZone[1].name
                    });
                  } else if (controllerType === 'isAccessControl'
                  &&  Controller.isAccessControl(subZone[1])) {
                    roomData.push({
                      number: roomNumber,
                      name: subZone[1].name
                    });
                  }
                }
              });
            })
        }
        });
      }
      });
    }); */
    return roomData;
  }

}
