<ng-container *ngIf="roomViewSize$ | async as roomViewSize">
 <!--  <ng-container *ngIf="roomViewSize === 'compact'">
    <div class="access-control-card-compact">
      <div
        [ngClass]="{
          'access-control-inactive-overlay-compact': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          'access-control-inactive-icon-compact': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: !controllers[0].isOnline
        }"
      >
        <div class="access-control-top-bar"></div>
        <div class="access-control-card-content-compact">
          <div class="status-container-full flex-row">
            <div class="access-control-name">{{ controllers[0].name }}</div>
          </div>
          <div class="flex-row width90 margin-top3 margin-left10">
            <div class="status-container-medium-compact flex-row">
              <div class="icon-temp font-size18 white icon-background-on"></div>
              <div class="status-text-on-compact">{{ getSetTemperature() }}</div>
            </div>
            <div class="status-container-medium-compact flex-row">
              <div class="icon-temp font-size18 white icon-background-on"></div>
              <div class="status-text-on-compact">{{ getCurrentTemperature() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container> -->

  <ng-container *ngIf="roomViewSize === 'full' || roomViewSize === 'scaledDown'">
    <div class="access-control-card">
      <div
        [ngClass]="{
          'access-control-inactive-overlay': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          'access-control-inactive-icon': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: !controllers[0].isOnline
        }"
      >
        <div class="access-control-top-bar"></div>
        <div class="access-control-card-content">
          <div class="status-container-full flex-row">
            <div class="icon-background-black icon-key font-size18 white"></div>
            <div class="access-control-name">{{ controllers[0].name }}</div>
          </div>
          <div class="flex-row width100 margin-top12">
            <div class="status-container-small margin-right6 flex-row">
              <div class="icon-temp font-size18 white icon-background-on"></div>
              <div class="status-text-off">{{'Set' | translate}} {{ getSetTemperature() }}</div>
            </div>
            <div class="status-container-small margin-left5 flex-row">
              <div class="icon-temp font-size18 white icon-background-on"></div>
              <div class="status-text-off">{{'Current' | translate}} {{ getCurrentTemperature() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="roomViewSize === 'minimal'">
    <div class="access-control-card-minimal">
      <div
        [ngClass]="{
          'access-control-inactive-overlay-minimal': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          'access-control-inactive-icon-minimal': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: !controllers[0].isOnline
        }"
      >
        <div class="access-control-top-bar-minimal"></div>
        <div class="flex-row">
          <div class="access-control-name-box-minimal">
            {{ controllers[0].name }}
          </div>
          <ng-container
            *ngIf="
              controllers
                | findProperty: isAnyAlarm
                | getActiveProps as activeAlarmProps
            "
          >
            <ng-container
              *ngIf="activeAlarmProps.length > 0; else noAlarmsActive"
              ><div class="margin-left5 icon-alert red"></div></ng-container
          ></ng-container>
          <ng-template #noAlarmsActive
            ><div class="margin-left5 icon-alert light-gray"></div></ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
