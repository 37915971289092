import { UserType } from './user-type.model';
import { Card } from '../card/card.model';
import { Role } from '../role.model';
import { SoftwarePermissionId } from '../permissions/software-permission-id.enum';

export class User {
    userId: number;
    id3dParty: string;
    typeId: number;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    refreshToken: string;
    customData: any;
    cards: Card[];
    type: UserType;
    fullName: string;
    cardLaters: Card[];
    token: string;
    permissions: any[];
    roles: Role[];
    activeUser: boolean;
    pin: string;
    licencePlate: string;

    constructor() {}

    havePermission(permissionId: number) {
        let userPermission = false;
        userPermission = this.roles?.some(role => role.permissions.some(permission => permission.permissionId === permissionId));
        if (!userPermission) {
           userPermission = this.permissions?.some(permission => permission.permissionId === permissionId);
        }
        return userPermission;
    }

    haveRole(roleId: number) {
        return this.roles?.some(role => role.roleId === roleId);
    }

    canEditRole(roleId: number): boolean {
        const roleToCheck: Role = this.roles?.find(r => r.roleId === roleId);
        return ((roleToCheck?.canEdit === 1) ?  true : false) || this.havePermission(SoftwarePermissionId.RolesUpdate);
      }
}
