
    <div class="log-title backgroundBlue flex-row-center-h">
      {{'Access control log' | translate}}
  </div>

  <div class="flex-row flex-row-space-evenly margin-left20 margin-top10 margin-right20">
      <ion-item class="width100">
        <ion-segment [(ngModel)]="activeCard"  (ngModelChange)="cardClicked($event)" >

          <ion-segment-button class="width25" value="access">
            <ion-label class="ion-text-wrap">{{'Access log' | translate}}</ion-label>
          </ion-segment-button>

          <ion-segment-button class="width25" value="alarm">
            <ion-label class="ion-text-wrap">{{'Alarm log' | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button class="width25" value="fullGraph">
            <ion-label class="ion-text-wrap">{{'Value log' | translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>
    </ion-item>
    </div>


  <div *ngIf="activeCard === 'access'">
  <app-access-logs [controllers]="controllers" [singleRoom]="true"></app-access-logs>
  </div>
  <div *ngIf="activeCard === 'alarm'">
      <app-alarm-logs [controllers]="controllers" [singleRoom]="true"></app-alarm-logs>
  </div>

  <div *ngIf="activeCard === 'fullGraph'">
    <div class="flex-row-center-v gap5 margin-top10 margin-left20">
      <ion-button color="primary" size="small" (click)="showLast24hours()">24 H</ion-button>
      <ion-button color="primary" size="small" (click)="showLast7days()">7 D</ion-button>
      <ion-button color="primary" size="small" (click)="showLast30days()">30 D</ion-button>
      <ion-button color="primary" size="small" (click)="showCustom()">{{'Custom' | translate}}</ion-button>
      <div class="flex-row-center-v">
        <select [(ngModel)]="selectedController" (ngModelChange)="controllerChanged($event)">
          <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
          </select>
      </div>
      <ion-list class="width40 margin-left20">
        <ion-item>
          <ion-label>{{'Properties' | translate}}
          </ion-label>
          <ion-select *ngIf="showSelect" multiple="true" (ionChange)="selectChanged($event)" [(ngModel)]="propSelect">
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDoorOpened" [value]="Property.isDoorOpened">{{'Door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWindowOpened" [value]="Property.isWindowOpened">{{'Window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCleaningStatus" [value]="Property.isCleaningStatus">{{'Cleaning status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isInspectedStatus" [value]="Property.isInspectedStatus">{{'Inspected status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDoorLock" [value]="Property.isDoorLock">{{'Door locked' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedWindow" [value]="Property.isBurglaryDetectedWindow">{{'Burglary detected on window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedDoor" [value]="Property.isBurglaryDetectedDoor">{{'Burglary detected on door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDndStatus" [value]="Property.isDndStatus">{{'DND status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isMinibarWasOpenedStatus" [value]="Property.isMinibarWasOpenedStatus">{{'Minibar was opened' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWelcomeLightStatus" [value]="Property.isWelcomeLightStatus">{{'Welcome light status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV1" [value]="Property.isFancoilV1">{{'Fancoil V1' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV2" [value]="Property.isFancoilV2">{{'Fancoil V2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV3" [value]="Property.isFancoilV3">{{'Fancoil V3' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomRadiator" [value]="Property.isBathroomRadiator">{{'Bathroom radiator' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomFloorHeating" [value]="Property.isBathroomFloorHeating">{{'Bathroom floor heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomFloorHeating" [value]="Property.isRoomFloorHeating">{{'Room floor heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHVACLargeTempDiffRoom" [value]="Property.isHVACLargeTempDiffRoom">{{'HVAC large temp diff room' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHVACLargeTempDiffBathroom" [value]="Property.isHVACLargeTempDiffBathroom">{{'HVAC large temp diff bathroom' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRegulation" [value]="Property.isHvacRegulation">{{'HVAC regulation' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacCalibration" [value]="Property.isHvacCalibration">{{'HVAC calibration' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomEcoHyst1" [value]="Property.isHvacRoomEcoHyst1">{{'HVAC room eco hys 1' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomEcoHyst2" [value]="Property.isHvacRoomEcoHyst2">{{'HVAC room eco hys 2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacBathroomEcoHyst1" [value]="Property.isHvacBathroomEcoHyst1">{{'HVAC bathroom eco hys 1' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacBathroomEcoHyst2" [value]="Property.isHvacBathroomEcoHyst2">{{'HVAC bathroom eco hys 2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacHeatCool" [value]="Property.isHvacHeatCool">{{'HVAC heating cooling' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacHeatCoolAuto" [value]="Property.isHvacHeatCoolAuto">{{'HVAC heating cooling auto' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFlood" [value]="Property.isFlood">{{'Flood' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacActive" [value]="Property.isHvacActive">{{'HVAC active' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilOff" [value]="Property.isHvacRoomControlFancoilOff">{{'HVAC room control fancoil off' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilLow" [value]="Property.isHvacRoomControlFancoilLow">{{'HVAC room control fancoil low' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilMedium" [value]="Property.isHvacRoomControlFancoilMedium">{{'HVAC room control fancoil medium' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilHigh" [value]="Property.isHvacRoomControlFancoilHigh">{{'HVAC room control fancoil high' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilAuto" [value]="Property.isHvacRoomControlFancoilAuto">{{'HVAC room control fancoil auto' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeBurglary" [value]="Property.isSafeBurglary">{{'Safe burglary' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeCommError" [value]="Property.isSafeCommError">{{'Safe comm error' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeDoor" [value]="Property.isSafeDoor">{{'Safe door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomArmed" [value]="Property.isRoomArmed">{{'Room armed' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglary" [value]="Property.isBurglary">{{'Burglary' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedByPIR" [value]="Property.isBurglaryDetectedByPIR">{{'Burglary detected by PIR' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isIgnoreCardTray" [value]="Property.isIgnoreCardTray">{{'Ignore card tray' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCardType" [value]="Property.isCardType">{{'Card type' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isIgnoreWindow" [value]="Property.isIgnoreWindow">{{'Ignore window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSOS" [value]="Property.isSOS">{{'SOS' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPirInput" [value]="Property.isPirInput">{{'PIR input' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPanelBlocked" [value]="Property.isPanelBlocked">{{'Panel blocked' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCardDeposited" [value]="Property.isCardDeposited">{{'Card deposited' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isValveHeating" [value]="Property.isValveHeating">{{'Valve heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isValveCooling" [value]="Property.isValveCooling">{{'Valve cooling' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isNewCard" [value]="Property.isNewCard">{{'New card' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDumper" [value]="Property.isDumper">{{'Dumper' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isContactor" [value]="Property.isContactor">{{'Contactor' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWelcomeLight" [value]="Property.isWelcomeLight">{{'Welcome light' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isLight" [value]="Property.isLight">{{'Light' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isMakeUpRoom" [value]="Property.isMakeUpRoom">{{'Make up room' | translate}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
      <ion-label>{{'Access log' | translate}}</ion-label>
      <ion-checkbox color="primary" (ionChange)="onAccessLogClick($event)"></ion-checkbox>
      <ion-label>{{'Alarm log' | translate}}</ion-label>
      <ion-checkbox color="primary" (ionChange)="onAlarmLogClick($event)"></ion-checkbox>
      <app-date-time-from-to #dateTimeFromTo (selectedDateTimeFromTo)="setDateTimeFromTo($event)" [hidden]="hideCustomTime"></app-date-time-from-to>
    </div>
    <div *ngIf="(tableCreated && tableProperties.length > 0) || ((giveAccess || giveAlarm) && !logsLoading)">
      <div class="flex-row-center-h margin-top20 width100">
          <table>
            <tr>
              <th class="   ">{{'Time recorded' | translate}}</th>
              <th *ngIf="tableCreated && tableProperties.length > 0" class=" ">{{'Property' | translate}}</th>
              <th *ngIf="tableCreated && tableProperties.length > 0" class=" ">{{'Value' | translate}}</th>
              <th *ngIf="giveAccess" class=" ">{{'User' | translate}}</th>
              <th *ngIf="giveAccess" class=" ">{{'Location' | translate}}</th>
              <th *ngIf="giveAlarm" class=" ">{{'Level' | translate}}</th>
              <th *ngIf="giveAlarm" class=" ">{{'Description' | translate}}</th>
            </tr>
              <tr *ngFor="let data of tableData; let i = index" class="br-gray-b">
                <td *ngIf="showDataAtPage(i)" class="   ">
                 {{ data.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}
                </td>
                <td  *ngIf="tableCreated && tableProperties.length > 0 && showDataAtPage(i)" class=" ">
                  {{ getPropertyName(data.propertyType) }}
                </td>
                <td *ngIf="tableCreated && tableProperties.length > 0 && showDataAtPage(i)" class=" ">
                  {{ data.value}}
                </td>
                <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                  {{ data.user}}
                </td>
                <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                  {{ data.locationName}}
                </td>
                <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                  {{ data.level}}
                </td>
                <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                  {{ data.description}}
                </td>
              </tr>
          </table>
        </div>
        <div class="flex-row width95 margin-top5">
          <app-room-log-paginator
            class="to-right"
            [paginationData]="tableData"
            (targetPage)="onPageChange($event)">
          </app-room-log-paginator>
        </div>
    </div>
  <div *ngIf="(!tableCreated && tableProperties.length > 0) || logsLoading" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>
  </div>

