import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { StoredFilter } from 'src/app/core/models/project/stored-filter.model';
import { ControllerFilters, ITemperatureDifferenceFilter, ITemperatureFilter } from 'src/app/core/models/view-filter.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { LocationGroup } from './api-location-groups.service';

@Injectable({
  providedIn: 'root'
})

export class FiltersService {

  private searchFilter$ = new BehaviorSubject<string>('');
  private filters$ = new BehaviorSubject<ControllerFilters>(new ControllerFilters(['Room2']));
  private commonAreaFilters$ = new BehaviorSubject<ControllerFilters>(new ControllerFilters(
    ['AccessControl_v1', 'IOCommonArea', 'HvacCommonArea']));
  loadedFiltersForUserId: number = undefined;
  loadedCommonAreaFiltersForUserId: number = undefined;

  constructor(
    private currentUserStoreService: CurrentUserStoreService,
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService
    ) {}

  getFilters() {
    return this.filters$.asObservable();
  }

  setFilters(controllerFilters: ControllerFilters, refresh = true) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    // const newControllerFilters = JSON.parse(JSON.stringify(controllerFilters));
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeGuestRoomFilters(activeUserId, newControllerFilters)
    this.filters$.next(newControllerFilters);
    if (refresh === true) {
      this.projectService.setLoadingFilteredControllersFat(true)
      this.apiProjectService.getFilteredControllersFat(newControllerFilters).subscribe(()=> {
        this.projectService.setLoadingFilteredControllersFat(false)
      });
    }
  }
  getCommonAreaFilters() {
    return this.commonAreaFilters$.asObservable();
  }

  setCommonAreaFilters(controllerFilters: ControllerFilters, refresh = true) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    // const newControllerFilters = JSON.parse(JSON.stringify(controllerFilters));
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeCommonAreaFilters(activeUserId, newControllerFilters)
    this.commonAreaFilters$.next(newControllerFilters);
    if (refresh === true) {
      this.projectService.setLoadingFilteredControllersFat(true)
      this.apiProjectService.getFilteredControllersFat(newControllerFilters).subscribe(()=> {
        this.projectService.setLoadingFilteredControllersFat(false)
      });
    }

  }

  initFilters(controllerFilters: ControllerFilters) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    // const newControllerFilters = JSON.parse(JSON.stringify(controllerFilters));
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeGuestRoomFilters(activeUserId, newControllerFilters)
    this.filters$.next(newControllerFilters);
  }

  initCommonAreaFilters(controllerFilters: ControllerFilters) {
    const activeUserId = this.currentUserStoreService.getUser().userId;
    // const newControllerFilters = JSON.parse(JSON.stringify(controllerFilters));
    const newControllerFilters = structuredClone(controllerFilters);
    StoredFilter.storeCommonAreaFilters(activeUserId, newControllerFilters)
    this.commonAreaFilters$.next(newControllerFilters);
  }

  getSearchFilter() {
    return this.searchFilter$.asObservable();
  }

  setSearchFilter(newSearchFilter: string) {
    this.searchFilter$.next(newSearchFilter);
  }

  getObjectsFilter(): Observable<number[]> {
    return this.filters$.pipe(map((f: ControllerFilters)=> {
      return f?.Location?.Objects
    }))
  }

  getObjectsFilterFull(): Observable<ProjectObject[]> {
    return this.projectService.getObjects().pipe(switchMap((objects: ProjectObject[])=> {
      return this.filters$.pipe(map((f: ControllerFilters)=> {
        return f?.Location?.Objects.map((objId)=> {
          return objects.find((obj: ProjectObject)=> {
            return obj.projectObjectId === objId;
          })
        })
      }))
    }))
  }

  setObjectsFilter(objectLocationIds: number[], refresh = true) {
    // const newFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Location.Objects = objectLocationIds
    newFilters.Location.Floors = [];
    this.setFilters(newFilters, refresh)
  }

  setCommonAreaObjectsFilter(objectLocationIds: number[], refresh = true) {
    // const newFilters =  JSON.parse(JSON.stringify(this.commonAreaFilters$.getValue()));
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    newFilters.Location.Objects = objectLocationIds
    newFilters.Location.Floors = [];
    this.setCommonAreaFilters(newFilters, refresh)
  }

  getHvacSpeed(): Observable<null | 1 | 2 | 3> {
    return this.filters$.pipe(map((f: ControllerFilters)=> {
      return f.Hvac.HvacSpeed
    }))
  }


  // FLOOR FILTER

  getFloorsFilter(): Observable<number[]> {
    return this.filters$.pipe(map((f: ControllerFilters)=> {
      return f.Location.Floors
    }))
  }

  changeFloorFilter(floor: LocationGroup, /* object, subObject, */ /* newValue: boolean *//* , user: User  */) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());


    const floorLocIdIndex =newFilters.Location.Floors.findIndex((flId: number) => {
      return flId === floor.id
    })
    if (floorLocIdIndex === -1) {
      newFilters.Location.Floors.push(floor.id)
    } else {
      newFilters.Location.Floors.splice(floorLocIdIndex,1)
    }
    this.setFilters(newFilters)
  }

  selectAllFloors(refresh = true) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());
    newFilters.Location.Floors = [];
    this.setFilters(newFilters, refresh)
  }


  // COMMON AREA FLOOR FILTER
  getCommonAreaFloorsFilter(): Observable<number[]> {
    return this.commonAreaFilters$.pipe(map((f: ControllerFilters)=> {
      return f.Location.Floors
    }))
  }

  changeCommonAreaFloorFilter(floor: LocationGroup, /* object, subObject, */ /* newValue: boolean *//* , user: User  */) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.commonAreaFilters$.getValue()));
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    const floorLocIdIndex =newFilters.Location.Floors.findIndex((flId: number) => {
      return flId === floor.id
    })
    if (floorLocIdIndex === -1) {
      newFilters.Location.Floors.push(floor.id)
    } else {
      newFilters.Location.Floors.splice(floorLocIdIndex,1)
    }
    this.setCommonAreaFilters(newFilters)
  }

  selectAllCommonAreaFloors(refresh = true) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.commonAreaFilters$.getValue()));
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    newFilters.Location.Floors = [];
    this.setCommonAreaFilters(newFilters, refresh)
  }


  // GROUP FILTER

  getGroupsFilter(): Observable<number[]> {
    return this.filters$.pipe(map((f: ControllerFilters)=> {
      return f.Location.Groups
    }))
  }

  changeGroupFilter(group: LocationGroup, /* object, subObject, */ /* newValue: boolean *//* , user: User  */) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    const groupLocIdIndex =newFilters.Location.Groups.findIndex((grId: number) => {
      return grId === group.id
    })
    if (groupLocIdIndex === -1) {
      newFilters.Location.Groups.push(group.id)
    } else {
      newFilters.Location.Groups.splice(groupLocIdIndex,1)
    }
    this.setFilters(newFilters)
  }

  selectAllGroups() {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Location.Groups = [];
    this.setFilters(newFilters)
  }

  // HVAC FILTER


   getTempType() {
    return this.filters$.pipe(map((filters)=> {
      return filters.Hvac?.TemperatureFilter?.Type;
     }));
   }

   getComparisonOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.Hvac?.TemperatureFilter?.Comparison;
     }));
   }

   setOperatorOption(operatorOption: 0 | 1 | 2) {
      // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
      const newFilters = structuredClone(this.filters$.getValue());

      newFilters.Hvac.TemperatureFilter.Comparison = operatorOption;
      this.setFilters(newFilters);
   }

   getLocationOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.Hvac?.TemperatureFilter?.Location
     }));
   }

   getTempFilter() {
    return this.filters$.pipe(map((filters)=> {
      return filters.Hvac?.TemperatureFilter
     }));
   }

   getTemperature() {
    return this.filters$.pipe(map((filters)=> {
     return filters.Hvac?.TemperatureFilter?.Value;
    }));
  }

  setTempFilter(newValue: null | ITemperatureFilter) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Hvac.TemperatureFilter = newValue;
    this.setFilters(newFilters);
  }

  setTempDiffFilter(newValue: null | ITemperatureDifferenceFilter) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Hvac.TemperatureDifferenceFilter = newValue;
    this.setFilters(newFilters);
  }

   setTemperature(temperature: any) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    if (temperature === undefined || temperature === null || temperature === '') {
      newFilters.Hvac.TemperatureFilter.Value = null;
    } else {
      newFilters.Hvac.TemperatureFilter.Value = temperature;
    }
    this.setFilters(newFilters);
   }

  changeTempType() {
    const currentTempOptionValue = this.filters$.getValue().Hvac.TemperatureFilter.Type
    if (currentTempOptionValue === 1) {
      this.setTempType(0);
    } else {
      this.setTempType(1);
    }
   }

  setTempType(tempType: 0 | 1) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Hvac.TemperatureFilter.Type = tempType;
    this.setFilters(newFilters)
   }

  changeOperatorOption() {
    const currentNumericalOperatorValue = this.filters$.getValue().Hvac.TemperatureFilter.Comparison;
    if (currentNumericalOperatorValue === 0) {
      this.setOperatorOption(1);
    } else if (currentNumericalOperatorValue === 1) {
      this.setOperatorOption(2);
    } else {
      this.setOperatorOption(0);
    }
  }

  changeRoomOrBathroom() {
    const currentLocationOption = this.filters$.getValue().Hvac.TemperatureFilter.Location
    if (currentLocationOption === 1) {
      this.setTempLocation(0);
    } else {
      this.setTempLocation(1);
    }
  }

  setTempLocation(newValue: 0 | 1) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
   const newFilters = structuredClone(this.filters$.getValue());
   newFilters.Hvac.TemperatureFilter.Location = newValue;
   this.setFilters(newFilters);
  }

  // Temperature difference filter

  getTempDiffLocationOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.Hvac.TemperatureDifferenceFilter?.Location;
     }));
   }

   getTempDiffComparisonOption() {
    return this.filters$.pipe(map((filters)=> {
      return filters.Hvac.TemperatureDifferenceFilter?.Comparison;
     }));
   }

   setTempDiffLocationOption(locationOption: 0 | 1) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Hvac.TemperatureDifferenceFilter.Location = locationOption;
    this.setFilters(newFilters);
   }

   getTempDiffFilter() {
    return this.filters$.pipe(map((filters)=> {
     return filters.Hvac.TemperatureDifferenceFilter;
    }));
  }

   getTempDiffValue() {
     return this.filters$.pipe(map((filters)=> {
      return filters.Hvac.TemperatureDifferenceFilter?.Value;
     }));
   }

   setTempDiffValue(temperatureDiff: any) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    if (temperatureDiff === undefined || temperatureDiff === null || temperatureDiff === '') {
      newFilters.Hvac.TemperatureDifferenceFilter.Value = null;
    } else {
      newFilters.Hvac.TemperatureDifferenceFilter.Value = temperatureDiff;
    }
    this.setFilters(newFilters);
   }


  changeTempDiffRoomOrBathroom() {
    const currentTempDiffLocationOption = this.filters$.getValue().Hvac.TemperatureDifferenceFilter.Location;
    if (currentTempDiffLocationOption === 1) {
      this.setTempDiffLocationOption(0);
    } else {
      this.setTempDiffLocationOption(1);
    }
  }

  changeTempDiffComparisonOption() {
    const currentNumericalOperatorValue = this.filters$.getValue().Hvac.TemperatureDifferenceFilter.Comparison;
    if (currentNumericalOperatorValue === 0) {
      this.setTempDiffOperatorOption(1);
    } else if (currentNumericalOperatorValue === 1) {
      this.setTempDiffOperatorOption(2);
    } else {
      this.setTempDiffOperatorOption(0);
    }
  }

  setTempDiffOperatorOption(operatorOption: 0 | 1 | 2) {
    // const newFilters: ControllerFilters = JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Hvac.TemperatureDifferenceFilter.Comparison = operatorOption;
    this.setFilters(newFilters);
 }

 clearStatusFilters() {
  const newFilters: ControllerFilters = this.filters$.getValue();
  newFilters.Cleaning = {
    Inspected:  null,
      Cleaned:  null,
      MakeUpRoom:  null
  };
  newFilters.ControllerTypes = {
    ControllerTypes: ['Room2']
  };
  newFilters.Hvac = {
    Active:  null,
    Heating:  null,
    Cooling:  null,
    HvacSpeed: null,
    TemperatureFilter: null ,
    TemperatureDifferenceFilter: null
  };
  newFilters.Statuses = {
    Online: null,
    Rented: null,
    AlarmActive: null,
    DoNotDisturb: null,
    RoomArmed: null,
    WindowOpen: null,
    DoorOpen: null,
    IgnoreWindow: null,
    IgnoreCardTray: null
    }

  this.setFilters(newFilters);
 }

  initializeFiltersFromStorage(userId: number) {
    if (this.loadedFiltersForUserId !== userId) { // check if filters are already loaded for this user
      const storedSettingsForUser = StoredFilter.getGuestRoomFilterFromStorage(userId);
      if (storedSettingsForUser) {
        this.initFilters(storedSettingsForUser.filters)
        this.loadedFiltersForUserId = userId;
      } else {
        this.initFilters(new ControllerFilters(['Room2']));
      }
    }
  }

  initializeCommonAreaFiltersFromStorage(userId: number) {
    if (this.loadedCommonAreaFiltersForUserId !== userId) { // check if filters are already loaded for this user
      const storedSettingsForUser = StoredFilter.getCommonAreaFilterFromStorage(userId);
      if (storedSettingsForUser) {
        this.initCommonAreaFilters(storedSettingsForUser.filters)
        this.loadedFiltersForUserId = userId;
      } else {
        this.initCommonAreaFilters(new ControllerFilters(['AccessControl_v1', 'IOCommonArea', 'HvacCommonArea']));
      }
    }
  }

  updateCleaningFilterStatus(statusName: string, newStatus: boolean | null ) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Cleaning[statusName] = newStatus;
    this.setFilters(newFilters)
  }

  updateOtherFilterStatus(statusName: string, newStatus: boolean | null ) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Statuses[statusName] = newStatus;
    this.setFilters(newFilters)
  }

  updateHvacFilterStatus(statusName: string, newStatus: boolean | null ) {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const newFilters = structuredClone(this.filters$.getValue());

    newFilters.Hvac[statusName] = newStatus;
    this.setFilters(newFilters)
  }

  // COMMON AREA FILTER
  isControllerTypeFilterActive(controllerTypeFilter: 'AccessControl_v1' | 'IOCommonArea' | 'HvacCommonArea') {
    const contTypesFilter = this.commonAreaFilters$.getValue().ControllerTypes.ControllerTypes;
   /*  if (!contTypesFilter.includes('AccessControl_v1') && !contTypesFilter.includes('IOCommonArea')
   && !contTypesFilter.includes('HvacCommonArea')){
      return true
    } */
  /*   if (contTypesFilter.length === 0) {
      return false;
    } */
    return contTypesFilter.includes(controllerTypeFilter);
  }

  onControllerTypeFilterClick(controllerTypeFilter: 'AccessControl_v1' | 'IOCommonArea' | 'HvacCommonArea') {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.commonAreaFilters$.getValue()));
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    const typeIndex = newFilters.ControllerTypes.ControllerTypes.findIndex((type: string) => {
      return controllerTypeFilter === type
    })
    if (typeIndex === -1) {
      newFilters.ControllerTypes.ControllerTypes.push(controllerTypeFilter)
    } else {
      newFilters.ControllerTypes.ControllerTypes.splice(typeIndex,1)
    };
    /* const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.filters$.getValue()));
    const floorLocIdIndex =newFilters.Location.Floors.findIndex((flId: number) => {
      return flId === floor.id
    })
    if (floorLocIdIndex === -1) {
      newFilters.Location.Floors.push(floor.id)
    } else {
      newFilters.Location.Floors.splice(floorLocIdIndex,1)
    } */
    this.setCommonAreaFilters(newFilters)
  }


  resetCommonAreaControllerTypeFilters() {
    // const newFilters: ControllerFilters =  JSON.parse(JSON.stringify(this.commonAreaFilters$.getValue()));
    const newFilters = structuredClone(this.commonAreaFilters$.getValue());

    newFilters.ControllerTypes.ControllerTypes = ['AccessControl_v1', 'IOCommonArea', 'HvacCommonArea'];
    this.setCommonAreaFilters(newFilters);
  }




}
