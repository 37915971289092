import { Component, OnInit, OnDestroy, HostListener, Optional, Inject, LOCALE_ID } from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { User } from '../../../core/models/user/user.model';
import { CardReaderService } from './../../../core/services/card-reader.service';
import { enterAnimation } from '../../animations/animations';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { AlarmAlert } from '../../../core/models/alarms/alarm-alert.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { ApiAlarmsService } from 'src/app/modules/alarms/services/http/api-alarms.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Platform } from '@ionic/angular';
import { UserSettingsService } from '../../services/user-settings.service';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { FiltersService } from '../../services/filters.service';
import { ApiHvacModesService } from 'src/app/modules/groups/services/api-hvac-modes.service';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { HvacModesService } from 'src/app/modules/groups/services/hvac-modes.service';
import { Router } from '@angular/router';
import { HvacModeType } from 'src/app/core/models/hvac-mode-type.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [enterAnimation],
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription;
  swPermissions = SoftwarePermissionId;
  cardReaderConnected: boolean;
  cardReaderConnectedSubscription: Subscription;
  dropdownOpened = false;
  langDropdownOpened = false;
  alarms: AlarmAlert[] = [];
  alarmsSubscription: Subscription;
  alarmsInit: boolean;
  currentLang: string;
  objectDropdownOpened = false;

  objects$ = this.projectService.getObjects();
  // selectedObject$ = this.projectService.getSelectedObject();
  // objectsFilter$ = this.filterService.getObjectsFilter()
  // project$: Observable<Project> = this.projectService.getProject();
  // updatedPipe$ = this.projectService.updatedPipe$.asObservable();
  // allControllersFat$: Observable<Controller[]> = this.projectService.getAllControllersFat();
  objectsFilter$: Observable<number[]> = this.filterService.getObjectsFilter(); // location ids
  // allObjects$: Observable<ProjectObject[]> = this.projectService.getObjects();
  objFilterSub: Subscription;
  // activeHvacMode: HvacModeType;
  HvacModeType = HvacModeType;
  activeHvacModeGlobal$: Observable<HvacModeType> = this.hvacModesService.getActiveHvacModeGlobal()


  constructor(private authService: AuthenticationService,
              private hvacModesService: HvacModesService,
              // private router: Router,
              private api: AuthenticationService,
              private cardReaderService: CardReaderService,
              private apiAlarmService: ApiAlarmsService,
              private projectService: ProjectService,
              // private roomOverviewService: RoomsOverviewService,
              public platform: Platform,
              private userSettingsService: UserSettingsService,
              private filterService: FiltersService,
              private apiHvacModesService: ApiHvacModesService,
              private currentUserStoreService: CurrentUserStoreService,
              private router: Router,
              @Inject(LOCALE_ID) private localeId: string,
              @Optional() private alarmAlertsService?: AlarmAlertsService,

             ) { }

  ngOnInit() {
    this.currentLang = this.userSettingsService.getLanguage();
    this.user = Object.assign(new User(), this.currentUserStoreService.getUser());
   /*  if (Object.keys(this.user).length>0) {
      this.projectService.initializeSelectedObjectFromStorage(this.user);
    } */
    if (this.user === undefined) {
      if (window.location.href.includes('home')) {
        this.api.getUserByToken().subscribe();
      }
    }
    this.userSubscription = this.currentUserStoreService.userChanged.subscribe(() => {
      this.user = Object.assign(new User(), this.currentUserStoreService.getUser());
      if (this.userHasPermissionToReceiveAnyAlarmType(this.user) && !this.alarmsInit) {
          this.getAlarms();
          this.alarmsInit = true;
      }
      /* if (Object.keys(this.user).length>0) {
        this.projectService.initializeSelectedObjectFromStorage(this.user);
      } */
     });
    if (this.userHasPermissionToReceiveAnyAlarmType(this.user) && !this.alarmsInit) {
      this.getAlarms();
      this.alarmsInit = true;
    }
    this.apiAlarmService.getUnconfirmedAlarms(1,100,{
      Filtering: '',
      Sorting: '',
      DateTimeFrom: '',
      DateTimeTo: '',
      Levels: [],
      Types: [],
      Confirmed: [0],
      ConfirmUsers: []
    }).subscribe();
    this.getCardReaderConnectedStatus();
    this.objFilterSub = this.objectsFilter$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
     ).subscribe((objsFilter: number[])=> {
      if (objsFilter.length === 0 ) {
        this.apiHvacModesService.getActiveHvacMode('0').subscribe()
      } else {
        this.apiHvacModesService.getActiveHvacMode(objsFilter[0].toString()).subscribe()
      }
    })
  }

  userHasPermissionToReceiveAnyAlarmType(user: User) {
    return user.havePermission(SoftwarePermissionId.AlarmsBurglaryReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsHvacReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsOpenTooLongReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSafetyReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSosReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSystemReceive);
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const el = event.target as HTMLElement;
    if (!el.classList.contains('header-title') && !el.classList.contains('header-arrow')
      && !el.classList.contains('header-dropdown') && !el.classList.contains('header-user')) {
      this.dropdownOpened = false;
      this.langDropdownOpened = false;
      this.objectDropdownOpened = false;
    }
  }


  getAlarms() {
    this.alarms = this.alarmAlertsService.getAlarms();
    this.alarmsSubscription = this.alarmAlertsService.alarmsChanged.subscribe(alarms => this.alarms = this.alarmAlertsService.getAlarms());

  }

  get unconfirmedAlerts() {
    return this.alarms.reduce((r, a)=> {
      return r + +(!a.confirmed);
    }, 0);
  }

  /* getUnconfirmedAlarmText(alarm?: any ) {
    let target;
    if (!alarm) {
      target = (this.alarms.find( al => !al.confirmed) as any);
    } else {
      target = alarm;
    }
    if (target.message) {
      return target.message
    }
    else if (target.description) {
      return target.description;
    } else {
      return ''
    }
  } */

  getCardReaderConnectedStatus() {
    this.cardReaderConnected = this.cardReaderService.getCardReaderConnected();
    this.cardReaderConnectedSubscription = this.cardReaderService.cardReaderConnectedChanged.subscribe(() =>
        this.cardReaderConnected = this.cardReaderService.getCardReaderConnected());
  }

  setLang(lang: string) {
    if (lang === 'en') {
      this.userSettingsService.setLanguage('en');
    } else if (lang === 'hr') {
      this.userSettingsService.setLanguage('hr');
    }
    location.reload()
  }

  logout() {
    this.authService.logout();
  }


  selectObject(object: ProjectObject) {
    let refreshRoomsPage = false;
    let refreshCommonaAreaPage = false;
    if (this.router.url === '/rooms') {
      refreshRoomsPage = true;
    }
    if (this.router.url === '/common-area') {
      refreshCommonaAreaPage = true;
    }
    if (object) { // select object
      this.filterService.setObjectsFilter([object.projectObjectId], refreshRoomsPage);
      this.filterService.setCommonAreaObjectsFilter([object.projectObjectId], refreshCommonaAreaPage);
    } else { // select all objects  []
      this.filterService.setObjectsFilter([], refreshRoomsPage)
      this.filterService.setCommonAreaObjectsFilter([], refreshCommonaAreaPage);
    }
    // this.filterService.selectAllFloors(refreshRoomsPage);
    // this.filterService.selectAllCommonAreaFloors(refreshCommonaAreaPage);
  }

  /* getAlarmsLevel() {
    const lvl = this.getAlarmsBiggestLvl();
    let value: string;
    switch(lvl) {
      case 1: {
         value = 'info';
         break;
      }
      case 2: {
         value = 'warning';
         break;
      }
      case 3: {
         value = 'danger';
         break;
      }
      default: {
         value = '';
         break;
      }
   }
   return value;
  } */

  /* getAlarmsBiggestLvl() {
    let max = 0;
    this.alarms.forEach(alarm => {
      if (max < alarm.level && +alarm.confirmed === 0) {
        max = alarm.level;
      }
    })
    return max;
  } */

  ngOnDestroy() {
    if (this.cardReaderConnectedSubscription) {
      this.cardReaderConnectedSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.alarmsSubscription) {
      this.alarmsSubscription.unsubscribe();
    }
    if (this.objFilterSub) {
      this.objFilterSub.unsubscribe();
    }
  }
}
