import { Pipe, PipeTransform } from '@angular/core';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'userHavePermissionForAnyAlarms'
})
export class UserHavePermissionForAnyAlarmsPipe implements PipeTransform {

swPermissions = SoftwarePermissionId;
  transform(user: User): boolean {
    return user.havePermission(SoftwarePermissionId.AlarmsBurglaryReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsHvacReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsOpenTooLongReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSafetyReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSosReceive) ||
    user.havePermission(SoftwarePermissionId.AlarmsSystemReceive);
  }

}
