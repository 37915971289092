import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';
import localeHrExtra from '@angular/common/locales/extra/hr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ModalSelectComponent } from './components/modal-select/modal-select.component';
import { UsersFilterPipe } from './pipes/users-filter.pipe';
import { UsersWithEmailPipe } from './pipes/users-with-email.pipe';
import { TextSizeFilterPipe } from './pipes/text-size-filter.pipe';
import { TextSizeFilterWhitespacePipe } from './pipes/text-size-filter-whitespace.pipe';
import { AfterValueChangedDirective } from './directives/after-value-changed.directive';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { IonicModule } from '@ionic/angular';
import { GetControllersForRoomsOnFloorPipe } from './pipes/get-controllers-for-rooms-on-floor.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GetControllersForRoomPipe } from './pipes/get-controllers-for-room.pipe';
import { AlarmSubscriptionFilterPipe } from './pipes/alarm-subscription-filter.pipe';
import { GenComTranslatePipe } from './pipes/gen-com-translate.pipe';
import { EditLocationModalComponent } from './components/edit-location-modal/edit-location-modal.component';
import { HasEveryAccessGuestRoomPermissionPipe } from './pipes/user-has-every-access-guest-room-permission.pipe';
import { HasEveryAccessGuestRoomPermissionInRolePipe } from './pipes/has-every-access-guest-room-permission-in-role.pipe';
import { GetObjectsPipe } from './pipes/get-objects.pipe';
import { GetSubobjectsPipe } from './pipes/get-subobjects.pipe';
import { GetUserForCardPipe } from './pipes/get-user-for-card.pipe';
import { GetCardTypeCodePipe } from './pipes/get-card-type-code.pipe';
import { GetCardTypeNameByCodePipe } from './pipes/get-card-type-name-by-code.pipe';
import { AccessLogsComponent } from './../modules/logs/pages/access-logs/access-logs.component';
import { DateTimeFromToComponent } from '../modules/logs/components/date-time-from-to/date-time-from-to.component';
import { AuditComponent } from '../modules/logs/pages/audit/audit.component';
import { AlarmLogsComponent } from '../modules/logs/pages/alarm-logs/alarm-logs.component';
import { ControllerIsInListPipe } from './pipes/controller-is-in-list.pipe';
import { FindPropertyByCodePipe } from './pipes/find-property-by-code.pipe';
import { PropertiesAreActivePipe } from './pipes/properties-are-active.pipe';
import { StatusIsActivePipe } from './pipes/status-is-active.pipe';
import { RoomLogPaginatorComponent } from './components/room-log-paginator/room-log-paginator.component';
import { LocationNameFilterPipe } from './pipes/location-name-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AccessControlCardComponent } from '../modules/corridors/components/access-control-card/access-control-card.component';
import { RoomModalComponent } from './components/room-modal/room-modal.component';
import { RoomModalCardsComponent } from './components/room-modal-cards/room-modal-cards.component';
import { RoomModalLogComponent } from './components/room-modal-log/room-modal-log.component';
import { RoomModalServiceComponent } from './components/room-modal-service/room-modal-service.component';
import { RoomModalSettingsComponent } from './components/room-modal-settings/room-modal-settings.component';
import { RoomModalViewComponent } from './components/room-modal-view/room-modal-view.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { BooleanInputComponent } from './components/boolean-input/boolean-input.component';
import { EditControllerComponent } from './components/edit-controller/edit-controller.component';
import { FindPropertyPipe } from './pipes/find-property.pipe';
import { GetActivePropsPipe } from './pipes/get-active-props.pipe';
import { GetFirstControllerWithPropertyPipe } from './pipes/get-first-controller-with-property.pipe';
import { GetFloorsForEditingGroupsPipe } from './pipes/get-floors-for-editing-groups.pipe';
import { GetRoomStatusColorPipe } from './pipes/get-room-status-color.pipe';
import { GetRoomsOnFloorPipe } from './pipes/get-rooms-on-floor.pipe';
import { PropIsActivePipe } from './pipes/prop-is-active.pipe';
import { TransformFloorsPipe } from './pipes/transform-floors.pipe';
import { FilterGroupsForSubobjectPipe } from './pipes/filter-groups-for-subobject.pipe';
import { GetRoomNumbersPipe } from './pipes/get-room-numbers.pipe';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { SelectTimeComponent } from './components/select-time/select-time.component';
import { NotFoundComponent } from './equipment/not-found/not-found.component';
import { FilterByControllerType } from './pipes/filter-by-controller-type.pipe';
import { IOCommonAreaCardComponent } from '../modules/corridors/components/io-common-area-card/io-common-area-card.component';
import { AccessControlModalCardsComponent } from '../modules/corridors/components/access-control-area-modals/access-control-modal-cards/access-control-modal-cards.component';
import { AccessControlModalLogComponent } from '../modules/corridors/components/access-control-area-modals/access-control-modal-log/access-control-modal-log.component';
import { AccessControlModalServiceComponent } from '../modules/corridors/components/access-control-area-modals/access-control-modal-service/access-control-modal-service.component';
import { AccessControlModalSettingsComponent } from '../modules/corridors/components/access-control-area-modals/access-control-modal-settings/access-control-modal-settings.component';
import { IOCommonAreaModalLogComponent } from '../modules/corridors/components/io-common-area-modals/io-common-area-modal-log/io-common-area-modal-log.component';
import { IOCommonAreaModalSettingsComponent } from '../modules/corridors/components/io-common-area-modals/io-common-area-modal-settings/io-common-area-modal-settings.component';
import { SelectTimeCommonRoomComponent } from './components/select-time-common-room/select-time-common-room.component';
import { HvacCommonAreaCardComponent } from '../modules/corridors/components/hvac-common-area-card/hvac-common-area-card.component';
import { HvacCommonAreaModalViewComponent } from '../modules/corridors/components/hvac-common-area-modals/hvac-common-area-modal-view/hvac-common-area-modal-view.component';
import { HvacCommonAreaModalLogComponent } from '../modules/corridors/components/hvac-common-area-modals/hvac-common-area-modal-log/hvac-common-area-modal-log.component';
import { HvacCommonAreaModalSettingsComponent } from '../modules/corridors/components/hvac-common-area-modals/hvac-common-area-modal-settings/hvac-common-area-modal-settings.component';
import { OutputModeCopyPasteComponent } from './components/output-mode-copy-paste/output-mode-copy-paste.component';
import { UserHasPermissionPipe } from '../modules/users/pipes/user-has-permission.pipe';
import { RoomModalFacilityComponent } from '../modules/rooms/components/room-modal-facility/room-modal-facility.component';
import { DisplayPropertyComponent } from './components/display-property/display-property.component';
import { D3RoomComponent } from '../modules/rooms/components/d3-room/d3-room.component';
import { StickyHeaderDirectiveCommonArea } from './directives/track-scroll-common-area.directive';
import { StickyHeaderDirectiveRooms } from './directives/track-scroll-rooms.directive';
import { FilterByGroupTypePipe } from './pipes/filter-by-group-type.pipe';
import { GroupSettingsComponent } from '../modules/rooms/components/group-settings/group-settings.component';
import { HvacModeModalComponent } from '../modules/rooms/components/hvac-mode-modal/hvac-mode-modal.component';
import { ShowSubobjectPipe } from './pipes/show-subobject.pipe';
import { FilterHvacModesPipe } from './pipes/filter-hvac-modes.pipe';
import { FilterProjectObjectsPipe } from './pipes/filter-project-objects.pipe';
import { FilterProjectSubobjectsPipe } from './pipes/filter-project-subobjects.pipe';
import { GetRolePipe } from './pipes/get-role.pipe';
import { GetObjectsByIdsPipe } from './pipes/get-objects-by-ids.pipe';
import { SearchControllersPipe } from './pipes/search-controllers.pipe';
import { FilterControllerByObjectAndSubobjectPipe } from './pipes/filter-by-object-and-subobject';
import { SelectDaysModalComponent } from '../modules/automation/components/select-days-modal/select-days-modal.component';
import { TimeConditionComponent } from '../modules/automation/components/time-condition/time-condition.component';
import { GetFloorsForControllerSelectionPipe } from './pipes/get-floors-for-controller-selection.pipe';
import { GetAccessControllsOnFloorPipe } from './pipes/get-access-controlls-on-floor.pipe';
import { GetFloorsForRoomSelectionPipe } from './pipes/get-floors-for-room-selection.pipe';
import { GetRoomsOnFloorForSelectionPipe } from './pipes/get-rooms-on-floor-for-selection.pipe';
import { GetPresetNamePipe } from './pipes/get-preset-name.pipe';
import { PropExistInControllerPipe } from './pipes/prop-exist-in-controller.pipe';
import { GetFloorsInSubobjectPipe } from './pipes/get-floors-in-subobject.pipe';
import { PropExistInControllersPipe } from './pipes/prop-exist-in-controllers.pipe';
import { CanIntervalBeSetPipe } from '../modules/corridors/pipes/can-interval-be-set.pipe';
import { OutputModeForLightIsControlPipe } from '../modules/corridors/pipes/output-mode-for-light-is-control.pipe';
import { UserCanEditRolePipe } from '../modules/users/pipes/user-can-edit-role.pipe';
import { RoomsCanBeJoinedPipe } from '../modules/rooms/pipes/rooms-can-be-joined.pipe';
import { IsAccessControlPipe } from '../modules/rooms/pipes/is-access-control.pipe';
import { ControllerIsSecondInJoinRoomPipe } from '../modules/rooms/pipes/controller-is-second-in-join-room.pipe';
import { UserCardPermissionPipe } from '../modules/users/pipes/user-card-permission.pipe';
import { IsPermissionAccessControl } from './pipes/is-permission-access-control.pipe';
import { UserHaveAccessOrAssignPermissionPipe } from '../modules/users/pipes/user-have-access-or-assign-permission.pipe';
import { UserHavePermissionForAnyAlarmsPipe } from '../modules/users/pipes/user-have-permission-for-any-alarms.pipe';
import { IsHvacCommonAreaPipe } from '../modules/rooms/pipes/is-hvac-common-area.pipe';
import { IsGuestRoomPipe } from '../modules/rooms/pipes/is-guest-room.pipe';
import { IsIoCommonAreaPipe } from '../modules/rooms/pipes/is-io-common-area.pipe';
import { DateTimeInlineComponent } from './components/date-time-inline/date-time-inline.component';
import { ModalViewBooleanPropComponent } from './components/modal-view-boolean-prop/modal-view-boolean-prop.component';
import { ModalViewNumericPropComponent } from './components/modal-view-numeric-prop/modal-view-numeric-prop.component';
import { GetLatestHighestPriorityAlarmPipe } from './pipes/get-latest-highest-priority-alarm.pipe';
import { ButtonsSelectComponent } from './components/buttons-select/buttons-select.component';
import { GetJoinedControllersNamePipe } from './pipes/get-joined-controllers-name.pipe';
import { RoomSettingsTempsComponent } from './components/room-settings-temps/room-settings-temps.component';
import { GetRoomTemperatureDataPipe } from './pipes/get-room-temperature-data.pipe';
import { RoomTempInfoComponent } from './components/room-temp-info/room-temp-info.component';
import { GetAlarmsLevelPipe } from './pipes/get-alarms-level.pipe';
import { GetUnconfirmedAlarmTextPipe } from './pipes/get-unconfirmed-alarm-text.pipe';
import { GetValidToCssClassPipe } from './pipes/get-valid-to-css-class.pipe';

registerLocaleData(localeHr, 'hr-HR', localeHrExtra);

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MessageBoxComponent,
    ModalConfirmationComponent,
    FilterComponent,
    PaginatorComponent,
    UsersFilterPipe,
    ModalSelectComponent,
    TextSizeFilterPipe,
    AfterValueChangedDirective,
    TextSizeFilterWhitespacePipe,
    UsersWithEmailPipe,
    GetControllersForRoomPipe,
    GetControllersForRoomsOnFloorPipe,
    AlarmSubscriptionFilterPipe,
    GenComTranslatePipe,
    EditLocationModalComponent,
    GetRoomNumbersPipe,
    HasEveryAccessGuestRoomPermissionPipe,
    HasEveryAccessGuestRoomPermissionInRolePipe,
    GetObjectsPipe,
    GetSubobjectsPipe,
    FilterGroupsForSubobjectPipe,
    GetUserForCardPipe,
    GetCardTypeCodePipe,
    GetCardTypeNameByCodePipe,
    AccessLogsComponent,
    DateTimeFromToComponent,
    AuditComponent,
    AlarmLogsComponent,
    ControllerIsInListPipe,
    FindPropertyByCodePipe,
    PropertiesAreActivePipe,
    StatusIsActivePipe,
    RoomLogPaginatorComponent,
    LocationNameFilterPipe,
    TransformFloorsPipe,
    NotFoundComponent,
    FindPropertyPipe,
    GetActivePropsPipe,
    AccessControlCardComponent,
    RoomModalComponent,
    RoomModalFacilityComponent,
    RoomModalServiceComponent,
    RoomModalLogComponent,
    RoomModalCardsComponent,
    RoomModalSettingsComponent,
    RoomModalViewComponent,
    PropIsActivePipe,
    NumberInputComponent,
    BooleanInputComponent,
    EditControllerComponent,
    GetRoomsOnFloorPipe,
    GetRoomStatusColorPipe,
    GetFloorsForEditingGroupsPipe,
    GetFirstControllerWithPropertyPipe,
    SelectTimeComponent,
    SelectTimeCommonRoomComponent,
    FilterByControllerType,
    IOCommonAreaCardComponent,
    HvacCommonAreaCardComponent,
    IOCommonAreaModalSettingsComponent,
    IOCommonAreaModalLogComponent,
    AccessControlModalCardsComponent,
    AccessControlModalLogComponent,
    AccessControlModalServiceComponent,
    AccessControlModalSettingsComponent,
    FilterControllerByObjectAndSubobjectPipe,
    HvacCommonAreaModalViewComponent,
    HvacCommonAreaModalLogComponent,
    HvacCommonAreaModalSettingsComponent,
    OutputModeCopyPasteComponent,
    UserHasPermissionPipe,
    DisplayPropertyComponent,
    D3RoomComponent,
    StickyHeaderDirectiveRooms,
    StickyHeaderDirectiveCommonArea,
    FilterByGroupTypePipe,
    GroupSettingsComponent,
    HvacModeModalComponent,
    ShowSubobjectPipe,
    FilterHvacModesPipe,
    FilterProjectObjectsPipe,
    FilterProjectSubobjectsPipe,
    GetRolePipe,
    GetObjectsByIdsPipe,
    SearchControllersPipe,
    SelectDaysModalComponent,
    TimeConditionComponent,
    GetFloorsForControllerSelectionPipe,
    GetAccessControllsOnFloorPipe,
    GetFloorsForRoomSelectionPipe,
    GetRoomsOnFloorForSelectionPipe,
    GetPresetNamePipe,
    PropExistInControllerPipe,
    PropExistInControllersPipe,
    GetFloorsInSubobjectPipe,
    CanIntervalBeSetPipe,
    OutputModeForLightIsControlPipe,
    UserCanEditRolePipe,
    RoomsCanBeJoinedPipe,
    IsAccessControlPipe,
    ControllerIsSecondInJoinRoomPipe,
    UserCardPermissionPipe,
    IsPermissionAccessControl,
    UserHaveAccessOrAssignPermissionPipe,
    UserHavePermissionForAnyAlarmsPipe,
    IsIoCommonAreaPipe,
    IsHvacCommonAreaPipe,
    IsGuestRoomPipe,
    DateTimeInlineComponent,
    ModalViewBooleanPropComponent,
    ModalViewNumericPropComponent,
    GetLatestHighestPriorityAlarmPipe,
    ButtonsSelectComponent,
    GetJoinedControllersNamePipe,
    RoomSettingsTempsComponent,
    GetRoomTemperatureDataPipe,
    RoomTempInfoComponent,
    GetAlarmsLevelPipe,
    GetUnconfirmedAlarmTextPipe,
    GetValidToCssClassPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    IonicModule,
    NgxChartsModule,
    DragToSelectModule,
    TranslateModule.forChild({extend: true})
  ],
  exports: [
    TranslateModule,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MessageBoxComponent,
    ModalConfirmationComponent,
    ModalSelectComponent,
    FilterComponent,
    PaginatorComponent,
    UsersFilterPipe,
    TextSizeFilterPipe,
    AfterValueChangedDirective,
    TextSizeFilterWhitespacePipe,
    UsersWithEmailPipe,
    IonicModule,
    GetControllersForRoomsOnFloorPipe,
    NgxChartsModule,
    GetControllersForRoomPipe,
    AlarmSubscriptionFilterPipe,
    GenComTranslatePipe,
    EditLocationModalComponent,
    GetRoomNumbersPipe,
    HasEveryAccessGuestRoomPermissionPipe,
    HasEveryAccessGuestRoomPermissionInRolePipe,
    GetObjectsPipe,
    GetSubobjectsPipe,
    FilterGroupsForSubobjectPipe,
    GetUserForCardPipe,
    GetCardTypeCodePipe,
    GetCardTypeNameByCodePipe,
    AccessLogsComponent,
    DateTimeFromToComponent,
    AuditComponent,
    AlarmLogsComponent,
    ControllerIsInListPipe,
    FindPropertyByCodePipe,
    PropertiesAreActivePipe,
    StatusIsActivePipe,
    RoomLogPaginatorComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TransformFloorsPipe,
    NotFoundComponent,
    FindPropertyPipe,
    GetActivePropsPipe,
    AccessControlCardComponent,
    RoomModalComponent,
    RoomModalFacilityComponent,
    RoomModalServiceComponent,
    RoomModalLogComponent,
    RoomModalCardsComponent,
    RoomModalSettingsComponent,
    RoomModalViewComponent,
    PropIsActivePipe,
    NumberInputComponent,
    BooleanInputComponent,
    EditControllerComponent,
    GetRoomsOnFloorPipe,
    GetRoomStatusColorPipe,
    GetFloorsForEditingGroupsPipe,
    GetFirstControllerWithPropertyPipe,
    SelectTimeComponent,
    SelectTimeCommonRoomComponent,
    FilterByControllerType,
    IOCommonAreaCardComponent,
    HvacCommonAreaCardComponent,
    IOCommonAreaModalSettingsComponent,
    IOCommonAreaModalLogComponent,
    AccessControlModalCardsComponent,
    AccessControlModalLogComponent,
    AccessControlModalServiceComponent,
    AccessControlModalSettingsComponent,
    FilterControllerByObjectAndSubobjectPipe,
    HvacCommonAreaModalViewComponent,
    HvacCommonAreaModalLogComponent,
    HvacCommonAreaModalSettingsComponent,
    UserHasPermissionPipe,
    DisplayPropertyComponent,
    D3RoomComponent,
    StickyHeaderDirectiveRooms,
    StickyHeaderDirectiveCommonArea,
    FilterByGroupTypePipe,
    GroupSettingsComponent,
    HvacModeModalComponent,
    ShowSubobjectPipe,
    FilterHvacModesPipe,
    FilterProjectObjectsPipe,
    FilterProjectSubobjectsPipe,
    GetRolePipe,
    GetObjectsByIdsPipe,
    SearchControllersPipe,
    SelectDaysModalComponent,
    TimeConditionComponent,
    GetFloorsForControllerSelectionPipe,
    GetAccessControllsOnFloorPipe,
    GetFloorsForRoomSelectionPipe,
    GetRoomsOnFloorForSelectionPipe,
    GetPresetNamePipe,
    PropExistInControllerPipe,
    PropExistInControllersPipe,
    GetFloorsInSubobjectPipe,
    CanIntervalBeSetPipe,
    OutputModeForLightIsControlPipe,
    UserCanEditRolePipe,
    RoomsCanBeJoinedPipe,
    IsAccessControlPipe,
    ControllerIsSecondInJoinRoomPipe,
    UserCardPermissionPipe,
    IsPermissionAccessControl,
    UserHaveAccessOrAssignPermissionPipe,
    UserHavePermissionForAnyAlarmsPipe,
    IsIoCommonAreaPipe,
    IsHvacCommonAreaPipe,
    IsGuestRoomPipe,
    GetLatestHighestPriorityAlarmPipe,
    GetJoinedControllersNamePipe,
    GetValidToCssClassPipe
  ],
  providers: [
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'hr-HR'},
    StatusIsActivePipe,
    PropertiesAreActivePipe,
    FindPropertyByCodePipe,
    GenComTranslatePipe,
    HasEveryAccessGuestRoomPermissionPipe,
    GetControllersForRoomPipe
  ]
})
export class SharedModule { }
