import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Setting } from 'src/app/core/models/project/setting.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';


@Component({
  selector: 'app-hvac-common-area-modal-settings',
  templateUrl: './hvac-common-area-modal-settings.component.html',
  styleUrls: ['./hvac-common-area-modal-settings.component.scss'],
})
export class HvacCommonAreaModalSettingsComponent implements OnInit {
  @Input() controllers: Controller[];
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;
  defaultSettings: DefaultsPreset[];
  defaultPresetsSubscription: Subscription;
  stopControllerPolling: boolean = false;
  controllerPollingSubscription: Subscription;

  fancoilMode: string;

  Property = Property;

  constructor(
    private settingsService: SettingsService,
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private apiSettingsService: ApiSettingsService
    ) {}

  ngOnInit(): void {
    this.selectController(this.controllers[0]);
    this.getFancoilMode();
    this.apiSettingsService.getDefaultPresets().subscribe();
    this.defaultPresetsSubscription = this.settingsService.getDefaultPresets().subscribe( value => {
      this.defaultSettings = value;
    })
    this.isControllerPolled();
  }

  isControllerPolled() {
    this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
          && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    })
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( () => {
      this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    })
  }

  getFancoilMode() {
    const target = this.selectedControllers[0].controllerProperties.$values.find( prop => {
      if ((Property.isHvacRoomControlFancoilOff(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
          (Property.isHvacRoomControlFancoilLow(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
          (Property.isHvacRoomControlFancoilMedium(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
          (Property.isHvacRoomControlFancoilHigh(prop) && typeof +prop.value === 'number' && prop.value !== '') ||
          (Property.isHvacRoomControlFancoilAuto(prop) && typeof +prop.value === 'number' && prop.value !== '')) {
        return true;
      }
    })

    if (target) {
      this.fancoilMode = target.value;
    } else {
      this.fancoilMode = '0'
    }
  }

  /* toggleHvacMode() {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatingCoolingAny);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    const newValue = Number(property.value) === 0 ? 1 : 0;
    this.apiProjectService
      .changeProperty(designation, propertyId, newValue)
      .subscribe();
  } */

  /* toggleHvacModeHeatingCooling() { // 0 is heating, 1 is cooling
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatCool);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    const newValue = Number(property.value) === 0 ? 1 : 0;
    this.apiProjectService
      .changeProperty(designation, propertyId, newValue)
      .subscribe();
  }


  toggleHvacModeHeatingCoolingAuto() { // 0 is heating, 1 is cooling, 2 is auto
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatCoolAuto);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;

    let newValue: number;
    if (Number(property.value) === 0) {
      newValue = 1;
    } else if (Number(property.value) === 1) {
      newValue = 2;
    } else if (Number(property.value) === 2) {
      newValue = 0;
    }

    this.apiProjectService
      .changeProperty(designation, propertyId, newValue)
      .subscribe();
  } */

  setHvacMode(hvacModeNewValue: 0 | 1 | 2) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatCool);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, hvacModeNewValue)
      .subscribe();
  }

  onFancoilSelect(event: any) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacRoomControlFancoilOff);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, event.target.value)
      .subscribe();
  }

  async resetAllToDefaultSettings() {
    // tomdo which default preset???
    /* const coolingHeatingModeProp = this.selectedControllers[0].controllerProperties.$values.find(Property.isHvacHeatingCooling)
    const heatingPreset = this.defaultSettings.find( setting => setting._id === 'heating');
    const coolingPreset = this.defaultSettings.find( setting => setting._id === 'cooling');
    if (Property.isActive(coolingHeatingModeProp)) {
      // cooling mode active
      if (!coolingPreset) {
        const toast = await this.toastController.create({
          header: 'No preset for active (cooling) mode.',
          message: 'Create a preset to apply settings',
          position: 'middle',
          buttons: [{
              text: 'OK',
              role: 'cancel'
            }
          ]
        });
        await toast.present();
        return;
      }
     } else {
      // heating mode active
      if (!heatingPreset) {
        const toast = await this.toastController.create({
          header: 'No preset for active (heating) mode.',
          message: 'Create a preset to apply settings',
          position: 'middle',
          buttons: [{
              text: 'OK',
              role: 'cancel'
            }
          ]
        });
        await toast.present();
        return;
      }
     }

    const alert = await this.alertController.create({
      header: 'Confirm reset to default settings',
      message:`Reset to default settings?`,
      buttons: [ {
        text: 'Cancel',
        role: 'cancel',
      },
        {
          text: 'Confirm',
          handler: async () => {
              if (Property.isActive(coolingHeatingModeProp)) {
                  // cooling mode active
                  this.apiSettingsService.activateDefaultPreset('cooling', this.selectedControllers[0].designation, true).subscribe()
              } else {
                // heating mode active
                  this.apiSettingsService.activateDefaultPreset('heating', this.selectedControllers[0].designation, true).subscribe()
              }
          }
        }
      ],
    });
    await alert.present(); */
  }


  selectController(controller: Controller) {
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
  }

  syncHvac() {
    this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
  }

  isPropertyInController(target: any) {
    let property: Property;
    const active: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(target);
        return property;
      }
    );

    return active
  }

  isSettingInController(target: any) {
    let setting: Setting;
    const active: Controller = this.controllers.find(
      (controller: Controller) => {
        setting = controller.controllerSettings.$values.find(target);
        return setting;
      }
    );

    if(setting && setting.value === '1') {
      return false;
    } else {
      return active
    }
  }

  changeControllerPooling(event: any) {
    const contToSend = structuredClone(this.controllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  onDestroy(){
    if (this.defaultPresetsSubscription) {
      this.defaultPresetsSubscription.unsubscribe();
    }
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }

  }

}
