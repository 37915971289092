import { RelationalOperators } from './relational-operators.enum';
import { RepeatType } from './repeat-type.enum';
import { SpecialTime } from './special-time.enum';
import { DatePipe } from '@angular/common';


export class TimedConditon {
  $type: string;
  id?: number;
  automationConditionType: 1;
  startTime: Date | string;
  specialStartTime: SpecialTime;
  startTimeOffsetMinutes: number;
  repeatType: RepeatType;
  repeatParameter?: string;
  activeFrom: Date | string;
  activeTo: Date | string;
  type: RelationalOperators;


  constructor() {
    this.$type = 'IRooms2.Models.Project.Automations.AutomationRequirements.AutomationTimedCondition, IRooms2';
}

public static getNextStartTime(cond: TimedConditon) {
  const datepipe: DatePipe = new DatePipe('hr-HR')  
  if ( (cond.activeFrom === null || new Date(Date.now()) > new Date(cond.activeFrom)) && (cond.activeTo === null || new Date(cond.activeTo) >  new Date(Date.now()))) {
    if (cond.specialStartTime === 1) {
      if (cond.startTimeOffsetMinutes === 0) {
        return 'sunrise';
      } else if (cond.startTimeOffsetMinutes > 0) {
        return `${Math.abs(cond.startTimeOffsetMinutes)} min after sunrise`;
      } else if (cond.startTimeOffsetMinutes < 0) {
        return `Starts ${Math.abs(cond.startTimeOffsetMinutes)} min before sunrise`;
      }
    } else if (cond.specialStartTime === 2) {
      if (cond.startTimeOffsetMinutes === 0) {
        return 'sunset';
      } else if (cond.startTimeOffsetMinutes > 0) {
        return `${Math.abs(cond.startTimeOffsetMinutes)} min after sunset`;
      } else if (cond.startTimeOffsetMinutes < 0) {
        return `${Math.abs(cond.startTimeOffsetMinutes)} min before sunset`;
      }
    } else {
      switch (cond.repeatType) {
        case 0:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
              return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
            } else {
              return null;
            }
        case 1:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
              //adding 1 minute to time, we also need to check if timeDiff > 0 because we add to time differently
              let timeDiff = new Date(cond.startTime).getSeconds() - new Date(Date.now()).getSeconds();
              if (timeDiff > 0) {
                return `${datepipe.transform(new Date(Date.now()), 'dd.MM.YYYY HH:mm') }`;
              } else {
                return `${datepipe.transform(new Date(Date.now()).getTime() + (60 * 1000), 'dd.MM.YYYY HH:mm') }`;
              }
          }
        case 2:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //adding 1 hour to time,  we also need to check if timeDiff > 0 because we add to time differently
            let timeDiff = new Date(cond.startTime).getMinutes() - new Date(Date.now()).getMinutes();
            if (timeDiff > 0) {
              return `${datepipe.transform(new Date(Date.now()).getTime() + (timeDiff * 60 * 1000), 'dd.MM.YYYY HH:mm') }`;
            } else {
              return `${datepipe.transform(new Date(Date.now()).getTime() + ((timeDiff+ 60) * 60 * 1000), 'dd.MM.YYYY HH:mm') }`;
            }
          }
        case 3:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeting every day with hours and minutes we setup at startTime
            let date = new Date(Date.now())
            date.setHours(new Date(cond.startTime).getHours())
            date.setMinutes(new Date(cond.startTime).getMinutes())
            if (new Date(Date.now()) > date) {
              date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
              return `${datepipe.transform(new Date(date), 'dd.MM.YYYY HH:mm') }`;
            } else {
              return `${datepipe.transform(new Date(date), 'dd.MM.YYYY HH:mm') }`;
            }
          }
        case 4:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeting every week from startTime
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate.setTime(startDate.getTime() + (7 * 24 * 60 * 60 * 1000));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 5:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeting every month from startTime
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 6:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeting every year from startTime
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 7:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeating at minute intervals
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate.setTime(startDate.getTime() + (+cond.repeatParameter * 60 * 1000));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 8:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeating at hours intervals
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate.setTime(startDate.getTime() + (+cond.repeatParameter * 60 * 60 * 1000));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 9:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeating at days intervals
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate.setTime(startDate.getTime() + (+cond.repeatParameter * 24 * 60 * 60 * 1000));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 10:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeating at weeks intervals
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate.setTime(startDate.getTime() + (+cond.repeatParameter * 7 * 24 * 60 * 60 * 1000));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 11:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeating at months intervals
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`;
          }
        case 12:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeating at yearly intervals
            let date = new Date(Date.now())
            let startDate = new Date(cond.startTime)
            while (date > startDate) {
              startDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
            }
            return `${datepipe.transform(startDate, 'dd.MM.YYYY HH:mm') }`; 
          }
        case 13:
          if (new Date(cond.startTime) >  new Date(Date.now())) {
            return `${datepipe.transform(cond.startTime, 'dd.MM.YYYY HH:mm') }`;
          } else {
            //repeating at custom days
            let startDate = new Date(cond.startTime)
            let targetHours = startDate.getHours()
            let targetMinutes = startDate.getMinutes()
            let todayDay = new Date (Date.now()).getDate();
            let targetYear = new Date (Date.now()).getFullYear();
          
            // let repeatArray = cond.repeatParameter as Array<number>
            let repeatArray = cond.repeatParameter.split(',').map( x => Number(x))
            repeatArray.sort(function(a, b) {
              return a - b;
            });
            let targetDay = repeatArray.find( target => {
              if (target > todayDay) { return true;}
            })
            let targetMonth;
            if (targetDay === undefined) {
              targetMonth = new Date (Date.now()).getMonth() + 1;
              targetDay = repeatArray[0];
            } else {
              targetMonth = new Date (Date.now()).getMonth();
            }
          
            let date = new Date(Date.now())
            date.setHours(new Date(cond.startTime).getHours())
            date.setMinutes(new Date(cond.startTime).getMinutes())
            let targetDate = new Date(targetYear, targetMonth, targetDay, targetHours, targetMinutes);
            return `${datepipe.transform(targetDate, 'dd.MM.YYYY HH:mm') }`;  
          }
       }
    }
  } else {
    return null;
  }
}


public static getTimedConditionDescription(cond: TimedConditon) {
  if (cond.specialStartTime === 0) {
    return `Starts at ${new Date(cond.startTime).toDateString()}`;
  } else if (cond.specialStartTime === 1) {
    if (cond.startTimeOffsetMinutes === 0) {
      return 'Starts at sunrise';
    } else if (cond.startTimeOffsetMinutes > 0) {
      return `Starts ${Math.abs(cond.startTimeOffsetMinutes)} min after sunrise`;
    } else if (cond.startTimeOffsetMinutes < 0) {
      return `Starts ${Math.abs(cond.startTimeOffsetMinutes)} min before sunrise`;
    }

  } else if (cond.specialStartTime === 2) {
    if (cond.startTimeOffsetMinutes === 0) {
      return 'Starts at sunset';
    } else if (cond.startTimeOffsetMinutes > 0) {
      return `Starts ${Math.abs(cond.startTimeOffsetMinutes)} min after sunset`;
    } else if (cond.startTimeOffsetMinutes < 0) {
      return `Starts ${Math.abs(cond.startTimeOffsetMinutes)} min before sunset`;
    }

  }
}

public static getRepeatTime(cond: TimedConditon) {
  switch (cond.repeatType) {
    case 0:
      return 'off'
    case 1:
      return "every minute"
    case 2:
      return "every hour"
    case 3:
      return "daily"
    case 4:
      return "weekly"
    case 5:
      return "monthly"
    case 6:
      return "yearly"
    case 7:
      return "custom interval in minutes"
    case 8:
      return "custom interval in hours"
    case 9:
      return "custom interval in days"
    case 10:
      return "custom interval in weeks"
    case 11:
      return "custom interval in months"
    case 12:
      return "custom interval in years"
    case 13:
      return "on custom days"
    default:
      }
}

}


