import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthenticationService } from './../authentication/authentication.service';
import { UserService } from '../services/user.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { User } from 'src/app/core/models/user/user.model';
import { ILoginResponse } from '../models/login-response.interface';
import { SoftwarePermissionId } from '../models/permissions/software-permission-id.enum';
import { lastValueFrom } from 'rxjs';
import { CurrentUserStoreService } from '../services/current-user-store.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(private authService: AuthenticationService,
                private router: Router,
                private userService: UserService,
                private messagingService: MessagingService,
                private currentUserStoreService: CurrentUserStoreService
                ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      // return true;
        if (this.authService.isValid()) {
            let user = this.currentUserStoreService.getUser();
            if (user === undefined) {
                await lastValueFrom(this.authService.getUserByToken()).then(u => {user = u }).catch(err => {
                });
            }
            user = Object.assign(new User(), user);
            if (Object.keys(user).length === 0 || !user.havePermission(SoftwarePermissionId.Login)) {
                this.authService.logout();
                this.messagingService.setMessage('Access denied!');
                this.routeToLogin(state);
                return false;
            }
            return true;
        } else {
            let response = <ILoginResponse>{};
            if (this.authService.getAccessToken() && this.authService.getRefreshToken()) {
                this.authService.setRefreshTokenInProgress(true);
                await lastValueFrom(this.authService.refreshAccessToken()).then(r => response = r).catch((err) => {
                  this.routeToLogin(state)
                });
                this.authService.setRefreshTokenInProgress(false);
            } else {
                this.routeToLogin(state);
                return false;
            }
            if (!Object.keys(response).length) {
                this.routeToLogin(state);
                return false;
            }
            this.userService.setUser(response.user);
            this.authService.setAccessToken(response.tokens.accessToken);
            this.authService.setRefreshToken(response.tokens.refreshToken);
            if (!this.authService.isValid()) {
                this.routeToLogin(state);
                return false;
            }
            return true;
        }

    }

    routeToLogin(state) {
        this.router.navigate(['/login'], {
            queryParams: {
                return: state.url
            }
        });
    }
}
