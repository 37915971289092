import { Component,  HostListener, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { PaginationData } from 'src/app/core/models/pagination-data.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { ApiAccessLogsService } from '../../services/http/api-access-logs.service';
import { LogStatus } from 'src/app/core/models/logs/log-status.enum';
import { LogStatusNameEn } from 'src/app/core/models/logs/log-status-name-en.map';
import { User } from 'src/app/core/models/user/user.model';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { LogStatusNameHr } from 'src/app/core/models/logs/log-status-name-hr.map';
import { HttpResponse } from '@angular/common/http';
import { Controller } from 'src/app/core/models/controller.model';
import moment from 'moment';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';


@Component({
  selector: 'app-access-logs',
  templateUrl: './access-logs.component.html',
  styleUrls: ['./access-logs.component.scss']
})
export class AccessLogsComponent implements OnInit {

  @Input() controllers: Controller[];
  @Input() singleRoom: boolean;
  mainController: Controller;
  accessLogData: AccessLog[] = [];
  paginationData: PaginationData = new PaginationData();

  // project$ = this.projectService.getProject();
  allControllersThin$ = this.apiProjectService.getAllAccessControlsThin()
  logStatus = LogStatus;
  logStatusName = LogStatusNameEn;
  prefix: string;

  queryParams = {
    pageNumber: 1,
    pageSize: 50,
    includeArchive: false
  };
  requestBody: SsFilterSort = new SsFilterSort();
  showOnlyCardsWithoutRecords = false;
  showAdditionalOptions = false;
  loading = false;

  additionalFilter = false;

  users: User[] = [];
  userFilteringSearchValue = '';
  usersInFilter: User[] = [];

  locations: Controller[] = [];
  locationFilteringSearchValue = '';
  locationsInFilter: Controller[] = [];
  localeId: string;

  constructor(
    private apiAccessLogService: ApiAccessLogsService,
    private usersService: UsersService,
    private apiUsers: ApiUsersService,
    // private projectService: ProjectService,
    private apiProjectService: ApiProjectService,
    private userSettingsService: UserSettingsService) { }

  ngOnInit(): void {
    this.localeId = this.userSettingsService.getLanguage();
    if (this.localeId === 'hr') {
      this.logStatusName = LogStatusNameHr;
      this.prefix = 'Ulaz - '
    } else {
      this.prefix = 'Entry - '
    }
    this.getUsers();
    if (this.singleRoom) {
      this.mainController = Controller.getMainController(this.controllers)
    }

    if (this.controllers && this.controllers.length > 0) {
      const designationFilter: string[] = this.controllers.map((cont: Controller)=> {
        return cont.designation
      })
      this.requestBody.locationDesignations = designationFilter;
    }

    this.onSubmit(1, 'DSC|TIME');
  }

  getUsers() {
    this.loading = true;
    this.users = this.usersService.getUsers();
    this.apiUsers.getUsers().subscribe((users: User[]) =>  {
      this.users = users;
      this.loading = false;
    });
  }

  controllerChanged(controller: Controller) {
    this.mainController = controller;
    this.onSubmit(1, 'DSC|TIME');
  }

  // getMainController() {
  //   this.controllers.find( (controller: Controller) => {
  //     const lastElement = controller.locationId.substring(controller.locationId.length - 1);
  //     if (lastElement === '0') {
  //       this.mainController = controller;
  //       return;
  //     }
  //   })
  // }

  searchFilterReady(searchFilter: string) {
    this.requestBody.filtering = searchFilter;
    this.onSubmit();
  }

  setDateTimeFromTo(selectedDateTime: any[]) {
    if ((selectedDateTime[0] && selectedDateTime[1]) && selectedDateTime[0] instanceof moment as any) {
      if (selectedDateTime[0]) {
        this.requestBody.dateTimeFrom = selectedDateTime[0]?._d.toUTCString()
      }
      if (selectedDateTime[1]) {
        this.requestBody.dateTimeTo = selectedDateTime[1]?._d.toUTCString();
      }
    } else if(selectedDateTime[0] && selectedDateTime[1]) {
      if (selectedDateTime[0]) {
        this.requestBody.dateTimeFrom = selectedDateTime[0]?.toUTCString();
      }
      if (selectedDateTime[1]) {
        this.requestBody.dateTimeTo = selectedDateTime[1]?.toUTCString();
      }
    }
    this.onSubmit();
  }

  locationFilterSelected(selectedController: Controller) {
    const controllerIsInList: boolean = this.locationsInFilter.some((cont)=> {
      return cont.locationId === selectedController.locationId
    })
    if (!controllerIsInList) {
      this.locationsInFilter.push(selectedController)
      this.requestBody.locationDesignations.push(selectedController.designation);
    }
    this.locationFilteringSearchValue = '';
    this.onSubmit();
  }

  onRemoveControllerLocationFromFilter(controller: Controller) {
    this.locationsInFilter = this.locationsInFilter.filter( loc => loc.locationId !== controller.locationId);
    this.requestBody.locationDesignations = this.requestBody.locationDesignations.filter (locDes => locDes !== controller.designation);
    this.onSubmit();
  }

  usersFilterSelected(user: User) {
    if (!this.usersInFilter.includes(user)) {
      this.usersInFilter.push(user);
      this.requestBody.users.push(user.userId);
    }
    this.userFilteringSearchValue = '';
    this.onSubmit();
  }

  onRemoveUserFromFilter(user: User) {
    this.usersInFilter = this.usersInFilter.filter( element => element.userId !== user.userId);
    this.requestBody.users = this.requestBody.users.filter (element => element !== user.userId);
    this.onSubmit();
   }


   @HostListener('document:click', ['$event'])
   documentClick(event: MouseEvent) {
     const el = event.target as HTMLElement;
     if (!el.classList.contains('user-dropdown') || !el.parentElement.classList.contains('user-dropdown')) {
       this.userFilteringSearchValue = '';
       this.locationFilteringSearchValue = '';
     }
   }

   onSort(sortBy: string) {
    if (this.requestBody.sorting === 'ASC|' + sortBy) {
      this.requestBody.sorting = 'DSC|' + sortBy;
    } else {
      this.requestBody.sorting = 'ASC|' + sortBy;
    }
    this.onSubmit(this.queryParams.pageNumber);
  }


  onSubmit(pageNumber: number = 1, pageSort?: string) {
    this.loading = true;
    if (pageSort) {
       this.requestBody.sorting = pageSort;
      }
    this.queryParams.pageNumber = pageNumber;
    if (!this.showOnlyCardsWithoutRecords) {

      this.apiAccessLogService.getAccessLogs(this.requestBody, this.queryParams).subscribe((resp: HttpResponse<AccessLog[]>) => {
      this.paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      this.accessLogData = resp.body;

      // filtering for single room
      if (this.singleRoom) {
        this.accessLogData = this.accessLogData.filter( accessData => {
          if (accessData.locationName.includes(this.mainController.name)) {
            return true;
          }
        })
      }
      this.loading = false;
    });
    } else {
      this.apiAccessLogService.getAccessLogsForCardWithoutRecord(this.requestBody, this.queryParams).subscribe(resp => {
        this.paginationData = JSON.parse(resp.headers.get('X-Pagination'));
        this.accessLogData = resp.body;

        // filtering for single room
        if (this.singleRoom) {
          this.accessLogData = this.accessLogData.filter( data => {
            if (data.locationName.includes(this.mainController.name)) {
              return true;
            }
          })
        }

        this.loading = false;
      });
    }

  }

  getTextualValueFromStatus(status: number) { 
    if (status == 0) {
      if (this.localeId == 'en') {
        return 'Arrival'
      } else {
        return 'Dolazak'
      }
    } else if (status == 100) {
      if (this.localeId == 'en') {
        return 'Card deponed' 
      } else {
        return 'Kartica deponirana'
      }
    } else if (status == 200) {
      if (this.localeId == 'en') {
        return 'Departure'
      } else {
        return 'Odlazak'
      }
    } else if (status == 10) {
      if (this.localeId == 'en') {
        return  'Arrival - driver startup'
      } else {
        return  'Dolazak - driver pokretanje'
      }
      }
      else if (status == 110) {
        if (this.localeId == 'en') {
          return 'Card deponed - driver startup'
        } else {
          return 'Kartica deponirana - driver pokretanje'
        }
      }
      else if (status == 210) {
        if (this.localeId == 'en') {
          return 'Departure - driver startup'
        } else {
          return 'Odlazak - driver pokretanje'
        }
      }
      else {
      if (this.localeId == 'en') {
        return 'Unknown status'
      } else {
        return 'Nepoznat status'
      }
    }
  }
}
