import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { LocationDragSelectModalComponent } from '../location-drag-select-modal/location-drag-select-modal.component';
import { RoomModalComponent } from '../../../../shared/components/room-modal/room-modal.component';

@Component({
  selector: 'app-enter-room-number-modal',
  templateUrl: './enter-room-number-modal.component.html',
  styleUrls: ['./enter-room-number-modal.component.scss']
})
export class EnterRoomNumberModalComponent implements OnInit, AfterViewInit {


  @ViewChild('search') searchElement: ElementRef;
  showError = false;
  constructor(private modalController: ModalController,
              private projectService: ProjectService) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    setTimeout( () => {
      this.searchElement.nativeElement.focus();
    },800)
  }

  async guestWithoutRoom() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: LocationDragSelectModalComponent,
      cssClass: 'locations-drag-modal',
      backdropDismiss: true,
      showBackdrop: true
    });
    return await modal.present();
  }


  async onClickControllerCard(roomNo: string) {
    // const rooms = this.projectService.getAllRooms();
    const controller = this.projectService.getThinControllerByRoomNumber(roomNo);
    if (controller) {
      this.modalController.dismiss();
      const modal = await this.modalController.create({
        component: RoomModalComponent,
        cssClass: 'room-modal',
        backdropDismiss: true,
        showBackdrop: true,
        componentProps:  {
          object: controller.object,
          subObject: controller.subObject,
          zone: controller.zone,
          startingView: 'cards',
          openNewCard: true
        }
      });
      return await modal.present();
    } else {
      this.showError = true;
    }
  }

}

