import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { GenComTranslatePipe } from '../../pipes/gen-com-translate.pipe';

@Component({
  selector: 'app-room-modal-view',
  templateUrl: './room-modal-view.component.html',
  styleUrls: ['./room-modal-view.component.scss']
})
export class RoomModalViewComponent implements OnInit, OnDestroy {
  @Input() controllers: Controller[];

  cardTypes: CardType[];
  cardTypesSubscription: Subscription;
  Property = Property;
  selectedControllers: Controller[] = []; // select one for filter, select all for filter off

  constructor(
    private apiProjectService: ApiProjectService,
    private cardsService: CardsService,
    private pipe: GenComTranslatePipe
    ) { }

  ngOnInit(): void {
      this.selectAllControllers();
      this.cardTypes = this.cardsService.getCardTypes();
      this.cardTypesSubscription = this.cardsService.cardTypesChanged.subscribe(() => this.cardTypes = this.cardsService.getCardTypes())
  }

  selectAllControllers() {
    this.selectControllers(this.controllers);
  }


  selectControllers(controllers: Controller | Controller[]) {
    if (Array.isArray(controllers)) {
      this.selectedControllers = controllers;
    } else {
      this.selectedControllers = [controllers];
    }
  }

  getAlarmName(prop: Property) {
    if (Property.isAnyBurglaryProperty(prop)) {
      return this.pipe.transform('Burglary detected')
    }
     else if (Property.isSafeCommError(prop)) {
      return this.pipe.transform('Safe comm error')
    }
    else if (Property.isHVACLargeTempDiffRoom(prop)) {
      return this.pipe.transform('HVAC - Large temp diff room')
    }
    else if (Property.isHVACLargeTempDiffBathroom(prop)) {
      return this.pipe.transform('HVAC - Large temp diff bathroom' )
    }
    else if (Property.isFlood(prop)) {
      return this.pipe.transform('Flood')
    }
    else if (Property.isBurglaryDetectedByPIR(prop)) {
      return this.pipe.transform('PIR - Burglary detected')
    }
     else if (Property.isSOS(prop)) {
      return this.pipe.transform('SOS')
    }
    else if (Property.isDoorOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Door open too long')
    }
    else if (Property.isWindowOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Win open too long')
    }
  }

  clearStatus() {
    // to clear status of /safety/sos,   /security/burglary, and  /security/safe/burglary,
    //  send any true value to any of those endpoints
    let sosDesignation: string;
    let sosPropertyId: number;
    let sosValue;
    let burglaryDesignation: string;
    let burglaryPropertyId: number;
    let burglaryValue;
    let safeBurglaryDesignation: string;
    let safeBurglaryPropertyId: number;
    let safeBurglaryValue;

    this.controllers.forEach((controller: Controller) => {
      controller.controllerProperties.$values.forEach((prop)=> {
        if(Property.isSOS(prop)) {
          sosDesignation = controller.designation;
          sosPropertyId = prop.id;
          sosValue = prop.value;
        }
        if(Property.isBurglary(prop)) {
          burglaryDesignation = controller.designation;
          burglaryPropertyId = prop.id;
          burglaryValue = prop.value;
        }
        if(Property.isSafeBurglary(prop)) {
          safeBurglaryDesignation = controller.designation;
          safeBurglaryPropertyId = prop.id;
          safeBurglaryValue = prop.value;
        }
      })
      }
    );

      if(Number(sosValue)) {
        this.apiProjectService.changeProperty(sosDesignation, sosPropertyId, 1).subscribe();
      } else if (Number(burglaryValue)) {
        this.apiProjectService.changeProperty(burglaryDesignation, burglaryPropertyId, 1).subscribe();
      } else if (Number(safeBurglaryValue)) {
        this.apiProjectService.changeProperty(safeBurglaryDesignation, safeBurglaryPropertyId, 1).subscribe();
      }
  }

  ngOnDestroy() {
    if (this.cardTypesSubscription) {
      this.cardTypesSubscription.unsubscribe();
    }
  }

}
