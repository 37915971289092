import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-room-temp-info',
  templateUrl: './room-temp-info.component.html',
  styleUrls: ['./room-temp-info.component.scss']
})
export class RoomTempInfoComponent implements OnInit {
  Property = Property;
  @Input() selectedControllers: Controller[];
  @Input() location: 'room' | 'bathroom';
  updated$ = this.projectService.updatedPipe$;


  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
  }

}
