import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { PermissionType } from 'src/app/core/models/permissions/permission.enum';
import { Permission } from 'src/app/core/models/permissions/permission.model';

@Pipe({
  name: 'hasEveryAccessGuestRoomPermission',
})
export class HasEveryAccessGuestRoomPermissionPipe implements PipeTransform {

  transform(permissionsList: Permission[], allPermissions: Permission[], controllers: Controller[]): boolean {
    if (!permissionsList || !allPermissions || !controllers) {
      return false
    }
    const accessGuestRoomPermissions = allPermissions.filter((perm) => {
      const permLocationId = perm?.name?.split(' ')[2];
      const controller = Controller.getControllerByLocationId( controllers, permLocationId);
      if (controller) {
        const isGuestRoom = Controller.isGuestRoom(controller);
        return perm.typeId === PermissionType.Unlock && isGuestRoom;
      }
    });
    if (accessGuestRoomPermissions.length === 0 ){
      return false;
    }

    return accessGuestRoomPermissions.every((accessGuestRoomPermission) => {
      return permissionsList?.find((userPerm) => {
        return accessGuestRoomPermission.permissionId === userPerm.permissionId;
      });
    });
  }
}

