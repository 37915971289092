import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { RoomViewSize, RoomsOverviewService } from 'src/app/shared/services/rooms-overview.service';

@Component({
  selector: 'app-hvac-common-area-card',
  templateUrl: './hvac-common-area-card.component.html',
  styleUrls: ['./hvac-common-area-card.component.scss']
})
export class HvacCommonAreaCardComponent implements OnInit {
  @Input() controllers: Controller[];
  roomViewSize$: Observable<RoomViewSize> = this.roomsOverviewService.getViewSize();
  isAnyAlarm = Property.isAnyAlarm;

  constructor(private roomsOverviewService: RoomsOverviewService) { }

  ngOnInit(): void {
  }

  getSetTemperature() {
   const target = this.controllers[0].controllerProperties.$values.find (prop => {
      if (Property.isRoomTemperatureCurrent(prop)) {
        return true;
      }
    })
    if (target) {
      return target.value;
    }
  }

  getCurrentTemperature() {
    const target =  this.controllers[0].controllerProperties.$values.find (prop => {
       if (Property.isRoomTemperatureSet(prop)) {
        return true;
       }
     })
     if (target) {
      return target.value;
    }
  }

}
