import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { API_BASE_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiAccessLogsService {
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient ) { }

  getAccessLogs(requestBody: SsFilterSort, queryParams) {
    const requestParams = new HttpParams()
    .set('PageNumber', queryParams.pageNumber.toString())
    .set('PageSize', queryParams.pageSize.toString())
    .set( 'includeArchive', queryParams.includeArchive);

    return this.http.post<AccessLog[]>(`${API_BASE_URL}/AccessLogs/`,  requestBody  ,
        {
      params: requestParams,
      headers: this.headers,
      observe: 'response'
        });
  }



  /* getAccessLogs(requestBody: SsFilterSort, queryParams) {
    return of (this.testData1)
  } */

  /* getAccessLogsForCardWithoutRecord(requestBody: SsFilterSort, queryParams) {
    const requestParams = new HttpParams()
    .set('PageNumber', queryParams.pageNumber.toString())
    .set('PageSize', queryParams.pageSize.toString())
    .set( 'includeArchive', queryParams.includeArchive);

    return this.http.post<AccessLog[]>(`${API_BASE_URL}/AccessLogs/cards_wo_record` ,  requestBody  ,
        {
      params: requestParams,
      headers: this.headers,
      observe: 'response'
        });
  } */

  getAccessLogsForCardWithoutRecord(requestBody: SsFilterSort, queryParams) {
    return of ({headers: undefined, body: undefined}) // todo

  }

}
