import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IMqttMessage } from 'ngx-mqtt';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';

@Injectable({
    providedIn: 'root'
})
export class AlarmAlertsService {
    alarmsInitialized = false;
    mqttBase: string;
    mqttAlarmsSubscription = new Subscription();
    alarms: AlarmAlert[] = [];
    alarmsChanged = new Subject<AlarmAlert[]>();
    alarmAdded = new Subject<AlarmAlert>();
    alarmsSize$ = new BehaviorSubject<boolean>(false);

    constructor() {}

    getAlarms(): AlarmAlert[] {
        return this.alarms.slice();
    }

    getAlarmsSize() {
        return this.alarmsSize$;
    }

    setAlarmsSize(value: boolean) {
        this.alarmsSize$.next(value);
    }

    setAlarms(alarms: AlarmAlert[]) {
        this.alarms = alarms;
        this.alarmsChanged.next(this.alarms);
    }

    addAlarm(alarm: AlarmAlert) {
        this.alarms.push(alarm);
        this.alarmAdded.next(alarm);
        this.alarmsChanged.next(this.alarms);
    }

    updateAlarm(i: number, alarm: AlarmAlert) {
        this.alarms[i] = alarm;
        this.alarmsChanged.next(this.alarms);
    }

    // initAlarmsService() {
    //     if (this.alarmsInitialized) {
    //         return;
    //     }
    //     this.api.getBaseMqttSubscription().subscribe(mqttBase => {
    //          this.mqttBase = mqttBase;
    //          this.subscribeToMqttAlarms();
    //         });
    //     this.alarmsInitialized = true;
    // }

    handleMqttAlarms(message: IMqttMessage) {
            const alertParts = message.payload.toString().split('|');
            let newAlarm;
            if (+alertParts[2].length === 1) {
                newAlarm = {
                    time: new Date(alertParts[0]),
                    message: alertParts[1],
                    level: +alertParts[2],
                    confirmed: false,
                    displayAlert: true
                };
            } else {
                newAlarm = {
                    time: new Date(alertParts[0]),
                    message: alertParts[1],
                    level: +alertParts[2][0],
                    confirmed: false,
                    displayAlert: true}
            }
            this.addAlarm(newAlarm);
    }

    confirmAlarm(id: number) {
        this.alarms = this.alarms.filter( element => element.alarmLogId !== id);
        this.alarmsChanged.next(this.alarms);
    }

    unsubscribeFromMqttAlarms() {
        this.mqttAlarmsSubscription.unsubscribe();
        this.mqttAlarmsSubscription = new Subscription();
        this.alarmsInitialized = false;
    }
}
