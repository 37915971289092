import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';

@Pipe({
  name: 'filterProjectObjects'
})
export class FilterProjectObjectsPipe implements PipeTransform {

  transform(objects: ProjectObject[], objectGlobalFilter: ProjectObject[], selectedDesignation: string): ProjectObject[] {
    if (objectGlobalFilter.length > 0) { // global object filter on
      return objectGlobalFilter;
    }

    if (objectGlobalFilter.length === 0) { // global object filter off
      if (selectedDesignation === '*/*/*/*') { // local object filter off
        return objects;
      } else { // local object filter on
        return objects.filter((obj)=> {
          return obj.designation.split('/')[0] === selectedDesignation.split('/')[0];
        })
      }
    }
  }

}
