import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationGroup } from 'src/app/shared/services/api-location-groups.service';

@Pipe({
  name: 'getControllersForRoomsOnFloor',
})

export class GetControllersForRoomsOnFloorPipe implements PipeTransform {

  transform(controllers: Controller[], fl: LocationGroup ,  updated: Date, /*targetObject: number, targetSubobject: number */)
    : Controller[][] {
    if (controllers.length === 0) {
      return [];
    }

    // if ((fl as LocationGroup)?.locationIds && ((fl as LocationGroup)?.locationIds?.length > 0) ) {
      return this.findControllersForFloor(controllers, fl as LocationGroup/* , targetObject, targetSubobject */);

   /*  } else {
      return this.findControllersForFloorNumber(controllers,controllerType, fl as number, targetObject, targetSubobject);
    } */
  }




  findControllersForFloor (controllers: Controller[],  floor: LocationGroup/* , targetObject, targetSubObject */) {
    const controllersOnFloor: Controller[] = [];
    /* controllers.forEach((driver) => {
      driver[1].forEach((object) => {
        if (Number(object[0]) === Number(targetObject) || (Number(targetObject) < 0) ) {
          object[1].forEach((subObj) => {
            if (Number(subObj[0]) === Number(targetSubObject) || (Number(targetSubObject) < 0)) {
              subObj[1].forEach((zone) => {
                zone[1].forEach((subZone) => {
                  let controllerTypeFilter = true;
                  if (controllerType === 'isGuestRoom'){
                    // controllerTypeFilter =  subZone[1].isGuestRoom;
                    controllerTypeFilter = Controller.isGuestRoom(subZone[1]);
                  }
                  else if (controllerType === 'isAccessControl'){
                    // controllerTypeFilter =  subZone[1].isAccessControl;
                    controllerTypeFilter = Controller.isAccessControl(subZone[1]);
                  } else if (controllerType === 'isIOCommonArea') {
                    controllerTypeFilter = Controller.isIOCommonArea(subZone[1]);
                  } else if (controllerType === 'isHVacCommonArea') {
                    controllerTypeFilter = Controller.isHvacCommonArea(subZone[1]);
                  }
                  if (floor.locationIds.includes(subZone[1].locationId) && controllerTypeFilter) {
                    controllersOnFloor.push(subZone[1]);
                  }
                });
              });
            }
          });
        }
      });
    }); */

    controllers.forEach((cont)=> {
      if (floor.locationIds.includes(cont.designation)) {
        controllersOnFloor.push(cont)
      }
    })

    const roomsNumbersOnFloor: number[] = controllersOnFloor.reduce(
      (roomNumsOnFloor, controller: Controller) => {
        const roomNumber = controller.designation.split('/')[2];
        if (!roomNumsOnFloor.includes(roomNumber) && floor.locationIds.includes(controller.designation)) {
          roomNumsOnFloor.push(roomNumber);
        }
        return roomNumsOnFloor;
      },
      []
    );

    roomsNumbersOnFloor.sort((a, b) => (a - b));

    const controllersForRoomsOnFloor: Controller[][] = [];
    roomsNumbersOnFloor.forEach((roomNo) => {
      const controllersInRoom: Controller[] = controllersOnFloor.filter(
        (controller) => {
          return controller.designation.split('/')[2] === roomNo.toString();
        }
      );
      controllersForRoomsOnFloor.push(controllersInRoom);
    });
    return controllersForRoomsOnFloor;
  }

  /* findControllersForFloorNumber (controllers: Controller[], controllerType: 'isGuestRoom'
  | 'isAccessControl' |'isIOCommonArea' | 'isHVacCommonArea',
   fl: number, targetObject, targetSubObject) {
    const floorNumber: number = fl;
    const controllersOnFloor: Controller[] = [];
    controllers.forEach((driver) => {
      driver[1].forEach((object) => {
        if (Number(object[0]) === Number(targetObject) || (Number(targetObject) < 0) ) {
          object[1].forEach((subObj) => {
            if (Number(subObj[0]) === Number(targetSubObject) || (Number(targetSubObject) < 0)) {
              subObj[1].forEach((zone) => {
                zone[1].forEach((subZone) => {
                  let controllerTypeFilter = true;
                  if (controllerType === 'isGuestRoom'){
                    // controllerTypeFilter =  subZone[1].isGuestRoom;
                    controllerTypeFilter = Controller.isGuestRoom(subZone[1]);
                  }
                  if (controllerType === 'isAccessControl'){
                    // controllerTypeFilter =  subZone[1].isAccessControl;
                    controllerTypeFilter = Controller.isAccessControl(subZone[1]);
                  } else if (controllerType === 'isIOCommonArea') {
                    controllerTypeFilter = Controller.isIOCommonArea(subZone[1]);
                  } else if (controllerType === 'isHVacCommonArea') {
                    controllerTypeFilter = Controller.isHvacCommonArea(subZone[1]);
                  }
                  if ( Controller.getFloorNumber(subZone[1]) === floorNumber.toString() && controllerTypeFilter
                  && (Number(subZone[1].object) === Number(targetObject) || (Number(targetObject) < 0))
                  && (Number(subZone[1].subObject) === Number(targetSubObject) || (Number(targetSubObject) < 0))
                  ) {
                    controllersOnFloor.push(subZone[1]);
                  }
                });
              });
            }
          });
        }
      });
    });

    const roomsNumbersOnFloor: number[] = controllersOnFloor.reduce(
      (roomNumsOnFloor, controller: Controller) => {
        const roomNumber = controller.zone;
        if (
          !roomNumsOnFloor.includes(roomNumber) &&
          Controller.getFloorNumber(controller) === floorNumber.toString()
        ) {
          roomNumsOnFloor.push(roomNumber);
        }
        return roomNumsOnFloor;
      },
      []
    );

    roomsNumbersOnFloor.sort();

    const controllersForRoomsOnFloor: Controller[][] = [];
    roomsNumbersOnFloor.forEach((roomNo) => {
      const controllersInRoom: Controller[] = controllersOnFloor.filter(
        (controller) => {
          return controller.zone === roomNo.toString();
        }
      );
      controllersForRoomsOnFloor.push(controllersInRoom);
    });

    return controllersForRoomsOnFloor;
  } */

}
