import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { DisplayPropertyParams } from 'src/app/shared/components/display-property/display-property.component';
import { FindPropertyByCodePipe } from 'src/app/shared/pipes/find-property-by-code.pipe';
import { PropertiesAreActivePipe } from 'src/app/shared/pipes/properties-are-active.pipe';
import { StatusIsActivePipe } from 'src/app/shared/pipes/status-is-active.pipe';


@Component({
  selector: 'app-room-modal-facility',
  templateUrl: './room-modal-facility.component.html',
  styleUrls: ['./room-modal-facility.component.scss']
})
export class RoomModalFacilityComponent implements OnInit, OnDestroy {


  controllers: Controller[];
  selectedControllers: Controller[] = [];
  selectedPropertyDisplayConfig$ = this.settingsService.getSelectedPropertyDisplayConfig();

  cardTypes: CardType[];
  cardTypesSubscription: Subscription;

  Property = Property;

  constructor(
              private router: Router,
              private findPropertyByCodePipe: FindPropertyByCodePipe,
              private propertiesAreActivePipe: PropertiesAreActivePipe,
              private statusIsActivePipe: StatusIsActivePipe,
              private cardsService: CardsService,
              private settingsService: SettingsService
              ) { }

  ngOnInit(): void {
    // this.controllers = this.roomsOverviewService.getFacilityControllers();
    if (!this.controllers) {
      this.router.navigateByUrl('/rooms');
    } else {
      this.selectController(this.controllers[0]);
    }
    this.cardTypes = this.cardsService.getCardTypes();
    this.cardTypesSubscription = this.cardsService.cardTypesChanged.subscribe(() => this.cardTypes = this.cardsService.getCardTypes())
  }


  selectController(controller: Controller) {
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
  }


  getIndicatorWidth() {
    if (this.controllers) {
      if (this.controllers.length === 1) {
        return '';
      }else if (this.controllers.length === 2) {
        return 'indicator-elements-2';
      } else if (this.controllers.length === 3) {
        return 'indicator-elements-3';
      }
    }
  }

  isBoxShadow(propToDisplay: DisplayPropertyParams) {

    if (propToDisplay.mode === 'singlePropType') {
        const properties = this.findPropertyByCodePipe.transform(
          this.selectedControllers, propToDisplay.propInfo.codeFrom, propToDisplay.propInfo.codeTo);
        const areActive = this.propertiesAreActivePipe.transform(properties, propToDisplay.propInfo.activeCondition)
        if (!areActive) {
          return 'no-box';
        }
    } else if (propToDisplay.mode === 'status') {
      const status = this.statusIsActivePipe.transform(
        this.selectedControllers, propToDisplay.propInfo.statusName, propToDisplay.propInfo.activeCondition);
      if (!status) {
        return 'no-box';
      }
    }
  }

  confirm() {
    this.router.navigate(['/rooms']);
  }


  ngOnDestroy(): void {

  }
}
