import { Component, OnInit } from '@angular/core';

import { User } from '../../../core/models/user/user.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  signedInUser: User;
  swPermissions = SoftwarePermissionId;
  constructor(
    private currentUserStoreService: CurrentUserStoreService,
    ) { }

  ngOnInit() {
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
  }

}
