<div style="margin: 20px">
  <div class="margin-left30 margin-right30">
    <div class="content-header flex-row">
      <h1 *ngIf="!userId">{{'New card' | translate}}</h1>
      <h1 *ngIf="userId">{{'Edit Card' | translate}}</h1>
      <!-- <div *ngIf="groupSelect !== 'none'" class="icon-back_arrow font-size24" (click)="goBack()"></div> -->
      <!-- <div *ngIf="groupSelect === 'access'" class="margin-auto">
        Location
        <select
          class="margin-left20"
          (change)="onChangeObjectSubobjectAccess($event)"
          ngModel="{{selectedObjectAccess}},{{selectedSubObjectAccess}}"
        >
          <option value="{{mainController.object}},{{mainController.subObject}}">
            Object: {{mainController.object}}
            Subobject: {{mainController.subObject}}
          </option>
          <option value="{{mainController.object}},{{-1}}">Object {{mainController.object}}</option>
          <option value="{{-1}},{{-1}}">All objects and subobjects</option>
        </select>

      </div> -->
      <div *ngIf="groupSelect === 'rooms'" class="margin-auto">
        <ng-container *ngIf="objectsFilterFull$ | async as selectedObjectGlobal">

        {{'Location' | translate}}
        <select
          class="margin-left20"
          (change)="onChangeObjectSubobjectRooms($event)"
          [ngModel]="selectedRoomDesignation"
        >
          <ng-container
            *ngFor="let object of (allObjects$ | async) | getObjects: selectedObjectGlobal[0]"
          >
            <option [value]="object.designation">
               {{ object.name }}
            </option>
            <option
              *ngFor="let subObject of object.projectSubobjects.$values"
              [value]="subObject.designation"
            >
             {{ subObject.name }}
            </option>
          </ng-container>
          <option
            [value]="'*/*/*/*'"
          >
            {{ "All" | translate }}
          </option>
        </select>
        <!-- <select
          class="margin-left20"
          (change)="onChangeObjectSubobjectRooms($event)"
          ngModel="{{selectedObjectRooms}},{{selectedObjectRooms}}"
        >
          <option value="{{mainController.object}},{{mainController.subObject}}">
            Object: {{mainController.object}}
            Subobject: {{mainController.subObject}}
          </option>
          <option value="{{mainController.object}},{{-1}}">Object {{mainController.object}}</option>
          <option value="{{-1}},{{-1}}">All objects and subobjects</option>
        </select> -->
      </ng-container>
      </div>
      <div class="to-right">
        <ion-button *ngIf="groupSelect !== 'none'"  size="default" (click)="goBack()">{{'Back' | translate}}</ion-button>
        <ion-button *ngIf="groupSelect !== 'none'" class="margin-left5" size="default" (click)="confirm()">{{'Confirm' | translate}}</ion-button>
      </div>
    </div>
  </div>
  <ion-content
  [scrollEvents]="true"
  (ionScrollEnd)="onScrollEnd()"
  (click)="onScrollEnd()"
  class="main-content"
  >
  <div *ngIf="userReady" [hidden]="groupSelect !== 'none'" class="flex-row">
    <div class="width35 br-r-s">
      <h2 class="margin-left30">{{'Card' | translate}}</h2> 
      <ion-list (keyup.enter)="onSave()" >
        <!-- TEST -->
        <ion-item>
          <div class="content-form-group">
            <label class="width-100px">{{'Arrival' | translate}}:</label>
            <div class="flex-row">
              <input
                valueChangedDelay="2000"
                (afterValueChanged)="timeFromChanged($event.target.value)"
                [ngModel]="selectedDateTime[0]  | date : 'd/M/y, H:mm'"
                type="text"
                placeholder="{{'Date and time' | translate}}"
              />
              <i class="icon-calendar font-size32 margin-left5" (click)="openCalendarForDateAndTime($event)"></i>
            </div>
          </div>
          <div *ngIf="fromDateNotValid" class="red margin-left5">{{'Invalid' | translate}}</div>
        </ion-item>

        <ion-item>
          <div class="content-form-group">
            <label class="width-100px">{{'Departure' | translate}}:</label>
            <div class="flex-row">
              <input
                type="text"
                valueChangedDelay="2000"
                (afterValueChanged)="timeToChanged($event.target.value)"
                [ngModel]="selectedDateTime[1]  | date : 'd/M/y, H:mm'"
                placeholder="{{'Date and time' | translate}}"/>
                <i class="icon-calendar font-size32 margin-left5" (click)="openCalendarForDateAndTime($event)"></i></div>
              </div>
              <div *ngIf="toDateNotValid" class="red margin-left5">{{'Invalid' | translate}}</div>
          </ion-item>

        <ion-item
          ><div class="content-form-group">
            <label class="width-100px">{{'User' | translate}}:</label>
            <input [disabled]="userId" [(ngModel)]="userFullName" type="text" placeholder="Korisnik"/>
          </div>
        </ion-item>
        <ion-item *ngIf="mainController | roomsCanBeJoined"
          ><div class="content-form-group">
            <label class="width-100px">{{'Room join' | translate}}:</label>
            <div *ngFor="let room of getJoinableRooms()" class="flex-row margin-top5">
              <ion-item no-lines>
                <ion-checkbox
                class="margin-right5"
                [checked]="joinRoomSelected(room)"
                (ionChange)="joinRoomCheckboxClicked($event, room.designation)">
              </ion-checkbox>
              {{ room.name }}
              </ion-item>
            </div>
          </div>
        </ion-item>
      </ion-list>
      <div class="margin-top30 margin-left20 margin-bottom30">
        <ion-button [disabled]="loading" class="margin-right5" size="default" (click)="onSave()">{{'Save' | translate}}</ion-button>
        <ion-button size="default" fill="outline" (click)="onCancel()">{{'Cancel' | translate}}</ion-button>
      </div>
    </div>
    <div class="br-r-s width30 margin-left20 margin-right20">
      <ng-container *ngIf="objectsFilterFull$ | async as selectedObjectGlobal">

      <div class="flex-row-center-full">
        <h2>{{'Key options' | translate}}</h2>
        <select
          class="margin-left20"
          (change)="onChangeObjectSubobjectAccess($event)"
          [ngModel]="selectedAccessDesignation"
        >
          <ng-container
            *ngFor="let object of (allObjects$ | async) | getObjects: selectedObjectGlobal[0]"
          >
            <option [value]="object.designation">
               {{ object.name }}
            </option>
            <option
              *ngFor="let subObject of object.projectSubobjects.$values"
              [value]="subObject.designation"
            >
             {{ subObject.name }}
            </option>
          </ng-container>
          <option
            [value]="'*/*/*/*'"
          >
            {{ "All" | translate }}
          </option>
        </select>
        <!-- <select
          class="margin-left20"
          (change)="onChangeObjectSubobjectAccess($event)"
          ngModel="{{selectedObjectAccess}},{{selectedSubObjectAccess}}"
        >
          <option value="{{mainController.object}},{{mainController.subObject}}">
            Object: {{mainController.object}}
            Subobject: {{mainController.subObject}}
          </option>
          <option value="{{mainController.object}},{{-1}}">Object {{mainController.object}}</option>
          <option value="{{-1}},{{-1}}">All objects and subobjects</option>
        </select> -->
       <!--  <ion-button class="margin-left5" (click)="onClickNewAccess()">New Access</ion-button> -->
      </div>
    </ng-container>


      <!-- <ion-list class="wrapper">
        <ion-item *ngFor="let location of accessControl">
          <ion-checkbox
            checked
            tabindex="-1"
            class="margin-right5"
            (click)="onClickAccesControlCheckbox(location)"
          ></ion-checkbox>
          <ion-label>{{ location.name }}</ion-label>
        </ion-item>
      </ion-list> -->

      <div class="rooms-data">
           <ng-container *ngIf="floorList$ | async as floorList">
             <ion-list>
             <ng-container
               *ngFor="
                 let floor of floorList
                   | getFloorsForControllerSelection
                     : accessControlListForLocationId
                     : selectedAccessDesignation
               "
             >
             <ion-list-header class="margin-left20">
              {{ floor.name || floor }}
             </ion-list-header>
             <div class="wrapper">
                   <ng-container
                     *ngFor="
                       let accControl of accessControlListForLocationId
                         | getAccessControllsOnFloor
                         : floor
                         : selectedAccessDesignation
                     "
                   >
                   <ion-item class="margin-left20">
                    <ion-checkbox
                      [checked]="accControl.checked"
                      tabindex="-1"
                      class="margin-right5"
                      (click)="clickAccessControlCheckbox($event, accControl)"
                  ></ion-checkbox>
                     {{ accControl.controller.name }}

                    </ion-item>
                    <!--  <ion-item class="margin-left20">
                      <ion-checkbox
                        [checked]="accControl | controllerIsInList: accessControls"
                        tabindex="-1"
                        class="margin-right5"
                        (click)="clickAccessControlCheckbox($event, accControl)"
                    ></ion-checkbox>
                       {{ accControl.name }}

                      </ion-item> -->
                   </ng-container>
                  </div>
                  </ng-container>
                </ion-list>
           </ng-container>
    </div>
    </div>
    <div *ngIf="!(mainController | isAccessControl)" class="width30">
      <div class="flex-row-center-full">
        <h2>{{'Added rooms' | translate}}</h2>
        <ion-button class="margin-left5" (click)="onClickNewRoom()">{{'New room' | translate}}</ion-button>
      </div>
      <ion-list class="wrapper">
        <ion-item *ngFor="let location of multipleRooms">
          <ion-checkbox
            checked
            tabindex="-1"
            class="margin-right5"
            (click)="onClickMultipleRoomsCheckbox(location)"
          ></ion-checkbox>
          <ion-label>{{ location.name }}</ion-label>
        </ion-item>
      </ion-list>
    </div>
  </div>

<dts-select-container
[hidden]="groupSelect !=='rooms'"
#selectContainerRooms="dts-select-container"
class="drag-area"
[(selectedItems)]="multipleRooms"
>
<div class="rooms-data">
   <ng-container *ngIf="guestControllersThin">
      <ng-container *ngIf="floorList$ | async as floorList">
        <div
          *ngFor="
            let floor of floorList
              | getFloorsForRoomSelection
                : selectedRoomDesignation
          "
          class="floor-box"
        >
          <div class="floor-number-box">
            <span [ngClass]="{ 'vertical-text': floor.name }">{{
              floor.name || floor
            }}</span>
          </div>
          <div>
            <div class="flex-row">
               <div
                *ngFor="
                  let room of guestControllersThin
                    | getRoomsOnFloorForSelection
                    :floor
                "
                [dtsSelectItem]="room"
                class="selectable-guest-room"
              >
                <p>{{ room.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
</div>
</dts-select-container>

</ion-content>
</div>
<app-modal-confirmation
    [type]="'cardholder'"
    [content]=""
    (decision)="onModalDecision($event)"
    *ngIf="openModal">
</app-modal-confirmation>

<app-modal-confirmation
    [type]="'cardExist'"
    [content]=""
    (decision)="onModalDecisionCardExist($event)"
    *ngIf="cardExist">
</app-modal-confirmation>
