import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';

import { API_BASE_URL } from 'src/environments/environment';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { AlarmLog } from 'src/app/core/models/logs/alarm-log.model';

@Injectable({
  providedIn: 'root'
})
export class ApiAlarmLogsService {

  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  getAlarmLogs(requestBody: SsFilterSort, queryParams) {

    const requestParams = new HttpParams()
    .set('PageNumber', queryParams.pageNumber.toString())
    .set('PageSize', queryParams.pageSize.toString());

    return this.http.post <AlarmLog[]>(`${API_BASE_URL}/alarms`, requestBody,
        {
      params: requestParams,
      headers: this.headers,
      observe: 'response'
        });
  }
}
