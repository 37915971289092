import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UsersService } from '../users.service';
import { User } from 'src/app/core/models/user/user.model';
import { API_BASE_URL } from 'src/environments/environment';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { UserType } from 'src/app/core/models/user/user-type.model';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Card } from 'src/app/core/models/card/card.model';
import { CardsService } from '../cards.service';

export interface GuestCard {
  uid: string,
  validFrom: Date,
  validTo: Date,
  userId: number,
  fullName: string,
  controllerDesignation: string,
  controllerName: string,
}

@Injectable({
  providedIn: 'root',
})
export class ApiUsersService {
  paginationSubject$ = new Subject();

  requestHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private usersService: UsersService,
    private cardsService: CardsService
  ) {}

  /* getUsersByPage(requestBody: SsFilterSort, queryParams) {
    const requestParams = new HttpParams()
      .set('PageNumber', queryParams.pageNumber)
      .set('PageSize', queryParams.pageSize);

    return this.http
      .post<User[]>(API_BASE_URL + '/users/all', requestBody, {
        params: requestParams,
        headers: this.requestHeaders,
        observe: 'response',
      })
      .pipe(
        map((resp) => {
          this.usersService.setUsers(resp.body);
          this.paginationSubject$.next(
            JSON.parse(resp.headers.get('X-Pagination'))
          );
          return resp.body;
        })
      );
  } */

  getUsers() {
    return this.http.get<User[]>(API_BASE_URL + '/users').pipe(
      tap((users) => {
        this.usersService.setUsers(users);
      })
    );
  }

  getUsersByType(requestBody: SsFilterSort, queryParams: { pageNumber: number, pageSize: number}, typeId: number) {
    const params = new HttpParams()
    .set('type', typeId.toString())
    .set('roles', true)
    .set('PageNumber', queryParams.pageNumber.toString())
    .set('PageSize', queryParams.pageSize.toString());;
    return this.http.post<User[]>(API_BASE_URL + '/users/byCardType', requestBody,
    { params,
      headers: this.requestHeaders,
      observe: 'response', })
      .pipe(
      map((resp) => {
        if (typeId === -1) {
          this.usersService.setNonGuestUsers(resp.body);
        }
          this.paginationSubject$.next(
            JSON.parse(resp.headers.get('X-Pagination'))
          );
          return resp.body;
      })
    );
  }

  getUser(id: number) {
    const params = new HttpParams().set('id', id.toString());
    return this.http.get<User>(API_BASE_URL + '/users/byId', { params }).pipe(
      tap((user) => {
        this.usersService.updateUser(user);
      })
    );
  }

  addUser(user: User) {
    return this.http.post<User>(API_BASE_URL + '/users', user).pipe(
      tap((userCreated) => {
        this.usersService.addUser(userCreated);
      })
    );
  }

  updateUser(userUpdated: User) {
    return this.http.put<User>(API_BASE_URL + '/users', userUpdated).pipe(
      tap((user) => {
        this.usersService.updateUser(user);
      })
    );
  }

  deleteUser(id: number) {
    const params = new HttpParams().set('id', id.toString());
    return this.http.delete(API_BASE_URL + '/users', { params }).pipe(
      tap(() => {
        this.usersService.deleteUser(id);
      })
    );
  }

  writeToAuditLog(dataToSend: string, loggedinUserId: number) {
    const body = {
      timestamp: new Date(),
      level: 40,
      data: dataToSend,
      userId: loggedinUserId,
    };
    return this.http.post(API_BASE_URL + '/auditlogs/log', body);
  }

  getUserTypes() {
    return this.http.get<UserType[]>(API_BASE_URL + '/users/types').pipe(
      tap((userTypes) => {
        this.usersService.setUserTypes(userTypes);
      })
    );
  }

  addUserCard(card: Card) {
    // we need to add card tech type before creating a card
    card.techTypeId = this.cardsService.lastCardOnHolderTechTypeId;
    return this.http.post<Card>(API_BASE_URL + '/cards', card).pipe(
      tap((cardCreated) => {
        console.log('kreirana kartica')
        console.log(cardCreated)
        this.usersService.addUserCard(cardCreated);
      })
    );
  }

  updateUserCard(card: Card) {
    return this.http.put<Card>(API_BASE_URL + '/cards', card).pipe(
      tap((updatedCard) => {
        this.usersService.updateUserCard(updatedCard);
      })
    );
  }

  updateUserCardLater(card: Card) {
    return this.http.put<Card>(API_BASE_URL + '/cards/later', card).pipe(
      tap((updatedCard) => {
        this.usersService.updateUserCardLater(updatedCard);
      })
    );
  }

  activateUserCard(card: Card) {
    return this.http.put<Card>(API_BASE_URL + '/cards/activate', card).pipe(
      tap((updatedCard) => {
        this.usersService.activateUserCard(updatedCard);
      })
    );
  }

  deactivateUserCard(card: Card) {
    return this.http.put<Card>(API_BASE_URL + '/cards/deactivate', card).pipe(
      tap((updatedCard) => {
        this.usersService.deactivateUserCard(updatedCard);
      })
    );
  }

  daleteUserCard(card: Card) {
    const params = new HttpParams().set('uid', card.uid);
    return this.http.delete(API_BASE_URL + '/cards', { params }).pipe(
      tap(() => {
        this.usersService.deleteUserCard(card);
      })
    );
  }

  getCardTypes() {
    return this.http.get<CardType[]>(API_BASE_URL + '/cards/types').pipe(
      tap((cardTypes) => {
        this.cardsService.setCardTypes(cardTypes);
      })
    );
  }

  /* getCards() {
    return this.http.get<Card[]>(API_BASE_URL + '/cards/').pipe(
      tap((cards) => {
        this.cardsService.setCards(cards);
      })
    );
  } */

  getGuestCards() {
    return this.http.get<GuestCard[]>(API_BASE_URL + '/cards/guest');
  }

  getStaffCards() {
    return this.http.get<Card[]>(API_BASE_URL + '/cards/staff');
  }

 /*  getPassageOnlyCards() {
    return this.http.get<Card[]>(API_BASE_URL + '/cards/passageonly')
  } */

  getProjectId() {
    return this.http
      .get(API_BASE_URL + '/controllers/projectid', { responseType: 'text' })
      .pipe(
        tap((projectId) => {
          this.usersService.setProjectId(+projectId);
        })
      );
  }

  getCardsByLocation(locationDesignation: string) {
    const params = new HttpParams()
      .set('locationDesignation', locationDesignation.toString())
      .set('cardType', '0');
    return this.http.get(API_BASE_URL + '/cards/byLocation', { params });
  }

  getCardsByLocationAndType(locationDesignation: string, cardType: number) {
    const params = new HttpParams()
      .set('locationDesignation', locationDesignation.toString())
      .set('cardType', `${cardType}`);
    return this.http.get(API_BASE_URL + '/cards/byLocation', { params });
  }

  syncCard(uid: string) {
    const params = new HttpParams().set('uid', uid.toString());
    return this.http.get(API_BASE_URL + '/cards/sync', { params });
  }

  syncCardsByType(typeId: number) {
    const params = new HttpParams().set('type', typeId.toString());
    return this.http.get(`${API_BASE_URL}/cards/sync/byType` , { params });
  }

  syncStaffCards() {
    return this.http.get(`${API_BASE_URL}/cards/sync/staff`);
  }

  getDefaultCardForUser(userId: number) {
    const params = new HttpParams().set('id', userId.toString());
    return this.http.get(`${API_BASE_URL}/cards/defaultCardTypeForUser` , { params });
  }

  getBackupCardForLocation(locationId: string) {
    const params = new HttpParams().set('locationDesignation', locationId.toString());
    return this.http.get(`${API_BASE_URL}/cards/backup`,{ params });
  }

  createBackupCardForLocation(card: Card, locationId: string) {
    const params = new HttpParams().set('locationDesignation', locationId.toString());
    return this.http.post(`${API_BASE_URL}/cards/backup`, card ,{ params });
  }

  updateBackupCardForLocation(card: Card, locationId: string) {
    const params = new HttpParams().set('locationDesignation', locationId.toString());
    return this.http.put(`${API_BASE_URL}/cards/backup`, card ,{ params });
  }

  getAvailableStaff(){
    return this.http.get<number>(`${API_BASE_URL}/cards/availablestaff`);
  }


  syncGuestCardsByLocation(locationId: string) {
    const params = new HttpParams().set('location', locationId.toString());
    return this.http.get(API_BASE_URL + '/cards/sync/guests/byLocation', { params });
  }

}
