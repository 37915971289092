import { Component, OnInit , EventEmitter, Output, OnDestroy, ViewChild, Input} from '@angular/core';
import { Subscription, Subject } from 'rxjs';

import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';

@Component({
  selector: 'app-date-time-from-to',
  templateUrl: './date-time-from-to.component.html',
  styleUrls: ['./date-time-from-to.component.scss']
})
export class DateTimeFromToComponent implements OnInit, OnDestroy {

  @ViewChild('dtFrom', { static: true }) dtFrom: OwlDateTimeComponent<any>;
  @ViewChild('dtTo', { static: true }) dtTo: OwlDateTimeComponent<any>;

  selectedDateTime: Date[] = [];
  @Output() selectedDateTimeFromTo: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  dateTimeSubject$ = new Subject<Date[]>();
  dateTimeSubscription: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.dateTimeSubscription = this.dateTimeSubject$.subscribe(value => {
      this.selectedDateTimeFromTo.emit(value);
    });
  }

  dateTimeInput() {
    let fromDT: Date;
    let toDT: Date;
    if (this.selectedDateTime[0] !== undefined && this.selectedDateTime[0] !== null) {
      fromDT = this.selectedDateTime[0];
    }
    if (this.selectedDateTime[1] !== undefined && this.selectedDateTime[1] !== null) {
      toDT = this.selectedDateTime[1];
    }
    this.dateTimeSubject$.next([fromDT, toDT]);
  }

  onClearDateTime(index: number) {
    this.selectedDateTime[index] = undefined;
    this.selectedDateTime = this.selectedDateTime.slice();
    this.dateTimeSubject$.next(this.selectedDateTime);
  }


  onCloseFrom() {
    this.selectedDateTime = [this.dtFrom.selecteds[0], this.dtFrom.selecteds[1]];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  onCloseTo() {
    this.selectedDateTime = [this.dtTo.selecteds[0], this.dtTo.selecteds[1]];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  public showLast24hours() {
    this.selectedDateTime = [ new Date(Date.now() - 24 * 60 * 60 * 1000), new Date()];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  public showLast7days() {
    this.selectedDateTime = [ new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  public showLast30days() {
    this.selectedDateTime = [ new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), new Date()];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  ngOnDestroy() {
    if (this.dateTimeSubscription) {
      this.dateTimeSubscription.unsubscribe();
    }
  }

}
