import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { UserService } from './../services/user.service';
import { User } from 'src/app/core/models/user/user.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { SoftwarePermissionId } from '../models/permissions/software-permission-id.enum';
import { Platform } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import { CurrentUserStoreService } from '../services/current-user-store.service';

@Injectable({
    providedIn: 'root'
})

export class UserPermissionGuard implements CanActivate {

    constructor(private currentUserStoreService: CurrentUserStoreService,
                private authService: AuthenticationService,
                private messagingService: MessagingService,
                private router: Router,
                public platform: Platform) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let user = this.currentUserStoreService.getUser();
        if (user === undefined) {
            if (this.authService.isValid()) {
                await lastValueFrom(this.authService.getUserByToken()).then(u => {user = u; }).catch(err => {});
            } else {
                if (!this.authService.getAccessToken() && !this.authService.getRefreshToken()) {
                    this.messagingService.setMessage('Access denied. Please login')
                    return false;
                }
                if (!this.authService.refreshTokenInProgress) {
                    this.authService.setRefreshTokenInProgress(true);
                    await lastValueFrom(this.authService.refreshAccessToken()).then(r => user = r.user).catch(() => {});
                    this.authService.setRefreshTokenInProgress(false);
                }
                await lastValueFrom(this.authService.refreshChanged.pipe(take(2))).then(val => {
                    user = this.currentUserStoreService.getUser()}).catch(err => {});
            }
            return this.validate(user, state);
        } else {
            return this.validate(user, state);
        }
    }

    validate(user: User, state: RouterStateSnapshot) {
        const splitedUrl = state.url.split('/');
        splitedUrl.shift();
        if (this.userContains(user, splitedUrl)) {
            return true;
        } else {
            this.router.navigateByUrl('/rooms');
            return false;
        }
    }

    userContains(user: User, wantedRoute: string[]) {
        let userPermission = false;
        let wantedId: number;
        switch (wantedRoute[0]) {
            case 'users': {
                wantedId = SoftwarePermissionId.UsersView;
                // TODO:: USERS PERMISSIONS
                if (+wantedRoute[1] === user.userId && wantedRoute.length === 2) {
                    return true;
                } else if (+wantedRoute[1] === user.userId && wantedRoute[2] === 'permissions') {
                    return false;
                } else if (wantedRoute[2] === 'permissions') {
                    wantedId = SoftwarePermissionId.UsersUpdate;
                } else if (+wantedRoute[1] === 0) {
                    wantedId = SoftwarePermissionId.UsersAdd;
                } else if (wantedRoute[2] === 'cards') {
                    wantedId = SoftwarePermissionId.CardsAdd;
                    if (+wantedRoute[1] === user.userId) {
                        wantedId = SoftwarePermissionId.CardsAddForSelf;
                    }
                }
                if (wantedRoute.length === 6 && wantedRoute[4] === 'rules') {
                    wantedId = SoftwarePermissionId.UsersUpdate;
                }
                if (wantedRoute.length === 4 && wantedRoute[3] === 'permissions') {
                    wantedId = SoftwarePermissionId.UsersUpdate;
                }
                break;
            }
            case 'logs': {
                if (wantedRoute.length === 2 && wantedRoute[1] === 'audit') {
                    wantedId = SoftwarePermissionId.AuditLogView;
                } else if (wantedRoute.length === 2 && wantedRoute[1] === 'access') {
                    wantedId = SoftwarePermissionId.AccessLogView;
                } else if (wantedRoute.length === 2 && wantedRoute[1] === 'alarms') {
                  wantedId = SoftwarePermissionId.AlarmsLogView;
                } else if (wantedRoute.length === 2 && wantedRoute[1] === 'card-events') {
                    wantedId = SoftwarePermissionId.CardsAdd;
                }
                break;
            }
            case 'alarms': {
                wantedId = SoftwarePermissionId.AlarmsBurglaryReceive;
                // if (wantedRoute.length === 2 && wantedRoute[1] === 'unconfirmed') {
                //     wantedId = SoftwarePermissionId.AlarmsReceive;
                // }
                break;
            }
            case 'rooms': {
                if (wantedRoute[1] === 'details' && this.platform.is('desktop')) {
                    break;
                } else {
                    wantedId = SoftwarePermissionId.Login;
                }
                break;
            }

            case 'roles': {
                wantedId = SoftwarePermissionId.RolesView;
                if (wantedRoute.length === 2 && wantedRoute[1] === '0') {
                    wantedId = SoftwarePermissionId.RolesAdd;
                }
                else if (wantedRoute.length === 2 && typeof (+wantedRoute[1]) === 'number') {
                    wantedId = SoftwarePermissionId.RolesUpdate;
                }
                break;
            }

            case 'settings': {
                wantedId = SoftwarePermissionId.Settings;
                break;
            }

            case 'cards': {
                wantedId = SoftwarePermissionId.CardsAdd;
                break;
            }

            case 'locations': {
                wantedId = SoftwarePermissionId.LocationSettings;
                break;
            }

            case 'automation': {
                wantedId = SoftwarePermissionId.LocationSettings;
                break;
            }

            case 'common-area' : {
                wantedId = SoftwarePermissionId.LocationSettings;
                break;
            }
            case 'groups' : {
              wantedId = SoftwarePermissionId.LocationGroupsUpdate;
              break;
          }
            case 'schedule' : {
              wantedId = SoftwarePermissionId.LocationSettings;
              break;
          }
          case 'hvac-mode' : {
            wantedId = SoftwarePermissionId.LocationSettings;
            break;
        }
            default: {
                return false;
            }
        }
        userPermission = user?.roles?.some(role => role?.permissions?.some(permission => {
          if (wantedId === SoftwarePermissionId.AlarmsBurglaryReceive) {
            return this.permissionIsAnyAlarm(permission.permissionId);
          } else {
            return permission.permissionId === wantedId;
          }
          }
        ));
        if (!userPermission) {
           userPermission = user?.permissions?.some(permission => {
            if (wantedId === SoftwarePermissionId.AlarmsBurglaryReceive) {
              return this.permissionIsAnyAlarm(permission.permissionId);
            } else {
              return permission.permissionId === wantedId;
            }
           });
        }
        return userPermission;
    }


    permissionIsAnyAlarm(permissionId: number): boolean {
      return permissionId === SoftwarePermissionId.AlarmsBurglaryReceive ||
      permissionId === SoftwarePermissionId.AlarmsHvacReceive ||
      permissionId === SoftwarePermissionId.AlarmsOpenTooLongReceive ||
      permissionId === SoftwarePermissionId.AlarmsSafetyReceive ||
      permissionId === SoftwarePermissionId.AlarmsSosReceive ||
      permissionId === SoftwarePermissionId.AlarmsSystemReceive;
    }
}
