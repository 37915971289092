import { lastValueFrom } from 'rxjs';
import { Injectable,  Injector, ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef } from '@angular/core';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { AlarmAlertsComponent } from 'src/app/modules/alarms/components/alarm-alerts/alarm-alerts.component';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { User } from 'src/app/core/models/user/user.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { MqttProjectService } from '../app-load/mqtt-project.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { ApiSettingsService } from 'src/app/modules/settings/services/http/api-settings.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { ApiLocationGroupsService } from 'src/app/shared/services/api-location-groups.service';
import { LocationGroupType } from '../models/location-group-type.enum';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CurrentUserStoreService } from './current-user-store.service';



@Injectable({
    providedIn: 'root'
})
export class UserService {


    swPermissionId = SoftwarePermissionId;
    alarmsInitialized = false;
    alarmsService: AlarmAlertsService;
    alarmAlertComponentRef: any;
    mqttInitialised = false

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private mqttProjectService: MqttProjectService,
        private apiProjectService: ApiProjectService,
        private api: ApiUsersService,
        private apiSettingsService: ApiSettingsService,
        private apiDaliProjectService: ApiDaliProjectService,
        private apiLocationGroupsService: ApiLocationGroupsService,
        private settingsService: SettingsService,
        private currentUserStoreService: CurrentUserStoreService
    ) {}

    async setUser(u: User) {
      const user = Object.assign(new User(), u);
      this.currentUserStoreService.setUser(user)

        if(!this.mqttInitialised) {
            this.mqttInitialised = true;
            // const filtersForUser = StoredFilter.getGuestRoomFilterFromStorage(this.user.userId);
            // const filter = filtersForUser ? filtersForUser.filters : new ControllerFilters(['Room2'])
            // const commonAreafiltersForUser = StoredFilter.getCommonAreaFilterFromStorage(this.user.userId);
            // const commonAreaFilter = commonAreafiltersForUser ? commonAreafiltersForUser.filters :
            // new ControllerFilters(['AccessControl_v1', 'IOCommonArea', 'HvacCommonArea'])
            await lastValueFrom(this.apiSettingsService.getFrontendSettings());// frontend settings needed to setup view
            this.settingsService.setupViewConfigs();
            this.settingsService.initializeViewFromStorage(user);
            lastValueFrom(this.apiProjectService.getAllControllersFat()); // tomdo remove, treba za hvac header,
            // treba za guest room permission
            Promise.all(
              [
                lastValueFrom(this.api.getCardTypes()),
                lastValueFrom(this.apiProjectService.getObjects()),
                lastValueFrom(this.apiProjectService.getSubobjects()),
                lastValueFrom(this.apiProjectService.getAllControllersThin()),
                lastValueFrom(this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.FloorGroup))
              ]).then(()=> {
                this.apiDaliProjectService.getProject().subscribe();
                this.mqttProjectService.initMqttForProject();
            })
            // this.api.getCardTypes().subscribe();
            // await lastValueFrom(this.apiSettingsService.getFrontendSettings());

            // await lastValueFrom(this.apiProjectService.getObjects())
            // await lastValueFrom(this.apiProjectService.getSubobjects())
            // tomdo get all location groups and sort filter floors
            // await lastValueFrom(this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.FloorGroup))
             // tomdo optimiziranje redosljeda
             // await lastValueFrom(this.apiProjectService.getAllControllersFat())
            // lastValueFrom(this.apiProjectService.getFilteredProjectFat(filter)).then(  () => {
            //     this.apiDaliProjectService.getProject().subscribe();
            //     // this.apiProjectService.reduceProjectByUserPermissions(this.user);
            //     this.mqttProjectService.initMqttForProject();
            // })
        }

            // if (this.user.havePermission(this.swPermissionId.AlarmsReceive)) {
            //     if (!this.alarmsInitialized) {
            //         this.alarmsService = <AlarmAlertsService>this.injector.get(AlarmAlertsService);
            //         this.alarmsService.initAlarmsService();
            //         this.appendAlarmAlertsToBody();
            //         this.alarmsInitialized = true;
            //     }
            // }
    }


    appendAlarmAlertsToBody() {
        // 1. Create a component reference from the component
        this.alarmAlertComponentRef = this.componentFactoryResolver
        .resolveComponentFactory(AlarmAlertsComponent)
        .create(this.injector);

        // 2. Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(this.alarmAlertComponentRef.hostView);

        // 3. Get DOM element from component
        const domElem = (this.alarmAlertComponentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

        // 4. Append DOM element to the body
        document.body.getElementsByTagName('app-root')[0].appendChild(domElem);
    }

    removeAlarmAlertsFromBody() {
        if (this.alarmsInitialized) {
            this.appRef.detachView(this.alarmAlertComponentRef.hostView);
            this.alarmAlertComponentRef.destroy();
            this.alarmsInitialized = false;
        }
    }
}
