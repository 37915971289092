import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ApiAlarmLogsService } from '../../services/http/api-alarm-logs.service';
import { AlarmLog } from 'src/app/core/models/logs/alarm-log.model';
import { PaginationData } from 'src/app/core/models/pagination-data.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { Controller } from 'src/app/core/models/controller.model';


@Component({
  selector: 'app-alarm-logs',
  templateUrl: './alarm-logs.component.html',
  styleUrls: ['./alarm-logs.component.scss']
})
export class AlarmLogsComponent implements OnInit {

  @Input() controllers: Controller[];
  @Input() singleRoom: boolean;
  mainController: Controller;
  alarmLogData: AlarmLog[]  = [];
  paginationData: PaginationData = new PaginationData();

  queryParams = {
    pageNumber: 1,
    pageSize: 50
  };
  requestBody: SsFilterSort = new SsFilterSort();
  loading = false;

  constructor(private apiAlarmLogsService: ApiAlarmLogsService) { }

  ngOnInit(): void {
    if (this.singleRoom) {
      // this.getMainController();
      this.mainController = Controller.getMainController(this.controllers)
    }
    this.onSubmit(1, 'DSC|TIME');
  }


  controllerChanged(controller: Controller) {
    this.mainController = controller;
    this.onSubmit(1, 'DSC|TIME');
  }


  onSubmit(pageNumber: number = 1, pageSort?: string) {
    this.loading = true;
    if (pageSort) {
       this.requestBody.sorting = pageSort;
      }
    if (this.singleRoom) {
      const location = this.mainController.designation.substring(0,this.mainController.designation.length - 2)
      this.requestBody.filtering = location;
    }
    this.queryParams.pageNumber = pageNumber;
    this.apiAlarmLogsService.getAlarmLogs(this.requestBody, this.queryParams).subscribe(resp => {
      this.paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      this.alarmLogData = resp.body;
      if (this.singleRoom) {
        this.alarmLogData = this.alarmLogData.filter( alarm => {
          if (alarm.data.includes(this.mainController.designation)) {
            return true;
          }
        })
      }

      this.loading = false;
    });
  }

  onSort(sortBy: string) {
    if (this.requestBody.sorting === 'ASC|' + sortBy) {
      this.requestBody.sorting = 'DSC|' + sortBy;
    } else {
      this.requestBody.sorting = 'ASC|' + sortBy;
    }
    this.onSubmit(this.queryParams.pageNumber);
  }

  searchFilterReady(searchFilter: string) {
    this.requestBody.filtering = searchFilter;
    this.onSubmit();
  }


  setDateTimeFromTo(selectedDateTime: Date[]) {
    this.requestBody.dateTimeFrom = selectedDateTime[0];
    this.requestBody.dateTimeTo = selectedDateTime[1];
    this.onSubmit();
  }

}
