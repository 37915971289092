import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { AnalyticsToolbarItem } from 'src/app/modules/rooms/components/analytics-toolbar/analytics-toolbar.component';

export interface DisplayConfig {
  name: string,
  permissionRequired: number;
  data: DisplayPropertyParams[],
  analyticsToolbar: AnalyticsToolbarItem[]
}



export interface DisplayPropertyParams {
  mode: 'singlePropType' | 'status' | 'numericalPropType' | 'custom(fanSpeed)' | 'custom(hvacHeatCoolAuto)',
  propInfo: {
    activeCondition: 'any' | 'every',
    activeName: string,
    activeIcon: string,
    activeTooltip: string,
    inactiveName: string,
    inactiveIcon: string,
    inactiveTooltip: string,
    codeFrom?: number,
    codeTo?: number,
    statusName?: string,
    sufix?: string
  },
}

@Component({
  selector: 'app-display-property',
  templateUrl: './display-property.component.html',
  styleUrls: ['./display-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayPropertyComponent implements OnInit {
  @Input() controllers: Controller[];
  @Input() propToDisplay: DisplayPropertyParams;
  @Input() displaySize: 'large' | 'minimal' | 'facility';

  Property = Property

  propDisplayArray: string[] = []

  constructor() { }

  // touchStart(e, propertyName: any) {
  //     this.propDisplayArray.push(propertyName)
  // }

  // touchEnd(e, propertyName: string) {

  //   this.propDisplayArray = this.propDisplayArray.filter( element => element !== propertyName);
  // }

  // USE THIS IN HTML
  // (touchstart)="touchStart($event, propToDisplay.propInfo.activeName)"
  // (touchend)="touchEnd($event, propToDisplay.propInfo.activeName)"

  // (touchstart)="touchStart($event, 'fanSpeed')"
  // (touchend)="touchEnd($event, 'fanSpeed')"

  ngOnInit(): void {
  }

}
