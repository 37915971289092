
export class ControllerFilters {
    Location: {
        Objects: number[], // object ids
        Subobjects: number[], // subobject ids
        Groups: number[], // group ids
        Floors: number[] // floor ids
      };
    Cleaning: {
        Inspected: boolean | null,
        Cleaned: boolean | null,
        MakeUpRoom: boolean | null
      };
    Hvac:{
        Active: boolean | null,
        Heating: boolean | null,
        Cooling: boolean | null,
        HvacSpeed: null | 1 | 2 | 3, // 1-low, 2-mid, 3-high
        TemperatureFilter: null | ITemperatureFilter,
        TemperatureDifferenceFilter: null | ITemperatureDifferenceFilter
      };
    Statuses:{
        Online: boolean | null,
        Rented: boolean | null,
        AlarmActive: boolean | null,
        DoNotDisturb: boolean | null,
        RoomArmed: boolean | null,
        WindowOpen: boolean | null,
        DoorOpen: boolean | null,
        IgnoreWindow: boolean | null,
        IgnoreCardTray: boolean | null
    }
    ControllerTypes : {
      ControllerTypes: string[];
  }

  constructor(controllerTypes: string[]) {
    this.Location = {
      Floors: [],
      Groups: [],
      Objects: [],
      Subobjects: []
    }

    this.Cleaning = {
      Inspected:  null,
        Cleaned:  null,
        MakeUpRoom:  null
    }

    this.Hvac = {
      Active:  null,
      Heating:  null,
      Cooling:  null,
      HvacSpeed: null,
      TemperatureFilter: null ,
      TemperatureDifferenceFilter: null
    };

    this.Statuses = {
        Online: null,
        Rented: null,
        AlarmActive: null,
        DoNotDisturb: null,
        RoomArmed: null,
        WindowOpen: null,
        DoorOpen: null,
        IgnoreWindow: null,
        IgnoreCardTray: null
    },
    this.ControllerTypes = {
      ControllerTypes: controllerTypes
  }
  }
}

export interface ITemperatureFilter {
  Type: 0 | 1, // 0-Set, 1-Current
  Location: 0 | 1, // 0-Room, 1-Bathroom
  Comparison: 0 | 1 | 2, // 0-Equal, 1-GreaterThan, 2-Less than
  Value: number
}

export interface ITemperatureDifferenceFilter {
  Location: 0 | 1, // 0-Room, 1-Bathroom
  Comparison: 0 | 1 | 2, // 0-Equal, 1-GreaterThan, 2-Less than
  Value: number
}
