import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';

@Pipe({
  name: 'getRoomsOnFloor'
})
export class GetRoomsOnFloorPipe implements PipeTransform {

  transform(
    controllers: Controller[], fl: LocationGroup, controllerType: 'isGuestRoom' | 'isAccessControl',
     targetObject?: ProjectObject, targetSubobject?: ProjectSubObject, needFullLocationId?: boolean)
    : {number, name, locationId: string}[] {

   /*  if (!project) {
      return [];
    }
    if (!floor) {
      return null;
    }

    return project?.controllers?.reduce((roomList, controller: Controller) => {
      const roomNumber = controller.zone;
      if (!roomList.includes(roomNumber) && Number(Controller.getFloorNumber(controller)) === Number(floor)) {
        roomList.push(roomNumber)
      }
      return roomList
    }, []) */
    if (controllers.length === 0) {
      return  [];
    }

    if (needFullLocationId) {
      return this.getControllersOnFloor(controllers, controllerType, fl as LocationGroup);
    }  else {
     /*  if ((fl as LocationGroup)?.locationIds && ((fl as LocationGroup)?.locationIds?.length > 0) ) { */
      return this.getRoomNumbersForFloor(controllers ,fl, controllerType, targetObject, targetSubobject);

    /*   }  else  {
        return this.getRoomNumbersForFloorNumber(project, fl as number, controllerType, targetObject, targetSubobject);
    } */
    // }
  }
  }

  getControllersOnFloor(controllers: Controller[], controllerType: string, fl: LocationGroup) {
    const floor: LocationGroup = fl;
    // const floorNumber: number = fl as number;
    const controllersList = []

    controllers.forEach( (cont: Controller) => {
     /*  driver[1].forEach( object => {
        object[1].forEach( subObj => {
          subObj[1].forEach( zone => {
            zone[1].forEach( subZone => { */
              if (controllerType === 'isGuestRoom' && /* subZone[1].isGuestRoom */ Controller.isGuestRoom(cont)) {
                controllersList.push(cont)
              } else if (controllerType === 'isAccessControl' && /* subZone[1].isAccessControl */ Controller.isAccessControl(cont)) {
                controllersList.push(cont)
              }
           /*  });
          })
        });
      }); */
    });


    const locationIdsOnFloor = [];
    return controllersList.reduce(( roomDataOnFloor, controller: Controller): Controller [] => {
      // const roomNumber = controller.zone;
     //  if ((fl as LocationGroup)?.locationIds && ((fl as LocationGroup)?.locationIds?.length > 0)) {
        if (!locationIdsOnFloor.includes(controller.designation) && floor.locationIds.includes(controller.designation)) {
          locationIdsOnFloor.push(controller.designation)
          roomDataOnFloor.push(controller)
      }
    /*   } else {
        if (!locationIdsOnFloor.includes(controller.designation) && Controller.getFloorNumber(controller) === floorNumber.toString()) {
          locationIdsOnFloor.push(controller.designation)
          roomDataOnFloor.push(controller)
        }
      } */
      return roomDataOnFloor
    }, [])

  }


  getRoomNumbersForFloor(controllers: Controller [],fl:LocationGroup, controllerType: 'isGuestRoom' | 'isAccessControl',
   targetObject: ProjectObject, targetSubobject: ProjectSubObject) {
      const floor: LocationGroup = fl as LocationGroup;
      const controllersOnFloor: Controller[] = [];

      controllers.forEach((cont)=> {
        if (Controller.isGuestRoom(cont) && controllerType === 'isGuestRoom' && floor.locationIds.includes(cont.designation)) {
          controllersOnFloor.push(cont)
        }
        if (Controller.isAccessControl(cont) && controllerType === 'isAccessControl' && floor.locationIds.includes(cont.designation)) {
          controllersOnFloor.push(cont)
        }
      })

      const locationIdsOnFloor = [];
      return controllersOnFloor.reduce(( roomDataOnFloor, controller: Controller) => {
        // const roomNumber = controller.zone;
        if (!locationIdsOnFloor.includes(controller.designation) && floor.locationIds.includes(controller.designation)) {
          locationIdsOnFloor.push(controller.designation)
          const roomNumber = controller.zone;
          roomDataOnFloor.push({number:roomNumber, name: controller.name, locationId: controller.designation})
        }
        return roomDataOnFloor
      }, [])
  }
}
