<div class="content-sub-header flex-row-center-full">
    <h4>{{ "Group settings" | translate | uppercase }}</h4>
</div>
<!-- <app-groups-hvac-modal
  [settingsType]="'hvac'"
  [selectedGroup]="selectedGroup"
></app-groups-hvac-modal> -->
<div
  *ngIf="defaultPresets"
  class="flex-row width100 margin-top30 padding-left15"
>
  <div>
    {{ "Presets" | translate }}:
    <ion-chip class="margin-right10" (click)="onClickNoPreset()">
      <ion-label class="pad20">{{'No preset' | translate}}</ion-label>
    </ion-chip>
    <ion-chip
      class="margin-right10"
      *ngFor="let preset of defaultPresets"
      (click)="onClickPreset(preset)"
    >
      <ion-label class="pad20">{{ preset.name | titlecase }}</ion-label>
    </ion-chip>
  </div>
</div>
<div *ngIf="targetProps" class="props-container padding-left15">
  <div class="props-container-top-right">
    <ion-button size="small" (click)="onApply()" [disabled]="loading">{{
      "Send selected values" | translate
    }}</ion-button>
  </div>
  <div *ngFor="let prop of targetProps">
    <p class="flex-row-center-v margin-top10 margin-bottom10">
      <ion-checkbox
        class="margin-right5"
        [(ngModel)]="prop.include"
      ></ion-checkbox>
      <input
        class="margin-right25 text-align-right"
        [(ngModel)]="prop.value"
        type="number"
      />
      <label>{{ prop.name }}</label>
    </p>
  </div>
</div>

