import { Input, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { ModalController, PopoverController } from '@ionic/angular';
import { SelectContainerComponent } from 'ngx-drag-to-select/lib/select-container.component';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { Card } from 'src/app/core/models/card/card.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { User } from 'src/app/core/models/user/user.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRolesService } from 'src/app/modules/roles/services/http/api-roles.service';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { LocationGroup } from '../../../../shared/services/api-location-groups.service';
import { Setting } from 'src/app/core/models/project/setting.model';
import { LocationGroupsService } from 'src/app/shared/services/location-groups.service';
import { take } from 'rxjs/operators';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CardOnHolderService } from 'src/app/core/services/card-on-holder.service';
import { ApiProjectService, KeyOption } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ControllerFilters } from 'src/app/core/models/view-filter.model';
import { DateTimeInlineComponent } from 'src/app/shared/components/date-time-inline/date-time-inline.component';
import { time } from 'console';


@Component({
  selector: 'app-edit-card-modal',
  templateUrl: './edit-card-modal.component.html',
  styleUrls: ['./edit-card-modal.component.scss']
})
export class EditCardModalComponent implements OnInit, OnDestroy {

  @ViewChild('selectContainerRooms') selectContainerRooms: SelectContainerComponent;
  // @ViewChild('selectContainerAccess') selectContainerAccess: SelectContainerComponent;
  @ViewChild('dtFrom') dtFrom: OwlDateTimeComponent<any>;
  @ViewChild('dtTo') dtTo: OwlDateTimeComponent<any>;
  @Input() controllersInRoom: Controller[];
  @Input() userId: number;
  editUser: User;
  originalEditUser: User;
  editUserCard: Card;
  cardOriginalState: Card;
  userReady = false;
  selectedDateTime: Date[] = [];
  multipleRooms: Controller[] = []
  mainController: Controller;

  fromDateNotValid = false;
  toDateNotValid = false;

  groupSelect: 'none' /* |'access' */ | 'rooms' = 'none';

  floorList$: Observable<LocationGroup[]> = this.locationGroupsService.getFloorList();

  permissions: Permission[];
  permissionsSubscription: Subscription;


  cardOnHolder: boolean;
  cardOnHolderSubscription: Subscription;
  openModal = false;
  cardExist = false;
  userFullName: string;
  userBeingCreated = false;
  roomsToJoin: string[] = [];
  guestControllersThin: Controller [];// $ = this.apiProjectService.getControllersThin(new ControllerFilters(['Room2']));
  allObjects$: Observable<ProjectObject[]> = this.projectService.getObjects();
  objectsFilterFull$ = this.filtersService.getObjectsFilterFull();
  selectedAccessDesignation = '*/*/*/*';
  selectedRoomDesignation = '*/*/*/*';
  accessControlListForLocationId: KeyOption[];
  loading = false;


  showFrom = false;

  constructor(private modalController: ModalController,
              private locationGroupsService: LocationGroupsService,
              private apiUsersService: ApiUsersService,
              private cardsService: CardsService,
              private projectService: ProjectService,
              private rolesService: RolesService,
              private apiRolesService: ApiRolesService,
              private usersService: UsersService,
              private settingsService: SettingsService,
              private cardOnHolderService: CardOnHolderService,
              private apiProjectService: ApiProjectService,
              private filtersService: FiltersService,
              private popoverController: PopoverController
             ) { }

  ngOnInit(): void {
    this.mainController = Controller.getMainController(this.controllersInRoom)
    forkJoin([
    this.apiProjectService.getKeyOptionsAccessControllListForLocationId(this.mainController.locationId),
    this.apiProjectService.getControllersThin(new ControllerFilters(['Room2']))
    ])
      .subscribe(([keyOptions, guestControllersThin])=> {
        this.accessControlListForLocationId = keyOptions;
        this.guestControllersThin = guestControllersThin;

    if (this.userId) { // EDIT CARD
      this.apiUsersService.getUser(this.userId).subscribe( user => {
        this.editUser = user;
        if (this.editUser.cards.length > 0) {
          this.editUserCard = this.editUser.cards[0];
        } else {
          this.editUserCard = this.editUser.cardLaters[0];
        }
        this.cardOriginalState = structuredClone(this.editUserCard)
        this.originalEditUser = structuredClone(this.editUser);
        this.selectedDateTime[0] = new Date(this.editUserCard.validFrom);
        this.selectedDateTime[1] = new Date(this.editUserCard.validTo);
        this.userFullName = user.fullName;

        const group = JSON.parse(this.editUserCard.groups)
        if (group.joinedRooms.length > 0) {
          group.joinedRooms.forEach( element => {
            const locationId = element + '/0';
            if (locationId !== this.mainController.designation) {
              this.roomsToJoin.push(locationId)
            }
          });
        }
        this.addCardPermissionsOnCardEdit(user);
        this.userReady = true;
      })
    } else { // NEW CARD

      combineLatest([
        this.settingsService.getDefaultCheckinTime(),
        this.settingsService.getDefaultStayDuration(),
        this.settingsService.getDefaultCheckoutTime()
        ])
        .pipe(take(1))
        .subscribe(([defaultCheckinTime, defaultStayDurationDays, defaultCheckoutTime])=> {
          const arrival = new Date();
          arrival.setHours(defaultCheckinTime.hour);
          arrival.setMinutes(defaultCheckinTime.minute);
          this.selectedDateTime[0] = arrival;

          const departure = new Date();
          departure.setHours(defaultCheckoutTime.hour);
          departure.setMinutes(defaultCheckoutTime.minute);
          departure.setDate(departure.getDate() + defaultStayDurationDays);
          this.selectedDateTime[1] = departure;
          // this.groupControllersByLocation();
          this.userReady = true;
        })

    /*   this.settingsService.getDefaultCheckinTime().pipe(take(1)).subscribe((defaultCheckinTime: CheckInOutTime)=> {
        // this.dtFrom?.selecteds[0]?.hours(defaultCheckinTime.hour);
        // this.dtFrom?.selecteds[0]?.minutes(defaultCheckinTime.minute);
        // this.selectedDateTime = [this.dtFrom.selecteds[0], this.dtFrom.selecteds[1]];

        const now = new Date();
        now.setHours(defaultCheckinTime.hour)
        now.setMinutes(defaultCheckinTime.minute)
        this.selectedDateTime[0] = now;
        // this.groupControllersByLocation();
        this.userReady = true;
      }) */
    }
    this.getPermissions();
    this.getCardOnHolderStatus();
    })

  }

  checkIfcardExists() {
    return this.cardOnHolderService.cardByUidExists(this.cardsService.lastCardsOnHolderUid);
  }

  getCardOnHolderStatus() {
    this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    this.cardOnHolderSubscription = this.cardOnHolderService.cardOnHolderChanged.subscribe(cardOnHolder => {
        this.cardOnHolderChanged(cardOnHolder)
      }
     );
  }

  ionViewDidEnter	() {
      this.selectContainerRooms.update();// "select container" needs to be updated on each change of its size and position
      // this.selectContainerAccess.update();// "select container" needs to be updated on each change of its size and position
  }



  onScrollEnd() {
    this.selectContainerRooms.update();
    // this.selectContainerAccess.update();
  }

  getPermissions() {
    this.permissions = this.rolesService.getPermissions();
    this.apiRolesService.getPermissions().subscribe();
    this.permissionsSubscription = this.rolesService.permissionsChanged.subscribe(() => {
      this.permissions = this.rolesService.getPermissions();
    });
  }

  onClickNewRoom() {
    this.groupSelect = 'rooms';
    const multipleRoomsIds = [];
    this.multipleRooms.forEach( cont => {
      multipleRoomsIds.push(cont.designation);
    })
    this.selectContainerRooms.clearSelection();
    this.selectContainerRooms.selectItems<Controller>(item => {
      if (multipleRoomsIds.includes(item.designation)) {
        return true;
      }
    })
    this.selectContainerRooms.update();
  }


  addCardPermissionsOnCardEdit(user: User) {
    this.accessControlListForLocationId.forEach((kO)=> {
      kO.checked = false;
    })

    user.permissions.forEach( perm => {
      const locationId = perm.name.split(' ')[2]
      if((locationId && perm.typeId === 1) && !(this.mainController.locationId === locationId)) {
        // check if perm is access control
        const keyOption = this.accessControlListForLocationId.find((kOption)=> {
          return kOption.controller.designation === locationId;
        })
        if (keyOption) {
          keyOption.checked = true;
          return;
        }
        // check if perm is guest room perm
        const guestRoomCont = this.guestControllersThin.find((cont: Controller)=> {
          return cont.designation === locationId;
        })
        if (guestRoomCont && !this.roomsToJoin.includes(locationId) && this.mainController.designation !== locationId) {
          this.multipleRooms.push(guestRoomCont);
        }

        // if (Controller.isAccessControl(this.projectService.getControllerByLocationId(locationId))) {
          //
        //  this.accessControls.push(this.projectService.getControllerByLocationId(locationId));
        // } else
        /*  if (Controller.isGuestRoom(this.projectService.getControllerByLocationId(locationId))
        && !this.roomsToJoin.includes(locationId)) {
          this.multipleRooms.push(this.projectService.getControllerByLocationId(locationId))
        } */
      }
    })
  }

  onClickMultipleRoomsCheckbox(controller: Controller) {
    this.multipleRooms = this.multipleRooms.filter( element => element.locationId !== controller.locationId)
  }

  timeFromChanged(event) {
    const timeValues = event.split(' ');
    let time = new Date()
    if(timeValues[0]) {
      time.setDate(timeValues[0])
    }
    if(timeValues[1]) {
      time.setMonth(timeValues[1]-1)
    }
    if(timeValues[2]) {
      time.setFullYear(timeValues[2])
    }
    if(timeValues[3]) {
      time.setHours(timeValues[3])
      time.setMinutes(0)
    }
    if(timeValues[4]) {
      time.setMinutes(timeValues[4])
    }

    this.selectedDateTime[0] = time;
  }

  timeToChanged(event) {
    const timeValues = event.split(' ');
    let time = new Date()
    if(timeValues[0]) {
      time.setDate(timeValues[0])
    }
    if(timeValues[1]) {
      time.setMonth(timeValues[1]-1)
    }
    if(timeValues[2]) {
      time.setFullYear(timeValues[2])
    }
    if(timeValues[3]) {
      time.setHours(timeValues[3])
      time.setMinutes(0)
    }
    if(timeValues[4]) {
      time.setMinutes(timeValues[4])
    }

    this.selectedDateTime[1] = time;
  }

  async openCalendarForDateAndTime(ev) {
    const popover = await this.popoverController.create({
      component: DateTimeInlineComponent,
      componentProps:  {selectedDateTime : this.selectedDateTime},
      cssClass: 'date-time-inline-popover',
      event: ev,
      showBackdrop: false
    });
    await popover.present();

    popover.onDidDismiss().then( (data) => {
    })
  }


  onCancel() {
    this.modalController.dismiss();
  }



  onModalDecision(decision) {
    this.openModal = false;
 }

  onModalDecisionCardExist(decision) {
    this.cardExist = false;
 }

  goBack() {
    this.selectContainerRooms.clearSelection();
    this.selectContainerRooms.update()
    this.groupSelect = 'none';
  }

  confirm() {
    this.groupSelect = 'none';
  }

  cardOnHolderChanged(cardOnHolder: boolean) {
    this.cardOnHolder = cardOnHolder;
    if (this.openModal === true && this.cardOnHolder === true) {
      this.onSave();
    }
  }

  checkRequiredFields() {
    this.fromDateNotValid = false;
    this.toDateNotValid = false;
    if (!this.selectedDateTime[0]) {
      this.fromDateNotValid = true;
    }
    if (!this.selectedDateTime[1] || this.selectedDateTime[1] < new Date()) {
      this.toDateNotValid = true;
    }
  }



  onSave() {
      this.checkRequiredFields();
      if (this.fromDateNotValid || this.toDateNotValid) {
       return;
      }

    if (this.userId) { // EDIT CARD
        const multipleRoomsIds: string[] = [];
        const accessControlIds: string[] = []
        this.multipleRooms.forEach( cont => {
          multipleRoomsIds.push(cont.designation);
        })

        this.accessControlListForLocationId.forEach( (keyOption: KeyOption) => {
          if (keyOption.checked) {
            accessControlIds.push(keyOption.controller.designation);
          }
        })
        // if (!this.selectedDateTime[0] || !this.selectedDateTime[1]) {
        //   this.checkRequiredFields();
        // }
        // else {
          this.editUser.permissions = [];

          this.permissions.forEach( perm => {
            const locationId = perm.name.split(' ')[2]
            if((locationId  && perm.typeId === 1) && (this.mainController.designation === locationId ||
               multipleRoomsIds.includes(locationId.toString()) || accessControlIds.includes(locationId.toString()))) {
               this.editUser.permissions.push(perm);
            }
          })

          this.roomsToJoin.forEach ( room => {
            const target = this.permissions.find( perm => {
              const locationId = perm.name.split(' ')[2];
              if ((locationId  && perm.typeId === 1) && ( room === locationId)) {
                return true;
              }
            })

            this.editUser.permissions.push(target);
          })

            this.editUserCard.validFrom = this.selectedDateTime[0];
            this.editUserCard.validTo = this.selectedDateTime[1];

            if (this.roomsToJoin.length > 0) {
              const joinRoomArray = [];

              this.roomsToJoin.forEach( room => {
                joinRoomArray.push(room.substring(0, room.length - 2))
              })

              joinRoomArray.push(this.mainController.designation.substring(0, this.mainController.designation.length-2))
              this.editUserCard.groups = '{"joinedRooms": [';
              joinRoomArray.forEach( (location, index) => {
                this.editUserCard.groups = this.editUserCard.groups + '"' + location + '"';
                if (index !== joinRoomArray.length -1) {
                  this.editUserCard.groups = this.editUserCard.groups + ',';
                }
              })
              this.editUserCard.groups = this.editUserCard.groups + ']}'
            } else {
              this.editUserCard.groups = null;
            }

            // need to do this for card update
            this.editUser.cards = this.originalEditUser.cards;
            this.editUser.cardLaters = this.originalEditUser.cardLaters;
            this.loading = true;
            this.apiUsersService.updateUser(this.editUser).subscribe({
              next: (createdUser) => {
                this.updateCard();
              },
              error: (err)=> {
                this.loading = false;
              }}
              );
        // }
    } else { // NEW CARD
      if (this.cardOnHolder === true) {
        this.checkIfcardExists().subscribe( (cardExists) => {
          if (cardExists === false) {
            const multipleRoomsIds = [];
            const accessControlIds = []
            this.multipleRooms.forEach( cont => {
              multipleRoomsIds.push(cont.designation);
            })
            this.accessControlListForLocationId.forEach( (keyOption: KeyOption) => {
              if (keyOption.checked) {
                accessControlIds.push(keyOption.controller.designation);
              }
            })

            // this.accessControls.forEach( cont => {
            //   accessControlIds.push(cont.designation);
            // })
            // if (!this.selectedDateTime[0] || !this.selectedDateTime[1]) {
            //   this.checkRequiredFields();
            // }
            // else {
              const user: User = new User();
              user.typeId = 2;
              if (this.userFullName === undefined || this.userFullName === '') {
                user.firstName = 'Guest'
                user.lastName = "";
                user.lastName = this.getRandomId();
              } else {
                const nameSplit = this.userFullName.split(' ')
                user.firstName = nameSplit[0]
                user.lastName = nameSplit[1]
                if (user.lastName === undefined || user.lastName === '') {
                  // user.lastName = "Guest";
                  user.lastName = this.getRandomId();
                }
              }
              user.permissions = [];

              this.permissions.forEach( perm => {
                const locationId = perm.name.split(' ')[2]
                if((locationId  && perm.typeId === 1) && (this.mainController.designation === locationId ||
                   multipleRoomsIds.includes(locationId.toString()) || accessControlIds.includes(locationId.toString()))) {
                  user.permissions.push(perm);
                }
              })

              this.roomsToJoin.forEach ( room => {
                const target = this.permissions.find( perm => {
                  const locationId = perm.name.split(' ')[2];
                  if ((locationId && perm.typeId === 1) && ( room === locationId)) {
                    return true;
                  }
                })
                user.permissions.push(target);
              })


              user.cards = [];
              const card: Card = new Card();

              if (this.roomsToJoin.length > 0) {
                const joinRoomArray = [];

                this.roomsToJoin.forEach( room => {
                  joinRoomArray.push(room.substring(0, room.length - 2))
                })

                joinRoomArray.push(this.mainController.designation.substring(0, this.mainController.designation.length-2))
                card.groups = '{"joinedRooms": [';
                joinRoomArray.forEach( (location, index) => {
                  card.groups = card.groups + '"' + location + '"';
                  if (index !== joinRoomArray.length -1) {
                    card.groups = card.groups + ',';
                  }
                })
                card.groups = card.groups + ']}'
              }

              card.type = 1;
              if (this.isAccessControll(this.mainController)) {
                user.firstName = 'AC'
                user.lastName = this.getRandomId();
                card.type = 7;
              }
              card.uid = this.cardsService.lastCardsOnHolderUid;
              card.userId = 0;
              card.validFrom = this.selectedDateTime[0];
              card.validTo = this.selectedDateTime[1];
              card.blacklisted = 0;
              user.cards.push(card);

              this.modalController.dismiss();
              if (!this.userBeingCreated) {
                this.userBeingCreated = true;
                this.loading = true;
                this.apiUsersService.addUser(user).subscribe({
                  next:(createdUser) => {
                    this.userBeingCreated = false;
                    this.usersService.guestAdded();
                  },
                  error: ()=> {
                    this.loading = false;
                  }
                }
                );
              }
            // }
          }else {
            this.cardExist = true;
          }
        })
      } else {
        this.openModal = true;
      }
    }
  }

  updateCard() {
    if ( new Date (this.editUserCard.validFrom) <= new Date()) {
      if (new Date (this.cardOriginalState.validFrom) <= new Date()) {
            this.apiUsersService.updateUserCard(this.editUserCard).subscribe({
              next: () => {
                this.modalController.dismiss();
                this.usersService.guestAdded();
              },
              error: ()=> {
                this.loading = false;
              }
            });
      } else {
        this.apiUsersService.activateUserCard(this.editUserCard).subscribe({
          next: (updatedCard: Card) => {
          this.modalController.dismiss();
          this.usersService.guestAdded();
          },
          error: ()=> {
            this.loading = false;
          }
      });
      }
    } else if (new Date (this.editUserCard.validFrom) > new Date()) {
      if (new Date (this.cardOriginalState.validFrom) > new Date()) {
        this.apiUsersService.updateUserCardLater(this.editUserCard).subscribe(
          {next: (updatedCard: Card) => {
            this.modalController.dismiss();
            this.usersService.guestAdded();
          },
          error: ()=> {
            this.loading = false;
          }
        });
      } else {
        this.apiUsersService.deactivateUserCard(this.editUserCard).subscribe({
          next: (updatedCard: Card) => {
            this.modalController.dismiss();
            this.usersService.guestAdded();
          },
          error: ()=> {
            this.loading = false;
          }
        });
      }
    }
  }

  getRandomId() {
    const randomInt = this.getRandomInt(1,9999999)
    return String(randomInt).padStart(7, '0'); // '0009'
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  onChangeObjectSubobjectAccess(event) {
    // this.selectedAccessDesignation = event.target.value;
    this.selectedAccessDesignation = event.target.value;
    /* this.selectedObjectAccess = event.target.value.split(',')[0];
    this.selectedSubObjectAccess = event.target.value.split(',')[1];
    // this.selectContainerAccess.clearSelection();
    // this.selectContainerAccess.update(); */

    // reset key options
    this.accessControlListForLocationId.forEach((kO)=> {
      kO.checked = false;
    })
     }



  onChangeObjectSubobjectRooms(event) {
    this.selectedRoomDesignation = event.target.value;
    /* this.selectedObjectRooms = event.target.value.split(',')[0];
    this.selectedSubObjectRooms = event.target.value.split(',')[1]; */
    this.selectContainerRooms.clearSelection();
    this.selectContainerRooms.update();
  }

  clickAccessControlCheckbox(event, accControl: KeyOption) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
    event.stopPropagation();
    if (event.target.checked) {
      accControl.checked = false;
      /* this.accessControls = this.accessControls.filter((ac)=> {
        return ac.locationId !== accControl.locationId;
      }).slice(); */
    } else if (!event.target.checked) {
      accControl.checked = true;
      /* this.accessControls.push(accControl);
      this.accessControls = this.accessControls.slice() */
    }
  }

  // clickAccessControlCheckbox(event, accControl: Controller) {
  //   event.preventDefault();
  //   event.stopImmediatePropagation();
  //   event.cancelBubble = true;
  //   event.stopPropagation();
  //   console.log(accControl)
  //   if (event.target.checked) {
  //     this.accessControls = this.accessControls.filter((ac)=> {
  //       return ac.locationId !== accControl.locationId;
  //     }).slice();
  //   } else if (!event.target.checked) {
  //     this.accessControls.push(accControl);
  //     this.accessControls = this.accessControls.slice()
  //   }
  // }


  getJoinableRooms() {
    const joinSetting = this.mainController.controllerSettings.$values.find( (setting: Setting) => {
      if (setting.name.toLowerCase() === 'joinedrooms' ) {
        return true;
      }
    })
    const controllers = [];
    const locationIds = joinSetting.value.split(',')
    locationIds.forEach ( (loc, index) => {
      loc = loc + '/0';
      // locationIds[index] = loc;
      if (this.mainController.designation !== loc) {
        controllers.push(this.projectService.getControllerByDesignation(loc));
      }
    })
    return controllers;
  }

  joinRoomCheckboxClicked(event: any, location: string) {
    if (event.target.checked) {
      this.roomsToJoin.push(location);
    } else {
      this.roomsToJoin = this.roomsToJoin.filter( element => element !== location)
    }
  }

  joinRoomSelected(controller: Controller) {
    return this.roomsToJoin.includes(controller.designation);
  }

  isAccessControll(controller: Controller) {
    return Controller.isAccessControl(controller);
  }

  ngOnDestroy() {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
    if (this.cardOnHolderSubscription) {
      this.cardOnHolderSubscription.unsubscribe();
    }
  }

}
