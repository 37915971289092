<ng-container *ngIf="roomViewSize$ | async as roomViewSize">
  <!-- FULL VIEW SIZE -->

  <ng-container *ngIf="roomViewSize === 'full' || roomViewSize === 'scaledDown'">
    <div class="access-control-card">
      <div
        [ngClass]="{
          'access-control-inactive-overlay': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          'access-control-inactive-icon': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: !controllers[0].isOnline
        }"
      >
        <div class="access-control-top-bar"></div>
        <div class="access-control-card-content">
          <div class="status-container-full flex-row">
            <div class="icon-background-black icon-key font-size18 white"></div>
            <div class="access-control-name">{{ controllers[0].name }}</div>
          </div>
          <div class="flex-row width100 margin-top12">
            <!-- FIRST PROP -->
            <ng-container
              *ngIf="
                controllers
                  | findProperty
                    : Property.ACDoorOpenAnyCard as doorOpenAnyCardProps
              "
            >
              <ng-container
                *ngIf="
                  doorOpenAnyCardProps | propIsActive;
                  else doorOpenAnyCardPropsInactive
                "
              >
                <div
                  class="status-container-small margin-right6 flex-row"
                  title="{{ 'Door open any card active' | translate }}"
                >
                  <div
                    class="icon-background-on icon-card font-size18 white"
                  ></div>
                  <div class="status-text-on">
                    {{ "Open any c." | translate }}
                  </div>
                </div>
              </ng-container>
              <ng-template #doorOpenAnyCardPropsInactive>
                <div
                  class="status-container-small margin-right6 flex-row"
                  title="{{ 'Door open any card inactive' | translate }}"
                >
                  <div
                    class="icon-background-off icon-card font-size18 black"
                  ></div>
                  <div class="status-text-off">
                    {{ "Open any c." | translate }}
                  </div>
                </div>
              </ng-template>
            </ng-container>

            <!-- SECOND PROP -->
            <ng-container
              *ngIf="
                controllers
                  | findProperty
                    : Property.ACDoorOpenPushButton as doorOpenPushButtonProps
              "
            >
              <ng-container
                *ngIf="
                  doorOpenPushButtonProps | propIsActive;
                  else doorOpenPushButtonPropsInactive
                "
              >
                <div
                  class="status-container-small margin-left5 flex-row"
                  title="{{ 'Door open push button active' | translate }}"
                >
                  <div
                    class="icon-background-on icon-card font-size18 white"
                  ></div>
                  <div class="status-text-on">
                    {{ "Open push b." | translate }}
                  </div>
                </div>
              </ng-container>
              <ng-template #doorOpenPushButtonPropsInactive>
                <div
                  class="status-container-small margin-left5 flex-row"
                  title="{{ 'Door open push button inactive' | translate }}"
                >
                  <div
                    class="icon-background-off icon-card font-size18 black"
                  ></div>
                  <div class="status-text-off">
                    {{ "Open push b." | translate }}
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- COMPACT VIEW SIZE -->
  <!-- <ng-container *ngIf="roomViewSize === 'compact'">
    <div class="access-control-card-compact">
      <div
        [ngClass]="{
          'access-control-inactive-overlay-compact': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          'access-control-inactive-icon-compact': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: !controllers[0].isOnline
        }"
      >
        <div class="access-control-top-bar"></div>
        <div class="access-control-card-content-compact">
          <div class="status-container-full flex-row">
            <div class="access-control-name">{{ controllers[0].name }}</div>
          </div>

          <div class="flex-row width90 margin-top3 margin-left10">

            <ng-container
              *ngIf="
                controllers
                  | findProperty
                    : Property.ACDoorOpenAnyCard as doorOpenAnyCardProps
              "
            >
              <ng-container
                *ngIf="
                  doorOpenAnyCardProps | propIsActive;
                  else doorOpenAnyCardPropsInactive
                "
              >
                <div
                  class="status-container-medium-compact flex-row"
                  title="{{ 'Door open any card active' | translate }}"
                >
                  <div
                    class="icon-background-on icon-card font-size18 white"
                  ></div>
                  <div class="status-text-on">
                    {{ "Y" | translate }}
                  </div>
                </div>
              </ng-container>
              <ng-template #doorOpenAnyCardPropsInactive>
                <div
                  class="status-container-medium-compact flex-row"
                  title="{{ 'Door open any card inactive' | translate }}"
                >
                  <div
                    class="icon-background-off icon-card font-size18 black"
                  ></div>
                  <div class="status-text-off">
                    {{ "N" | translate }}
                  </div>
                </div>
              </ng-template>
            </ng-container>

            <ng-container
              *ngIf="
                controllers
                  | findProperty
                    : Property.ACDoorOpenPushButton as doorOpenPushButtonProps
              "
            >
              <ng-container
                *ngIf="
                  doorOpenPushButtonProps | propIsActive;
                  else doorOpenPushButtonPropsInactive
                "
              >
                <div
                  class="status-container-medium-compact flex-row"
                  title="{{ 'Door open push button active' | translate }}"
                >
                  <div
                    class="icon-background-on icon-card font-size18 white"
                  ></div>
                  <div class="status-text-on">
                    {{ "Y" | translate }}
                  </div>
                </div>
              </ng-container>
              <ng-template #doorOpenPushButtonPropsInactive>
                <div
                  class="status-container-medium-compact flex-row"
                  title="{{ 'Door open push button inactive' | translate }}"
                >
                  <div
                    class="icon-background-off icon-card font-size18 black"
                  ></div>
                  <div class="status-text-off">
                    {{ "N" | translate }}
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container> -->

  <!-- MINIMAL VIEW SIZE -->
  <ng-container *ngIf="roomViewSize === 'minimal'">
    <div class="access-control-card-minimal">
      <div
        [ngClass]="{
          'access-control-inactive-overlay-minimal': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          'access-control-inactive-icon-minimal': !controllers[0].isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: !controllers[0].isOnline
        }"
      >
        <div class="access-control-top-bar-minimal"></div>
        <div class="flex-row">
          <div class="access-control-name-box-minimal">
            {{ controllers[0].name }}
          </div>
          <ng-container
            *ngIf="
              controllers
                | findProperty : Property.isAnyAlarm
                | getActiveProps as activeAlarmProps
            "
          >
            <ng-container
              *ngIf="activeAlarmProps.length > 0; else noAlarmsActive"
              ><div class="margin-left5 icon-alert red"></div></ng-container
          ></ng-container>
          <ng-template #noAlarmsActive
            ><div class="margin-left5 icon-alert light-gray"></div></ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
