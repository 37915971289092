<div *ngIf="!singleRoom" class="content-header flex-row">
  <h1>{{'Access Log' | translate}}</h1>
  <div *ngIf="loading" class="loading-indicator"><div></div><div></div><div></div><div></div></div>
</div>

<div class="content-container">
  <div class="flex-row">

    <app-filter
      class="margin-right50"
      [inputDelay]="1500"
      (filteredValue)="searchFilterReady($event)">
    </app-filter>
    <div *ngIf="singleRoom" class="flex-row">
      <select [(ngModel)]="mainController" (ngModelChange)="controllerChanged($event)" >
      <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
      </select>
    </div>

    <app-date-time-from-to
      class="margin-right50"
      (selectedDateTimeFromTo)="setDateTimeFromTo($event)"
      ></app-date-time-from-to>

    <!-- <span class="icon-three-vert-dots font-size24 margin-top4 tooltip margin-right10" (click)="showAdditionalOptions=!showAdditionalOptions">
      <span class="bottom">{{'Additional options' | translate}}</span>
    </span>

    <span class="icon-filter font-size24  margin-top4 tooltip" (click)="additionalFilter=!additionalFilter">
      <span class="bottom">{{'Filtering options' | translate}}</span>
    </span> -->

    <app-paginator
      *ngIf="accessLogData.length > 0"
      class="to-right"
      [paginationData]="paginationData"
      (targetPage)="onSubmit($event)">
   </app-paginator>
  </div>

    <div *ngIf="showAdditionalOptions" class="flex-row mr-b">
      <label for="showArchive">
      <input
        class="mr-l-75 option-input-sm"
        type="checkbox"
        id="showArchive"
        name="showArchive"
        [(ngModel)]="queryParams.includeArchive"
        (change)="onSubmit()">
        <span>{{'Search archived logs' | translate}}</span></label>
      <label for="cardsWoRecords">
      <input
        class="option-input-sm mr-l-25"
        type="checkbox"
        id="cardsWoRecords"
        name="cardsWoRecords"
        [(ngModel)]="showOnlyCardsWithoutRecords"
        (change)="onSubmit()"
        >
        <span>{{'Search only cards without user' | translate}}</span></label>
    </div>

    <div *ngIf="additionalFilter" class="mr-t-30 mr-b-20 mr-l-15">
      <div class="flex-row flex-align-center br-b-s">
        <span>{{'Choose users to filter' | translate}}</span>
        <div class="flex-column">
          <input
            type="text"
            autocomplete="disabled"
            class="icon-search font-size18 mr-l-25 mr-t"
            placeholder="User"
            valueChangedDelay='500'
            [(ngModel)]="userFilteringSearchValue"
            name="userForFiltering"/>
        <div class="user-dropdown-wrapper">
          <div *ngIf="userFilteringSearchValue.length > 0" class="user-dropdown">
            <div class="content-list-row" *ngFor="let user of users | userfilter: userFilteringSearchValue">
              <div class="text-tooltip">
                <p (click)="usersFilterSelected(user)">{{user.fullName | textSizeFilterPipe : 30}}</p>
                <span class="bottom" *ngIf="user.fullName.length > 30">{{user.fullName}}</span>
              </div>
            </div>
          </div>
        </div>
       </div>
       <div class="mr-l-25">{{'Users in filter' | translate}}: </div>
       <div class="mr-l" *ngIf="usersInFilter.length===0">{{'Empty' | translate}}</div>
       <div *ngFor="let user of usersInFilter;let i = index" class="filter-box mr-l">
           {{ user.fullName }} <i class="icon-x font-size12 mr-l" (click)="onRemoveUserFromFilter(user)"></i>
       </div>
      </div>
      <ng-container *ngIf="(allControllersThin$ | async)?.controllers | getControllersForRoom: '-1': '-1': '-1' : null as controllers" >
      <div class="flex-row flex-align-center br-b-s margin-top10">
        <span>{{'Choose locations to filter' | translate}}</span>
        <div class="flex-column">
          <input
            type="text"
            autocomplete="disabled"
            class="icon-search font-size18 mr-l-25 mr-t"
            placeholder="Location"
            valueChangedDelay='500'
            [(ngModel)]="locationFilteringSearchValue"
            name="userForFiltering"/>
        <div class="user-dropdown-wrapper">
          <div *ngIf="locationFilteringSearchValue.length > 0" class="user-dropdown">
            <div class="content-list-row" *ngFor="let controller of controllers | locationNameFilter: locationFilteringSearchValue">
              <div class="text-tooltip">
                <p (click)="locationFilterSelected(controller)">{{controller.name | textSizeFilterPipe : 30}}</p>
                <span class="bottom" *ngIf="controller.name.length > 30">{{controller.name.fullName}}</span>
              </div>
            </div>
          </div>
        </div>
       </div>
       <div class="mr-l-25">{{'Locations in filter' | translate}}: </div>
       <div class="mr-l" *ngIf="locationsInFilter.length===0">{{'Empty' | translate}}</div>
       <div *ngFor="let controllerLoc of locationsInFilter;let i = index" class="filter-box mr-l">
           {{ controllerLoc.name }} <i class="icon-x font-size12 mr-l" (click)="onRemoveControllerLocationFromFilter(controllerLoc)"></i>
       </div>
      </div>
    </ng-container>

    </div>

<div *ngIf="accessLogData.length === 0 && !loading" >{{'No results found' | translate}}.</div>
<div *ngIf="singleRoom && loading" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>

<div class="margin-top30" *ngIf="accessLogData.length > 0">
  <table class="logs-table" >
    <thead>
      <tr>
        <th class="clickable" (click)="onSort('TIME')">
          <span>{{'Date and time' | translate}}</span>
          {{this.requestBody.sorting==='ASC|TIME'?"▲":""}}
          {{this.requestBody.sorting==='DSC|TIME'?"▼":""}}
        </th>

        <th>
          <span>{{'Card UID' | translate}}</span>
        </th>
        <th class="clickable"(click)="onSort('USER')">
          <span>User</span>
          {{this.requestBody.sorting==='ASC|USER'?"▲":""}}
          {{this.requestBody.sorting==='DSC|USER'?"▼":""}}

        </th>

        <th>
          <span >{{'Location name' | translate}}</span>
        </th>

        <th>
          <span >{{'Event status' | translate}}</span>
        </th>
        </tr>
    </thead>

    <tr *ngFor="let log of accessLogData">
      <td class="opacity0-4">{{log.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}</td>
      <td class="opacity0-4">{{log.uid}}</td>
      <td class="opacity0-4">
        <div class="text-tooltip">
          <p>{{log.userFullName | textSizeFilterPipe : 15}}</p>
        <span class="bottom" *ngIf="log.userFullName.length > 15">{{log.userFullName}}</span>
      </div>
      </td>
      <td class="opacity0-4">
        <div class="text-tooltip">
          <p>{{log.locationName | textSizeFilterPipe : 15}}</p>
          <span class="bottom" *ngIf="log.locationName.length > 15">{{log.locationName}}</span>
        </div>
      </td>
    <td >{{ getTextualValueFromStatus(log.eventStatus)}}</td>
    </tr>
  </table>

  <app-paginator class="flex-row from-back mr-t margin-top20 margin-bottom20"
    [paginationData]="paginationData"
    (targetPage)="onSubmit($event)">
  </app-paginator>
</div>

</div>
