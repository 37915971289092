<ng-container *ngIf="controllers | findProperty : propFilterFunction as props">
  <div
    *ngFor="let prop of props"
    class="status-container-small flex-row-center-v margin-bottom12 width200"
  >
    <ng-container *ngIf="prop | propIsActive; else propNotActive">
      <div [ngClass]="iconActive" class="icon-background-on font-size19"></div>
      <div class="status-text-on">{{ activeText | translate }}</div>
    </ng-container>
    <ng-template #propNotActive>
      <div
        [ngClass]="iconInactive"
        class="icon-background-off font-size19"
      ></div>
      <div class="status-text-off">{{ inactiveText | translate }}</div>
    </ng-template>
  </div>
</ng-container>
