import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Setting } from 'src/app/core/models/project/setting.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiLocationGroupsService, LocationGroup } from '../../services/api-location-groups.service';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { ButtonsSelectComponent } from '../buttons-select/buttons-select.component';
import { PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-room-modal-settings',
  templateUrl: './room-modal-settings.component.html',
  styleUrls: ['./room-modal-settings.component.scss']
})
export class RoomModalSettingsComponent implements OnInit {
  @Input() controllers: Controller[];
  isAccessControl = Controller.isAccessControl;
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;
  defaultSettings: DefaultsPreset[];
  defaultPresetsSubscription: Subscription;
  stopControllerPolling = false;
  controllerPollingSubscription: Subscription;

  locationCrossRef: LocationPresetCrossRef[] =[];
  defaultPresets: DefaultsPreset[] = [];
  hvacModeLocationGroups: LocationGroup[] = [];

  Property = Property;
  renderNumberInputs = true;

  constructor(
    private settingsService: SettingsService,
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private apiSettingsService: ApiSettingsService,
    private apiLocationGroupsService: ApiLocationGroupsService,
    private popoverController: PopoverController
    ) {}

  ngOnInit(): void {
    this.selectController(this.controllers[0]);
    this.apiSettingsService.getDefaultPresets().subscribe();
    this.defaultPresetsSubscription = this.settingsService.getDefaultPresets().subscribe( value => {
      this.defaultSettings = value;
    })
    this.isControllerPolled();
    this.getLocationPreseCrossRef();
    this.getDefaultPresets();
    this.getHvacModeLocationGroups();
  }

  isControllerPolled() {
    this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
          && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    })
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( () => {
      this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    })
  }

  async changeResetDefaultPropertiesOnCheckout(event: any) {
    if (event.target.checked) {
      /* const HC = this.selectedControllers[0].controllerProperties.$values.find( prop => Property.isHvacHeatingCooling(prop));
      const heating = this.defaultSettings.find( setting => setting._id === 'heating');
      const cooling = this.defaultSettings.find( setting => setting._id === 'cooling');
      if (HC && +HC.value === 1 && !cooling) {
        const toast = await this.toastController.create({
          header: 'No preset for active mode.',
          message: 'Create a preset to apply settings',
          position: 'middle',
          buttons: [{
              text: 'OK',
              role: 'cancel'
            }
          ]
        });
        await toast.present();
      } else if (HC && +HC.value === 0 && !heating) {
        const toast = await this.toastController.create({
          header: 'No preset for active mode.',
          message: 'Create a preset to apply settings',
          position: 'middle',
          buttons: [{
              text: 'OK',
              role: 'cancel'
            }
          ]
        });
        await toast.present();
      } */
      this.selectedControllers[0].restoreDefaultOnCheckout = true;
      this.apiProjectService.updateControllers([this.selectedControllers[0]]).subscribe();
    } else {
      this.selectedControllers[0].restoreDefaultOnCheckout = false;
      this.apiProjectService.updateControllers([this.selectedControllers[0]]).subscribe();
    }
  }

  getLocationPreseCrossRef() {
    this.apiSettingsService.getLocationPresetCrossReferences().subscribe( value => {
      this.locationCrossRef = value;
    })
  }

  getDefaultPresets() {
    this.apiSettingsService.getDefaultPresets().subscribe( value => {
      this.defaultPresets = value;
    })
  }

  getHvacModeLocationGroups() {
    this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.HvacModeGroup).subscribe( value => {
      this.hvacModeLocationGroups = value;
    })
  }

  armRoom() {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isRoomArmed);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    const value = 1;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  resetMinibar() {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isMinibarWasOpenedStatus);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    const value = 1;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  setHvacMode(hvacModeNewValue: 0 | 1 | 2) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatCool);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, hvacModeNewValue)
      .subscribe();
  }

  async openPopoverToSelectPreset(ev) {
    const popover = await this.popoverController.create({
      component: ButtonsSelectComponent,
      cssClass: 'button-select',
      showBackdrop: false,
      event: ev,
      componentProps: {
        locationCrossRef: this.locationCrossRef,
        defaultPresets: this.defaultPresets,
        hvacModeLocationGroups: this.hvacModeLocationGroups,
        controller: this.selectedControllers[0]
      }
    });
    await popover.present();

    popover.onDidDismiss().then( (value) => {
      this.apiSettingsService.activateDefaultPreset(value.data, this.selectedControllers[0].designation, true).subscribe()
    })

}


  /* setDefaultPreset(preset: DefaultsPreset, propertyTypes: PropertyType[]) {
    const defaultProps = preset.properties.map((propSetting) => {
      const propType: any = propertyTypes.find((pType) => {
        return (
          propSetting.type >= pType.CodeFrom &&
          propSetting.type <= pType.CodeTo
        );
      });
      propType.value = propSetting.value;
      return propType;
    });
    this.controllers.forEach((controller) => {
      const defaultsToSend = controller.properties.reduce((defPropsToSend, prop) => {
          const defaultForProp = defaultProps.find((def) => {
            return prop.type >= def.CodeFrom && prop.type <= def.CodeTo;
          });
          if (defaultForProp) {
            defPropsToSend.push({
              typeCode: prop.id, // TODO change typecode to prop id
              value: defaultForProp.value,
            });
          }
        return defPropsToSend;
        },
        []
      );
      this.apiProjectService.changeMultiplePropertiesByLocation(controller.locationId, defaultsToSend).subscribe();
    });
  } */

  selectController(controller: Controller) {
    this.renderNumberInputs = false;
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
    setTimeout(() => { // render is reset to reinit child components and restart ngoinit and ngodestroy of child components
      this.renderNumberInputs = true;
    }, 0);

  }


  isPropertyInController(target: any) {
    let property: Property;
    const active: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(target);
        return property;
      }
    );

    return active
  }

  isSettingInController(target: any) {
    let setting: Setting;
    const active: Controller = this.controllers.find(
      (controller: Controller) => {
        setting = controller.controllerSettings.$values.find(target);
        return setting;
      }
    );

    if(setting && setting.value === '1') {
      return false;
    } else {
      return active
    }
  }

  syncHvac() {
    this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
  }

  changeControllerPooling(event: any) {
    const contToSend = structuredClone(this.controllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  onDestroy(){
    if (this.defaultPresetsSubscription) {
      this.defaultPresetsSubscription.unsubscribe();
    }
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }

  }


}
